import axios, { routePathAPI } from '../../plugins/axios.js'
import router from '../../router'

// initial state
const state = () => ({
  isUserLoggedIn: false,
})

// getters
const getters = {
  isUserLoggedIn(state) {
    if (state.isUserLoggedIn === false) {
      return (
        localStorage.getItem('inresolve.accessToken') !== null &&
        localStorage.getItem('inresolve.refreshToken') !== null
      )
    }
    return state.isUserLoggedIn
  },
  accessToken(state) {
    return localStorage.getItem('inresolve.accessToken') || null
    // return state.isUserLoggedIn
  },
  refreshToken(state) {
    return localStorage.getItem('inresolve.refreshToken') || null
    // return state.isUserLoggedIn
  },
  activeUser(state) {
    return JSON.parse(localStorage.getItem('inresolve.activeUser')) || null
  },
  activeEmail(state) {
    return localStorage.getItem('inresolve.activeEmail') || null
  },
}

// actions
const actions = {
  setActiveUser({ commit, state }, activeUser) {
    return commit('SET_ACTIVE_USER', activeUser)
  },
  setActiveEmail({ commit, state }, activeEmail) {
    return commit('SET_ACTIVE_EMAIL', activeEmail)
  },
  resetActiveEmail({ commit, state }) {
    return commit('REMOVE_ACTIVE_EMAIL')
  },
  updateLoginState({ commit, state }, loggedIn) {
    return commit('SET_IS_USER_LOGGED_IN', loggedIn)
  },
  register({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axios
        .post(
          `${routePathAPI()}/auth/register`,
          {
            first_name: payload.first_name,
            last_name: payload.last_name,
            username: payload.username ? payload.username : null,
            email: payload.email,
            phone: payload.phone,
            password: payload.password,
            oauth_id: payload.oauth_id ? payload.oauth_id : null,
            avatar: payload.avatar ? payload.avatar : null,
          },
          {
            headers: {
              'x-language-code': currentLanguageCode,
              platform: import.meta.env.VITE_PLATFORM || null,
              device: import.meta.env.VITE_DEVICE || null,
            },
          }
        )
        .then(async (response) => {
          if (response.status === 200) {
            // // SET TOKEN
            // const token = response.data.data.token
            // const accessToken = token.access_token
            // const refreshToken = token.refresh_token

            // const tokenType = 'Bearer'
            // axios.defaults.headers.common[
            //   'Authorization'
            // ] = `${tokenType} ${accessToken}`

            // await commit('SET_ACCESS_TOKEN', accessToken)
            // await commit('SET_REFRESH_TOKEN', refreshToken)

            // // SET ACTIVE USER
            // const user = response.data.data.user
            // commit('SET_ACTIVE_USER', user)

            resolve(response.data)
          } else {
            reject(response.data)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  login({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axios
        .post(
          `${routePathAPI()}/auth/login`,
          {
            email: payload.email,
            password: payload.password,
          },
          {
            headers: {
              'x-language-code': currentLanguageCode,
              platform: import.meta.env.VITE_PLATFORM || null,
              device: import.meta.env.VITE_DEVICE || null,
            },
          }
        )
        .then(async (response) => {
          if (response.status === 200) {
            // SET TOKEN
            // const token = response.data.data.token
            // const accessToken = token.access_token
            // const refreshToken = token.refresh_token

            // const tokenType = 'Bearer'
            // axios.defaults.headers.common[
            //   'Authorization'
            // ] = `${tokenType} ${accessToken}`

            // await commit('SET_ACCESS_TOKEN', accessToken)
            // await commit('SET_REFRESH_TOKEN', refreshToken)

            // // SET ACTIVE USER
            // const user = response.data.data.user
            // commit('SET_ACTIVE_USER', user)

            resolve(response.data)
          } else {
            reject(response.data)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  resendOTP({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axios
        .post(
          `${routePathAPI()}/auth/resend`,
          {
            email: payload.email,
          },
          {
            headers: {
              'x-language-code': currentLanguageCode,
              platform: import.meta.env.VITE_PLATFORM || null,
              device: import.meta.env.VITE_DEVICE || null,
            },
          }
        )
        .then(async (response) => {
          if (response.status === 200) {
            resolve(response.data)
          } else {
            reject(response.data)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  verifyOTP({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axios
        .post(
          `${routePathAPI()}/auth/verify`,
          {
            email: payload.email,
            otp: payload.otp,
          },
          {
            headers: {
              'x-language-code': currentLanguageCode,
              platform: import.meta.env.VITE_PLATFORM || null,
              device: import.meta.env.VITE_DEVICE || null,
            },
          }
        )
        .then(async (response) => {
          if (response.status === 200) {
            // SET TOKEN
            const token = response.data.data.token
            const accessToken = token.access_token
            const refreshToken = token.refresh_token

            const tokenType = 'Bearer'
            axios.defaults.headers.common[
              'Authorization'
            ] = `${tokenType} ${accessToken}`

            await commit('SET_ACCESS_TOKEN', accessToken)
            await commit('SET_REFRESH_TOKEN', refreshToken)

            // SET ACTIVE USER
            const user = response.data.data.user
            commit('SET_ACTIVE_USER', user)
            commit('REMOVE_ACTIVE_EMAIL')

            resolve(response.data)
          } else {
            reject(response.data)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  logout({ commit }, payload) {
    commit('SET_IS_USER_LOGGED_IN', false)
    commit('REMOVE_ACCESS_TOKEN')
    commit('REMOVE_REFRESH_TOKEN')
    commit('REMOVE_ACTIVE_USER')
    return true
  },
}

// mutations
const mutations = {
  SET_ACCESS_TOKEN(state, accessToken) {
    localStorage.setItem('inresolve.accessToken', accessToken)
  },
  SET_REFRESH_TOKEN(state, refreshToken) {
    localStorage.setItem('inresolve.refreshToken', refreshToken)
  },
  SET_ACTIVE_USER(state, user) {
    localStorage.setItem('inresolve.activeUser', JSON.stringify(user))
  },
  SET_ACTIVE_EMAIL(state, activeEmail) {
    localStorage.setItem('inresolve.activeEmail', activeEmail)
  },
  SET_IS_USER_LOGGED_IN(state, isUserLoggedIn) {
    state.isUserLoggedIn = isUserLoggedIn
  },
  REMOVE_ACCESS_TOKEN(state) {
    localStorage.removeItem('inresolve.accessToken')
  },
  REMOVE_REFRESH_TOKEN(state) {
    localStorage.removeItem('inresolve.refreshToken')
  },
  REMOVE_ACTIVE_USER(state) {
    localStorage.removeItem('inresolve.activeUser')
  },
  REMOVE_ACTIVE_EMAIL(state) {
    localStorage.removeItem('inresolve.activeEmail')
  },
  UPDATE_USER_INFO(state, payload) {
    // Get Data localStorage
    const userInfo =
      JSON.parse(localStorage.getItem('userInfo')) || state.AppActiveUser

    for (const property of Object.keys(payload)) {
      if (payload[property] !== null) {
        // If some of user property is null - user default property defined in state.AppActiveUser
        state.AppActiveUser[property] = payload[property]

        // Update key in localStorage
        userInfo[property] = payload[property]
      }
    }
    // Store data in localStorage
    localStorage.setItem('userInfo', JSON.stringify(userInfo))
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
