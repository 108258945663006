<template>
  <div class="flex h-screen overflow-hidden">
    <!-- Sidebar -->
    <Sidebar
      :sidebar-open="sidebarOpen"
      :case-state="$route.query.status"
      @close-sidebar="sidebarOpen = false"
    />

    <!-- Content area -->
    <div
      class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden"
    >
      <!-- Site header -->
      <!-- <Header
          :sidebarOpen="sidebarOpen"
          @toggle-sidebar="sidebarOpen = !sidebarOpen"
        /> -->

      <main>
        <div class="max-w-9xl mx-auto w-full px-4 py-8 sm:px-6 lg:px-8">
          <div class="flex w-full flex-col">
            <h1 class="text-2xl font-bold text-black">
              <span
                class="cursor-pointer hover:text-primary"
                @click="
                  $router.push({
                    name: 'documents',
                    params: { slug: $route.params.slug },
                    query: {
                      name: $route.query.name,
                      status: $route.query.status,
                      sub_status: $route.query.sub_status,
                      lang: $route.query.lang,
                    },
                  })
                "
              >
                {{ $t('document.title') }}
              </span>
              <span class="text-gray-500"> / {{ folderDetail.name }}</span>
            </h1>
            <p>{{ $t('folder.description') }} {{ folderDetail.name }}</p>
          </div>
          <div class="my-4 flex w-full justify-between">
            <SearchForm
              :placeholder="$t('document.searchPlaceholder')"
              @search="searchDocument"
            />
            <ButtonAdd
              :title="$t('document.newFile')"
              custom-class="rounded-xl"
              @click.stop="newFileModalOpen = true"
            />
          </div>

          <div v-if="documents.length > 0 && !isLoading" class="w-full pb-20">
            <Table :header-data="headerData" tbody-class="mt-4"> </Table>
            <div v-for="document in documents" :key="document.id">
              <DocumentItem
                :document="document"
                class="mt-2 py-6"
                @info-document="infoDocument($event)"
                @edit-document="editDocument($event)"
                @remove-document="deleteDocument($event)"
              />
            </div>
          </div>
          <DualRingLoader v-else-if="isLoading" />
          <NoData v-else />
        </div>
      </main>

      <ModalDocumentInfo
        :is-open="infoDocumentModalOpen"
        :document="activeDocument"
        @closed="infoDocumentModalOpen = false"
      />

      <ModalDelete
        :id="activeDocument.id"
        :is-open="deleteDocumentModalOpen"
        :title="$t('document.modalRemoveFile.title')"
        :description="$t('document.modalRemoveFile.description')"
        @closed="deleteDocumentModalOpen = false"
        @delete="deleteDocumentAPI($event)"
      />

      <ModalCreateFile
        :is-open="editDocumentModalOpen"
        :share-with="caseParties"
        :is-edit="true"
        :document="activeDocument"
        :from-folder="true"
        :me="activeUser"
        :folders="folders"
        :active-folder="$route.params.folderId"
        @closed="editCloseModal"
        @save-file="saveFile"
        @update-file="updateFile"
      />
      <ModalCreateFile
        :is-open="newFileModalOpen"
        :share-with="caseParties"
        :from-folder="true"
        :me="activeUser"
        :active-folder="$route.params.folderId"
        @closed="newFileModalOpen = false"
        @save-file="saveFile"
      />
    </div>

    <RightSidebar
      :sidebar-open="sidebarOpen"
      @close-sidebar="sidebarOpen = false"
    />
  </div>
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import globalMixin from '@/mixins/global.js'
import caseMixin from '@/mixins/case.js'
import documentMixin from '@/mixins/document.js'

import Sidebar from '@/partials/Sidebar.vue'
import RightSidebar from '@/partials/RightSidebar.vue'
import SearchForm from '@/components/SearchForm.vue'
import Table from '@/components/Table.vue'
import DocumentItem from '@/components/DocumentItem.vue'
import ButtonAdd from '@/components/ButtonAdd.vue'

import ModalCreateFile from '@/components/ModalCreateFile.vue'
import ModalDocumentInfo from '@/components/modal/document/ModalDocumentInfo.vue'
import ModalDelete from '@/components/ModalDelete.vue'
import NoData from '@/components/NoData.vue'
import DualRingLoader from '@/components/DualRingLoader.vue'

export default {
  name: 'DocumentPage',
  components: {
    Sidebar,
    RightSidebar,
    SearchForm,
    Table,
    NoData,
    DocumentItem,
    ButtonAdd,
    ModalDocumentInfo,
    ModalCreateFile,
    ModalDelete,
    DualRingLoader,
  },
  mixins: [globalMixin, caseMixin, documentMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const toast = useToast()
    const sidebarOpen = ref(false)
    const headerData = ref([
      {
        name: t('document.headerTable.name'),
        colspan: 1,
      },
      {
        name: t('document.headerTable.owner'),
        colspan: 1,
      },
      {
        name: t('document.headerTable.type'),
        colspan: 1,
      },
    ])

    return {
      t,
      store,
      toast,
      sidebarOpen,
      headerData,
    }
  },
  mounted() {
    this.isInsideFolder = true

    this.activeUser = this.store.getters['auth/activeUser']
    this.getFolderDocument()
    this.getDetailById()
    this.getFolders()
  },
}
</script>
