import axios, { routePathAPI } from '@/plugins/axios.js'
import router from '@/router'

// initial state
const state = () => ({})

// getters
const getters = {}

// actions
const actions = {
  createNote({ state, commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {
        title: payload.title,
        content: payload.content,
        is_pinned: payload.is_pinned,
      }

      axios
        .post(`${routePathAPI()}/notebook/${payload.case_id}/create`, params, {
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateNote({ state, commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {
        title: payload.title,
        content: payload.content,
        is_pinned: payload.is_pinned,
      }

      axios
        .put(`${routePathAPI()}/notebook/update/${payload.id}`, params, {
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteNote({ state, commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axios
        .delete(`${routePathAPI()}/notebook/delete/${payload.id}`, {
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getNoteDetail({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axios
        .get(`${routePathAPI()}/notebook/detail/${payload.id}`, {
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getAllNote({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {}
      if (payload.page) params.page = payload.page
      if (payload.take) params.take = payload.take
      if (payload.order) params.order = payload.order
      if (payload.q) params.q = payload.q
      if (payload.is_deleted) params.is_deleted = payload.is_deleted
      if (payload.is_pinned) params.is_pinned = payload.is_pinned

      axios
        .get(`${routePathAPI()}/notebook/${payload.case_id}`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
