<template>
  <div class="-m-1.5 flex flex-wrap items-center">
    <div class="m-1.5">
      <ModalBasic
        id="notes-modal"
        :modal-open="isOpen"
        :show-border-header="false"
        :title="
          isEdit
            ? isFolder
              ? $t('document.modalEditFile.titleFolder')
              : $t('document.modalEditFile.title')
            : isFolder
            ? $t('document.modalCreateFile.titleFolder')
            : $t('document.modalCreateFile.title')
        "
        @close-modal="close"
      >
        <Form
          ref="form"
          v-slot="{ errors }"
          :validation-schema="
            !isFolder
              ? isEdit
                ? updateDocumentSchema
                : newDocumentSchema
              : newFolderSchema
          "
          @submit="save"
        >
          <div class="px-5">
            <div class="space-y-3">
              <div>
                <label class="mb-1 block text-xs font-bold" for="name">
                  <span v-if="isFolder">
                    {{ $t('document.modalCreateFile.folderName') }}
                  </span>
                  <span v-else>
                    {{ $t('document.modalCreateFile.fileName') }}
                  </span>
                </label>
                <Field v-slot="{ field }" v-model="name" name="name">
                  <input
                    id="name"
                    v-bind="field"
                    class="form-input w-full p-2"
                    type="text"
                    required
                    :placeholder="
                      $t('document.modalCreateFile.fileNamePlaceholder')
                    "
                  />
                </Field>
                <div v-if="errors.name !== undefined" class="flex w-full">
                  <span class="text-sm text-red-500">
                    {{ $t(errors.name) }}
                  </span>
                </div>
              </div>
              <div v-if="!isFolder && (!fromFolder || isEdit)">
                <label class="mb-1 block text-xs font-bold" for="folders">
                  {{ $t('document.modalCreateFile.folder') }}
                </label>
                <select
                  id="folders"
                  v-model="folderId"
                  class="form-select w-full p-2"
                  @change="checkFolder"
                >
                  <option value="" class="text-gray-100">
                    {{ $t('document.modalCreateFile.withoutFolder') }}
                  </option>
                  <option
                    v-for="folder in folders"
                    :key="folder.id"
                    :value="folder.id"
                  >
                    {{ folder.name }}
                  </option>
                </select>
              </div>
              <div v-if="fromFolder">
                <input
                  id="folders"
                  v-model="folderId"
                  type="text"
                  class="form-select hidden w-full p-2"
                />
              </div>
              <div v-if="showShareWith" :class="[fromFolder ? 'hidden' : '']">
                <label class="mb-1 block text-xs font-bold" for="shareWith">
                  {{ $t('document.modalCreateFile.shareWith') }}
                </label>
                <VueMultiselect
                  v-model="partyId"
                  :options="shareWith.filter((item) => item.id !== me.id)"
                  :multiple="true"
                  :close-on-select="true"
                  :placeholder="$t('validation.selectShareWith')"
                  label="name"
                  track-by="id"
                >
                </VueMultiselect>
              </div>
              <div v-show="!isFolder">
                <label class="mb-1 block text-xs font-bold" for="fileTypes">
                  {{ $t('document.modalCreateFile.fileType') }}
                </label>
                <select
                  id="fileTypes"
                  v-model="fileTypeId"
                  class="form-select w-full p-2"
                >
                  <option
                    v-for="fileType in fileTypes"
                    :key="fileType.id"
                    :value="fileType.id"
                  >
                    {{ fileType.name }}
                  </option>
                </select>
              </div>
              <div v-if="!isFolder && !isEdit">
                <label class="mb-1 block text-xs font-bold" for="files">
                  {{ $t('forms.uploadFile') }}
                </label>
                <div class="flex w-full items-center justify-center">
                  <Field v-model="attachment" name="attachment">
                    <label
                      for="files"
                      class="flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-gray-300 bg-gray-50 hover:bg-gray-100"
                    >
                      <div
                        class="flex flex-col items-center justify-center py-2"
                      >
                        <svg
                          aria-hidden="true"
                          class="mb-3 h-10 w-10 text-gray-400"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                          ></path>
                        </svg>
                        <p class="text-sm text-gray-500">
                          {{ $t('forms.dragAndDrop') }}
                        </p>
                        <p class="text-sm text-gray-500">
                          {{ $t('forms.or') }}
                        </p>
                        <p class="text-sm font-semibold text-primary">
                          {{ $t('forms.browseFiles') }}
                        </p>
                      </div>
                      <input
                        id="files"
                        type="file"
                        class="hidden"
                        @change="fileInput"
                      />
                      <span v-if="attachment">{{ attachment.name }}</span>
                    </label>
                  </Field>
                </div>
                <div
                  v-if="errors.attachment !== undefined"
                  class="flex w-full pb-4"
                >
                  <span class="pl-3 text-sm text-red-500">
                    {{ $t(errors.attachment) }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="py-4">
            <div class="flex flex-wrap justify-center space-x-2 px-4">
              <button
                v-if="!isEdit"
                type="submit"
                class="btn-sm hover:bg-primary-600 w-full whitespace-nowrap rounded-lg bg-primary p-3 text-white"
              >
                <span v-if="isFolder">
                  {{ $t('document.modalCreateFile.createFolder') }}
                </span>
                <span v-else>
                  {{ $t('document.modalCreateFile.uploadFile') }}
                </span>
              </button>
              <button
                v-else
                type="submit"
                class="btn-sm hover:bg-primary-600 w-full whitespace-nowrap rounded-lg bg-primary p-3 text-white"
              >
                <span v-if="isFolder">
                  {{ $t('document.modalCreateFile.updateFolder') }}
                </span>
                <span v-else>
                  {{ $t('document.modalCreateFile.updateFile') }}
                </span>
              </button>
            </div>
          </div>
        </Form>
      </ModalBasic>
    </div>
  </div>
</template>
<script>
import ModalBasic from '@/components/ModalBasic.vue'
import { Form, Field } from 'vee-validate'
import * as Yup from 'yup'

export default {
  name: 'ModalCreateFileComponent',
  components: {
    ModalBasic,
    Form,
    Field,
  },
  inject: ['checkEmptyObject'],
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    isFolder: {
      type: Boolean,
      default: false,
    },
    fromFolder: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    document: {
      type: Object,
      default: () => {
        return {}
      },
    },
    shareWith: {
      type: Array,
      default: () => {
        return []
      },
    },
    folders: {
      type: Array,
      default: () => {
        return []
      },
    },
    activeFolder: {
      type: String,
      default: '',
    },
  },
  emits: [
    'closed',
    'save-folder',
    'save-file',
    'empty-member',
    'update-folder',
    'update-file',
  ],
  data: () => ({
    name: '',
    partyId: [],
    fileTypeId: '',
    folderId: '',
    attachment: null,
    showShareWith: true,
    fileTypes: [],
    filterDataFileTypes: {
      page: 1,
      take: 50,
      search: '',
      order: 'DESC',
    },
    metaDataFileTypes: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    updateDocumentSchema: Yup.object().shape({
      name: Yup.string()
        .typeError('validation.enterFileName')
        .required('validation.enterFileName'),
    }),
    newDocumentSchema: Yup.object().shape({
      name: Yup.string()
        .typeError('validation.enterFileName')
        .required('validation.enterFileName'),
      attachment: Yup.mixed().required('validation.attachmentRequired'),
    }),
    newFolderSchema: Yup.object().shape({
      name: Yup.string()
        .typeError('validation.enterFolderName')
        .required('validation.enterFolderName'),
    }),
    me: null,
  }),
  watch: {
    isOpen(val) {
      if (!val) this.close()
      // else this.$refs.form.resetForm()
    },
    document(val) {
      if (!this.checkEmptyObject(val)) {
        this.name = val.name
        this.partyId = val.shares.map((item) => ({
          id: item.user.id,
          name: `${item.user.first_name} ${item.user.last_name}`,
        }))
      }
    },
  },
  created() {
    if (this.document.type !== 'Folder') {
      this.getFileTypes()
    }
    this.me = this.$store.getters['auth/activeUser']
    if (this.fromFolder) this.folderId = this.activeFolder
  },
  methods: {
    close() {
      this.$refs.form.resetForm()
      this.partyId = []
      this.$emit('closed')
    },
    save() {
      const payload = {
        name: this.name,
        party_id: this.partyId.map((item) => item.id),
        file_type_id: this.fileTypeId,
        folder_id: this.folderId,
        attachment: this.attachment,
      }
      if (this.isEdit) {
        payload.id = this.document.id
        if (this.isFolder) {
          this.$emit('update-folder', payload)
        } else {
          this.$emit('update-file', payload)
        }
      } else {
        if (this.isFolder) {
          this.$emit('save-folder', payload)
        } else {
          this.$emit('save-file', payload)
        }
      }
    },
    fileInput(event) {
      this.attachment = event.target.files[0]
    },
    async getFileTypes() {
      this.isLoading = true
      await this.$store
        .dispatch('document/getFileTypes', {
          order: this.filterDataFileTypes.order,
          page: this.filterDataFileTypes.page,
          take: this.filterDataFileTypes.take,
          q: this.filterDataFileTypes.search,
        })
        .then((response) => {
          this.isLoading = false
          const responseData = response.data.data
          if (responseData.data) {
            this.fileTypes = responseData.data
            this.fileTypeId = this.fileTypes[0].id
          }
          if (responseData.meta) {
            this.metaDataFileTypes = responseData.meta
          }
        })
        .catch((error) => {
          this.isLoading = false
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    checkFolder() {
      this.showShareWith = this.folderId === ''
    },
  },
}
</script>
