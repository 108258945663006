<template>
  <div class="flex">
    <button
      v-for="i in totalRating"
      :key="i"
      type="button"
      :class="i < totalRating ? 'mr-1' : ''"
      @click="$emit('input', i)"
    >
      <svg
        class="block"
        :class="[
          value >= i ? 'text-yellow-400' : 'text-gray-400',
          canHover ? 'hover:text-yellow-400' : '',
          customClass !== '' ? customClass : 'h-10 w-10',
        ]"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path
          d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z"
        />
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: 'StarRating',
  props: {
    totalRating: {
      type: Number,
      required: true,
    },
    value: {
      type: Number,
      default: 0,
    },
    canHover: {
      type: Boolean,
      default: true,
    },
    customClass: {
      type: String,
      default: '',
    },
  },
}
</script>
