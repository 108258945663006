<template>
  <div class="flex h-screen overflow-hidden">
    <div
      class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden"
    >
      <!-- Site header -->
      <Header
        :sidebar-open="sidebarOpen"
        @toggle-sidebar="sidebarOpen = !sidebarOpen"
      />

      <main>
        <div class="w-full">
          <div class="mt-10 flex h-full flex-col justify-center bg-gray-100">
            <BreadcrumNewCase :active="1" :is-show="true" />
            <div
              class="w-full self-center rounded-lg bg-white px-8 py-8 shadow-md md:w-4/5"
            >
              <h1 class="mb-2 text-3xl font-bold text-slate-800">
                {{ $t('newCase.title') }}
              </h1>
              <p class="text-sm">
                {{ $t('newCase.description') }}
              </p>
              <Form
                v-slot="{ errors }"
                :validation-schema="newCaseSchema"
                @submit="createComplain"
              >
                <div class="mt-4 space-y-6">
                  <div>
                    <label
                      class="mb-1 block text-sm font-medium"
                      for="caseName"
                    >
                      {{ $t('newCase.caseName') }}
                    </label>
                    <Field
                      v-slot="{ field }"
                      v-model="caseName"
                      name="caseName"
                    >
                      <input
                        id="caseName"
                        v-bind="field"
                        class="form-input h-12 w-full rounded-md"
                        :class="[
                          errors.caseName !== undefined
                            ? 'focus:border-red-500'
                            : '',
                        ]"
                        type="text"
                        :placeholder="$t('newCase.caseNamePlaceholder')"
                      />
                    </Field>
                    <span class="text-sm text-red-500">
                      <template v-if="errors.caseName !== undefined">
                        {{ $t(errors.caseName) }}
                      </template>
                    </span>
                  </div>

                  <div class="grid grid-cols-2 gap-2">
                    <div>
                      <label
                        class="mb-1 block text-sm font-medium"
                        for="typeOfPart"
                      >
                        {{ $t('newCase.typeOfPart') }}
                      </label>
                      <select
                        id="typeOfPart"
                        v-model="typeOfPart"
                        class="form-select w-full p-2"
                      >
                        <option
                          v-for="caseType in caseTypes"
                          :key="caseType.id"
                          :value="caseType.id"
                        >
                          {{ caseType.name }}
                        </option>
                      </select>
                    </div>
                    <div>
                      <label
                        class="mb-1 block text-sm font-medium"
                        for="caseArea"
                      >
                        {{ $t('newCase.caseArea') }}
                      </label>
                      <select
                        id="caseFcaseArearom"
                        v-model="caseArea"
                        class="form-select w-full p-2"
                      >
                        <option
                          v-for="caseArea in caseAreas"
                          :key="caseArea.id"
                          :value="caseArea.id"
                        >
                          {{ caseArea.name }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div>
                    <label
                      class="mb-1 block text-sm font-medium"
                      for="caseFrom"
                    >
                      {{ $t('newCase.caseFrom') }}
                    </label>
                    <select
                      id="caseFrom"
                      v-model="caseFrom"
                      class="form-select w-full p-2"
                    >
                      <option
                        v-for="from in caseFroms"
                        :key="from.id"
                        :value="from.id"
                      >
                        {{ from.name }}
                      </option>
                    </select>
                  </div>

                  <div>
                    <label
                      class="mb-1 block text-sm font-medium"
                      for="caseWhen"
                    >
                      {{ $t('newCase.caseWhen') }}
                    </label>
                    <Datepicker
                      v-model="caseDate"
                      wrap-class="form-input h-12 w-full rounded-md pl-9"
                      mode="single"
                      @change-date="changeDate"
                    />
                  </div>

                  <div>
                    <label
                      class="mb-1 block text-sm font-medium"
                      for="caseDescription"
                    >
                      {{ $t('newCase.caseDescription') }}
                    </label>
                    <Field
                      v-slot="{ field }"
                      v-model="caseDescription"
                      name="caseDescription"
                    >
                      <textarea
                        id="caseDescription"
                        v-bind="field"
                        class="form-input h-20 w-full rounded-md"
                        :class="[
                          errors.caseDescription !== undefined
                            ? 'focus:border-red-500'
                            : '',
                        ]"
                        :placeholder="$t('newCase.caseDescriptionPlaceholder')"
                      ></textarea>
                    </Field>
                    <span class="text-sm text-red-500">
                      <template v-if="errors.caseDescription !== undefined">
                        {{ $t(errors.caseDescription) }}
                      </template>
                    </span>
                  </div>

                  <div>
                    <label
                      class="mb-1 block text-sm font-medium"
                      for="caseHowMuch"
                    >
                      {{ $t('newCase.caseHowMuch') }}
                    </label>
                    <div class="flex w-full">
                      <div class="w-10/12 pr-4">
                        <Field
                          v-slot="{ field }"
                          v-model="caseAmount"
                          name="caseAmount"
                        >
                          <input
                            id="caseHowMuch"
                            v-bind="field"
                            class="form-input h-12 w-full rounded-md"
                            :class="[
                              errors.caseAmount !== undefined
                                ? 'focus:border-red-500'
                                : '',
                            ]"
                            type="number"
                            :placeholder="$t('newCase.caseHowMuchPlaceholder')"
                          />
                        </Field>
                        <span class="text-sm text-red-500">
                          <template v-if="errors.caseAmount !== undefined">
                            {{ $t(errors.caseAmount) }}
                          </template>
                        </span>
                      </div>
                      <div class="w-2/12">
                        <select
                          v-model="caseCurrency"
                          class="form-input h-12 w-full"
                        >
                          <option
                            v-for="currency in caseCurrencies"
                            :key="currency.id"
                            :value="currency.id"
                          >
                            {{ currency.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div>
                    <label class="mb-1 block text-sm font-medium" for="files">
                      {{ $t('modal.attachment') }}
                      ({{ $t('general.optional') }})
                    </label>
                    <div class="flex w-full items-center justify-center">
                      <label
                        for="files"
                        class="flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-gray-300 bg-gray-50 hover:bg-gray-100"
                      >
                        <div
                          class="flex flex-col items-center justify-center py-2"
                        >
                          <svg
                            aria-hidden="true"
                            class="mb-3 h-10 w-10 text-gray-400"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                            ></path>
                          </svg>
                          <p class="text-sm text-gray-500">
                            {{ $t('forms.dragAndDrop') }}
                          </p>
                          <p class="text-sm text-gray-500">
                            {{ $t('forms.or') }}
                          </p>
                          <p class="text-sm font-semibold text-primary">
                            {{ $t('forms.browseFiles') }}
                          </p>
                        </div>
                        <input
                          id="files"
                          type="file"
                          class="hidden"
                          @change="fileInput"
                        />
                        <span v-if="attachment !== null">{{
                          attachment.name
                        }}</span>
                      </label>
                    </div>
                  </div>

                  <div>
                    <label
                      class="mb-1 block text-sm font-medium"
                      for="caseNotification"
                    >
                      {{ $t('newCase.caseNotification') }}
                    </label>
                    <select
                      id="caseNotification"
                      v-model="caseNotification"
                      class="form-select w-full p-2"
                    >
                      <option
                        v-for="from in caseNotifications"
                        :key="from.id"
                        :value="from.id"
                      >
                        {{ $t(`newCase.${from.name}`) }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="mt-5 flex flex-col">
                  <div class="flex w-full cursor-pointer text-justify">
                    <input
                      v-model="caseCheck"
                      type="checkbox"
                      class="form-input h-5 checked:bg-primary focus:bg-primary"
                    />
                    <p class="pl-2">
                      <span>{{ $t('newCase.bySubmit') }}</span>
                      <router-link
                        class="px-1 text-sm font-bold text-primary"
                        to="/register"
                      >
                        {{ $t('newCase.terms') }}
                      </router-link>
                      <span>{{ $t('newCase.and') }}</span>
                      <router-link
                        class="px-1 text-sm font-bold text-primary"
                        to="/register"
                      >
                        {{ $t('newCase.privacyPolicy') }}
                      </router-link>
                    </p>
                  </div>
                  <button
                    class="btn text-md mt-6 h-12 w-full rounded-md"
                    :class="[
                      errors.caseName === undefined &&
                      errors.caseDescription === undefined &&
                      errors.caseAmount === undefined &&
                      caseName !== '' &&
                      caseDescription !== '' &&
                      caseAmount !== ''
                        ? 'hover:bg-primary-600 cursor-pointer bg-primary text-white'
                        : 'cursor-not-allowed bg-gray-300 text-slate-100',
                    ]"
                  >
                    {{ $t('action.next') }}
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import { Form, Field } from 'vee-validate'
import globalMixin from '@/mixins/global.js'
import caseMixin from '@/mixins/case.js'

import Header from '@/partials/Header.vue'
import Datepicker from '@/components/Datepicker.vue'
import BreadcrumNewCase from '@/components/case/BreadcrumNewCase.vue'

export default {
  name: 'NewCasePage',
  components: {
    Form,
    Field,
    Header,
    Datepicker,
    BreadcrumNewCase,
  },
  mixins: [globalMixin, caseMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const toast = useToast()
    const sidebarOpen = ref(false)
    const caseCurrencies = [
      {
        id: 'USD',
        name: 'USD',
      },
    ]

    return {
      t,
      n,
      store,
      toast,
      sidebarOpen,
      caseCurrencies,
    }
  },
  data() {
    return {
      caseFroms: [],
      caseNotifications: [
        {
          id: 'system',
          name: 'system',
        },
        {
          id: 'email',
          name: 'email',
        },
      ],
      caseName: '',
      caseDescription: '',
      caseNotification: 'system',
      caseFrom: null,
      caseAmount: null,
      caseDate: new Date(),
      caseCurrency: 'USD',
      caseCheck: false,
      attachment: null,
    }
  },
  mounted() {
    this.getAllBank()
    this.getAllAreas()
    this.getAllTypes()
    const newCaseData = this.$store.getters['cases/newCase']
    if (!this.checkEmptyObject(newCaseData)) {
      this.caseName = newCaseData.name
      this.typeOfPart = newCaseData.case_type_id
      this.caseArea = newCaseData.case_area_id
      this.caseDescription = newCaseData.description
      this.caseNotification = newCaseData.notif_via
      this.caseFrom = newCaseData.bank_id
      this.caseAmount = newCaseData.amount
      this.caseDate = newCaseData.time
      this.caseCurrency = newCaseData.currency
      this.caseCheck = newCaseData.caseCheck
      this.attachment = newCaseData.attachment
    }
  },
  methods: {
    openLink(id) {
      this.$router.push({
        name: 'dashboard',
        params: { slug: id },
        query: {
          name: this.$route.query.name,
          status: this.$route.query.status,
          sub_status: this.$route.query.sub_status,
          lang: this.$route.query.lang,
        },
      })
    },
    async getAllBank() {
      await this.store
        .dispatch('bank/getAllBank')
        .then((response) => {
          const responseData = response.data.data
          this.caseFroms = responseData
          if (responseData.length > 0) this.caseFrom = responseData[0].id
        })
        .catch((error) => {
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    createComplain() {
      if (!this.caseCheck) {
        this.toast.error(this.t('newCase.pleaseCheckAggreement'))
        return
      }
      const newCaseData = {
        name: this.caseName,
        case_type_id: this.typeOfPart,
        case_area_id: this.caseArea,
        description: this.caseDescription,
        notif_via: this.caseNotification,
        bank_id: this.caseFrom,
        amount: parseInt(this.caseAmount),
        time: this.caseDate,
        currency: this.caseCurrency,
        caseCheck: this.caseCheck,
        attachment: this.attachment,
      }
      this.store.dispatch('cases/setNewCase', newCaseData)
      this.store.dispatch('cases/setActiveNewCaseBreadcrum', 2)

      this.$router.push({
        name: 'advocate-requirement',
        query: {
          lang: this.$route.query.lang,
        },
      })
    },
    fileInput(event) {
      this.attachment = event.target.files[0]
    },
    changeDate(val) {
      this.caseDate = val
    },
  },
}
</script>
