<template>
  <div class="flex h-screen overflow-hidden">
    <!-- Sidebar -->
    <Sidebar
      :sidebar-open="sidebarOpen"
      :case-state="
        activeCase.stage !== undefined ? activeCase.stage : $route.query.status
      "
      @close-sidebar="sidebarOpen = false"
    />

    <!-- Content area -->
    <div
      class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden"
    >
      <main>
        <div class="max-w-9xl mx-auto w-full px-4 py-8 sm:px-6 lg:px-8">
          <div class="flex w-full flex-col pb-4">
            <h1 class="text-2xl font-bold text-black">
              {{ $t('finalDecision.title') }}
            </h1>
            <p>{{ $t('finalDecision.description') }}</p>
          </div>
          <template v-if="!isLoading">
            <!-- Case Details -->
            <div class="rounded-lg bg-white px-6 py-2 shadow-sm">
              <div class="flex items-start">
                <div class="grow">
                  <div class="items-start justify-between py-4 sm:flex">
                    <div
                      class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                    >
                      <p>{{ $t('caseDetail.details.caseName') }}</p>
                    </div>
                    <div
                      class="mb-2 whitespace-nowrap text-lg font-medium text-slate-800 sm:mb-0"
                    >
                      <p>{{ activeCase.name }}</p>
                    </div>
                  </div>
                  <div
                    class="items-start justify-between border-t py-4 sm:flex"
                  >
                    <div
                      class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                    >
                      <p>{{ $t('caseDetail.details.submissionDate') }}</p>
                    </div>
                    <div
                      class="mb-2 whitespace-nowrap text-lg font-medium text-slate-800 sm:mb-0"
                    >
                      <p>
                        {{ formatDateDay(activeCase.time) }}
                      </p>
                    </div>
                  </div>
                  <div
                    class="items-start justify-between border-t py-4 sm:flex"
                  >
                    <div
                      class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                    >
                      <p>{{ $t('caseDetail.details.submittedBy') }}</p>
                    </div>
                    <div
                      class="mb-2 whitespace-nowrap text-lg font-medium text-slate-800 sm:mb-0"
                    >
                      <p v-if="activeCase.submitted_by">
                        {{ activeCase.submitted_by.first_name }}
                        {{ activeCase.submitted_by.last_name }}
                      </p>
                      <p v-else>-</p>
                    </div>
                  </div>
                  <div
                    class="items-start justify-between border-t py-4 sm:flex"
                  >
                    <div
                      class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                    >
                      <p>{{ $t('finalDecision.resolveDate') }}</p>
                    </div>
                    <div
                      class="mb-2 whitespace-nowrap text-lg font-medium text-slate-800 sm:mb-0"
                    >
                      <p v-if="activeCase.case_solved">
                        {{ formatDateDay(activeCase.case_solved.modified) }}
                      </p>
                      <p v-else>-</p>
                    </div>
                  </div>

                  <div
                    class="items-start justify-between border-t py-4 sm:flex"
                  >
                    <div
                      class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                    >
                      <p>{{ $t('finalDecision.disputeResult') }}</p>
                    </div>
                    <div
                      class="mb-2 whitespace-nowrap text-lg font-medium text-green-500 sm:mb-0"
                    >
                      <p v-if="activeCase.case_solved">
                        {{ activeCase.case_solved.result }}
                      </p>
                      <p v-else>-</p>
                    </div>
                  </div>

                  <div
                    class="items-start justify-between border-t py-4 sm:flex"
                  >
                    <div
                      class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                    >
                      <p>{{ $t('finalDecision.notes') }}</p>
                    </div>
                    <div
                      class="mb-2 whitespace-nowrap text-lg font-medium sm:mb-0"
                    >
                      <p v-if="activeCase.case_solved">
                        {{ activeCase.case_solved.notes }}
                      </p>
                      <p v-else>-</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Case Details -->

            <!-- Final Decision -->
            <div
              v-if="activeCase.case_solved"
              class="mt-4 rounded-lg bg-white px-6 pt-2 shadow-sm"
            >
              <div>
                <div class="flex flex-row py-4">
                  <ImageLoader
                    wrapper-class="mr-3 shrink-0 rounded-full"
                    :src="null"
                    width="60"
                    height="60"
                    :alternate="activeCase.case_solved.result"
                  />
                  <div class="pl-4">
                    <p class="text-lg font-semibold text-slate-800">
                      {{ activeCase.case_solved.solved_by.first_name }}
                      {{ activeCase.case_solved.solved_by.last_name }}
                    </p>
                    <p class="text-sm text-slate-500">
                      {{ activeCase.case_solved.solved_by.email }}
                    </p>
                  </div>
                </div>
                <div class="flex items-start">
                  <div class="grow">
                    <div class="items-start justify-between py-4 sm:flex">
                      <div
                        class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                      >
                        <p>{{ $t('caseDetail.parties.role') }}</p>
                      </div>
                      <div
                        class="mb-2 whitespace-nowrap text-lg font-medium text-slate-800 sm:mb-0"
                      >
                        <p>{{ activeCase.case_solved.solved_by.role.name }}</p>
                      </div>
                    </div>
                    <div
                      class="items-start justify-between border-t py-4 sm:flex"
                    >
                      <div
                        class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                      >
                        <p>{{ $t('caseDetail.parties.phoneNumber') }}</p>
                      </div>
                      <div
                        class="mb-2 whitespace-nowrap text-lg font-medium text-slate-800 sm:mb-0"
                      >
                        <p>
                          {{ activeCase.case_solved.solved_by.phone ?? '-' }}
                        </p>
                      </div>
                    </div>
                    <div
                      class="items-start justify-between border-t py-4 sm:flex"
                    >
                      <div
                        class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                      >
                        <p>{{ $t('caseDetail.parties.email') }}</p>
                      </div>
                      <div
                        class="mb-2 whitespace-nowrap text-lg font-medium text-slate-800 sm:mb-0"
                      >
                        <p>
                          {{ activeCase.case_solved.solved_by.email ?? '-' }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex items-start">
                <div class="grow">
                  <div
                    class="items-start justify-between border-t py-4 sm:flex"
                  >
                    <div class="mb-2 items-center text-left sm:mb-0">
                      <FileItem
                        :file="{
                          name: activeCase.case_solved.result,
                          files: [{ file: activeCase.case_solved.attachment }],
                        }"
                        wrapper-class="min-w-72 p-4"
                      />
                      <div class="flex flex-col py-8">
                        <span class="text-md font-bold">
                          {{ activeCase.case_solved.result }}
                        </span>
                        <span class="text-sm text-slate-500">
                          {{ activeCase.case_solved.notes }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End of Final Decision -->
          </template>
          <DualRingLoader v-else />
        </div>
      </main>
    </div>

    <RightSidebar
      :sidebar-open="sidebarOpen"
      @close-sidebar="sidebarOpen = false"
    />
  </div>
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import globalMixin from '@/mixins/global.js'
import DualRingLoader from '@/components/DualRingLoader.vue'
import ImageLoader from '@/components/image/ImageLoader.vue'
import FileItem from '@/components/FileItem.vue'

import Sidebar from '@/partials/Sidebar.vue'
import RightSidebar from '@/partials/RightSidebar.vue'

export default {
  name: 'CaseDetailPage',
  components: {
    Sidebar,
    RightSidebar,
    DualRingLoader,
    ImageLoader,
    FileItem,
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const toast = useToast()
    const sidebarOpen = ref(false)
    const activeCase = ref({})

    const parties = ref([])

    return {
      t,
      n,
      store,
      toast,
      sidebarOpen,
      activeCase,
      parties,
    }
  },
  mounted() {
    this.checkUserAccess('USER')
    this.getCaseDetail()
  },
  methods: {
    async getCaseDetail() {
      this.isLoading = true
      await this.store
        .dispatch('cases/getCaseDetail', {
          id: this.$route.params.slug,
        })
        .then((response) => {
          this.isLoading = false
          const responseData = response.data.data
          this.activeCase = responseData
          // If case stage not 5
          if (this.activeCase.stage !== 5) {
            this.$router.push({
              name: 'dashboard',
              params: {
                slug: this.$route.params.slug,
              },
            })
          }
        })
        .catch((error) => {
          this.isLoading = false
          this.toast.error(this.extractErrorResponse(error))
        })
    },
  },
}
</script>
