<template>
  <div v-if="isRequestDocument" class="text-sm">
    <p v-if="agenda.owner" class="font-semibold">
      {{
        $t('calendar.modalRequestDocument.requestAdditional', {
          from: agenda.owner.first_name,
          to: requestedTo,
        })
      }}
    </p>
    <p v-else class="font-semibold">
      {{ $t('calendar.modalRequestDocument.title') }}
    </p>
  </div>
  <div v-else>
    <p class="space-x-4">
      <span>
        {{ agenda.title }}
      </span>
      <span v-if="agenda.meeting_url !== null">-</span>
      <a
        v-if="agenda.meeting_url !== null"
        :href="agenda.meeting_url"
        target="_BLANK"
        class="cursor-pointer text-blue-200 hover:text-white"
      >
        {{ $t('calendar.joinMeeting') }}
      </a>
    </p>
    <p
      v-if="agenda.meeting_time_from && agenda.meeting_time_to"
      class="space-x-2"
    >
      <span>{{ agenda.meeting_time_from }}</span>
      <span>-</span>
      <span>{{ agenda.meeting_time_to }}</span>
    </p>
    <p>
      <span>
        {{ agenda.description }}
      </span>
    </p>
  </div>
  <p>
    <span v-for="(invitee, index) in agenda.invitations" :key="invitee.id">
      <span v-if="invitee.user">
        {{ invitee.user.first_name }}
        {{ invitee.user.last_name }}
        <span v-if="index !== agenda.invitations.length - 1">, </span>
      </span>
    </span>
  </p>
</template>
<script>
export default {
  name: 'CalendarItemComponent',
  props: {
    agenda: {
      type: Object,
      required: true,
    },
    isRequestDocument: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    requestedTo() {
      if (this.agenda.type === 'Document') {
        let names = []
        this.agenda.requests.forEach((request) => {
          names.push(request.user.first_name)
        })
        if (names.length > 0) return names.join(', ')
      }

      return ''
    },
  },
}
</script>
