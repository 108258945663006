<template>
  <div
    v-for="item in groups"
    :key="item.id"
    class="col-span-12 flex transform cursor-pointer flex-col rounded-2xl bg-white shadow-sm transition duration-500 hover:scale-105 sm:col-span-6 lg:col-span-4"
    @click="buttonClicked(item)"
  >
    <div class="flex h-full flex-col justify-between px-5 py-5">
      <div clas="flex">
        <p
          v-if="item.title !== null || item.title !== ''"
          class="text-xl font-bold"
        >
          {{ item.title }}
          <svg
            class="inline h-4 w-4 fill-current text-black"
            viewBox="0 0 16 16"
          >
            <path d="M6.6 13.4L5.2 12l4-4-4-4 1.4-1.4L12 8z" />
          </svg>
        </p>
        <p v-else class="text-xl font-bold">
          {{ $t('chat.group.private') }}
          <svg
            class="inline h-4 w-4 fill-current text-black"
            viewBox="0 0 16 16"
          >
            <path d="M6.6 13.4L5.2 12l4-4-4-4 1.4-1.4L12 8z" />
          </svg>
        </p>
      </div>

      <div v-if="item.last_chat" class="py-3">
        <p
          class="text-xs"
          :class="item.closed_at !== null ? 'text-red-500' : 'text-slate-600'"
        >
          <span v-if="item.last_chat.created_by" class="font-bold">
            {{ item.last_chat.created_by.first_name }}
            {{ item.last_chat.created_by.last_name }}:
          </span>
          <span v-else class="font-bold">{{ item.last_chat.title }}:</span>
          <span class="pl-1">{{ item.last_chat.content }}</span>
        </p>
      </div>

      <div
        class="mt-8 flex w-full max-w-full flex-row flex-wrap justify-between"
      >
        <div class="flex">
          <Avatars :extract-user="true" :parties="item.member" />
        </div>
        <div
          class="flex flex-col items-center justify-end space-x-2 self-center"
        >
          <p class="text-sm">
            {{ item.member.length }} {{ $t('case.parties') }}
          </p>
          <p
            v-if="item.closed_at !== null"
            class="text-xs font-bold text-red-500"
          >
            {{ $t('chat.group.closed') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatars from './Avatars.vue'

export default {
  name: 'ChatGropItem',
  components: {
    Avatars,
  },
  props: {
    groups: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  emits: ['clicked'],
  methods: {
    buttonClicked(groupId) {
      this.$emit('clicked', groupId)
    },
  },
}
</script>
