<template>
  <div class="flex h-screen overflow-hidden">
    <!-- Sidebar -->
    <Sidebar :sidebar-open="sidebarOpen" @close-sidebar="sidebarOpen = false" />

    <!-- Content area -->
    <div
      class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden"
    >
      <main>
        <div class="max-w-9xl mx-auto w-full px-4 py-8 sm:px-6 lg:px-8">
          <div class="flex justify-between">
            <div class="flex w-full flex-col">
              <h1 class="text-2xl font-bold text-black">
                {{ $t('admin.dashboard.title') }}
              </h1>
              <p>{{ $t('admin.dashboard.description') }}</p>
            </div>

            <DropdownNotifications
              align="right"
              class="md:mr-4"
              wrapper-class="bg-white rounded-lg"
            />
          </div>

          <!-- Overview -->
          <div>
            <div class="flex justify-between">
              <h1 class="pt-10 text-2xl font-bold text-black">
                {{ $t('admin.dashboard.overview') }}
              </h1>
              <div class="flex justify-between space-x-4">
                <button
                  class="group flex h-12 items-center justify-between space-x-4 rounded-lg border border-gray-100 bg-white px-5 shadow-sm"
                  @click="downloadPDF"
                >
                  <div class="text-sm text-slate-800">
                    {{ $t('dashboard.downloadPDF') }}
                  </div>
                  <!-- <svg
                    class="h-8 w-8 shrink-0 fill-current text-slate-400 group-hover:text-slate-500"
                    viewBox="0 0 32 32"
                  >
                    <path d="M16 20l-5.4-5.4 1.4-1.4 4 4 4-4 1.4 1.4z" />
                  </svg> -->
                  <DualRingLoader v-if="isDownloadLoading" :size="10" />
                  <DownloadIcon
                    v-else
                    class="shrink-0 fill-current text-slate-400 group-hover:text-slate-500"
                  />
                </button>
                <button
                  class="group flex h-12 items-center justify-between rounded-lg border border-gray-100 bg-white px-5 shadow-sm"
                  @click.stop="shareModalOpen = true"
                >
                  <div class="text-sm text-slate-800">
                    {{ $t('dashboard.sharePDF') }}
                  </div>
                </button>
                <select
                  v-model="semesterSelected"
                  class="h-12 rounded-lg border border-gray-100 bg-white px-8 shadow-sm"
                  @change="changeSemester"
                >
                  <option
                    v-for="opt in semesterOptions"
                    :key="opt.id"
                    :value="opt"
                  >
                    {{
                      $t('dashboard.semester', {
                        semester: `${opt.year}-${opt.semester}`,
                      })
                    }}
                  </option>
                </select>
              </div>
            </div>
            <div class="flex justify-between space-x-2">
              <div
                v-if="!checkEmptyObject(chartData)"
                class="flex w-2/6 flex-col items-center justify-center py-10"
              >
                <PieChart :data="chartData" width="389" height="270" />
              </div>
              <div class="w-4/6 py-5">
                <div
                  v-if="!caseOverviewLoading && overviewData.length > 0"
                  class="grid grid-cols-12 gap-6"
                >
                  <OverviewCard
                    :overviews="overviewData"
                    @clicked="openLinkOverview"
                  />
                </div>
                <DualRingLoader v-else-if="caseOverviewLoading" />
                <NoData v-else />
              </div>
            </div>
          </div>
          <!-- End of Overview -->

          <!-- Bar Chart -->
          <div class="w-full rounded-lg bg-white pb-5">
            <section v-if="!caseAreaLoading">
              <div class="flex w-full px-5 py-5">
                <div
                  v-for="(caseVal, index) in totalCase"
                  :key="index"
                  :class="colorByArea(index, 'bg')"
                  :style="`width: ${getPercentage(caseVal, totalCaseSum)}%`"
                  class="py-4 text-center text-xl font-bold first:rounded-l-lg last:rounded-r-lg"
                >
                  {{ getPercentage(caseVal, totalCaseSum) }}%
                </div>
              </div>
              <div class="grid grid-cols-12 gap-6 px-5">
                <div
                  v-for="area in caseAreaOverviews"
                  :key="area.id"
                  class="col-span-12 flex transform cursor-pointer flex-col rounded-2xl border bg-white shadow-sm transition duration-500 hover:scale-105 sm:col-span-6 lg:col-span-4 xl:col-span-3"
                >
                  <div class="flex h-full flex-col justify-between px-5 py-5">
                    <div clas="flex w-full space-x-2 items-center">
                      <span
                        class="mr-2 px-2"
                        :class="colorByArea(area.name.toLowerCase(), 'bg')"
                      ></span>
                      <span class="text-xl font-bold">
                        {{ $t(`admin.cases.area.${area.name.toLowerCase()}`) }}
                      </span>
                    </div>

                    <div class="ng mt-8 flex w-full flex-row justify-between">
                      <p>
                        <span class="pr-2 text-2xl font-bold">
                          {{ area.total }}
                        </span>
                        <span>{{ $t('admin.cases.cases') }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <DualRingLoader v-else-if="caseAreaLoading" />
          </div>
          <!-- End of Bar Chart -->

          <!-- New Case -->
          <div v-if="allCases.length > 0" class="w-full space-y-5 py-10">
            <h1 class="text-2xl font-bold text-black">
              {{ $t('admin.dashboard.pendingCase') }}
            </h1>
            <div
              v-if="!caseLoading && allCases.length > 0"
              class="grid grid-cols-12 gap-6"
            >
              <CaseCard
                :cases="allCases"
                @click-case="toCaseDetail($event, true)"
              />
            </div>
            <DualRingLoader v-else-if="caseLoading" />
            <NoData v-else />
          </div>
          <!-- End of New Case -->

          <!-- Users -->
          <div v-if="users.length > 0" class="w-full space-y-5 py-10">
            <h1 class="text-2xl font-bold text-black">
              {{ $t('admin.dashboard.newUser') }}
            </h1>
            <section v-if="!isUserLoading">
              <Table :header-data="headerData" tbody-class="mt-4" />
              <div v-for="user in users" :key="user.id">
                <UserItem
                  :user="user"
                  class="mt-2 py-6"
                  @edit-user="editUser($event)"
                  @remove-user="deleteUser($event)"
                />
              </div>
            </section>
            <DualRingLoader v-else-if="isUserLoading" />
            <NoData v-else />
          </div>
          <!-- End of Users -->
        </div>
      </main>

      <ModalDelete
        :id="currentUser.id"
        :is-open="deleteUserModalOpen"
        :title="$t('admin.user.modalRemoveUser.title')"
        :description="$t('admin.user.modalRemoveUser.description')"
        @closed="deleteUserModalOpen = false"
        @delete="deleteUserAPI($event)"
      />

      <ModalCreateUser
        :is-open="newUserModalOpen"
        :roles="roles"
        :banks="banks"
        :is-edit="isUserEdit"
        :current-user="currentUser"
        @closed="modalUserClose"
        @submit-data="submitData"
      />

      <ModalShareEmail
        :is-open="shareModalOpen"
        :is-loading="isDownloadLoading"
        @share="shareEmail"
        @closed="shareModalOpen = false"
      />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import { tailwindConfig } from '@/utils/Utils'

import globalMixin from '@/mixins/global.js'
import caseMixin from '@/mixins/case.js'

import Sidebar from '@/partials/Sidebar.vue'
import Table from '@/components/Table.vue'
import UserItem from '@/components/UserItem.vue'

import PieChart from '@/charts/PieChart.vue'
import ModalCreateUser from '@/components/ModalCreateUser.vue'
import ModalShareEmail from '@/components/modal/ModalShareEmail.vue'
import ModalDelete from '@/components/ModalDelete.vue'
import CaseCard from '@/components/CaseCard.vue'
import OverviewCard from '@/components/OverviewCard.vue'
import DualRingLoader from '@/components/DualRingLoader.vue'
import NoData from '@/components/NoData.vue'
import DropdownNotifications from '@/components/DropdownNotifications.vue'

import DownloadIcon from '@/assets/svg/download-round.svg'

export default {
  name: 'AdminDashboardPage',
  components: {
    Sidebar,
    Table,
    ModalCreateUser,
    ModalShareEmail,
    ModalDelete,
    UserItem,
    CaseCard,
    OverviewCard,
    DualRingLoader,
    PieChart,
    NoData,
    DropdownNotifications,
    DownloadIcon,
  },
  mixins: [globalMixin, caseMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const toast = useToast()

    const sidebarOpen = ref(false)
    const newUserModalOpen = ref(false)
    const shareModalOpen = ref(false)
    const deleteUserModalOpen = ref(false)
    const isUserLoading = ref(false)
    const caseOverviewLoading = ref(false)
    const caseLoading = ref(false)
    const isUserEdit = ref(false)
    const isDownloadLoading = ref(false)
    const currentUser = ref({})
    const headerData = ref([
      {
        name: t('admin.user.headerTable.name'),
        colspan: 1,
      },
      {
        name: t('admin.user.headerTable.role'),
        colspan: 1,
      },
      {
        name: t('admin.user.headerTable.email'),
        colspan: 1,
      },
    ])

    return {
      t,
      store,
      toast,
      sidebarOpen,
      currentUser,
      isUserEdit,
      isUserLoading,
      caseOverviewLoading,
      deleteUserModalOpen,
      caseLoading,
      newUserModalOpen,
      isDownloadLoading,
      shareModalOpen,
      headerData,
    }
  },
  data: () => ({
    allCases: [],
    overviewData: [],
    roles: [],
    users: [],
    filterDataUser: {
      page: 1,
      take: 10,
      search: '',
      order: 'ASC',
    },
    metaDataUser: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    filterDataCases: {
      page: 1,
      take: 10,
      search: '',
      order: 'DESC',
      status: 'On prepare',
    },
    metaDataCases: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    formData: {
      first_name: '',
      last_name: '',
      username: '',
      email: '',
      phone: '',
      password: '',
      role_id: '',
    },
    banks: [],
    totalCase: {},
    caseAreaOverviews: [],
    semesterOptions: [],
    chartData: {},
    semesterSelected: {},
  }),
  computed: {
    totalCaseSum() {
      return this.sumObject(this.totalCase)
    },
  },
  beforeMount() {
    this.checkUserAccess('REDEK_ADMIN')
    this.init()
  },
  mounted() {
    this.getAllAreas()
  },
  methods: {
    init() {
      this.setSemesterOption()
      this.getCaseOverview()
      this.getCaseAreaOverview()
      this.getAllRoles()
      this.getAllUsers()
      this.getAllCases()
      this.getAllBanks()
    },
    setChartData() {
      this.chartData.labels = Object.keys(this.totalCase)
      this.chartData.datasets = [
        {
          label: 'Case by Area',
          data: Object.values(this.totalCase),
          backgroundColor: [
            tailwindConfig().theme.colors.rose[100],
            tailwindConfig().theme.colors.orange[300],
            tailwindConfig().theme.colors.green[300],
            tailwindConfig().theme.colors.sky[300],
          ],
          hoverBackgroundColor: [
            tailwindConfig().theme.colors.rose[100],
            tailwindConfig().theme.colors.orange[300],
            tailwindConfig().theme.colors.green[300],
            tailwindConfig().theme.colors.sky[300],
          ],
          borderWidth: 0,
        },
      ]
    },
    newUser(event) {
      this.newUserModalOpen = true
      this.isUserEdit = false
      this.currentUser = {}
    },
    editUser(event) {
      this.newUserModalOpen = true
      this.isUserEdit = true
      this.currentUser = event.user
    },
    deleteUser(event) {
      this.deleteUserModalOpen = true
      this.isUserEdit = false
      this.currentUser = event.user
    },
    async getAllRoles() {
      await this.store
        .dispatch('user/getAllRoles')
        .then((response) => {
          const responseData = response.data.data
          if (responseData) {
            this.roles = responseData
          }
        })
        .catch((error) => {
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async getAllUsers() {
      this.isUserLoading = true
      await this.store
        .dispatch('user/getAllUsers', {
          order: this.filterDataUser.order,
          page: this.filterDataUser.page,
          take: this.filterDataUser.take,
          q: this.filterDataUser.search,
        })
        .then((response) => {
          this.isUserLoading = false
          const responseData = response.data.data
          if (responseData.data) {
            this.users = responseData.data
          }
          if (responseData.meta) {
            this.metaDataUser = responseData.meta
          }
        })
        .catch((error) => {
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    submitData(formData) {
      this.formData = formData
      if (formData.user_id === null) {
        this.createUser()
      } else {
        this.updateUser()
      }
    },
    modalUserClose() {
      this.newUserModalOpen = false
      this.currentUser = {}
    },
    async updateUser() {
      await this.store
        .dispatch('user/updateUser', {
          first_name: this.formData.first_name,
          last_name: this.formData.last_name,
          username: this.formData.username,
          email: this.formData.email,
          phone: this.formData.phone,
          user_id: this.formData.user_id,
        })
        .then((response) => {
          if (response.data.statusCode === 200) {
            const message = response.data.message
            this.toast.success(message)
            this.newUserModalOpen = false
            this.getAllUsers()
          }
        })
        .catch((error) => {
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async createUser() {
      await this.store
        .dispatch('user/createUser', {
          first_name: this.formData.first_name,
          last_name: this.formData.last_name,
          username: this.formData.username,
          email: this.formData.email,
          phone: this.formData.phone,
          password: this.formData.password,
          role_id: this.formData.role_id,
          bank_id: this.formData.bank_id,
        })
        .then((response) => {
          if (response.data.statusCode === 200) {
            const message = response.data.message
            this.toast.success(message)
            this.newUserModalOpen = false
            this.getAllUsers()
          }
        })
        .catch((error) => {
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async deleteUserAPI(events) {
      await this.store
        .dispatch('user/deleteUser', {
          user_id: events.id,
        })
        .then((response) => {
          if (response.data.statusCode === 200) {
            const message = response.data.message
            this.toast.success(message)
            this.deleteUserModalOpen = false
            this.getAllUsers()
          }
        })
        .catch((error) => {
          console.log(error)
          this.toast.error(this.extractErrorResponse(error))
        })
    },

    async getCaseOverview() {
      this.caseOverviewLoading = true
      await this.store
        .dispatch('cases/getCaseOverview')
        .then((response) => {
          this.caseOverviewLoading = false
          const responseData = response.data.data
          if (responseData) {
            let overviewData = []
            responseData.forEach((item) => {
              item.status = this.setStatusByName(item.name)
              overviewData.push(item)
            })
            this.overviewData = overviewData
          }
        })
        .catch((error) => {
          console.log(error)
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async getCaseAreaOverview() {
      if (this.checkEmptyObject(this.semesterSelected)) return

      const params = {}
      params.year = this.semesterSelected.year
      params.semester = this.semesterSelected.semester

      this.caseAreaLoading = true
      await this.store
        .dispatch('cases/getCaseAreaOverview', params)
        .then((response) => {
          this.caseAreaLoading = false
          const responseData = response.data.data
          if (responseData) {
            // let overviewData = []
            const overviewAreas = responseData
            const overviewAreaObj = overviewAreas.reduce(
              (obj, item) => ({
                ...obj,
                [item.name.toLowerCase()]: item.total,
              }),
              {}
            )
            this.totalCase = overviewAreaObj
            this.caseAreaOverviews = overviewAreas
            this.setChartData()
          }
        })
        .catch((error) => {
          console.log(error)
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async generatePDFCaseAPI() {
      this.isDownloadLoading = true
      const params = {}
      if (!this.checkEmptyObject(this.semesterSelected)) {
        params.year = this.semesterSelected.year
        params.semester = this.semesterSelected.semester
      }

      await this.store
        .dispatch('cases/generatePDFCase', params)
        .then((response) => {
          this.isDownloadLoading = false
          const file = new Blob([response.data], { type: 'application/pdf' })
          const fileURL = URL.createObjectURL(file)
          window.open(fileURL)
        })
        .catch((error) => {
          this.isDownloadLoading = false
          console.log(error)
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async sendPDFCaseAPI(to) {
      this.isDownloadLoading = true
      const params = {}
      if (!this.checkEmptyObject(this.semesterSelected)) {
        params.year = this.semesterSelected.year
        params.semester = this.semesterSelected.semester
        params.title = this.t('report.inresolveCaseSemester', {
          year: this.semesterSelected.year,
          semester: this.semesterSelected.semester,
        })
        params.to = to
      }

      await this.store
        .dispatch('cases/sendPDFCase', params)
        .then((response) => {
          this.isDownloadLoading = false
          if (response.data.statusCode === 200) {
            this.shareModalOpen = false
          }
        })
        .catch((error) => {
          this.shareModalOpen = true
          this.isDownloadLoading = false

          console.log(error)
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async getAllCases() {
      this.caseLoading = true
      await this.store
        .dispatch('cases/getAllCasesAdmin', {
          order: this.filterDataCases.order,
          page: this.filterDataCases.page,
          take: this.filterDataCases.take,
          q: this.filterDataCases.search,
          status: this.filterDataCases.status,
        })
        .then((response) => {
          this.caseLoading = false
          const responseData = response.data.data
          if (responseData.data) {
            let mappedDataCase = []
            responseData.data.forEach((item) => {
              item.status = this.stripAndLower(item.status)
              mappedDataCase.push(item)
            })
            this.cases = mappedDataCase
            this.allCases = mappedDataCase
          }
          if (responseData.meta) {
            this.metaDataCases = responseData.meta
          }
        })
        .catch((error) => {
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    openLinkOverview(item) {
      this.$router.push({
        name: `${item.status}-cases`,
      })
    },
    async getAllBanks() {
      await this.store
        .dispatch('bank/getAllBank')
        .then((response) => {
          const responseData = response.data.data
          this.banks = responseData
        })
        .catch((error) => {
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    colorByArea(status, type = 'text') {
      switch (status) {
        case 0:
        case 'penal':
          return `${type}-primary-300`
        case 1:
        case 'publico':
          return `${type}-green-300`
        case 2:
        case 'laboral':
          return `${type}-orange-300`
        case 3:
        case 'familia':
          return `${type}-rose-100`
        default:
          return `${type}-red-400`
      }
    },
    setSemesterOption() {
      const startYear = 2023
      const now = new Date()
      const endYear = now.getFullYear()
      const semesterList = [1, 2]

      const listYear = []
      for (let i = startYear; i <= endYear; i++) {
        listYear.push(i)
      }

      const semesterOptions = []
      listYear.forEach((year) => {
        semesterList.forEach((semester) => {
          semesterOptions.push({
            id: (Math.random() + 1).toString(36).substring(7),
            semester,
            year,
          })
        })
      })
      this.semesterOptions = semesterOptions
      if (semesterOptions.length > 0) {
        this.semesterSelected = semesterOptions[semesterOptions.length - 1]
      }
    },
    changeSemester() {
      this.getCaseAreaOverview()
    },
    downloadPDF() {
      // const params = {}
      // params.year = this.semesterSelected.year
      // params.semester = this.semesterSelected.semester

      // console.log(params)
      // generatePDFCase
      // alert('download pdf')
      this.generatePDFCaseAPI()
    },
    shareEmail(event) {
      this.sendPDFCaseAPI(event.email)
    },
  },
}
</script>
