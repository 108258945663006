<template>
  <div class="grow">
    <!-- Panel body -->
    <div class="space-y-4 p-6">
      <h2 class="text-2xl font-bold text-slate-800">
        {{ $t('profile.editProfile') }}
      </h2>
      <p class="text-slate-800">
        {{ $t('profile.editProfileDesc') }}
      </p>
    </div>

    <!-- Change Password Form -->
    <Form
      v-if="passwordChange"
      ref="form"
      v-slot="{ errors }"
      :validation-schema="changePasswordSchema"
      @submit="updatePassword"
    >
      <div class="space-y-4 p-6">
        <div>
          <label class="mb-1 block text-sm font-medium" for="currentPassword">
            {{ $t('formLabel.currentPassword') }}
            <span class="text-rose-500">*</span></label
          >
          <Field
            v-slot="{ field }"
            v-model="currentPassword"
            name="currentPassword"
          >
            <div class="relative">
              <input
                id="currentPassword"
                v-bind="field"
                autocomplete="new-password"
                class="form-input h-12 w-full rounded-md"
                :type="showCurrentPassword ? 'text' : 'password'"
                :placeholder="$t('formInput.enterCurrentPassword')"
              />
              <div
                class="absolute inset-0 left-auto flex cursor-pointer items-center"
              >
                <span class="px-3 text-sm font-medium text-slate-400">
                  <EyeStrikeIcon
                    v-if="showCurrentPassword"
                    class="h-6 w-6"
                    @click="showCurrentPassword = false"
                  />
                  <EyeIcon
                    v-else
                    class="h-6 w-6"
                    @click="showCurrentPassword = true"
                  />
                </span>
              </div>
            </div>
          </Field>
          <span class="text-sm text-red-500">
            <template v-if="errors.currentPassword !== undefined">
              {{ $t(errors.currentPassword) }}
            </template>
          </span>
        </div>
        <div>
          <label class="mb-1 block text-sm font-medium" for="password">
            {{ $t('formLabel.newPassword') }}
            <span class="text-rose-500">*</span></label
          >
          <Field v-slot="{ field }" v-model="password" name="password">
            <div class="relative">
              <input
                id="password"
                v-bind="field"
                autocomplete="new-password"
                class="form-input h-12 w-full rounded-md"
                :type="showPassword ? 'text' : 'password'"
                :placeholder="$t('formInput.enterNewPassword')"
              />
              <div
                class="absolute inset-0 left-auto flex cursor-pointer items-center"
              >
                <span class="px-3 text-sm font-medium text-slate-400">
                  <EyeStrikeIcon
                    v-if="showPassword"
                    class="h-6 w-6"
                    @click="showPassword = false"
                  />
                  <EyeIcon
                    v-else
                    class="h-6 w-6"
                    @click="showPassword = true"
                  />
                </span>
              </div>
            </div>
          </Field>
          <span class="text-sm text-red-500">
            <template v-if="errors.password !== undefined">
              {{ $t(errors.password) }}
            </template>
          </span>
        </div>
        <div>
          <label class="mb-1 block text-sm font-medium" for="confirmPassword">
            {{ $t('formLabel.newPasswordConfirmation') }}
            <span class="text-rose-500">*</span></label
          >
          <Field
            v-slot="{ field }"
            v-model="confirmPassword"
            name="confirmPassword"
          >
            <div class="relative">
              <input
                id="confirmPassword"
                v-bind="field"
                class="form-input h-12 w-full rounded-md"
                autocomplete="new-password"
                :type="showConfirmPassword ? 'text' : 'password'"
                :placeholder="$t('formInput.enterNewConfirmPassword')"
                required="true"
              />
              <div
                class="absolute inset-0 left-auto flex cursor-pointer items-center"
              >
                <span class="px-3 text-sm font-medium text-slate-400">
                  <EyeStrikeIcon
                    v-if="showConfirmPassword"
                    class="h-6 w-6"
                    @click="showConfirmPassword = false"
                  />
                  <EyeIcon
                    v-else
                    class="h-6 w-6"
                    @click="showConfirmPassword = true"
                  />
                </span>
              </div>
            </div>
          </Field>
          <span class="text-sm text-red-500">
            <template v-if="errors.confirmPassword !== undefined">
              {{ $t(errors.confirmPassword) }}
            </template>
          </span>
        </div>
      </div>
      <footer>
        <div class="flex flex-col px-6 py-5">
          <div class="flex space-x-4 self-start">
            <button
              class="btn rounded-lg border-red-500 text-red-500 hover:bg-red-600 hover:text-white"
              @click="passwordChange = false"
            >
              {{ $t('button.cancel') }}
            </button>
            <button
              class="btn hover:bg-primary-600 rounded-lg bg-primary text-white"
              type="submit"
            >
              {{ $t('button.saveChanges') }}
            </button>
          </div>
        </div>
      </footer>
    </Form>
    <!-- End of Change Password Form -->

    <!-- Change profile Form -->
    <Form
      v-else
      ref="form"
      v-slot="{ errors }"
      :validation-schema="updateProfileSchema"
      @submit="updateProfile"
    >
      <div class="space-y-4 p-6">
        <div class="flex justify-between">
          <section class="w-4/6 space-y-5 border-r">
            <section>
              <div
                class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-4"
              >
                <div class="sm:w-1/3">
                  <label class="mb-1 block text-sm font-medium" for="firstName">
                    {{ $t('profile.firstName') }}
                  </label>

                  <Field
                    v-slot="{ field }"
                    v-model="firstName"
                    name="firstName"
                  >
                    <input
                      id="firstName"
                      v-bind="field"
                      class="form-input w-full"
                      type="text"
                    />
                  </Field>
                  <span class="text-sm text-red-500">
                    <template v-if="errors.firstName !== undefined">
                      {{ $t(errors.firstName) }}
                    </template>
                  </span>
                </div>
                <div class="sm:w-1/3">
                  <label class="mb-1 block text-sm font-medium" for="lastName">
                    {{ $t('profile.lastName') }}
                  </label>
                  <Field v-slot="{ field }" v-model="lastName" name="lastName">
                    <input
                      id="lastName"
                      v-bind="field"
                      class="form-input w-full"
                      type="text"
                    />
                  </Field>
                  <span class="text-sm text-red-500">
                    <template v-if="errors.lastName !== undefined">
                      {{ $t(errors.lastName) }}
                    </template>
                  </span>
                </div>
              </div>
            </section>

            <section>
              <div
                class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-4"
              >
                <div class="sm:w-1/3">
                  <label class="mb-1 block text-sm font-medium" for="email">
                    {{ $t('profile.email') }}
                  </label>
                  <Field v-slot="{ field }" v-model="email" name="email">
                    <input
                      id="email"
                      v-bind="field"
                      class="form-input w-full"
                      type="email"
                    />
                  </Field>
                  <span class="text-sm text-red-500">
                    <template v-if="errors.email !== undefined">
                      {{ $t(errors.email) }}
                    </template>
                  </span>
                </div>
                <div class="sm:w-1/3">
                  <label
                    class="mb-1 block text-sm font-medium"
                    for="phoneNumber"
                  >
                    {{ $t('profile.phoneNumber') }}
                  </label>
                  <Field
                    v-slot="{ field }"
                    v-model="phoneNumber"
                    name="phoneNumber"
                  >
                    <input
                      id="phoneNumber"
                      class="form-input w-full"
                      type="text"
                      v-bind="field"
                      :placeholder="$t('formInput.enterPhone')"
                    />
                  </Field>
                  <span class="text-sm text-red-500">
                    <template v-if="errors.phoneNumber !== undefined">
                      {{ $t(errors.phoneNumber) }}
                    </template>
                  </span>
                </div>
              </div>
            </section>

            <section>
              <div
                class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-4"
              >
                <div class="sm:w-1/3">
                  <label class="mb-1 block text-sm font-medium" for="username">
                    {{ $t('profile.username') }}
                  </label>
                  <Field v-slot="{ field }" v-model="username" name="username">
                    <input
                      id="username"
                      v-bind="field"
                      class="form-input w-full"
                      type="text"
                    />
                  </Field>
                  <span class="text-sm text-red-500">
                    <template v-if="errors.username !== undefined">
                      {{ $t(errors.username) }}
                    </template>
                  </span>
                </div>
              </div>
            </section>

            <!-- Password -->
            <section class="space-y-2">
              <div class="text-sm font-medium">Password</div>
              <p class="flex items-center space-x-2 self-center">
                <span>********</span>
                <span
                  class="hover:text-primary-600 cursor-pointer text-primary"
                  @click="passwordChange = true"
                >
                  {{ $t('button.change') }}
                </span>
              </p>
            </section>
          </section>

          <section class="w-2/6">
            <div class="flex flex-col items-center space-y-2">
              <div>
                <ImageLoader
                  wrapper-class="h-32 w-32 rounded-full"
                  :src="profileData.avatar"
                  width="50"
                  height="50"
                  :alternate="profileData.first_name"
                />
              </div>
              <button
                class="btn hover:bg-primary-600 border-primary-500 text-primary hover:text-white"
                @click.stop.prevent="$refs.uploadpic.click()"
              >
                {{ $t('profile.uploadPicture') }}
              </button>
              <input
                ref="uploadpic"
                type="file"
                class="hidden"
                name="uploadpic"
                @change="fileAvatarChange"
              />
              <p class="text-xs">*{{ $t('profile.maximumSize') }} : 2Mb</p>
              <p class="text-xs">*{{ $t('profile.fileType') }} : .jpeg, .png</p>
            </div>
          </section>
        </div>
      </div>
      <footer>
        <div class="flex flex-col px-6 py-5">
          <div class="flex self-start">
            <button
              class="btn hover:bg-primary-600 rounded-lg bg-primary text-white"
              type="submit"
            >
              {{ $t('button.saveChanges') }}
            </button>
          </div>
        </div>
      </footer>
    </Form>
    <!-- End of Change profile Form -->
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import globalMixins from '@/mixins/global.js'
import profileMixins from '@/mixins/profile.js'
import { Form, Field } from 'vee-validate'

import ImageLoader from '@/components/image/ImageLoader.vue'

import EyeIcon from '@/assets/svg/eye.svg'
import EyeStrikeIcon from '@/assets/svg/eye-strike.svg'

export default {
  name: 'AccountPanel',
  components: {
    ImageLoader,
    Form,
    Field,
    EyeIcon,
    EyeStrikeIcon,
  },
  mixins: [globalMixins, profileMixins],
  setup() {
    const store = useStore()
    const toast = useToast()

    return {
      store,
      toast,
    }
  },
  mounted() {
    this.getProfile()
  },
}
</script>
