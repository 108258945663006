<template>
  <button
    class="btn hover:bg-primary-600 bg-primary text-white"
    :class="[customClass]"
    @click.stop="buttonClicked"
  >
    <PlusIcon class="h-4 w-4 shrink-0 fill-current opacity-50" />
    <span class="xs:block ml-2 hidden">{{ title }}</span>
  </button>
</template>

<script>
import PlusIcon from '../assets/svg/plus.svg'

export default {
  name: 'ButtonAddComponent',
  components: {
    PlusIcon,
  },
  props: {
    title: {
      type: String,
      default: 'Add',
    },
    customClass: {
      type: String,
      default: '',
    },
  },
  methods: {
    buttonClicked() {
      this.$emit('clicked')
    },
  },
}
</script>
