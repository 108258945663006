import axios, { routePathAPI } from '../../plugins/axios.js'
import router from '../../router'

// initial state
const state = () => ({
  timeline: [],
})

// getters
const getters = {
  currentTimeline() {
    return localStorage.getItem('inresolve.currentTimeline') !== null
      ? JSON.parse(localStorage.getItem('inresolve.currentTimeline'))
      : {}
  },
}

// actions
const actions = {
  setTimeline({ state, commit }, payload) {
    commit('SET_TIMELINE', payload)
  },
  resetTimeline({ state, commit }, payload) {
    commit('RESET_TIMELINE')
  },
  createTimeline({ state, commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {
        type: payload.type ? payload.type : null,
        title: payload.title ? payload.title : null,
        subtitle: payload.subtitle ? payload.subtitle : null,
        content: payload.content ? payload.content : null,
        extra: payload.extra ? payload.extra : null,
      }

      axios
        .post(`${routePathAPI()}/timeline/${payload.case_id}/create`, params, {
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getTimelineDetail({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axios
        .get(`${routePathAPI()}/timeline/detail/${payload.id}`, {
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getAllTimeline({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {}
      params.page = payload.page ? payload.page : 1
      params.take = payload.take ? payload.take : 50
      params.order = payload.order ? payload.order : 'DESC'
      if (payload.q) params.q = payload.q
      if (payload.type) params.type = payload.type

      axios
        .get(`${routePathAPI()}/timeline/${payload.case_id}`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            commit('SET_TIMELINE', responseData.data.data)
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {
  SET_TIMELINE(state, payload) {
    localStorage.setItem('inresolve.currentTimeline', JSON.stringify(payload))
  },
  RESET_TIMELINE(state) {
    localStorage.removeItem('inresolve.currentTimeline')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
