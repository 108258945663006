<template>
  <div class="-m-1.5 flex flex-wrap items-center">
    <div class="m-1.5">
      <ModalBasic
        id="document-info-modal"
        :modal-open="isOpen"
        :show-border-header="false"
        :backdrop="true"
        :title="
          isRequestDocument
            ? $t('calendar.modalRequestDocument.title')
            : activeAgenda.title
        "
        @close-modal="close"
      >
        <div class="space-y-5 px-5 pb-5">
          <div v-if="activeAgenda.document_file_type" class="-mt-5 text-sm">
            {{ activeAgenda.document_file_type.name }}
          </div>
          <div class="flex items-center justify-start space-x-3">
            <div>
              <CalendarIcon />
            </div>
            <div
              :class="[
                diffDateFromNow(activeAgenda.due_date) <= 0
                  ? 'text-red-500'
                  : '',
              ]"
            >
              {{
                $t('calendar.modalInfo.dueDate', {
                  date: formattedDate(activeAgenda.due_date),
                  totalDay: diffDateFromNow(activeAgenda.due_date),
                })
              }}
            </div>
          </div>
          <div
            v-if="activeAgenda.owner"
            class="flex items-center justify-start space-x-3"
          >
            <div>
              <UserIcon />
            </div>
            <div class="flex items-center space-x-2">
              <div class="space-x-2">
                <span>
                  {{
                    $t('calendar.modalInfo.requestBy', {
                      by: `${activeAgenda.owner.first_name} ${activeAgenda.owner.last_name}`,
                    })
                  }}
                </span>
              </div>
              <div
                v-if="activeAgenda.owner.role.name"
                class="flex justify-start"
              >
                <p
                  class="rounded-lg bg-purple-100 px-2 text-center text-xs font-semibold text-purple-600"
                >
                  {{ activeAgenda.owner.role.name }}
                </p>
              </div>
            </div>
          </div>
          <div class="flex items-center justify-start space-x-3">
            <div>
              <PaperIcon />
            </div>
            <div>
              {{ activeAgenda.description }}
            </div>
          </div>

          <div
            v-if="
              activeAgenda.owner && me.id !== activeAgenda.owner.id && unRespond
            "
            class="flex flex-col space-y-2"
          >
            <button
              class="btn-sm w-full whitespace-nowrap rounded-lg p-3 text-white"
              :class="[
                diffDateFromNow(activeAgenda.due_date) <= 0
                  ? 'cursor-not-allowed bg-gray-300'
                  : 'hover:bg-primary-600  cursor-pointer bg-primary',
              ]"
              @click.stop="acceptRequest"
            >
              {{ $t('calendar.modalInfo.provideDocument') }}
            </button>
            <button
              class="btn-sm w-full whitespace-nowrap rounded-lg p-3"
              :class="[
                diffDateFromNow(activeAgenda.due_date) <= 0
                  ? 'cursor-not-allowed bg-gray-300 text-white'
                  : 'cursor-pointer  border-red-500 text-red-500 hover:bg-red-500 hover:text-white',
              ]"
              @click.stop="rejectRequest"
            >
              {{ $t('calendar.modalInfo.rejectRequest') }}
            </button>
          </div>
          <div
            v-else-if="activeAgenda.owner && me.id === activeAgenda.owner.id"
            class="space-y-5 px-1"
          >
            <p>{{ $t('calendar.modalInfo.requestTo') }}</p>
            <div
              v-for="request in activeAgenda.requests"
              :key="request.id"
              class="pb-3"
            >
              <CalendarRequestItem :request="request" />
            </div>
          </div>
          <div
            v-else-if="requestToMe !== null && !unRespond"
            class="space-y-5 px-1"
          >
            <p>{{ $t('calendar.modalInfo.yourRespond') }}</p>
            <CalendarRequestItem :request="requestToMe" />
          </div>
        </div>
      </ModalBasic>
    </div>
  </div>
</template>
<script>
import globalMixin from '@/mixins/global.js'
import ModalBasic from '@/components/ModalBasic.vue'
import CalendarRequestItem from '@/components/CalendarRequestItem.vue'
import CalendarIcon from '@/assets/svg/calendar-event.svg'
import PaperIcon from '@/assets/svg/paper-small.svg'
import UserIcon from '@/assets/svg/user-small.svg'

export default {
  name: 'ModalCalendarInfoComponent',
  components: {
    ModalBasic,
    CalendarRequestItem,
    CalendarIcon,
    PaperIcon,
    UserIcon,
  },
  mixins: [globalMixin],
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    isRequestDocument: {
      type: Boolean,
      default: false,
    },
    activeAgenda: {
      type: Object,
      required: true,
    },
  },
  emits: ['closed', 'reject-request', 'accept-request'],
  data: () => ({
    me: null,
  }),
  computed: {
    requestToMe() {
      if (
        !this.checkEmptyObject(this.activeAgenda) &&
        this.activeAgenda.requests.length > 0
      ) {
        const requestToMe = this.activeAgenda.requests.find(
          (request) => request.user.id === this.me.id
        )
        if (typeof requestToMe !== 'undefined') return requestToMe
        else return null
      }
      return null
    },
    unRespond() {
      if (this.requestToMe !== null) {
        return (
          this.requestToMe !== null &&
          (this.requestToMe.is_accept === null ||
            this.requestToMe.reason === null ||
            this.requestToMe.reason === '')
        )
      } else {
        return true
      }
    },
  },
  created() {
    this.me = this.$store.getters['auth/activeUser']
  },
  methods: {
    close() {
      this.$emit('closed')
    },
    rejectRequest() {
      if (this.diffDateFromNow(this.activeAgenda.due_date) > 0) {
        this.$emit('reject-request')
      }
    },
    acceptRequest() {
      if (this.diffDateFromNow(this.activeAgenda.due_date) > 0) {
        this.$emit('accept-request')
      }
    },
  },
}
</script>
