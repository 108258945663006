<template>
  <div class="flex h-screen overflow-hidden">
    <!-- Sidebar -->
    <Sidebar :sidebar-open="sidebarOpen" @close-sidebar="sidebarOpen = false" />

    <!-- Content area -->
    <div
      class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden"
    >
      <main>
        <div class="max-w-9xl mx-auto w-full px-4 py-8 sm:px-6 lg:px-8">
          <div class="flex justify-between">
            <div class="flex w-full flex-col">
              <h1 class="text-2xl font-bold text-black">
                {{ $t('admin.user.title') }}
              </h1>
              <p>{{ $t('admin.user.description') }}</p>
            </div>
            <DropdownNotifications
              align="right"
              class="md:mr-4"
              wrapper-class="bg-white rounded-lg"
            />
          </div>
          <div class="my-4 flex w-full justify-between">
            <SearchForm
              :placeholder="$t('admin.user.searchPlaceholder')"
              @search="searchUser"
            />

            <ButtonAdd
              :title="$t('admin.user.buttonAdd')"
              custom-class="rounded-xl"
              @clicked="newUser()"
            />
          </div>

          <div v-if="!isLoading && users.length > 0" class="w-full">
            <Table :header-data="headerData" tbody-class="mt-4" />
            <div v-for="user in users" :key="user.id">
              <UserItem
                :user="user"
                class="mt-2 py-6"
                @edit-user="editUser($event)"
                @remove-user="deleteUser($event)"
              />
            </div>
          </div>
          <DualRingLoader v-else-if="isLoading" />
          <NoData v-else :search-keyword="filterDataUser.search" />
        </div>
      </main>

      <ModalDelete
        :id="currentUser.id"
        :is-open="deleteUserModalOpen"
        :title="$t('admin.user.modalRemoveUser.title')"
        :description="$t('admin.user.modalRemoveUser.description')"
        @closed="deleteUserModalOpen = false"
        @delete="deleteUserAPI($event)"
      />

      <ModalCreateUser
        :is-open="newUserModalOpen"
        :roles="roles"
        :banks="banks"
        :is-edit="isUserEdit"
        :current-user="currentUser"
        @closed="modalUserClose"
        @submit-data="submitData"
      />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import globalMixin from '@/mixins/global.js'

import Sidebar from '@/partials/Sidebar.vue'
import SearchForm from '@/components/SearchForm.vue'
import ButtonAdd from '@/components/ButtonAdd.vue'
import Table from '@/components/Table.vue'
import UserItem from '@/components/UserItem.vue'

import ModalCreateUser from '@/components/ModalCreateUser.vue'
import ModalDelete from '@/components/ModalDelete.vue'
import DualRingLoader from '@/components/DualRingLoader.vue'
import NoData from '@/components/NoData.vue'
import DropdownNotifications from '@/components/DropdownNotifications.vue'

export default {
  name: 'AdminUserPage',
  components: {
    Sidebar,
    SearchForm,
    ButtonAdd,
    Table,
    ModalCreateUser,
    ModalDelete,
    UserItem,
    DualRingLoader,
    NoData,
    DropdownNotifications,
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const toast = useToast()

    const sidebarOpen = ref(false)
    const newUserModalOpen = ref(false)
    const deleteUserModalOpen = ref(false)
    const isUserEdit = ref(false)
    const currentUser = ref({})
    const headerData = ref([
      {
        name: t('admin.user.headerTable.name'),
        colspan: 1,
      },
      {
        name: t('admin.user.headerTable.role'),
        colspan: 1,
      },
      {
        name: t('admin.user.headerTable.email'),
        colspan: 1,
      },
      // {
      //   name: t('admin.user.headerTable.action'),
      //   colspan: 1,
      // },
    ])

    return {
      t,
      store,
      toast,
      sidebarOpen,
      isUserEdit,
      currentUser,
      deleteUserModalOpen,
      newUserModalOpen,
      headerData,
    }
  },
  data: () => ({
    roles: [],
    users: [],
    filterDataUser: {
      page: 1,
      take: 50,
      search: '',
      order: 'DESC',
    },
    metaDataUser: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    formData: {
      first_name: '',
      last_name: '',
      username: '',
      email: '',
      phone: '',
      password: '',
      role_id: '',
    },
    banks: [],
  }),
  mounted() {
    this.checkUserAccess('REDEK_ADMIN')
    this.getAllRoles()
    this.getAllUsers()
    this.getAllBanks()
  },
  methods: {
    newUser(event) {
      this.newUserModalOpen = true
      this.isUserEdit = false
      this.currentUser = {}
    },
    editUser(event) {
      this.newUserModalOpen = true
      this.isUserEdit = true
      this.currentUser = event.user
    },
    deleteUser(event) {
      this.deleteUserModalOpen = true
      this.isUserEdit = false
      this.currentUser = event.user
    },
    async getAllRoles() {
      await this.store
        .dispatch('user/getAllRoles')
        .then((response) => {
          const responseData = response.data.data
          if (responseData) {
            this.roles = responseData
          }
        })
        .catch((error) => {
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async getAllUsers() {
      this.isLoading = true
      await this.store
        .dispatch('user/getAllUsers', {
          order: this.filterDataUser.order,
          page: this.filterDataUser.page,
          take: this.filterDataUser.take,
          q: this.filterDataUser.search,
        })
        .then((response) => {
          this.isLoading = false
          const responseData = response.data.data
          if (responseData.data) {
            this.users = responseData.data
          }
          if (responseData.meta) {
            this.metaDataUser = responseData.meta
          }
        })
        .catch((error) => {
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    submitData(formData) {
      this.formData = formData
      if (formData.user_id === null) {
        this.createUser()
      } else {
        this.updateUser()
      }
    },
    modalUserClose() {
      this.newUserModalOpen = false
      this.currentUser = {}
    },
    async updateUser() {
      await this.store
        .dispatch('user/updateUser', {
          first_name: this.formData.first_name,
          last_name: this.formData.last_name,
          username: this.formData.username,
          email: this.formData.email,
          phone: this.formData.phone,
          user_id: this.formData.user_id,
        })
        .then((response) => {
          if (response.data.statusCode === 200) {
            const message = response.data.message
            this.toast.success(message)
            this.newUserModalOpen = false
            this.getAllUsers()
          }
        })
        .catch((error) => {
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async createUser() {
      await this.store
        .dispatch('user/createUser', {
          first_name: this.formData.first_name,
          last_name: this.formData.last_name,
          username: this.formData.username,
          email: this.formData.email,
          phone: this.formData.phone,
          password: this.formData.password,
          role_id: this.formData.role_id,
          bank_id: this.formData.bank_id,
        })
        .then((response) => {
          if (response.data.statusCode === 200) {
            const message = response.data.message
            this.toast.success(message)
            this.newUserModalOpen = false
            this.getAllUsers()
          }
        })
        .catch((error) => {
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async deleteUserAPI(events) {
      await this.store
        .dispatch('user/deleteUser', {
          user_id: events.id,
        })
        .then((response) => {
          if (response.data.statusCode === 200) {
            const message = response.data.message
            this.toast.success(message)
            this.deleteUserModalOpen = false
            this.getAllUsers()
          }
        })
        .catch((error) => {
          console.log(error)
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    searchUser(event) {
      this.filterDataUser.search = event
      this.getAllUsers()
    },
    async getAllBanks() {
      await this.store
        .dispatch('bank/getAllBank')
        .then((response) => {
          const responseData = response.data.data
          this.banks = responseData
        })
        .catch((error) => {
          this.toast.error(this.extractErrorResponse(error))
        })
    },
  },
}
</script>
