<template>
  <div class="flex h-screen justify-center overflow-hidden">
    <div
      class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden"
    >
      <!-- Site header -->
      <!-- <Header
        :sidebarOpen="sidebarOpen"
        @toggle-sidebar="sidebarOpen = !sidebarOpen"
      /> -->

      <main>
        <div class="w-full">
          <div class="flex h-full flex-col justify-center bg-gray-100">
            <div
              class="w-full justify-center self-center rounded-lg px-8 py-8 text-center md:w-1/3"
            >
              <ThumbIcon class="mx-auto mb-10 h-72" />
              <h1 class="text-3xl font-bold text-black">
                {{ $t('arbitratorSubmitted.thanks') }}
              </h1>
              <p class="line-clamp-1 pt-10 text-lg font-semibold">
                {{ $t('arbitratorSubmitted.description') }}
              </p>
              <p class="line-clamp-1 text-lg font-semibold">
                {{ $t('arbitratorSubmitted.description2') }}
              </p>
              <div class="mt-5 flex flex-row justify-center">
                <div class="w-5/12 px-2">
                  <button
                    class="btn text-md hover:bg-primary-600 mt-6 h-12 w-full rounded-full bg-primary text-white"
                    @click="nextPage"
                  >
                    {{ $t('arbitratorSubmitted.return') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import Sidebar from '../partials/Sidebar.vue'
import Header from '../partials/Header.vue'
import WelcomeBanner from '../partials/dashboard/WelcomeBanner.vue'
import Avatars from '../components/Avatars.vue'
import FilterButton from '../components/DropdownFilter.vue'
import Datepicker from '../components/Datepicker.vue'
import ButtonAdd from '../components/ButtonAdd.vue'
import CaseCard from '../components/CaseCard.vue'
import Tooltip from '../components/Tooltip.vue'

import ThumbIcon from '../assets/svg/thumb.svg'

export default {
  name: 'ArbitratorSubmittedPage',
  components: {
    Sidebar,
    Header,
    WelcomeBanner,
    Avatars,
    FilterButton,
    Datepicker,
    CaseCard,
    ButtonAdd,
    Tooltip,
    ThumbIcon,
  },
  setup() {
    const sidebarOpen = ref(false)

    return {
      sidebarOpen,
    }
  },
  data() {
    return {}
  },
  methods: {
    nextPage() {
      return this.$router.push({
        name: 'dashboard',
        params: { slug: this.$route.params.slug },
        query: {
          name: this.$route.query.name,
          status: this.$route.query.status,
          sub_status: 'arbitration',
          lang: this.$route.query.lang,
        },
      })
    },
  },
}
</script>
