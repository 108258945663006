<template>
  <main class="bg-white">
    <div class="relative flex">
      <!-- Content -->
      <div class="w-full">
        <div
          class="flex h-full min-h-screen flex-col justify-center bg-gray-100"
        >
          <div class="mb-8 flex justify-center">
            <img
              class="h-14 object-cover object-center"
              src="../assets/images/logo.png"
              alt="InResolve Logo"
            />
          </div>
          <div
            class="w-full self-center rounded-lg bg-white px-8 py-8 shadow-md lg:w-1/3"
          >
            <h1 class="mb-2 text-3xl font-bold text-slate-800">
              {{ $t('forgotPassword.title') }}
            </h1>
            <p class="text-justify text-sm">
              {{ $t('forgotPassword.description') }}
            
              <div class="inline w-full text-sm">
                  <span class="pr-1">{{ $t('forms.or') }}</span>
                  <router-link class=" font-bold text-primary" to="/login">
                    {{ $t('button.loginInstead') }}
                  </router-link>
                </div>
            </p>
            <!-- Form -->
            <form>
              <div class="mt-4 space-y-4">
                <div>
                  <label class="mb-1 block text-sm font-medium" for="email">
                    {{ $t('formLabel.email') }}
                  </label>
                  <input
                    id="email"
                    v-model="email"
                    class="form-input h-12 w-full rounded-md"
                    type="email"
                    :placeholder="$t('formInput.enterEmail')"
                  />
                </div>
              </div>
              <div class="mt-1 flex flex-col">
                <button
                  class="btn text-md mt-2 h-12 w-full rounded-md bg-primary text-white hover:bg-primary-600"
                  @click="doReset()"
                >
                  {{ $t('button.resetPassword') }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { useToast } from 'vue-toastification'
import EyeIcon from '../assets/svg/eye.svg'
import EyeStrikeIcon from '../assets/svg/eye-strike.svg'

export default {
  name: 'LoginPage',
  components: {
    EyeIcon,
    EyeStrikeIcon,
  },
  setup() {
    const toast = useToast()
    return { toast }
  },
  data: () => ({
    email: '',
    showPassword: false,
  }),
  methods: {
    async doReset() {
      const payload = {
        email: this.email,
      }
      console.log(payload)
      return
    },
  },
}
</script>
