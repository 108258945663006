import axios, { routePathAPI } from '../../plugins/axios.js'
import router from '../../router'

// initial state
const state = () => ({})

// getters
const getters = {}

// actions
const actions = {
  profile({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${routePathAPI()}/user/profile`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateProfile({ state, commit }, formData) {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
    return new Promise((resolve, reject) => {
      axios
        .post(`${routePathAPI()}profile/`, formData, config)
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getAllRoles({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axios
        .get(`${routePathAPI()}/role`, {
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getAllUsers({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {}
      if (payload.page) params.page = payload.page
      if (payload.take) params.take = payload.take
      if (payload.order) params.order = payload.order
      if (payload.q) params.q = payload.q

      axios
        .get(`${routePathAPI()}/user/list`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createUser({ state, commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {
        first_name: payload.first_name ?? '',
        last_name: payload.last_name ?? '',
        username: payload.username ?? '',
        bank_id: payload.bank_id ?? '',
        email: payload.email ?? '',
        phone: payload.phone ?? '',
        password: payload.password ?? '',
        role_id: payload.role_id ?? '',
      }

      axios
        .post(`${routePathAPI()}/user/add`, params, {
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  deleteUser({ state, commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axios
        .delete(`${routePathAPI()}/user/delete/${payload.user_id}`, {
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateUser({ state, commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {
        first_name: payload.first_name ?? '',
        last_name: payload.last_name ?? '',
        username: payload.username ?? '',
        email: payload.email ?? '',
        phone: payload.phone ?? '+62888328328322',
      }

      axios
        .put(`${routePathAPI()}/user/update/${payload.user_id}`, params, {
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {
  UPDATE_USER_INFO(state, payload) {
    // Get Data localStorage
    const userInfo =
      JSON.parse(localStorage.getItem('userInfo')) || state.AppActiveUser

    for (const property of Object.keys(payload)) {
      if (payload[property] !== null) {
        // If some of user property is null - user default property defined in state.AppActiveUser
        state.AppActiveUser[property] = payload[property]

        // Update key in localStorage
        userInfo[property] = payload[property]
      }
    }
    // Store data in localStorage
    localStorage.setItem('userInfo', JSON.stringify(userInfo))
  },
  SET_ACTIVE_USER(state, payload) {
    state.activeUserId = payload
  },
  SET_NEED_VERIFY_STATUS_EDIT(state, status) {
    state.needVerifyStatusEdit = status
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
