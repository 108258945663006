<template>
  <div class="-m-1.5 flex flex-wrap items-center">
    <div class="m-1.5">
      <ModalBasic
        id="notes-modal"
        :modal-open="isOpen"
        :show-border-header="false"
        title="Make Offer"
        subtitle="Please describe your offer by filling the form below"
        @close-modal="close"
      >
        <Form
          ref="form"
          v-slot="{ errors }"
          :validation-schema="makeOfferSchema"
          @submit="save"
        >
          <div class="px-5">
            <div class="space-y-3">
              <div>
                <label class="mb-1 block text-xs font-bold" for="subject">
                  {{ $t('chat.discussion.offerSubject') }}
                </label>
                <Field v-slot="{ field }" v-model="subject" name="subject">
                  <input
                    id="subject"
                    v-bind="field"
                    class="form-input w-full p-2"
                    type="text"
                    required
                    :placeholder="$t('chat.discussion.offerSubjectPlaceholder')"
                  />
                </Field>
                <div v-if="errors.subject !== undefined" class="flex w-full">
                  <span class="text-sm text-red-500">
                    {{ $t(errors.subject) }}
                  </span>
                </div>
              </div>

              <div>
                <label class="mb-1 block text-xs font-bold" for="shareWith">
                  {{ $t('chat.discussion.offerTo') }}
                </label>
                <VueMultiselect
                  v-model="offerTo"
                  :options="members"
                  :multiple="true"
                  :close-on-select="true"
                  :placeholder="$t('validation.selectOfferTo')"
                  label="name"
                  track-by="id"
                >
                </VueMultiselect>
                <!-- <select
                id="shareWith"
                v-model="offerTo"
                class="form-select w-full p-2"
              >
                <option
                  v-for="member in chatMembers"
                  :key="member.user.id"
                  :value="member.user.id"
                >
                  {{ member.user.first_name }} {{ member.user.last_name }}
                </option>
              </select> -->
              </div>

              <div>
                <label class="mb-1 block text-sm font-medium" for="caseHowMuch">
                  {{ $t('newCase.caseHowMuch') }}
                </label>
                <div class="flex w-full">
                  <div class="w-10/12 pr-4">
                    <Field v-slot="{ field }" v-model="amount" name="amount">
                      <input
                        id="caseHowMuch"
                        v-bind="field"
                        class="form-input h-12 w-full rounded-md"
                        type="number"
                        :placeholder="$t('newCase.caseHowMuchPlaceholder')"
                      />
                    </Field>
                    <div v-if="errors.amount !== undefined" class="flex w-full">
                      <span class="text-sm text-red-500">
                        {{ $t(errors.amount) }}
                      </span>
                    </div>
                  </div>
                  <div class="w-2/12">
                    <select v-model="currency" class="form-input h-12 w-full">
                      <option
                        v-for="currencyData in caseCurrencies"
                        :key="currencyData.id"
                        :value="currencyData.id"
                      >
                        {{ currencyData.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="flex justify-between">
                <div class="flex flex-col pr-4">
                  <label class="mb-1 block text-xs font-bold" for="meetingTime">
                    {{ $t('chat.discussion.dueTime') }}
                  </label>
                  <!--  -->
                  <Datepicker
                    v-model="deadlineDate"
                    wrap-class="form-input h-12 w-full rounded-md pl-9"
                    mode="single"
                    :max-today="false"
                    :max-one-month="false"
                    :max-one-week="true"
                    :min-twoday="true"
                    @change-date="changeDate"
                  />
                </div>
                <!-- <div class="flex w-2/6 flex-col pr-4">
                  <label class="mb-1 block text-xs font-bold" for="from">
                    {{ $t('calendar.modalNewAgenda.from') }}
                  </label>
                  <Timepicker
                    v-model="deadlineTimeFrom"
                    :max-today="false"
                    :min-today="false"
                    wrap-class="form-input h-12 w-full rounded-md"
                    @click.stop
                  />
                </div>
                <div class="flex w-2/6 flex-col pr-4">
                  <label class="mb-1 block text-xs font-bold" for="to">
                    {{ $t('calendar.modalNewAgenda.to') }}
                  </label>
                  <Timepicker
                    v-model="deadlineTimeTo"
                    :max-today="false"
                    :min-today="false"
                    wrap-class="form-input h-12 w-full rounded-md"
                    @click.stop
                  />
                </div> -->
              </div>

              <div class="flex w-full flex-col">
                <label class="mb-1 block text-xs font-bold" for="descriptions">
                  {{ $t('chat.discussion.description') }}
                </label>
                <Field
                  v-slot="{ field }"
                  v-model="description"
                  name="description"
                >
                  <textarea
                    v-bind="field"
                    :placeholder="$t('chat.discussion.description')"
                    class="form-input w-full p-2"
                  ></textarea>
                </Field>
                <div
                  v-if="errors.description !== undefined"
                  class="flex w-full"
                >
                  <span class="text-sm text-red-500">
                    {{ $t(errors.description) }}
                  </span>
                </div>
              </div>

              <div>
                <label class="mb-1 block text-xs font-bold" for="files">
                  {{ $t('forms.uploadFile') }}
                </label>
                <div class="flex w-full items-center justify-center">
                  <Field v-model="attachment" name="attachment">
                    <label
                      for="files"
                      class="flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-gray-300 bg-gray-50 hover:bg-gray-100"
                    >
                      <div
                        class="flex flex-col items-center justify-center py-2"
                      >
                        <svg
                          aria-hidden="true"
                          class="mb-3 h-10 w-10 text-gray-400"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                          ></path>
                        </svg>
                        <p class="text-sm text-gray-500">
                          {{ $t('forms.dragAndDrop') }}
                        </p>
                        <p class="text-sm text-gray-500">
                          {{ $t('forms.or') }}
                        </p>
                        <p class="text-sm font-semibold text-primary">
                          {{ $t('forms.browseFiles') }}
                        </p>
                      </div>
                      <input
                        id="files"
                        type="file"
                        class="hidden"
                        @change="fileInput"
                      />
                      <span v-if="attachment">{{ attachment.name }}</span>
                    </label>
                  </Field>
                </div>
                <div v-if="errors.attachment !== undefined" class="flex w-full">
                  <span class="text-sm text-red-500">
                    {{ $t(errors.attachment) }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="py-4">
            <div class="flex flex-wrap justify-center space-x-2 px-4">
              <button
                type="submit"
                class="btn-sm hover:bg-primary-600 w-full whitespace-nowrap rounded-lg bg-primary p-3 text-white"
              >
                {{ $t('chat.discussion.makeOffer') }}
              </button>
            </div>
          </div>
        </Form>
      </ModalBasic>
    </div>
  </div>
</template>
<script>
import ModalBasic from './ModalBasic.vue'
import Datepicker from '@/components/Datepicker.vue'
// import Timepicker from '@/components/Timepicker.vue'

import { Form, Field } from 'vee-validate'
import * as Yup from 'yup'

export default {
  name: 'ModalMakeOfferComponent',
  components: {
    ModalBasic,
    Datepicker,
    // Timepicker,
    Form,
    Field,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    chatMembers: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  emits: ['closed', 'save', 'empty-member'],
  data: () => ({
    makeOfferSchema: Yup.object().shape({
      subject: Yup.string()
        .typeError('validation.enterSubject')
        .required('validation.enterSubject')
        .min(5, 'validation.minimumCaseName'),
      description: Yup.string()
        .typeError('validation.enterDescription')
        .required('validation.enterDescription')
        .min(5, 'validation.minimumCaseName'),
      amount: Yup.number()
        .typeError('validation.enterAmount')
        .required('validation.enterAmount')
        .test(
          'checkMinimumAmount',
          'validation.minimumCaseAmount',
          (value) => value >= 100
        ),
      attachment: Yup.mixed().required('validation.attachmentRequired'),
    }),
    caseCurrencies: [
      {
        id: 'USD',
        name: 'USD',
      },
    ],
    subject: '',
    offerTo: '',
    amount: '',
    currency: 'USD',
    dueDate: '',
    dueTime: '',
    description: '',
    file: '',
    attachment: null,
    deadlineDate: null,
    deadlineTimeFrom: null,
    deadlineTimeTo: null,
  }),
  computed: {
    members() {
      const members = []
      this.chatMembers.forEach((item) => {
        if (item.user && item.user.role.code === 'USER') {
          members.push({
            id: item.user.id,
            name: `${item.user.first_name} ${item.user.last_name}`,
            role: '',
          })
        }
      })
      return members
    },
  },
  watch: {
    isOpen(val) {
      if (!val) this.close()
      else this.$refs.form.resetForm()
    },
  },
  methods: {
    close() {
      this.$refs.form.resetForm()
      this.offerTo = []
      this.$emit('closed')
    },
    save() {
      if (this.offerTo.length <= 0) return this.$emit('empty-member')
      let params = {}
      params.content = this.description
      params.case_id = this.$route.params.slug
      params.chat_room_id = this.$route.params.id
      params.type = 'offer'
      params.title = this.subject
      params.description = this.description
      params.amount = this.amount
      params.currency = this.currency
      params.deadline_at = this.deadlineDate
      params.attachment = this.attachment
      params.party_ids = this.offerTo.map((item) => item.id)
      this.$emit('save', params)
    },
    fileInput(event) {
      this.attachment = event.target.files[0]
    },
    changeDate(val) {
      this.deadlineDate = val
    },
  },
}
</script>
