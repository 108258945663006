<template>
  <div class="flex h-screen overflow-hidden">
    <div
      class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden"
    >
      <!-- Site header -->
      <Header
        :sidebar-open="sidebarOpen"
        @toggle-sidebar="sidebarOpen = !sidebarOpen"
      />
      <main>
        <div class="w-full">
          <div class="flex h-full flex-col justify-center bg-gray-100 md:mt-10">
            <div
              class="w-full self-center px-2 py-5 text-left md:w-5/6 md:py-2 md:px-0"
            >
              <BreadcrumNewCase :active="3" :is-show="true" />
            </div>
            <div
              class="grid w-full grid-cols-2 gap-2 divide-x-2 self-center rounded-lg bg-white px-8 py-8 shadow-md md:w-5/6"
            >
              <div class="space-y-8 px-2">
                <div class="space-y-2">
                  <h1 class="text-3xl font-bold text-slate-800">
                    {{ $t('newCase.applicationSummary') }}
                  </h1>
                  <p class="text-sm">
                    {{ $t('newCase.applicationSummaryDesc') }}
                  </p>
                </div>

                <div class="space-y-2">
                  <h2 class="text-xl font-bold text-slate-800">
                    {{ $t('newCase.odrDesc') }}
                  </h2>
                  <p class="text-sm text-gray-500">
                    {{ newCaseData.description }}
                  </p>
                </div>

                <div v-if="caseType" class="space-y-2">
                  <h2 class="text-xl font-bold text-slate-800">
                    {{ $t('newCase.typeOfPart') }}
                  </h2>
                  <p class="text-sm text-gray-500">{{ caseType.name }}</p>
                </div>

                <div v-if="caseArea" class="space-y-2">
                  <h2 class="text-xl font-bold text-slate-800">
                    {{ $t('newCase.caseArea') }}
                  </h2>
                  <p class="text-sm text-gray-500">
                    {{ caseArea.name }}
                  </p>
                </div>

                <div class="space-y-2">
                  <h2 class="text-xl font-bold text-slate-800">
                    {{ $t('newCase.amountOfProcess') }}
                  </h2>
                  <Currency
                    :total-in-string="newCaseData.amount"
                    :with-prefix="false"
                    :leading-text="newCaseData.currency"
                    class="text-sm text-gray-500"
                  />
                </div>
                <div class="space-y-2">
                  <h2 class="text-xl font-bold text-slate-800">
                    {{ $t('newCase.estimatedStartDate') }}
                  </h2>
                  <p class="text-sm text-gray-500">
                    {{ formatDateDay(estimatedTimeCase()) }}
                  </p>
                </div>
              </div>
              <div class="flex flex-col justify-center pl-4 pb-10">
                <div class="space-y-2">
                  <h1 class="text-3xl font-bold text-slate-800">
                    {{ $t('newCase.partOfProcess') }}
                  </h1>
                  <p class="text-sm">
                    {{ $t('newCase.partOfProcessDesc') }}
                  </p>
                </div>
                <div class="mt-4 rounded-lg border border-gray-300 p-4">
                  <div
                    class="flex justify-between border-b border-gray-300 pb-3"
                  >
                    <h3 class="text-base font-bold text-slate-800">
                      {{ $t('newCase.caseNamePlaceholder') }}
                    </h3>
                    <p class="text-sm text-gray-500">
                      {{ newCaseData.name }}
                    </p>
                  </div>
                  <div
                    v-if="caseType"
                    class="flex justify-between border-b border-gray-300 py-3"
                  >
                    <h3 class="text-base font-bold text-slate-800">
                      {{ $t('newCase.typeOfPart') }}
                    </h3>
                    <p class="text-sm text-gray-500">
                      {{ caseType.name }}
                    </p>
                  </div>
                  <div
                    class="flex justify-between border-b border-gray-300 py-3"
                  >
                    <h3 class="text-base font-bold text-slate-800">
                      {{ $t('newCase.notificationMethod') }}
                    </h3>
                    <p class="text-sm text-gray-500">
                      {{ $t(`newCase.${newCaseData.notif_via}`) }}
                    </p>
                  </div>
                  <div class="flex flex-wrap space-y-2 py-3">
                    <h3 class="flex w-full text-base font-bold text-slate-800">
                      {{ $t('newCase.descriptionOnDemands') }}
                    </h3>
                    <p class="flex w-full text-sm text-gray-500">
                      {{ newCaseData.description }}
                    </p>
                  </div>
                </div>

                <div class="mt-4">
                  <AdvocateItem :advocate="newCaseData.advocate" />
                </div>

                <button
                  class="btn text-md hover:bg-primary-600 mt-6 h-12 w-full rounded-md bg-primary text-white"
                  @click="startCase"
                >
                  {{ $t('action.startODR') }}
                </button>
              </div>
            </div>
            <DualRingLoader v-if="isLoading" />
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import globalMixin from '@/mixins/global.js'
import caseMixin from '@/mixins/case.js'

import Header from '@/partials/Header.vue'
import BreadcrumNewCase from '@/components/case/BreadcrumNewCase.vue'
import Currency from '@/components/currency/Currency.vue'
import AdvocateItem from '@/components/AdvocateItem.vue'
import DualRingLoader from '@/components/DualRingLoader.vue'

export default {
  name: 'AdvocateSelectionPage',
  components: {
    Header,
    BreadcrumNewCase,
    Currency,
    AdvocateItem,
    DualRingLoader,
  },
  mixins: [globalMixin, caseMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const toast = useToast()
    const sidebarOpen = ref(false)

    return {
      t,
      n,
      store,
      toast,
      sidebarOpen,
    }
  },
  data: () => ({
    caseType: {},
    caseArea: {},
  }),
  mounted() {
    this.newCaseData = this.store.getters['cases/newCase']
    if (this.checkEmptyObject(this.newCaseData)) {
      return this.$router.push({
        name: 'new-case',
        query: { lang: this.$route.query.lang },
      })
    }
    this.getAllAreas().then((response) => {
      this.caseArea = this.caseAreas.find(
        (item) => item.id === this.newCaseData.case_area_id
      )
    })
    this.getAllTypes().then((response) => {
      this.caseType = this.caseTypes.find(
        (item) => item.id === this.newCaseData.case_type_id
      )
    })
  },
  methods: {
    startCase() {
      if (!this.isLoading) {
        this.createCase()
      }
    },
    async createCase() {
      this.isLoading = true
      await this.store
        .dispatch('cases/createCase', this.newCaseData)
        .then((response) => {
          this.isLoading = false
          const responseData = response.data

          this.store.dispatch('cases/resetActiveCase')
          this.store.dispatch('cases/resetNewCase')
          this.store.dispatch('cases/resetActiveNewCaseBreadcrum')

          this.toast.success(responseData.message)
          return this.$router.push({ name: 'case-submitted' })
        })
        .catch((error) => {
          this.isLoading = false
          this.toast.error(this.extractErrorResponse(error))
        })
    },
  },
}
</script>
