<template>
  <main>
    <div class="relative flex">
      <!-- Content -->
      <div class="w-full">
        <div
          class="flex h-full min-h-screen flex-col justify-center bg-gray-100"
        >
          <div class="mb-8 flex justify-center">
            <img
              class="h-14 object-cover object-center"
              src="../assets/images/logo.png"
              alt="InResolve Logo"
            />
          </div>
          <div
            class="w-2/4 self-center rounded-lg bg-white px-8 py-8 shadow-md lg:w-1/3"
          >
            <h1 class="mb-2 text-3xl font-bold text-darkYellow">
              {{ $t('login.title') }}
            </h1>
            <p class="text-justify text-sm">
              {{ $t('login.already') }}
              <router-link
                class="text-sm font-bold text-primary"
                to="/register"
              >
                {{ $t('login.signUp') }}
              </router-link>
            </p>
            <!-- Form -->

            <Form
              v-slot="{ errors }"
              :validation-schema="loginSchema"
              @submit="doLogin"
            >
              <div class="mt-4 space-y-4">
                <div>
                  <label class="mb-1 block text-sm font-medium" for="email">
                    {{ $t('formLabel.email') }}
                  </label>
                  <Field v-slot="{ field }" v-model="email" name="email">
                    <input
                      id="email"
                      v-bind="field"
                      autofocus
                      class="form-input h-12 w-full rounded-md"
                      type="email"
                      autocomplete="new-email"
                      :placeholder="$t('formInput.enterEmail')"
                    />
                  </Field>
                  <span class="text-sm text-red-500">
                    <template v-if="errors.email !== undefined">
                      {{ $t(errors.email) }}
                    </template>
                  </span>
                </div>
                <div>
                  <label class="mb-1 block text-sm font-medium" for="password">
                    {{ $t('formLabel.password') }}
                  </label>
                  <Field v-slot="{ field }" v-model="password" name="password">
                    <div class="relative">
                      <input
                        id="password"
                        v-bind="field"
                        class="form-input h-12 w-full rounded-md"
                        :type="showPassword ? 'text' : 'password'"
                        required="true"
                        autocomplete="new-password"
                        :placeholder="$t('formInput.enterPassword')"
                      />
                      <div
                        class="absolute inset-0 left-auto flex cursor-pointer items-center"
                      >
                        <span class="px-3 text-sm font-medium text-slate-400">
                          <EyeStrikeIcon
                            v-if="showPassword"
                            class="h-6 w-6"
                            @click="showPassword = false"
                          />
                          <EyeIcon
                            v-else
                            class="h-6 w-6"
                            @click="showPassword = true"
                          />
                        </span>
                      </div>
                    </div>
                  </Field>
                  <span class="text-sm text-red-500">
                    <template v-if="errors.password !== undefined">
                      {{ $t(errors.password) }}
                    </template>
                  </span>
                </div>
              </div>
              <div class="mt-2 flex flex-col">
                <div class="flex w-full">
                  <router-link
                    class="text-sm font-bold text-primary"
                    to="/reset-password"
                  >
                    {{ $t('button.forgotPassword') }}
                  </router-link>
                </div>
                <button
                  class="btn text-md mt-6 h-12 w-full rounded-md"
                  :class="[
                    errors.email === undefined && errors.password === undefined
                      ? 'hover:bg-primary-600 cursor-pointer bg-primary text-white'
                      : 'cursor-not-allowed bg-gray-300 text-slate-100',
                  ]"
                >
                  {{ $t('button.login') }}
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { useToast } from 'vue-toastification'
import { Form, Field } from 'vee-validate'
import globalMixin from '@/mixins/global.js'

import EyeIcon from '@/assets/svg/eye.svg'
import EyeStrikeIcon from '@/assets/svg/eye-strike.svg'

export default {
  name: 'LoginPage',
  components: {
    Form,
    Field,
    EyeIcon,
    EyeStrikeIcon,
  },
  mixins: [globalMixin],
  setup() {
    const toast = useToast()
    return { toast }
  },
  data: () => ({
    email: '',
    password: '',
    showPassword: false,
    activeUser: {},
  }),
  mounted() {
    this.checkUserAccess('', true)
  },
  methods: {
    async doLogin() {
      const payload = {
        email: this.email,
        password: this.password,
      }

      await this.$store
        .dispatch('auth/login', payload)
        .then((response) => {
          if (response.statusCode === 200) {
            this.$store.dispatch('auth/setActiveEmail', this.email)
            this.$router.push({
              name: 'login-otp',
              query: {
                lang: this.$route.query.lang,
              },
            })
          }
          this.toast.success(response.message)
        })
        .catch((error) => {
          this.toast.error(this.extractErrorResponse(error))
        })
    },
  },
}
</script>
