import { createRouter, createWebHistory } from 'vue-router'

// import Analytics from './pages/Analytics.vue'
// import Fintech from './pages/Fintech.vue'
// import Customers from './pages/ecommerce/Customers.vue'
// import Orders from './pages/ecommerce/Orders.vue'
// import Invoices from './pages/ecommerce/Invoices.vue'
// import Shop from './pages/ecommerce/Shop.vue'
// import Shop2 from './pages/ecommerce/Shop2.vue'
// import Product from './pages/ecommerce/Product.vue'
// import Cart from './pages/ecommerce/Cart.vue'
// import Cart2 from './pages/ecommerce/Cart2.vue'
// import Cart3 from './pages/ecommerce/Cart3.vue'
// import Pay from './pages/ecommerce/Pay.vue'
// import Campaigns from './pages/Campaigns.vue'
// import UsersTabs from './pages/community/UsersTabs.vue'
// import UsersTiles from './pages/community/UsersTiles.vue'
// import Profile from './pages/community/Profile.vue'
// import Feed from './pages/community/Feed.vue'
// import Forum from './pages/community/Forum.vue'
// import ForumPost from './pages/community/ForumPost.vue'
// import Meetups from './pages/community/Meetups.vue'
// import MeetupsPost from './pages/community/MeetupsPost.vue'
// import CreditCards from './pages/finance/CreditCards.vue'
// import Transactions from './pages/finance/Transactions.vue'
// import TransactionDetails from './pages/finance/TransactionDetails.vue'
// import JobListing from './pages/job/JobListing.vue'
// import JobPost from './pages/job/JobPost.vue'
// import CompanyProfile from './pages/job/CompanyProfile.vue'
// import TasksKanban from './pages/tasks/TasksKanban.vue'
// import TasksList from './pages/tasks/TasksList.vue'
// import Inbox from './pages/Inbox.vue'
// import Notifications from './pages/settings/Notifications.vue'
// import Apps from './pages/settings/Apps.vue'
// import Plans from './pages/settings/Plans.vue'
// import Billing from './pages/settings/Billing.vue'
// import Feedback from './pages/settings/Feedback.vue'
// import Changelog from './pages/utility/Changelog.vue'
// import Roadmap from './pages/utility/Roadmap.vue'
// import Faqs from './pages/utility/Faqs.vue'
// import EmptyState from './pages/utility/EmptyState.vue'
// import KnowledgeBase from './pages/utility/KnowledgeBase.vue'
// import Onboarding01 from './pages/Onboarding01.vue'
// import Onboarding02 from './pages/Onboarding02.vue'
// import Onboarding03 from './pages/Onboarding03.vue'
// import Onboarding04 from './pages/Onboarding04.vue'
// import ButtonPage from './pages/component/ButtonPage.vue'
// import FormPage from './pages/component/FormPage.vue'
// import DropdownPage from './pages/component/DropdownPage.vue'
// import AlertPage from './pages/component/AlertPage.vue'
// import ModalPage from './pages/component/ModalPage.vue'
// import PaginationPage from './pages/component/PaginationPage.vue'
// import TabsPage from './pages/component/TabsPage.vue'
// import BreadcrumbPage from './pages/component/BreadcrumbPage.vue'
// import BadgePage from './pages/component/BadgePage.vue'
// import AvatarPage from './pages/component/AvatarPage.vue'
// import TooltipPage from './pages/component/TooltipPage.vue'
// import AccordionPage from './pages/component/AccordionPage.vue'
// import IconsPage from './pages/component/IconsPage.vue'

import Account from './pages/settings/Account.vue'
import ProfileRating from './pages/settings/Rating.vue'
import PageNotFound from './pages/utility/PageNotFound.vue'
import Login from './pages/Login.vue'
import LoginOTP from './pages/LoginOTP.vue'
import Register from './pages/Register.vue'
import ResetPassword from './pages/ResetPassword.vue'
import Case from './pages/Case.vue'
import NewCase from './pages/NewCase.vue'
import Dashboard from './pages/Dashboard.vue'
import AdvocateRequirement from './pages/AdvocateRequirement.vue'
import AdvocateSelection from './pages/AdvocateSelection.vue'
import ReviewApplication from './pages/ReviewApplication.vue'
import CaseSubmitted from './pages/CaseSubmitted.vue'
import MediatorRequirement from './pages/MediatorRequirement.vue'
import MediatorSelection from './pages/MediatorSelection.vue'
import MediatorSubmitted from './pages/MediatorSubmitted.vue'
import ArbitratorRequirement from './pages/ArbitratorRequirement.vue'
import ArbitratorSelection from './pages/ArbitratorSelection.vue'
import ArbitratorSubmitted from './pages/ArbitratorSubmitted.vue'
import CaseStagePage from './pages/CaseStage.vue'
import PrivateNotebookPage from './pages/PrivateNotebook.vue'
import Messages from './pages/Messages.vue'
import TimelinePage from './pages/Timeline.vue'
import NotificationPage from './pages/Notification.vue'
import Calendar from './pages/Calendar.vue'
import CaseDetailPage from './pages/CaseDetail.vue'
import FinalDecision from './pages/FinalDecision.vue'
import DocumentPage from './pages/Document.vue'
import FolderPage from './pages/Folder.vue'

import AdminUserPage from './pages/admin/User.vue'
import AdminActivitiesPage from './pages/admin/Activities.vue'
import AdminDashboard from './pages/admin/Dashboard.vue'
import AdminCases from './pages/admin/Cases.vue'
import AdminCaseDetail from './pages/admin/CaseDetail.vue'

import ChatGroup from './pages/ChatGroup.vue'
import ChatDiscussion from './pages/ChatDiscussion.vue'
// import { Sidebar } from 'lucide-vue-next'

const routerHistory = createWebHistory()

const router = createRouter({
  history: routerHistory,
  routes: [
    {
      path: '/',
      name: 'index',
      component: Case,
    },
    {
      path: '/new-case',
      name: 'new-case',
      component: NewCase,
    },
    {
      path: '/advocate-requirement',
      name: 'advocate-requirement',
      component: AdvocateRequirement,
    },
    {
      path: '/advocate-selection',
      name: 'advocate-selection',
      component: AdvocateSelection,
    },
    {
      path: '/review-application',
      name: 'review-application',
      component: ReviewApplication,
    },
    {
      path: '/case-submitted',
      name: 'case-submitted',
      component: CaseSubmitted,
    },

    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/login/otp',
      name: 'login-otp',
      component: LoginOTP,
    },
    {
      path: '/register',
      name: 'register',
      component: Register,
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: ResetPassword,
    },
    // Admin routes
    {
      path: '/admin',
      name: 'admin',
      meta: { title: 'Admin', layout: 'default' },
      // component: Sidebar,
      children: [
        {
          path: 'dashboard',
          name: 'admin-dashboard',
          meta: { layout: 'default' },
          component: AdminDashboard,
        },
        {
          path: 'user',
          name: 'admin-users',
          meta: { layout: 'default' },
          component: AdminUserPage,
        },
        {
          path: 'activities',
          name: 'admin-activities',
          meta: { layout: 'default' },
          component: AdminActivitiesPage,
        },
        {
          path: 'all-cases',
          name: 'all-cases',
          meta: { layout: 'default' },
          component: AdminCases,
          props: { status: 'all' },
        },
        {
          path: 'pending-cases',
          name: 'pending-cases',
          meta: { layout: 'default' },
          component: AdminCases,
          props: { status: 'onprepare' },
        },
        {
          path: 'ongoing-cases',
          name: 'ongoing-cases',
          meta: { layout: 'default' },
          component: AdminCases,
          props: { status: 'ongoing' },
        },
        {
          path: 'settled-cases',
          name: 'settled-cases',
          meta: { layout: 'default' },
          component: AdminCases,
          props: { status: 'solved' },
        },
        {
          path: 'case-detail/:slug',
          name: 'case-detail-admin',
          meta: { layout: 'default' },
          component: AdminCaseDetail,
        },
      ],
    },
    // End of Admin routes
    {
      path: '/dashboard/:slug',
      name: 'dashboard',
      meta: { layout: 'default' },
      component: Dashboard,
    },
    {
      path: '/case-stage/:slug',
      name: 'case-stage',
      meta: { layout: 'default' },
      component: CaseStagePage,
    },
    {
      path: '/case-stage/:slug/mediator-requirement',
      name: 'mediator-requirement',
      component: MediatorRequirement,
    },
    {
      path: '/case-stage/:slug/mediator-selection',
      name: 'mediator-selection',
      component: MediatorSelection,
    },
    {
      path: '/case-stage/:slug/mediator-submitted',
      name: 'mediator-submitted',
      component: MediatorSubmitted,
    },
    {
      path: '/case-stage/:slug/arbitrator-requirement',
      name: 'arbitrator-requirement',
      component: ArbitratorRequirement,
    },
    {
      path: '/case-stage/:slug/arbitrator-selection',
      name: 'arbitrator-selection',
      component: ArbitratorSelection,
    },
    {
      path: '/case-stage/:slug/arbitrator-submitted',
      name: 'arbitrator-submitted',
      component: ArbitratorSubmitted,
    },
    {
      path: '/private-notebook/:slug',
      name: 'private-notebook',
      meta: { layout: 'default' },
      component: PrivateNotebookPage,
    },
    {
      path: '/chats/group/:slug',
      name: 'chat-group',
      meta: { layout: 'default' },
      component: ChatGroup,
    },
    {
      path: '/chats/:id/:slug',
      name: 'chats',
      meta: { layout: 'default' },
      component: ChatDiscussion,
    },
    {
      path: '/documents/:slug',
      name: 'documents',
      meta: { layout: 'default' },
      component: DocumentPage,
    },
    {
      path: '/documents/:slug/folder/:folderId',
      name: 'folder',
      meta: { layout: 'default' },
      component: FolderPage,
    },
    {
      path: '/timeline/:slug',
      name: 'timeline',
      meta: { layout: 'default' },
      component: TimelinePage,
    },
    {
      path: '/notification',
      name: 'notification',
      meta: { layout: 'default' },
      component: NotificationPage,
    },
    {
      path: '/notification/:slug',
      name: 'notification-case',
      meta: { layout: 'default' },
      component: NotificationPage,
    },
    {
      path: '/calendar/:slug',
      name: 'calendar',
      meta: { layout: 'default' },
      component: Calendar,
    },
    {
      path: '/case-detail/:slug',
      name: 'case-detail',
      meta: { layout: 'default' },
      component: CaseDetailPage,
    },
    {
      path: '/final-decision/:slug',
      name: 'final-decision',
      meta: { layout: 'default' },
      component: FinalDecision,
    },
    // Customer Service Route
    {
      path: '/customer-service',
      name: 'customer-service',
      meta: { title: 'Customer Service', layout: 'default' },
      // component: Sidebar,
      children: [
        {
          path: 'all',
          name: 'all-cs-chat',
          meta: { layout: 'default' },
          component: Messages,
          props: { status: 'all' },
        },
        {
          path: 'unanswered',
          name: 'unanswered-cs-chat',
          meta: { layout: 'default' },
          component: Messages,
          props: { status: 'unanswered' },
        },
        {
          path: 'answered',
          name: 'answered-cs-chat',
          meta: { layout: 'default' },
          component: Messages,
          props: { status: 'answered' },
        },
        {
          path: 'archived',
          name: 'archived-cs-chat',
          meta: { layout: 'default' },
          component: Messages,
          props: { status: 'archived' },
        },
      ],
    },

    // End of Customer Service Route

    // TEMPLATE PAGES
    // {
    //   path: '/dashboard/analytics',
    //   component: Analytics,
    // },
    // {
    //   path: '/dashboard/fintech',
    //   component: Fintech,
    // },
    // {
    //   path: '/ecommerce/customers',
    //   component: Customers,
    // },
    // {
    //   path: '/ecommerce/orders',
    //   component: Orders,
    // },
    // {
    //   path: '/ecommerce/invoices',
    //   component: Invoices,
    // },
    // {
    //   path: '/ecommerce/shop',
    //   component: Shop,
    // },
    // {
    //   path: '/ecommerce/shop-2',
    //   component: Shop2,
    // },
    // {
    //   path: '/ecommerce/product',
    //   component: Product,
    // },
    // {
    //   path: '/ecommerce/cart',
    //   component: Cart,
    // },
    // {
    //   path: '/ecommerce/cart-2',
    //   component: Cart2,
    // },
    // {
    //   path: '/ecommerce/cart-3',
    //   component: Cart3,
    // },
    // {
    //   path: '/ecommerce/pay',
    //   component: Pay,
    // },
    // {
    //   path: '/campaigns',
    //   component: Campaigns,
    // },
    // {
    //   path: '/community/users-tabs',
    //   component: UsersTabs,
    // },
    // {
    //   path: '/community/users-tiles',
    //   component: UsersTiles,
    // },
    // {
    //   path: '/community/profile',
    //   component: Profile,
    // },
    // {
    //   path: '/community/feed',
    //   component: Feed,
    // },
    // {
    //   path: '/community/forum',
    //   component: Forum,
    // },
    // {
    //   path: '/community/forum-post',
    //   component: ForumPost,
    // },
    // {
    //   path: '/community/meetups',
    //   component: Meetups,
    // },
    // {
    //   path: '/community/meetups-post',
    //   component: MeetupsPost,
    // },
    // {
    //   path: '/finance/cards',
    //   component: CreditCards,
    // },
    // {
    //   path: '/finance/transactions',
    //   component: Transactions,
    // },
    // {
    //   path: '/finance/transaction-details',
    //   component: TransactionDetails,
    // },
    // {
    //   path: '/job/job-listing',
    //   component: JobListing,
    // },
    // {
    //   path: '/job/job-post',
    //   component: JobPost,
    // },
    // {
    //   path: '/job/company-profile',
    //   component: CompanyProfile,
    // },
    // {
    //   path: '/messages',
    //   component: Messages,
    // },
    // {
    //   path: '/tasks/kanban',
    //   component: TasksKanban,
    // },
    // {
    //   path: '/tasks/list',
    //   component: TasksList,
    // },
    // {
    //   path: '/inbox',
    //   component: Inbox,
    // },

    {
      path: '/profile/account',
      component: Account,
    },
    {
      path: '/profile/ratings',
      component: ProfileRating,
    },
    // // {
    // //   path: '/settings/notifications',
    // //   component: Notifications,
    // // },
    // // {
    // //   path: '/settings/apps',
    // //   component: Apps,
    // // },
    // // {
    // //   path: '/settings/plans',
    // //   component: Plans,
    // // },
    // // {
    // //   path: '/settings/billing',
    // //   component: Billing,
    // // },
    // {
    //   path: '/settings/feedback',
    //   component: Feedback,
    // },
    // {
    //   path: '/utility/changelog',
    //   component: Changelog,
    // },
    // {
    //   path: '/utility/roadmap',
    //   component: Roadmap,
    // },
    // {
    //   path: '/utility/faqs',
    //   component: Faqs,
    // },
    // {
    //   path: '/utility/empty-state',
    //   component: EmptyState,
    // },
    // {
    //   path: '/utility/404',
    //   component: PageNotFound,
    // },
    // {
    //   path: '/utility/knowledge-base',
    //   component: KnowledgeBase,
    // },
    // {
    //   path: '/onboarding-01',
    //   component: Onboarding01,
    // },
    // {
    //   path: '/onboarding-02',
    //   component: Onboarding02,
    // },
    // {
    //   path: '/onboarding-03',
    //   component: Onboarding03,
    // },
    // {
    //   path: '/onboarding-04',
    //   component: Onboarding04,
    // },
    // {
    //   path: '/component/button',
    //   component: ButtonPage,
    // },
    // {
    //   path: '/component/form',
    //   component: FormPage,
    // },
    // {
    //   path: '/component/dropdown',
    //   component: DropdownPage,
    // },
    // {
    //   path: '/component/alert',
    //   component: AlertPage,
    // },
    // {
    //   path: '/component/modal',
    //   component: ModalPage,
    // },
    // {
    //   path: '/component/pagination',
    //   component: PaginationPage,
    // },
    // {
    //   path: '/component/tabs',
    //   component: TabsPage,
    // },
    // {
    //   path: '/component/breadcrumb',
    //   component: BreadcrumbPage,
    // },
    // {
    //   path: '/component/badge',
    //   component: BadgePage,
    // },
    // {
    //   path: '/component/avatar',
    //   component: AvatarPage,
    // },
    // {
    //   path: '/component/tooltip',
    //   component: TooltipPage,
    // },
    // {
    //   path: '/component/accordion',
    //   component: AccordionPage,
    // },
    // {
    //   path: '/component/icons',
    //   component: IconsPage,
    // },

    {
      path: '/:pathMatch(.*)*',
      component: PageNotFound,
    },
  ],
})

export default router
