<template>
  <div>
    <!-- Sidebar backdrop (mobile only) -->
    <div
      class="fixed inset-0 z-40 bg-white bg-opacity-30 transition-opacity duration-200 lg:z-auto lg:hidden"
      :class="sidebarOpen ? 'opacity-100' : 'pointer-events-none opacity-0'"
      aria-hidden="true"
    ></div>

    <!-- Sidebar -->
    <div
      id="sidebar"
      ref="sidebar"
      class="no-scrollbar lg:sidebar-expanded:!w-64 absolute left-0 top-0 z-40 flex h-screen w-64 shrink-0 transform flex-col overflow-y-scroll bg-white p-4 transition-all duration-200 ease-in-out lg:static lg:left-auto lg:top-auto lg:w-20 lg:translate-x-0 lg:overflow-y-auto 2xl:!w-64"
      :class="sidebarOpen ? 'translate-x-0' : '-translate-x-64'"
    >
      <!-- Sidebar header -->
      <div class="mb-10 flex flex-col pr-3 sm:px-2">
        <div
          v-if="
            [
              'BANK_LAWYER',
              'USER',
              'REDEK_ADVOCATE',
              'REDEK_MEDIATOR',
              'REDEK_ARBITRATOR',
            ].includes(activeUserRole)
          "
          class="flex w-full cursor-pointer flex-row items-center"
          @click="
            $router.push({
              name: 'index',
              query: {
                name: $route.query.name,
                status: $route.query.status,
                sub_status: $route.query.sub_status,
                lang: $route.query.lang,
              },
            })
          "
        >
          <ArrowBackIcon
            class="h-7 w-7 fill-current text-slate-500"
            :class="sidebarExpanded && 'w-3'"
          />
          <span
            v-if="sidebarExpanded"
            class="hover:text-primary-400 ml-2 text-xs text-slate-500"
            >{{ $t('action.back') }}</span
          >
        </div>
        <div class="mt-12 flex w-full justify-center">
          <!-- Logo -->
          <template v-if="sidebarExpanded === true">
            <img
              class="w-36 object-cover object-center"
              src="/src/assets/images/logo.png"
              alt="InResolve Logo"
            />
          </template>
          <template v-else>
            <img
              class="w-8 object-cover object-center"
              src="/src/assets/images/icon.png"
              alt="InResolve Icon"
            />
          </template>
        </div>
      </div>

      <!-- Links -->
      <div>
        <template
          v-if="
            [
              'BANK_LAWYER',
              'USER',
              'REDEK_ADVOCATE',
              'REDEK_MEDIATOR',
              'REDEK_ARBITRATOR',
            ].includes(activeUserRole)
          "
        >
          <!-- Pages group -->
          <div>
            <ul>
              <!-- Dashboard -->
              <li
                class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                :class="sidebarExpanded && 'px-4'"
              >
                <a
                  class="block truncate text-slate-400 transition duration-150 hover:text-primary"
                  @click="openLink('dashboard')"
                >
                  <div class="flex items-center">
                    <DashboardIcon
                      class="h-6 w-6 shrink-0"
                      :class="
                        currentRouteName === 'dashboard' && 'text-primary-300'
                      "
                    />
                    <span
                      class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium duration-200 lg:opacity-0 2xl:opacity-100"
                      :class="
                        currentRouteName === 'dashboard' && 'text-primary-400'
                      "
                    >
                      {{ $t('menu.dashboard') }}
                    </span>
                  </div>
                </a>
              </li>

              <!-- Final Decision -->
              <li
                v-if="caseState === 'solved' || parseInt(caseState) === 5"
                class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                :class="sidebarExpanded && 'px-4'"
              >
                <a
                  class="block truncate text-slate-400 transition duration-150 hover:text-primary"
                  @click="openLink('final-decision')"
                >
                  <div class="flex items-center">
                    <FinalIcon
                      class="h-6 w-6 shrink-0"
                      :class="
                        currentRoute.fullPath.includes('final-decision') &&
                        'text-primary-400'
                      "
                    />

                    <span
                      class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium duration-200 lg:opacity-0 2xl:opacity-100"
                      :class="
                        currentRoute.fullPath.includes('final-decision') &&
                        'text-primary-400'
                      "
                    >
                      {{ $t('menu.finalDecision') }}
                    </span>
                  </div>
                </a>
              </li>

              <!-- Case Stage -->
              <li
                class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                :class="sidebarExpanded && 'px-4'"
              >
                <a
                  class="block truncate text-slate-400 transition duration-150 hover:text-primary"
                  @click="openLink('case-stage')"
                >
                  <div class="flex items-center">
                    <CaseStageIcon
                      class="h-6 w-6 shrink-0"
                      :class="
                        currentRoute.fullPath.includes('case-stage') &&
                        '!text-primary'
                      "
                    />
                    <span
                      class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium duration-200 lg:opacity-0 2xl:opacity-100"
                      :class="
                        currentRoute.fullPath.includes('case-stage') &&
                        'text-primary-400'
                      "
                    >
                      {{ $t('menu.caseStage') }}
                    </span>
                  </div>
                </a>
              </li>
              <!-- PrivateNotebook -->
              <li
                class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                :class="sidebarExpanded && 'px-4'"
              >
                <a
                  class="block truncate text-slate-400 transition duration-150 hover:text-primary"
                  @click="openLink('private-notebook')"
                >
                  <div class="flex items-center">
                    <PrivateNoteIcon
                      class="h-6 w-6 shrink-0"
                      :class="
                        currentRoute.fullPath.includes('private-notebook') &&
                        '!text-primary'
                      "
                    />
                    <span
                      class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium duration-200 lg:opacity-0 2xl:opacity-100"
                      :class="
                        currentRoute.fullPath.includes('private-notebook') &&
                        'text-primary-400'
                      "
                    >
                      {{ $t('menu.privateNotebook') }}
                    </span>
                  </div>
                </a>
              </li>
            </ul>
          </div>

          <div class="mt-5">
            <h3
              class="pl-2 text-xs font-semibold uppercase text-slate-500"
              :class="sidebarExpanded && 'pl-4'"
            >
              <span class="lg:sidebar-expanded:block lg:hidden 2xl:block">
                {{ $t('menu.communication.title') }}
              </span>
            </h3>
            <ul class="mt-3" :class="sidebarExpanded && 'pl-2'">
              <li
                class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                :class="sidebarExpanded && 'px-4'"
              >
                <a
                  class="block truncate text-slate-400 transition duration-150 hover:text-primary"
                  @click="openLink('chat-group')"
                >
                  <div class="flex items-center">
                    <ChatIcon
                      class="h-6 w-6 shrink-0"
                      :class="
                        currentRoute.fullPath.includes('chats') &&
                        '!text-primary'
                      "
                    />
                    <span
                      class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium duration-200 lg:opacity-0 2xl:opacity-100"
                      :class="
                        currentRoute.fullPath.includes('chats') &&
                        'text-primary-400'
                      "
                    >
                      {{ $t('menu.communication.chats') }}
                    </span>
                  </div>
                </a>
              </li>
            </ul>
            <!-- <ul class="mt-3" :class="sidebarExpanded && 'pl-2'">
            <li
              class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
              :class="sidebarExpanded && 'px-4'"
            >
              <a
                class="block truncate text-slate-400 transition duration-150 hover:text-primary"
                @click="openLink('customer-service-chats')"
              >
                <div class="flex items-center">
                 
                  <ChatIcon
                    class="h-6 w-6 shrink-0"
                    :class="
                      currentRoute.fullPath.includes('customer-service') && '!text-primary'
                    "
                  />

                  <span
                    class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium duration-200 lg:opacity-0 2xl:opacity-100"
                    :class="
                      currentRoute.fullPath.includes('customer-service') &&
                      'text-primary-400'
                    "
                    >Customer service chat</span
                  >
                </div>
              </a>
            </li>
          </ul> -->
          </div>

          <div class="mt-3">
            <ul>
              <!-- Documents -->
              <li
                class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                :class="sidebarExpanded && 'px-4'"
              >
                <a
                  class="block truncate text-slate-400 transition duration-150 hover:text-primary"
                  @click="openLink('documents')"
                >
                  <div class="flex items-center">
                    <DocumentIcon
                      class="h-6 w-6 shrink-0"
                      :class="
                        currentRoute.fullPath.includes('documents') &&
                        '!text-primary'
                      "
                    />
                    <span
                      class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium duration-200 lg:opacity-0 2xl:opacity-100"
                      :class="
                        currentRoute.fullPath.includes('documents') &&
                        'text-primary-400'
                      "
                      >{{ $t('menu.documents') }}</span
                    >
                  </div>
                </a>
              </li>

              <!-- Timelines -->
              <li
                class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                :class="sidebarExpanded && 'px-4'"
              >
                <a
                  class="block truncate text-slate-400 transition duration-150 hover:text-primary"
                  @click="openLink('timeline')"
                >
                  <div class="flex items-center">
                    <TimelineIcon
                      class="h-6 w-6 shrink-0"
                      :class="
                        currentRoute.fullPath.includes('timeline') &&
                        '!text-primary'
                      "
                    />
                    <span
                      class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium duration-200 lg:opacity-0 2xl:opacity-100"
                      :class="
                        currentRoute.fullPath.includes('timeline') &&
                        'text-primary-400'
                      "
                      >{{ $t('menu.timeline') }}</span
                    >
                  </div>
                </a>
              </li>

              <!-- Calendar -->
              <li
                class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                :class="sidebarExpanded && 'px-4'"
              >
                <a
                  class="block truncate text-slate-400 transition duration-150 hover:text-primary"
                  @click="openLink('calendar')"
                >
                  <div class="flex items-center">
                    <CalendarIcon
                      class="h-6 w-6 shrink-0"
                      Icon:class="
                        currentRoute.fullPath.includes('calendar') &&
                        '!text-primary'
                      "
                    />
                    <span
                      class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium duration-200 lg:opacity-0 2xl:opacity-100"
                      :class="
                        currentRoute.fullPath.includes('calendar') &&
                        'text-primary-400'
                      "
                      >{{ $t('menu.calendar') }}</span
                    >
                  </div>
                </a>
              </li>

              <!-- Case Detail -->
              <li
                class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                :class="sidebarExpanded && 'px-4'"
              >
                <a
                  class="block truncate text-slate-400 transition duration-150 hover:text-primary"
                  @click="openLink('case-detail')"
                >
                  <div class="flex items-center">
                    <CaseDetailIcon
                      class="h-6 w-6 shrink-0"
                      :class="
                        currentRoute.fullPath.includes('case-detail') &&
                        '!text-primary'
                      "
                    />
                    <span
                      class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium duration-200 lg:opacity-0 2xl:opacity-100"
                      :class="
                        currentRoute.fullPath.includes('case-detail') &&
                        'text-primary-400'
                      "
                      >{{ $t('menu.caseDetails') }}</span
                    >
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </template>

        <!-- Admin Menu -->
        <template v-if="activeUserRole === 'REDEK_ADMIN'">
          <div class="mt-10">
            <ul>
              <!-- Dashboard -->
              <li
                class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                :class="sidebarExpanded && 'px-4'"
              >
                <a
                  class="block truncate text-slate-400 transition duration-150 hover:text-primary"
                  @click="openLink('admin-dashboard')"
                >
                  <div class="flex items-center">
                    <DashboardIcon
                      class="h-6 w-6 shrink-0"
                      :class="
                        currentRouteName === 'admin-dashboard' &&
                        'text-primary-300'
                      "
                    />
                    <span
                      class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium duration-200 lg:opacity-0 2xl:opacity-100"
                      :class="
                        currentRouteName === 'admin-dashboard' &&
                        'text-primary-400'
                      "
                    >
                      {{ $t('menu.dashboard') }}
                    </span>
                  </div>
                </a>
              </li>
              <!-- Users -->
              <li
                class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                :class="sidebarExpanded && 'px-4'"
              >
                <a
                  class="block truncate text-slate-400 transition duration-150 hover:text-primary"
                  @click="openLink('admin-users')"
                >
                  <div class="flex items-center">
                    <UserIcon
                      class="h-6 w-6 shrink-0"
                      :class="
                        currentRouteName === 'admin-users' && 'text-primary-300'
                      "
                    />
                    <span
                      class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium duration-200 lg:opacity-0 2xl:opacity-100"
                      :class="
                        currentRouteName === 'admin-users' && 'text-primary-400'
                      "
                    >
                      {{ $t('menu.user') }}
                    </span>
                  </div>
                </a>
              </li>
              <!-- <li
                class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                :class="sidebarExpanded && 'px-4'"
              >
                <a
                  class="block truncate text-slate-400 transition duration-150 hover:text-primary"
                  @click="openLink('admin-activities')"
                >
                  <div class="flex items-center">
                    <TimelineIcon
                      class="h-6 w-6 shrink-0"
                      :class="
                        currentRouteName === 'admin-activities' &&
                        'text-primary-300'
                      "
                    />
                    <span
                      class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium duration-200 lg:opacity-0 2xl:opacity-100"
                      :class="
                        currentRouteName === 'admin-activities' &&
                        'text-primary-400'
                      "
                    >
                      {{ $t('menu.timeline') }}
                    </span>
                  </div>
                </a>
              </li> -->
            </ul>
          </div>
          <div class="mt-5">
            <h3
              class="pl-2 text-xs font-semibold uppercase text-slate-500"
              :class="sidebarExpanded && 'pl-4'"
            >
              <span class="lg:sidebar-expanded:block lg:hidden 2xl:block">
                {{ $t('menu.cases.title') }}
              </span>
            </h3>
            <ul class="mt-3" :class="sidebarExpanded && 'pl-2'">
              <li
                class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                :class="sidebarExpanded && 'px-4'"
              >
                <a
                  class="block truncate text-slate-400 transition duration-150 hover:text-primary"
                  @click="openLink('all-cases')"
                >
                  <div class="flex items-center">
                    <CaseDetailIcon
                      class="h-6 w-6 shrink-0"
                      :class="
                        currentRouteName === 'all-cases' && '!text-primary'
                      "
                    />
                    <span
                      class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium duration-200 lg:opacity-0 2xl:opacity-100"
                      :class="
                        currentRouteName === 'all-cases' && 'text-primary-400'
                      "
                    >
                      {{ $t('menu.cases.allCase') }}
                    </span>
                  </div>
                </a>
              </li>
              <li
                class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                :class="sidebarExpanded && 'px-4'"
              >
                <a
                  class="block truncate text-slate-400 transition duration-150 hover:text-primary"
                  @click="openLink('pending-cases')"
                >
                  <div class="flex items-center">
                    <TimelineIcon
                      class="h-6 w-6 shrink-0"
                      :class="
                        currentRouteName === 'pending-cases' && '!text-primary'
                      "
                    />
                    <span
                      class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium duration-200 lg:opacity-0 2xl:opacity-100"
                      :class="
                        currentRouteName === 'pending-cases' &&
                        'text-primary-400'
                      "
                    >
                      {{ $t('menu.cases.pendingCase') }}
                    </span>
                  </div>
                </a>
              </li>
              <li
                class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                :class="sidebarExpanded && 'px-4'"
              >
                <a
                  class="block truncate text-slate-400 transition duration-150 hover:text-primary"
                  @click="openLink('ongoing-cases')"
                >
                  <div class="flex items-center">
                    <UserGroupIcon
                      class="h-6 w-6 shrink-0"
                      :class="
                        currentRouteName === 'ongoing-cases' && '!text-primary'
                      "
                    />
                    <span
                      class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium duration-200 lg:opacity-0 2xl:opacity-100"
                      :class="
                        currentRouteName === 'ongoing-cases' &&
                        'text-primary-400'
                      "
                    >
                      {{ $t('menu.cases.ongoingCase') }}
                    </span>
                  </div>
                </a>
              </li>
              <li
                class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                :class="sidebarExpanded && 'px-4'"
              >
                <a
                  class="block truncate text-slate-400 transition duration-150 hover:text-primary"
                  @click="openLink('settled-cases')"
                >
                  <div class="flex items-center">
                    <FinalIcon
                      class="h-6 w-6 shrink-0"
                      :class="
                        currentRouteName === 'settled-cases' && '!text-primary'
                      "
                    />
                    <span
                      class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium duration-200 lg:opacity-0 2xl:opacity-100"
                      :class="
                        currentRouteName === 'settled-cases' &&
                        'text-primary-400'
                      "
                    >
                      {{ $t('menu.cases.settledCase') }}
                    </span>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div class="mt-2">
            <ul>
              <!-- Dashboard -->
              <li
                class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                :class="sidebarExpanded && 'px-4'"
              >
                <span
                  class="block truncate text-red-400 transition duration-150 hover:text-primary"
                  @click="logout"
                >
                  <div class="flex items-center">
                    <LogoutIcon class="h-6 w-6 shrink-0 text-red-700" />
                    <span
                      class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium text-red-400 duration-200 lg:opacity-0 2xl:opacity-100"
                    >
                      {{ $t('menu.logout') }}
                    </span>
                  </div>
                </span>
              </li>
            </ul>
          </div>
          <!-- End  of Admin Menu -->
        </template>

        <!-- PCC (Menu) -->
        <template v-if="activeUserRole === 'REDEK_CS'">
          <div class="mt-5">
            <h3
              class="pl-2 text-xs font-semibold uppercase text-slate-500"
              :class="sidebarExpanded && 'pl-4'"
            >
              <span class="lg:sidebar-expanded:block lg:hidden 2xl:block">
                {{ $t('menu.inbox.title') }}
              </span>
            </h3>
            <ul class="mt-3" :class="sidebarExpanded && 'pl-2'">
              <li
                class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                :class="sidebarExpanded && 'px-4'"
              >
                <a
                  class="block truncate text-slate-400 transition duration-150 hover:text-primary"
                  @click="openLink('all-cs-chat')"
                >
                  <div class="flex items-center">
                    <MessageIcon
                      class="h-6 w-6 shrink-0"
                      :class="
                        currentRouteName === 'all-cs-chat' && 'text-primary'
                      "
                    />

                    <span
                      class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium duration-200 lg:opacity-0 2xl:opacity-100"
                      :class="
                        currentRouteName === 'all-cs-chat' && 'text-primary-400'
                      "
                    >
                      {{ $t('menu.inbox.allInbox') }}
                    </span>
                  </div>
                </a>
              </li>
              <li
                class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                :class="sidebarExpanded && 'px-4'"
              >
                <a
                  class="block truncate text-slate-400 transition duration-150 hover:text-primary"
                  @click="openLink('unanswered-cs-chat')"
                >
                  <div class="flex items-center">
                    <InfoIcon
                      class="h-6 w-6 shrink-0"
                      :class="
                        currentRouteName === 'unanswered-cs-chat' &&
                        '!text-primary'
                      "
                    />

                    <span
                      class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium duration-200 lg:opacity-0 2xl:opacity-100"
                      :class="
                        currentRouteName === 'unanswered-cs-chat' &&
                        'text-primary-400'
                      "
                    >
                      {{ $t('menu.inbox.unanswered') }}
                    </span>
                  </div>
                </a>
              </li>
              <li
                class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                :class="sidebarExpanded && 'px-4'"
              >
                <a
                  class="block truncate text-slate-400 transition duration-150 hover:text-primary"
                  @click="openLink('answered-cs-chat')"
                >
                  <div class="flex items-center">
                    <UserGroupIcon
                      class="h-6 w-6 shrink-0"
                      :class="
                        currentRouteName === 'answered-cs-chat' &&
                        '!text-primary'
                      "
                    />

                    <span
                      class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium duration-200 lg:opacity-0 2xl:opacity-100"
                      :class="
                        currentRouteName === 'answered-cs-chat' &&
                        'text-primary-400'
                      "
                    >
                      {{ $t('menu.inbox.answered') }}
                    </span>
                  </div>
                </a>
              </li>
              <li
                class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                :class="sidebarExpanded && 'px-4'"
              >
                <a
                  class="block truncate text-slate-400 transition duration-150 hover:text-primary"
                  @click="openLink('archived-cs-chat')"
                >
                  <div class="flex items-center">
                    <CaseDetailIcon
                      class="h-6 w-6 shrink-0"
                      :class="
                        currentRouteName === 'archived-cs-chat' &&
                        '!text-primary'
                      "
                    />

                    <span
                      class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium duration-200 lg:opacity-0 2xl:opacity-100"
                      :class="
                        currentRouteName === 'archived-cs-chat' &&
                        'text-primary-400'
                      "
                    >
                      {{ $t('menu.inbox.archived') }}
                    </span>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div class="mt-2">
            <ul>
              <!-- Dashboard -->
              <li
                class="mb-0.5 cursor-pointer rounded-sm px-2 py-3 last:mb-0"
                :class="sidebarExpanded && 'px-4'"
              >
                <span
                  class="block truncate text-red-400 transition duration-150 hover:text-primary"
                  @click="logout"
                >
                  <div class="flex items-center">
                    <LogoutIcon class="h-6 w-6 shrink-0 text-red-700" />
                    <span
                      class="lg:sidebar-expanded:opacity-100 ml-3 text-sm font-medium text-red-400 duration-200 lg:opacity-0 2xl:opacity-100"
                    >
                      {{ $t('menu.logout') }}
                    </span>
                  </div>
                </span>
              </li>
            </ul>
          </div>
          <!-- End of PCC Menu -->
        </template>
      </div>

      <!-- Expand / collapse button -->
      <div
        class="mt-auto hidden justify-between pt-3 lg:inline-flex 2xl:hidden"
      >
        <div
          class="flex justify-start pl-2"
          :class="!sidebarExpanded && 'hidden'"
        >
          <Languages direction="top" />
        </div>
        <div class="flex py-2">
          <button @click.prevent="sidebarExpanded = !sidebarExpanded">
            <span class="sr-only">Expand / collapse sidebar</span>
            <svg
              class="h-10 w-12"
              :class="!sidebarExpanded && 'rotate-180'"
              viewBox="0 0 50 42"
              fill="none"
            >
              <path
                d="M33.0645 15.0566L28.2258 19.8114L33.0645 24.5661"
                stroke="#6F6C99"
                stroke-width="2.4"
              />
              <path
                d="M23.3871 16.6416L20.1613 19.8114L23.3871 22.9812"
                stroke="#6F6C99"
                stroke-width="2.4"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

import Languages from '@/components/DropdownLanguages.vue'

import FinalIcon from '@/assets/svg/final.svg'
import DashboardIcon from '@/assets/svg/dashboard.svg'
import CaseStageIcon from '@/assets/svg/case_stage.svg'
import PrivateNoteIcon from '@/assets/svg/private_note.svg'
import ChatIcon from '@/assets/svg/chat.svg'
import DocumentIcon from '@/assets/svg/document.svg'
import TimelineIcon from '@/assets/svg/timeline.svg'
import CalendarIcon from '@/assets/svg/calendar.svg'
import CaseDetailIcon from '@/assets/svg/case_detail.svg'
import UserIcon from '@/assets/svg/profile.svg'
import UserGroupIcon from '@/assets/svg/user_group.svg'
import MessageIcon from '@/assets/svg/message.svg'
import InfoIcon from '@/assets/svg/info.svg'
import LogoutIcon from '@/assets/svg/logout.svg'
import ArrowBackIcon from '@/assets/svg/arrow-back.svg'

export default {
  name: 'SidebarPartials',
  components: {
    Languages,
    FinalIcon,
    DashboardIcon,
    CaseStageIcon,
    PrivateNoteIcon,
    ChatIcon,
    DocumentIcon,
    TimelineIcon,
    CalendarIcon,
    CaseDetailIcon,
    UserIcon,
    UserGroupIcon,
    MessageIcon,
    InfoIcon,
    LogoutIcon,
    ArrowBackIcon,
  },
  props: ['sidebarOpen', 'caseState'],
  setup(props, { emit }) {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const currentRoute = router.currentRoute.value
    const currentRouteName = route.name
    const trigger = ref(null)
    const sidebar = ref(null)

    const activeUserRole = ref(null)
    const activeUser = store.getters['auth/activeUser']
    if (!activeUser) {
      router.push({ name: 'login' })
    } else {
      activeUserRole.value = activeUser.role.code.toUpperCase()
    }

    const storedSidebarExpanded = localStorage.getItem('sidebar-expanded')
    const sidebarExpanded = ref(
      storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true'
    )

    // close on click outside
    const clickHandler = ({ target }) => {
      if (!sidebar.value || !trigger.value) return
      if (
        !props.sidebarOpen ||
        sidebar.value.contains(target) ||
        trigger.value.contains(target)
      )
        return
      emit('close-sidebar')
    }

    // close if the esc key is pressed
    const keyHandler = ({ keyCode }) => {
      if (!props.sidebarOpen || keyCode !== 27) return
      emit('close-sidebar')
    }

    const openLink = (routeName) => {
      router.push({
        name: routeName,
        params: { slug: route.params.slug },
        query: {
          name: route.query.name,
          status: route.query.status,
          sub_status: route.query.sub_status,
          lang: route.query.lang,
        },
      })
    }

    const logout = () => {
      store.dispatch('auth/logout')
      router.push({ name: 'login' })
    }

    onMounted(() => {
      document.addEventListener('click', clickHandler)
      document.addEventListener('keydown', keyHandler)
    })

    onUnmounted(() => {
      document.removeEventListener('click', clickHandler)
      document.removeEventListener('keydown', keyHandler)
    })

    watch(sidebarExpanded, () => {
      localStorage.setItem('sidebar-expanded', sidebarExpanded.value)
      if (sidebarExpanded.value) {
        document.querySelector('body').classList.add('sidebar-expanded')
      } else {
        document.querySelector('body').classList.remove('sidebar-expanded')
      }
    })

    return {
      store,
      router,
      activeUser,
      activeUserRole,
      trigger,
      sidebar,
      sidebarExpanded,
      currentRoute,
      currentRouteName,
      openLink,
      logout,
    }
  },
}
</script>
