<template>
  <div class="-m-1.5 flex flex-wrap items-center">
    <div class="m-1.5">
      <ModalBasic
        id="notes-modal"
        :backdrop="true"
        :modal-open="isOpen"
        :show-border-header="false"
        :title="$t('calendar.modalNewAgenda.title')"
        @close-modal="close"
      >
        <Form
          ref="form"
          v-slot="{ errors }"
          :validation-schema="newAgendaSchema"
          @submit="confirm"
        >
          <div class="px-5 pb-4">
            <div class="space-y-5">
              <div>
                <label class="mb-1 block text-xs font-bold" for="title">
                  {{ $t('calendar.modalNewAgenda.formTitle') }}
                </label>
                <Field v-slot="{ field }" v-model="title" name="title">
                  <input
                    v-bind="field"
                    id="title"
                    class="form-input w-full p-2"
                    type="text"
                    required
                    :placeholder="$t('calendar.modalNewAgenda.formTitle')"
                  />
                </Field>
                <div v-if="errors.title !== undefined" class="flex w-full">
                  <span class="text-sm text-red-500">
                    {{ $t(errors.title) }}
                  </span>
                </div>
              </div>
              <div>
                <label class="mb-1 block text-xs font-bold" for="agendaTypes">
                  {{ $t('calendar.modalNewAgenda.agendaType') }}
                </label>
                <Field v-slot="{ field }" v-model="type" name="type">
                  <select
                    id="agendaTypes"
                    v-bind="field"
                    class="form-select w-full p-2"
                    @change="changeType"
                  >
                    <option
                      v-for="agendaType in agendaTypes"
                      :key="agendaType.id"
                      :value="agendaType.id"
                    >
                      {{ agendaType.name }}
                    </option>
                  </select>
                </Field>
                <div v-if="errors.type !== undefined" class="flex w-full">
                  <span class="text-sm text-red-500">
                    {{ $t(errors.type) }}
                  </span>
                </div>
              </div>
              <div v-if="isMeeting">
                <label class="mb-1 block text-xs font-bold" for="meetingTypes">
                  {{ $t('calendar.modalNewAgenda.meetingType') }}
                </label>
                <select
                  id="meetingTypes"
                  v-model="meeting_type"
                  class="form-select w-full p-2"
                >
                  <option
                    v-for="meetingType in meetingTypes"
                    :key="meetingType.id"
                    :value="meetingType.id"
                  >
                    {{ meetingType.name }}
                  </option>
                </select>
              </div>
              <div v-if="isMeeting">
                <label
                  class="mb-1 block text-xs font-bold"
                  for="meetingPlatform"
                >
                  {{ $t('calendar.modalNewAgenda.meetingPlatform') }}
                </label>
                <Field
                  v-slot="{ field }"
                  v-model="meeting_platform"
                  name="meeting_platform"
                >
                  <div class="flex pt-2">
                    <div
                      v-for="platform in meetingPlatforms"
                      :key="platform.id"
                      class="mr-4 flex items-center"
                    >
                      <input
                        id="inline-radio"
                        v-bind="field"
                        :value="platform.id"
                        type="radio"
                        name="inline-radio-group"
                        class="h-4 w-4 border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500"
                      />
                      <label
                        for="inline-radio"
                        class="ml-2 text-sm font-medium text-gray-900"
                      >
                        <span v-if="platform.id === 'inresolve'">{{
                          $t('calendar.modalNewAgenda.inresolveVideoMeet')
                        }}</span>
                        <span v-else>{{
                          $t('calendar.modalNewAgenda.provideURL')
                        }}</span>
                      </label>
                    </div>
                  </div>
                </Field>
                <div
                  v-if="errors.meeting_platform !== undefined"
                  class="flex w-full"
                >
                  <span class="text-sm text-red-500">
                    {{ $t(errors.meeting_platform) }}
                  </span>
                </div>
              </div>
              <div v-if="meeting_platform === 'other' && isMeeting">
                <label class="mb-1 block text-xs font-bold" for="meeting_url">
                  {{ $t('calendar.modalNewAgenda.meetingURL') }}
                </label>
                <Field
                  v-slot="{ field }"
                  v-model="meeting_url"
                  name="meeting_url"
                >
                  <input
                    v-bind="field"
                    id="meeting_url"
                    class="form-input w-full p-2"
                    type="text"
                    required
                    :placeholder="$t('calendar.modalNewAgenda.meetingURL')"
                  />
                </Field>
                <div
                  v-if="errors.meeting_url !== undefined"
                  class="flex w-full"
                >
                  <span class="text-sm text-red-500">
                    {{ $t(errors.meeting_url) }}
                  </span>
                </div>
              </div>
              <div class="flex justify-between">
                <div class="flex w-2/6 flex-col pr-4">
                  <label class="mb-1 block text-xs font-bold" for="meetingTime">
                    {{ $t('calendar.modalNewAgenda.meetingTime') }}
                  </label>
                  <Datepicker
                    v-model="meeting_date"
                    :max-today="false"
                    :max-one-month="true"
                    :min-today="true"
                    :show-icon="false"
                    :date-only="true"
                    wrap-class="form-input h-11 w-full rounded-md"
                    mode="single"
                    @change-date="changeDate"
                  />
                </div>
                <div class="flex w-2/6 flex-col pr-4">
                  <label class="mb-1 block text-xs font-bold" for="from">
                    {{ $t('calendar.modalNewAgenda.from') }}
                  </label>
                  <VueMultiselect
                    v-model="meeting_time_from"
                    :options="timeOptions"
                    :multiple="false"
                    :close-on-select="true"
                    :search="true"
                    :placeholder="$t('validation.selectTime')"
                    label="id"
                    track-by="id"
                    class="border-gray-100"
                  >
                  </VueMultiselect>
                </div>
                <div class="flex w-2/6 flex-col pr-4">
                  <label class="mb-1 block text-xs font-bold" for="to">
                    {{ $t('calendar.modalNewAgenda.to') }}
                  </label>
                  <VueMultiselect
                    v-model="meeting_time_to"
                    :options="timeOptions"
                    :multiple="false"
                    :close-on-select="true"
                    :search="true"
                    :placeholder="$t('validation.selectTime')"
                    label="id"
                    track-by="id"
                    class="border-gray-100"
                  >
                  </VueMultiselect>
                </div>
              </div>
              <div v-if="isMeeting">
                <label
                  class="mb-1 block text-xs font-bold"
                  for="meetingInvitees"
                >
                  {{ $t('calendar.modalNewAgenda.meetingInvitees') }}
                </label>
                <VueMultiselect
                  v-model="party_ids"
                  :options="meetingInvitees.filter((item) => item.id !== me.id)"
                  :multiple="true"
                  :close-on-select="true"
                  :placeholder="$t('validation.selectInvitees')"
                  label="name"
                  track-by="id"
                  :allow-empty="false"
                >
                </VueMultiselect>
              </div>
              <div class="flex justify-between">
                <div class="flex w-3/6 flex-col pr-4">
                  <label class="mb-1 block text-xs font-bold" for="description">
                    {{ $t('calendar.modalNewAgenda.agenda') }}
                  </label>

                  <Field
                    v-slot="{ field }"
                    v-model="description"
                    name="description"
                  >
                    <textarea
                      v-bind="field"
                      class="form-input w-full resize-y p-2"
                      :placeholder="$t('calendar.modalNewAgenda.agenda')"
                    ></textarea>
                  </Field>
                  <div
                    v-if="errors.description !== undefined"
                    class="flex w-full"
                  >
                    <span class="text-sm text-red-500">
                      {{ $t(errors.description) }}
                    </span>
                  </div>
                </div>

                <div class="flex w-3/6 flex-col pr-4">
                  <label
                    class="mb-1 block text-xs font-bold"
                    for="otherDetails"
                  >
                    {{ $t('calendar.modalNewAgenda.otherDetails') }}
                  </label>
                  <textarea
                    v-model="detail"
                    :placeholder="
                      $t('calendar.modalNewAgenda.otherDetailPlaceholder')
                    "
                    class="form-input w-full p-2"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="px-5 py-4">
            <div class="flex flex-wrap justify-center space-x-2">
              <button
                type="submit"
                class="btn-sm hover:bg-primary-600 w-full whitespace-nowrap rounded-lg bg-primary p-3 text-white"
              >
                {{ $t('calendar.modalNewAgenda.addEvent') }}
              </button>
            </div>
          </div>
        </Form>
      </ModalBasic>
    </div>
  </div>
</template>
<script>
import { Form, Field } from 'vee-validate'
import * as Yup from 'yup'
import ModalBasic from '@/components/ModalBasic.vue'
import Datepicker from '@/components/Datepicker.vue'

export default {
  name: 'ModalNewAgendaComponent',
  components: {
    Form,
    Field,
    ModalBasic,
    Datepicker,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    meetingInvitees: {
      type: Array,
      default: () => {
        return []
      },
    },
    meetingTypes: {
      type: Array,
      default: () => {
        return []
      },
    },
    agendaTypes: {
      type: Array,
      default: () => {
        return []
      },
    },
    timeOptions: {
      type: Array,
      default: () => {
        return []
      },
    },
    meetingPlatforms: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  emits: ['closed', 'confirm', 'empty-member', 'empty-time', 'invalid-time'],
  data: () => ({
    party_ids: [],
    title: null,
    type: 'Meeting',
    description: '',
    detail: '',
    meeting_date: null,
    meeting_url: '',
    meeting_type: 'video',
    meeting_platform: '',
    meeting_time_from: null,
    meeting_time_to: null,
    isMeeting: true,
    newAgendaSchema: Yup.object().shape({
      meeting_platform: Yup.string().when('type', {
        is: 'Meeting',
        then: Yup.string().required('validation.selectMeetingPlatform'),
      }),
      type: Yup.string().required('validation.selectAgendaType'),
      title: Yup.string()
        .typeError('validation.enterAgendaTitle')
        .required('validation.enterAgendaTitle'),
      description: Yup.string()
        .typeError('validation.enterAgenda')
        .required('validation.enterAgenda'),
      meeting_url: Yup.string().when('type', {
        is: 'Meeting',
        then: Yup.string().when('meeting_platform', {
          is: 'other',
          then: Yup.string()
            .required('validation.enterMeetingURL')
            .matches(
              /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9-#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
              'validation.enterCorrectURL'
            ),
        }),
      }),
    }),
    me: null,
  }),
  watch: {
    isOpen(val) {
      if (!val) this.close()
      else this.$refs.form.resetForm()
    },
  },
  created() {
    this.me = this.$store.getters['auth/activeUser']
  },
  methods: {
    close() {
      this.$refs.form.resetForm()
      this.party_ids = []
      this.meeting_time_from = ''
      this.meeting_time_to = ''
      this.$emit('closed')
    },
    confirm() {
      if (this.party_ids.length <= 0 && this.type === 'Meeting')
        return this.$emit('empty-member')

      if (
        this.meeting_time_from === '' ||
        this.meeting_time_from === null ||
        this.meeting_time_to === '' ||
        this.meeting_time_to === null
      )
        return this.$emit('empty-time')

      if (this.checkTime()) {
        return this.$emit('invalid-time')
      }

      const newAgendaData = {}
      newAgendaData.party_ids = this.party_ids.map((item) => item.id)
      newAgendaData.title = this.title
      newAgendaData.type = this.type
      newAgendaData.description = this.description
      newAgendaData.detail = this.detail
      newAgendaData.meeting_date = this.meeting_date
      newAgendaData.meeting_url = this.meeting_url
      newAgendaData.meeting_type = this.meeting_type
      newAgendaData.meeting_platform = this.meeting_platform
      newAgendaData.meeting_time_from = this.meeting_time_from.id
      newAgendaData.meeting_time_to = this.meeting_time_to.id

      this.$emit('confirm', newAgendaData)
    },
    changeType() {
      this.isMeeting = this.type.toLowerCase() === 'meeting'
    },
    changeDate(val) {
      this.meeting_date = val
    },
    checkTime() {
      const timeFrom = this.meeting_time_from
      const timeTo = this.meeting_time_to

      let hourFrom = 0
      let minuteFrom = 0
      let hourTo = 0
      let minuteTo = 0
      if (timeFrom !== null) {
        const timeFromArr = timeFrom.id.split(':')
        hourFrom = parseInt(timeFromArr[0])
        minuteFrom = parseInt(timeFromArr[1])
      }
      if (timeTo !== null) {
        const timeToArr = timeTo.id.split(':')
        hourTo = parseInt(timeToArr[0])
        minuteTo = parseInt(timeToArr[1])
      }

      if (hourTo === hourFrom) {
        if (minuteFrom >= minuteTo) {
          return true
        }
      }

      if (hourFrom > hourTo) {
        return true
      }

      return false
    },
    // changeTime(val, type) {
    //   let timeFrom = this.meeting_time_from
    //   let timeTo = this.meeting_time_to
    //   if (type === 'from') {
    //     timeFrom = val
    //   } else {
    //     timeTo = val
    //   }

    //   let hourFrom = 0
    //   let minuteFrom = 0
    //   let hourTo = 0
    //   let minuteTo = 0
    //   if (timeFrom !== null) {
    //     const timeFromArr = timeFrom.id.split(':')
    //     hourFrom = parseInt(timeFromArr[0])
    //     minuteFrom = parseInt(timeFromArr[1])
    //   }
    //   if (timeTo !== null) {
    //     const timeToArr = timeTo.id.split(':')
    //     hourTo = parseInt(timeToArr[0])
    //     minuteTo = parseInt(timeToArr[1])
    //   }

    //   if (hourTo === hourFrom) {
    //     if (minuteFrom > minuteTo) {
    //       return this.$emit('invalid-time')
    //     }
    //   }

    //   if (hourFrom > hourTo) {
    //     return this.$emit('invalid-time')
    //   }
    // },
  },
}
</script>
