<template>
  <div class="flex h-screen overflow-hidden">
    <div
      class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden"
    >
      <!-- Site header -->
      <Header
        :sidebar-open="sidebarOpen"
        @toggle-sidebar="sidebarOpen = !sidebarOpen"
      />

      <main>
        <div class="w-full">
          <div class="flex h-full flex-col justify-center bg-gray-100">
            <div
              class="w-full self-center px-2 pb-5 pt-10 text-center md:w-5/6 md:px-0 md:py-2"
            >
              <!-- Start -->
              <ul class="inline-flex flex-wrap text-sm font-medium">
                <li class="flex items-center">
                  <router-link to="/" class="text-slate-500 hover:text-primary">
                    {{ $t('case.caseSelection') }}
                  </router-link>
                  <svg
                    class="mx-3 h-4 w-4 fill-current text-slate-400"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.6 13.4L5.2 12l4-4-4-4 1.4-1.4L12 8z" />
                  </svg>
                </li>
                <li class="flex items-center">
                  <router-link
                    to="/new-case"
                    class="text-slate-500 hover:text-primary"
                  >
                    {{ $t('case.newComplaint') }}
                  </router-link>
                  <svg
                    class="mx-3 h-4 w-4 fill-current text-slate-400"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.6 13.4L5.2 12l4-4-4-4 1.4-1.4L12 8z" />
                  </svg>
                </li>
                <li class="flex items-center">
                  <router-link
                    to="/advocate-requirement"
                    class="text-slate-500 hover:text-primary"
                  >
                    {{ $t('case.advocateRequirement') }}
                  </router-link>
                  <svg
                    class="mx-3 h-4 w-4 fill-current text-slate-400"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.6 13.4L5.2 12l4-4-4-4 1.4-1.4L12 8z" />
                  </svg>
                </li>
                <li class="flex items-center">
                  <router-link
                    to="/advocate-selection"
                    class="text-slate-500 hover:text-primary"
                  >
                    {{ $t('case.advocateSelection') }}
                  </router-link>
                  <svg
                    class="mx-3 h-4 w-4 fill-current text-slate-400"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.6 13.4L5.2 12l4-4-4-4 1.4-1.4L12 8z" />
                  </svg>
                </li>
                <li class="flex items-center">
                  <router-link
                    to="/case-submitted"
                    class="text-slate-700 hover:text-primary"
                  >
                    {{ $t('case.caseSubmitted') }}
                  </router-link>
                </li>
              </ul>
              <!-- End -->
            </div>
            <div
              class="w-full justify-center self-center rounded-lg px-8 py-8 text-center md:w-5/6"
            >
              <ThumbIcon class="mx-auto mb-10 h-72" />
              <h1 class="text-3xl font-bold text-black">
                {{ $t('caseSubmitted.thanks') }}
              </h1>
              <p class="line-clamp-1 pt-10 text-lg font-semibold">
                {{ $t('caseSubmitted.description') }}
              </p>
              <p class="line-clamp-1 text-lg font-semibold">
                {{ $t('caseSubmitted.description2') }}
              </p>
              <div class="mt-5 flex flex-row justify-center">
                <div class="w-full px-2 md:w-5/12">
                  <button
                    class="btn text-md hover:bg-primary-600 mt-6 h-12 w-full rounded-full bg-primary text-white"
                    @click="nextPage"
                  >
                    {{ $t('caseSubmitted.return') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import Header from '@/partials/Header.vue'
import ThumbIcon from '@/assets/svg/thumb.svg'

export default {
  name: 'CaseSubmittedPage',
  components: {
    Header,
    ThumbIcon,
  },
  setup() {
    const sidebarOpen = ref(false)

    return {
      sidebarOpen,
    }
  },
  data() {
    return {}
  },
  methods: {
    nextPage() {
      return this.$router.push({ name: 'index' })
    },
  },
}
</script>
