import { createStore } from 'vuex'
import global from './global'
import advocate from './advocate'
import arbitrator from './arbitrator'
import auth from './auth'
import bank from './bank'
import calendar from './calendar'
import cases from './cases'
import chat from './chat'
import document from './document'
import mediator from './mediator'
import notes from './notes'
import notification from './notification'
import timeline from './timeline'
import user from './user'
import review from './review'
import profile from './profile'

export default createStore({
  modules: {
    global,
    advocate,
    arbitrator,
    auth,
    bank,
    calendar,
    cases,
    chat,
    document,
    mediator,
    notification,
    notes,
    timeline,
    profile,
    review,
    user,
  },
})
