import { createApp } from 'vue'
import { createPinia } from 'pinia'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import Shimmer from 'vue3-shimmer'
import VueLoadImage from 'vue-load-image'
import Toast, { POSITION } from 'vue-toastification'
import { configure } from 'vee-validate'
import Multiselect from 'vue-multiselect'

import App from './App.vue'
import router from './router'
import axios from './plugins/axios.js'
import i18n from './plugins/i18n.js'
// import globalComponents from './global-components'
import utils from './utils'
import store from './stores/vuex'

import VOtpInput from 'vue3-otp-input'

// import Iconly from 'vue-iconly'

import '@vueform/multiselect/themes/default.css'
import 'vue-toastification/dist/index.css'
import 'vue-multiselect/dist/vue-multiselect.css'

import './assets/css/style.scss'

const app = createApp(App)
dayjs.extend(relativeTime)

configure({
  validateOnInput: true,
})

app.use(store)
app.use(createPinia())
app.use(i18n)
app.use(router)
app.use(Shimmer)
app.use(Toast, {
  position: POSITION.BOTTOM_RIGHT,
  timeout: 2000,
})
// app.use(Iconly)

app.provide('axios', axios) // provide axios
app.provide('dayjs', dayjs) // provide dayJS
app.provide(
  'defaultImage',
  'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
) // provide defaultImage
const checkEmptyObject = (obj) => {
  // because Object.keys(new Date()).length === 0;
  // we have to do some additional check
  return (
    obj && // 👈 null and undefined check
    Object.keys(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype
  )
}
app.provide('checkEmptyObject', checkEmptyObject) // provide checkEmptyObject
const getImgUrl = (paramsData = { filename, type, subfolder }) => {
  return new URL(
    `../src/assets/${paramsData.subfolder}${paramsData.filename}.${paramsData.type}`,
    import.meta.url
  ).href
}
app.provide('getImgUrl', getImgUrl) // provide getImgUrl

// app.prototype.$axios = axios
// app.config.globalProperties.$http = axios

app.component('VueLoadImage', VueLoadImage)
app.component('VOtpInput', VOtpInput)
app.component('VueMultiselect', Multiselect)

// globalComponents(app)

utils(app)

app.mount('#app')
