<template>
  <div class="relative">
    <label for="action-search" class="sr-only">Search</label>
    <input
      id="action-search"
      v-model="search"
      class="form-input pl-9 focus:border-slate-300"
      type="search"
      :placeholder="placeholder"
      @change.stop="changeSearch"
    />
    <button
      class="group absolute inset-0 right-auto"
      type="submit"
      aria-label="Search"
    >
      <SearchIcon />
    </button>
  </div>
</template>

<script>
import SearchIcon from '@/assets/svg/search.svg'

export default {
  name: 'SearchForm',
  components: { SearchIcon },
  props: {
    placeholder: {
      type: String,
      default: 'Search…',
    },
  },
  emits: ['search'],
  data: () => ({
    search: '',
  }),
  methods: {
    changeSearch() {
      this.$emit('search', this.search)
    },
  },
}
</script>
