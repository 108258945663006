export default {
  data: () => ({
    activeUser: {},
    chatMembers: [],
    allChatRoom: [],
    privateChatRoom: [],
    allChatRoomDocuments: [],
    activeRoom: {},
    activeChat: {},
    newChatRoomData: {},
    isCurrentUserJoined: false,
    filterChat: {
      page: 1,
      take: 50,
      order: 'ASC',
    },
    chatData: [],
    chatId: null,
    accept: false,
    reason: '',
  }),
  methods: {
    // async method
    // Chat Discussion
    async getAllChat(paramsData = { id: '', type: '' }) {
      this.isLoading = true
      const params = {
        page: this.filterChat.page,
        take: this.filterChat.take,
        order: this.filterChat.order,
      }
      if (typeof this.$route.params.id !== 'undefined') {
        params.room_id = this.$route.params.id
      } else {
        params.room_id = paramsData.id
      }
      if (paramsData.type && paramsData.type !== '') {
        params.type = paramsData.type
      }
      await this.store
        .dispatch('chat/getAllChat', params)
        .then((response) => {
          this.isLoading = false
          const responseData = response.data.data
          this.chatData = responseData.data
        })
        .catch((error) => {
          this.isLoading = false
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async closeChatAPI() {
      this.isLoading = true
      await this.store
        .dispatch('chat/closeChat', {
          room_id: this.$route.params.id,
        })
        .then((response) => {
          this.isLoading = false
          this.toChatGroup()
        })
        .catch((error) => {
          this.isLoading = false
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async createChatAPI(params) {
      this.isLoading = true
      await this.store
        .dispatch('chat/createChat', params)
        .then((response) => {
          this.isLoading = false
          this.makeOfferModalOpen = false
          this.chatModalOpen = false
          const responseData = response.data
          if (responseData.statusCode === 200) {
            this.toast.success(responseData.message)
            this.getAllChat()
          }
        })
        .catch((error) => {
          this.isLoading = false
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async offerActionAPI() {
      this.isLoading = true

      await this.store
        .dispatch('chat/chatOfferAction', {
          room_id: this.$route.params.slug,
          chat_id: this.chatId,
          accept: this.accept,
          reason: this.reason || '',
        })
        .then((response) => {
          this.isLoading = false
          const responseData = response.data.data
          this.getAllChat()
        })
        .catch((error) => {
          console.log(error)
          this.isLoading = false
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    // Chat rooms
    async getAllRoom() {
      this.isLoading = true

      await this.store
        .dispatch('chat/getAllRoom', {
          case_id: this.$route.params.slug,
        })
        .then((response) => {
          this.isLoading = false
          const responseData = response.data.data
          this.allChatRoom = responseData.data.filter(
            (item) => item.private_chat !== true
          )
          this.privateChatRoom = responseData.data.filter(
            (item) => item.private_chat === true
          )
        })
        .catch((error) => {
          this.isLoading = false
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async getAllDocumentRoom() {
      this.isLoading = true

      await this.store
        .dispatch('chat/getAllDocumentRoom', {
          id: this.$route.params.id,
        })
        .then((response) => {
          this.isLoading = false
          const responseData = response.data.data
          this.allChatRoomDocuments = responseData
        })
        .catch((error) => {
          this.isLoading = false
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async getDetailRoom() {
      this.isLoading = true

      await this.store
        .dispatch('chat/getDetailRoom', {
          id: this.$route.params.id,
        })
        .then((response) => {
          this.isLoading = false
          const responseData = response.data.data
          this.activeChat = responseData
          this.chatMembers = responseData.member

          this.activeUser = this.store.getters['auth/activeUser']
          const activeUserId = this.activeUser.id

          const isJoined = responseData.member.some((member) => {
            return member.user.id === activeUserId
          })
          this.isCurrentUserJoined = isJoined
          if (!isJoined) {
            this.joinRoom(true)
          }
        })
        .catch((error) => {
          this.isLoading = false
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async getActiveChat() {
      this.isLoading = true

      await this.store
        .dispatch('chat/getActiveChat', {
          case_id: this.$route.params.slug,
        })
        .then((response) => {
          this.isLoading = false

          const responseData = response.data.data
          this.activeChat = responseData
          this.activeUser = this.store.getters['auth/activeUser']
          const activeUserId = this.activeUser.id

          const isJoined = this.activeChat.member.some((member) => {
            return member.user.id === activeUserId
          })
          this.isCurrentUserJoined = isJoined
        })
        .catch((error) => {
          this.isLoading = false
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async joinRoom(fromGroup = false) {
      this.isLoading = true

      if (this.activeChat.hasOwnProperty('id')) {
        await this.store
          .dispatch('chat/joinRoom', {
            room_id: this.activeChat.id,
          })
          .then((response) => {
            this.isLoading = false

            // const message = response.data.message
            // this.toast.success(message)

            if (fromGroup) {
              this.getAllChat()
            } else {
              this.toChatGroup()
            }
          })
          .catch((error) => {
            console.log(error)
            this.isLoading = false
            this.toast.error(this.extractErrorResponse(error))
          })
      } else if (this.toast !== 'undefined') {
        this.toast.error('Failed to join room')
      }
    },
    async createChatRoomAPI() {
      this.isLoading = true

      await this.store
        .dispatch('chat/createChatPrivate', this.newChatRoomData)
        .then((response) => {
          this.isLoading = false
          this.chatModalOpen = false
          this.getAllRoom()
        })
        .catch((error) => {
          this.isLoading = false
          this.chatModalOpen = true
          this.toast.error(this.extractErrorResponse(error))
        })
    },

    // local method
    joinChatRoom(fromGroup = false) {
      this.joinRoom(fromGroup)
    },
    toChatGroup() {
      return this.$router.push({
        name: 'chat-group',
        params: { slug: this.$route.params.slug },
        query: {
          name: this.$route.query.name,
          status: this.$route.query.status,
          sub_status: this.$route.query.sub_status,
          lang: this.$route.query.lang,
        },
      })
    },
    toChatDiscussion(item, fromDiscussion = false) {
      let chatRoomId = item.id
      if (fromDiscussion) {
        chatRoomId = item.chat_room_id
      }

      return this.$router.push({
        name: 'chats',
        params: {
          slug: this.$route.params.slug,
          id: chatRoomId,
        },
        query: {
          name: item.name,
          status: item.status,
          lang: this.$route.query.lang,
        },
      })
    },
    createChatRoom(event) {
      this.newChatRoomData = event
      this.createChatRoomAPI()
    },
  },
}
