<template>
  <div>
    <table class="w-full table-auto">
      <tr class="table-header-group text-left">
        <th class="py-2 px-4">Name</th>
        <th class="py-2 px-4 text-center">Status</th>
        <th class="py-2 px-4 text-center">Urgency</th>
        <th class="py-2 px-4">Parties</th>
      </tr>

      <tr
        v-for="item in cases"
        :key="item.id"
        class="cursor-pointer bg-white hover:bg-gray-200"
        @click="buttonClicked(item)"
      >
        <td class="py-2 px-4">{{ item.name }}</td>
        <td class="justify-center py-2 px-4 text-center">
          <div
            v-if="item.status === 'onprepare'"
            class="flex w-full justify-center rounded-lg bg-red-100 py-1 px-4"
          >
            <p class="text-xs font-bold text-red-500">
              {{ $t('case.onPrepare') }}
            </p>
          </div>
          <div
            v-else-if="item.status === 'ongoing'"
            class="flex w-full justify-center rounded-lg bg-orange-100 py-1 px-4"
          >
            <p class="text-xs font-bold text-orange-500">
              {{ $t('case.onGoing') }}
            </p>
          </div>
          <div
            v-else-if="item.status === 'solved'"
            class="flex w-full justify-center rounded-lg bg-green-100 py-1 px-4"
          >
            <p class="text-xs font-bold text-green-500">
              {{ $t('case.solved') }}
            </p>
          </div>
        </td>
        <td class="justify-center py-2 px-4 text-center">
          <div
            v-if="item.priority === 'high'"
            class="flex w-full justify-center rounded-lg bg-red-100 py-1 px-4"
          >
            <p class="text-xs font-bold text-red-500">
              {{ $t('case.highUrgency') }}
            </p>
          </div>
          <div
            v-else-if="item.priority === 'medium'"
            class="flex w-full justify-center rounded-lg bg-orange-100 py-1 px-4"
          >
            <p class="text-xs font-bold text-orange-500">
              {{ $t('case.mediumUrgency') }}
            </p>
          </div>
          <div
            v-else-if="item.priority === 'low'"
            class="flex w-full justify-center rounded-lg bg-green-100 py-1 px-4"
          >
            <p class="text-xs font-bold text-green-500">
              {{ $t('case.lowUrgency') }}
            </p>
          </div>
        </td>
        <td class="py-2 px-4"><Avatars :parties="item.parties" /></td>
      </tr>
    </table>
  </div>
</template>

<script>
import Avatars from './Avatars.vue'

export default {
  name: 'CaseList',
  components: {
    Avatars,
  },
  props: {
    cases: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  emits: ['click-case'],
  methods: {
    buttonClicked(item) {
      this.$emit('click-case', item)
    },
  },
}
</script>
