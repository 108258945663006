<template>
  <div class="flex h-screen overflow-hidden">
    <!-- Sidebar -->
    <Sidebar
      :sidebar-open="sidebarOpen"
      :case-state="$route.query.status"
      @close-sidebar="sidebarOpen = false"
    />

    <!-- Content area -->
    <div
      class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden"
    >
      <!-- Site header -->
      <!-- <Header
        :sidebarOpen="sidebarOpen"
        @toggle-sidebar="sidebarOpen = !sidebarOpen"
      /> -->

      <main>
        <div class="max-w-9xl mx-auto w-full px-4 py-8 sm:px-6 lg:px-8">
          <!-- Page header -->
          <div class="mb-4 sm:flex sm:items-center sm:justify-between">
            <!-- Left: Title -->
            <div class="mb-4 sm:mb-0">
              <h1 class="text-2xl font-bold text-slate-800 md:text-3xl">
                <span>{{ `${monthNames[month]} ${year}` }}</span>
              </h1>
            </div>

            <!-- Right: Actions -->
            <div
              class="grid grid-flow-col justify-start gap-2 sm:auto-cols-max sm:justify-end"
            >
              <!-- Previous month button -->
              <button
                class="btn border-slate-200 bg-white px-2.5 text-slate-500 hover:border-slate-300 hover:text-slate-600 disabled:cursor-not-allowed disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400"
                :disabled="month === 0 ? true : false"
                @click="month--, getDays()"
              >
                <span class="sr-only">Previous month</span><wbr />
                <svg class="h-4 w-4 fill-current" viewBox="0 0 16 16">
                  <path d="M9.4 13.4l1.4-1.4-4-4 4-4-1.4-1.4L4 8z" />
                </svg>
              </button>

              <!-- Next month button -->
              <button
                class="btn border-slate-200 bg-white px-2.5 text-slate-500 hover:border-slate-300 hover:text-slate-600 disabled:cursor-not-allowed disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400"
                :disabled="month === 11 ? true : false"
                @click="month++, getDays()"
              >
                <span class="sr-only">Next month</span><wbr />
                <svg class="h-4 w-4 fill-current" viewBox="0 0 16 16">
                  <path d="M6.6 13.4L5.2 12l4-4-4-4 1.4-1.4L12 8z" />
                </svg>
              </button>

              <hr class="mx-1 h-full w-px bg-slate-200" />

              <!-- Create event button -->
              <EditMenu
                align="right"
                class="relative inline-flex"
                custom-class="rounded-xl bg-primary text-white hover:bg-primary-600 p-2"
                :show-text="true"
                :show-dots="true"
                :text="$t('calendar.buttonAdd')"
              >
                <li>
                  <span
                    class="flex cursor-pointer py-1 px-3 text-sm font-medium text-slate-600 hover:text-slate-800"
                    @click.stop="newRequestDocumentModalOpen = true"
                  >
                    {{ $t('calendar.documentRequest') }}
                  </span>
                </li>
                <li>
                  <span
                    class="flex cursor-pointer py-1 px-3 text-sm font-medium text-slate-600 hover:text-slate-800"
                    @click.stop="newAgendaModalOpen = true"
                  >
                    {{ $t('calendar.newAgenda') }}
                  </span>
                </li>
              </EditMenu>
            </div>
          </div>

          <!-- Daily Calendar table -->
          <div class="bg-inherit">
            <!-- Days of the month -->
            <div
              id="days-calendar"
              class="flex w-full max-w-full overflow-x-scroll"
            >
              <div
                v-for="(day, index) in dateDays"
                :key="index"
                class="flex w-full"
              >
                <div
                  :id="`id-${day.date}`"
                  class="mr-2 w-full cursor-pointer px-7 py-3 text-center text-sm font-medium"
                  :class="[
                    day.date === dateActive
                      ? 'text-primary-400 rounded-t-lg bg-white '
                      : 'text-slate-500',
                  ]"
                  @click="changeDate(day.date)"
                >
                  <p>
                    {{ day.date }}
                  </p>
                  <p>
                    {{ day.name.substring(0, 3) }}
                  </p>
                </div>
              </div>
            </div>

            <div
              class="flex w-full flex-row justify-between bg-white pt-10 text-slate-600"
            >
              <div class="relative flex h-20 w-1/12 border-t text-sm">
                <div class="absolute -top-2.5 bg-white px-2 pl-5">
                  {{ dateActive }} {{ monthNames[month].slice(0, 3) }}
                </div>
              </div>
              <div class="relative flex h-20 w-11/12 border-t border-l">
                <div
                  class="flex w-full items-center justify-between space-x-1 self-center"
                >
                  <div
                    v-for="agenda in agendas.filter(
                      (item) => item.type === 'Document'
                    )"
                    :key="agenda.id"
                    class="z-10 flex h-20 w-full cursor-pointer items-center self-center overflow-scroll rounded-lg py-4 px-5 text-white"
                    :class="[randomBackground()]"
                    @click.stop="showCalendarDetail(agenda)"
                  >
                    <CalendarItem
                      :agenda="agenda"
                      :is-request-document="true"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              v-for="(time, index) in times"
              :key="index"
              class="flex w-full flex-row justify-between bg-white pt-0 text-slate-600"
            >
              <div class="relative flex h-20 w-1/12 border-t text-sm">
                <div class="absolute -top-2.5 bg-white px-2 pl-5">
                  {{ time.twelve }}
                </div>
              </div>
              <div class="relative flex h-20 w-11/12 border-t border-l">
                <div class="flex w-full justify-between space-x-1">
                  <div
                    v-for="agenda in agendas.filter(
                      (item) =>
                        item.type !== 'Document' &&
                        item.meeting_time_from
                          .slice(0, 2)
                          .includes(time.twentyFour.padStart(2, '0'))
                    )"
                    :key="agenda.id"
                    class="sticky z-10 w-full cursor-pointer overflow-scroll rounded-lg py-4 px-5 text-white"
                    :class="[randomBackground()]"
                    :style="`height: ${
                      5 *
                      (parseInt(agenda.meeting_time_to.slice(0, 2)) -
                        parseInt(agenda.meeting_time_from.slice(0, 2)))
                    }rem;
                    margin-left: ${getMarginLeftCalendar(
                      agenda.meeting_time_from.slice(0, 2),
                      agenda.meeting_time_to.slice(0, 2)
                    )}rem;
                    `"
                  >
                    <CalendarItem :agenda="agenda" />
                  </div>
                </div>
                <div
                  v-if="time.twentyFour == timeNow"
                  class="relative h-1 w-full border-t border-red-500"
                >
                  <div
                    class="absolute -bottom-1 -left-2 h-4 w-4 rounded-full bg-red-500"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>

    <ModalNewAgenda
      :is-open="newAgendaModalOpen"
      :agenda-types="agendaTypes"
      :meeting-invitees="caseParties"
      :meeting-types="meetingTypes"
      :meeting-platforms="meetingPlatforms"
      :time-options="timeOptions"
      @closed="newAgendaModalOpen = false"
      @confirm="createNewAgenda"
      @empty-member="emptyMember('selectInvitees')"
      @empty-time="emptyMember('selectTimeFirst')"
      @invalid-time="emptyMember('selectTimeInvalid')"
    />

    <ModalRequestDocument
      :is-open="newRequestDocumentModalOpen"
      :case-parties="caseParties"
      @closed="newRequestDocumentModalOpen = false"
      @confirm="createNewRequestDocument"
      @empty-member="emptyMember('selectRequestTo')"
    />

    <ModalCalendarInfo
      :is-open="calendarDetailAgendaOpen"
      :active-agenda="activeAgenda"
      :is-request-document="activeAgenda.type === 'Document'"
      @closed="calendarDetailAgendaOpen = false"
      @reject-request="rejectRequestDocumentModalOpen = true"
      @accept-request="acceptRequestDocumentModalOpen = true"
    />

    <ModalProvideDocument
      :is-open="acceptRequestDocumentModalOpen"
      :active-agenda="activeAgenda"
      @closed="acceptRequestDocumentModalOpen = false"
      @confirm="accepetRequestDocumentAction"
    />

    <ModalAgendaConfirm
      :is-open="newAgendaModalActionOpen"
      @closed="newAgendaModalActionOpen = false"
      @confirmed="confirmedNewAgenda"
    />

    <ModalRejectOffer
      :id="activeAgenda.id"
      :is-open="rejectRequestDocumentModalOpen"
      :title="$t('calendar.modalReject.title')"
      @reject="rejectRequestDocumentAction"
      @closed="rejectRequestDocumentModalOpen = false"
    />

    <RightSidebar
      :sidebar-open="sidebarOpen"
      @close-sidebar="sidebarOpen = false"
    />
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import globalMixin from '@/mixins/global.js'
import Sidebar from '@/partials/Sidebar.vue'
import RightSidebar from '@/partials/RightSidebar.vue'

import EditMenu from '@/components/DropdownEditMenu.vue'
import CalendarItem from '@/components/calendar/CalendarItem.vue'
import ModalNewAgenda from '@/components/ModalNewAgenda.vue'
import ModalRequestDocument from '@/components/modal/calendar/ModalRequestDocument.vue'
import ModalProvideDocument from '@/components/modal/document/ModalProvideDocument.vue'
import ModalCalendarInfo from '@/components/modal/calendar/ModalCalendarInfo.vue'
import ModalAgendaConfirm from '@/components/ModalAgendaConfirm.vue'
import ModalRejectOffer from '@/components/ModalRejectOffer.vue'

export default {
  name: 'CalendarPage',
  components: {
    Sidebar,
    RightSidebar,
    EditMenu,
    CalendarItem,
    ModalNewAgenda,
    ModalCalendarInfo,
    ModalProvideDocument,
    ModalRequestDocument,
    ModalAgendaConfirm,
    ModalRejectOffer,
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const toast = useToast()
    const sidebarOpen = ref(false)
    const today = new Date()
    const newAgendaModalOpen = ref(false)
    const newRequestDocumentModalOpen = ref(false)
    const newAgendaModalActionOpen = ref(false)
    const monthlyCalendar = ref(false)
    const timeNow = today.getHours()
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    const times = [
      { twentyFour: '0', twelve: '12 AM' },
      { twentyFour: '1', twelve: '1 AM' },
      { twentyFour: '2', twelve: '2 AM' },
      { twentyFour: '3', twelve: '3 AM' },
      { twentyFour: '4', twelve: '4 AM' },
      { twentyFour: '5', twelve: '5 AM' },
      { twentyFour: '6', twelve: '6 AM' },
      { twentyFour: '7', twelve: '7 AM' },
      { twentyFour: '8', twelve: '8 AM' },
      { twentyFour: '9', twelve: '9 AM' },
      { twentyFour: '10', twelve: '10 AM' },
      { twentyFour: '11', twelve: '11 AM' },
      { twentyFour: '12', twelve: '12 PM' },
      { twentyFour: '13', twelve: '1 PM' },
      { twentyFour: '14', twelve: '2 PM' },
      { twentyFour: '15', twelve: '3 PM' },
      { twentyFour: '16', twelve: '4 PM' },
      { twentyFour: '17', twelve: '5 PM' },
      { twentyFour: '18', twelve: '6 PM' },
      { twentyFour: '19', twelve: '7 PM' },
      { twentyFour: '20', twelve: '8 PM' },
      { twentyFour: '21', twelve: '9 PM' },
      { twentyFour: '22', twelve: '10 PM' },
      { twentyFour: '23', twelve: '11 PM' },
    ]
    const dayNames = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ]
    const month = ref(today.getMonth())
    const year = ref(today.getFullYear())

    const daysInMonth = ref([])
    const dateDays = ref([])
    const dateActive = ref(1)
    const startingBlankDays = ref([])
    const endingBlankDays = ref([])
    const events = ref([])
    const timeOptions = ref([])
    const meetingTypes = ref([
      {
        id: 'video',
        name: 'Video Call',
      },
    ])
    const agendaTypes = ref([
      {
        id: 'Meeting',
        name: 'Meeting',
      },
      {
        id: 'Alert',
        name: 'Alert',
      },
    ])
    const meetingPlatforms = ref([
      { id: 'other', name: 'Other' },
      { id: 'inresolve', name: 'InResolve' },
    ])

    const isToday = (date) => {
      const day = new Date(year.value, month.value, date)
      return today.toDateString() === day.toDateString() ? true : false
    }

    const getEvents = (date) => {
      return events.value.filter(
        (e) =>
          new Date(e.eventStart).toDateString() ===
          new Date(year.value, month.value, date).toDateString()
      )
    }

    const getDays = () => {
      const days = new Date(year.value, month.value + 1, 0).getDate()

      // starting empty cells (previous month)
      const startingDayOfWeek = new Date(year.value, month.value).getDay()
      let startingBlankDaysArray = []
      for (let i = 1; i <= startingDayOfWeek; i++) {
        startingBlankDaysArray.push(i)
      }

      // ending empty cells (next month)
      const endingDayOfWeek = new Date(year.value, month.value + 1, 0).getDay()
      let endingBlankDaysArray = []
      for (let i = 1; i < 7 - endingDayOfWeek; i++) {
        endingBlankDaysArray.push(i)
      }

      // current month cells
      let daysArray = []
      let daysNameArray = []
      let dayIndex = startingDayOfWeek
      for (let i = 1; i <= days; i++) {
        daysArray.push(i)

        daysNameArray.push({
          date: i,
          dateString: getDateString(i),
          name: dayNames[dayIndex],
        })
        if (dayIndex >= 6) {
          dayIndex = 0
        } else {
          dayIndex += 1
        }
      }
      const hours = times.map((item) => item.twentyFour.padStart(2, '0'))
      // const minutes = ['00', '30']
      const minutes = ['00']
      let timeOps = []
      hours.forEach((hourItem) => {
        minutes.forEach((minuteItem) => {
          timeOps.push({ id: hourItem.concat(':', minuteItem) })
        })
      })
      timeOptions.value = timeOps

      startingBlankDays.value = startingBlankDaysArray
      endingBlankDays.value = endingBlankDaysArray
      daysInMonth.value = daysArray
      dateDays.value = daysNameArray
      dateActive.value = today.getDate()
    }

    const eventColor = (color) => {
      switch (color) {
        case 'sky':
          return 'text-white bg-primary'
        case 'yellow':
          return 'text-white bg-amber-500'
        case 'emerald':
          return 'text-white bg-emerald-500'
        case 'red':
          return 'text-white bg-rose-400'
        default:
          return ''
      }
    }

    const getDateString = (date) => {
      const dateString = `${year.value}-${String(month.value + 1).padStart(
        2,
        '0'
      )}-${String(date).padStart(2, '0')}`

      return dateString
    }

    // Auto Scroll to active date
    setTimeout(() => {
      document.getElementById(`id-${dateActive.value}`).scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      })
    }, 1000)

    onMounted(() => {
      getDays()
    })

    return {
      t,
      n,
      store,
      toast,
      sidebarOpen,
      monthNames,
      dayNames,
      month,
      year,
      times,
      timeNow,
      daysInMonth,
      dateActive,
      dateDays,
      startingBlankDays,
      endingBlankDays,
      events,
      isToday,
      getEvents,
      getDays,
      eventColor,
      monthlyCalendar,
      newAgendaModalOpen,
      newRequestDocumentModalOpen,
      newAgendaModalActionOpen,
      meetingTypes,
      agendaTypes,
      meetingPlatforms,
      timeOptions,
      getDateString,
    }
  },
  data: () => ({
    caseParties: [],
    acceptRejectRequestData: {},
    newAgendaData: {},
    filterDataAgenda: {
      page: 1,
      take: 50,
      search: '',
      order: 'DESC',
      date: '',
    },
    metaDataAgenda: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    agendas: [],
    activeAgenda: {},
    marginIndexCalendar: {},
    calendarDetailAgendaOpen: false,
    acceptRequestDocumentModalOpen: false,
    rejectRequestDocumentModalOpen: false,
  }),
  mounted() {
    this.setDateQuery()
    this.getCaseParties()
  },
  methods: {
    setDateQuery() {
      if (typeof this.$route.query.date !== 'undefined') {
        const queryDate = new Date(this.$route.query.date)
        if (queryDate instanceof Date && !isNaN(queryDate)) {
          this.month = queryDate.getMonth()
          this.dateActive = queryDate.getDate()
          this.filterDataAgenda.date = this.toDatetime(queryDate, false)
        }
      }
      this.getAllAgenda()
    },
    editAgenda(event) {
      this.newAgendaModalOpen = true
    },
    createNewRequestDocument(event) {
      const newAgendaData = {}
      newAgendaData.title = event.title
      newAgendaData.party_ids = event.party_ids
      newAgendaData.type = 'Document'
      newAgendaData.description = event.description
      newAgendaData.due_date = event.due_date
      newAgendaData.meeting_date = event.due_date
      newAgendaData.document_file_type_id = event.document_file_type_id
      newAgendaData.case_id = this.$route.params.slug
      this.newAgendaData = newAgendaData
      this.newAgendaModalActionOpen = true
    },
    createNewAgenda(event) {
      const newAgendaData = {}
      newAgendaData.title = event.title
      newAgendaData.party_ids = event.party_ids
      newAgendaData.type = event.type
      newAgendaData.description = event.description
      newAgendaData.detail = event.detail
      newAgendaData.meeting_date = event.meeting_date
      newAgendaData.meeting_url = event.meeting_url
      newAgendaData.meeting_type = event.meeting_type
      newAgendaData.meeting_platform = event.meeting_platform
      newAgendaData.meeting_time_from = event.meeting_time_from
      newAgendaData.meeting_time_to = event.meeting_time_to
      newAgendaData.case_id = this.$route.params.slug
      this.newAgendaData = newAgendaData
      this.newAgendaModalActionOpen = true
    },
    confirmedNewAgenda() {
      this.createAgenda()
    },
    async createAgenda() {
      await this.store
        .dispatch('calendar/createAgenda', this.newAgendaData)
        .then((response) => {
          const responseData = response.data
          // this.caseFroms = responseData
          // if (responseData.length > 0) this.caseFrom = responseData[0].id
          this.toast.success(responseData.message)
          this.newAgendaModalOpen = false
          this.newRequestDocumentModalOpen = false
          this.newAgendaModalActionOpen = false
          this.getAllAgenda()
        })
        .catch((error) => {
          this.newAgendaModalActionOpen = false
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async acceptOrRejectRequest() {
      await this.store
        .dispatch(
          'calendar/acceptOrRejectRequest',
          this.acceptRejectRequestData
        )
        .then((response) => {
          const responseData = response.data
          this.toast.success(responseData.message)
          this.calendarDetailAgendaOpen = false
          this.acceptRequestDocumentModalOpen = false
          this.rejectRequestDocumentModalOpen = false
          this.getAllAgenda()
        })
        .catch((error) => {
          this.calendarDetailAgendaOpen = false
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async getAllAgenda() {
      this.isLoading = true
      await this.store
        .dispatch('calendar/getAllAgenda', {
          order: this.filterDataAgenda.order,
          page: this.filterDataAgenda.page,
          take: this.filterDataAgenda.take,
          q: this.filterDataAgenda.search,
          case_id: this.$route.params.slug,
          date:
            this.filterDataAgenda.date === ''
              ? this.toDatetime(new Date(), false)
              : this.filterDataAgenda.date,
        })
        .then((response) => {
          this.isLoading = false
          const responseData = response.data.data
          if (responseData.data) {
            const agendas = responseData.data
            this.agendas = agendas

            agendas.forEach((agenda) => {
              if (agenda.type !== 'Document') {
                this.setMarginLeftCalendar(
                  agenda.meeting_time_from.slice(0, 2),
                  agenda.meeting_time_to.slice(0, 2)
                )
              }
            })
          }

          if (responseData.meta) {
            this.metaDataAgenda = responseData.meta
          }
        })
        .catch((error) => {
          console.log(error)
          this.isLoading = false
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async getAgendaDetail() {
      this.isLoading = true
      await this.store
        .dispatch('calendar/getAgendaDetail', {
          id: this.$route.params.slug,
        })
        .then((response) => {
          this.isLoading = false
          const responseData = response.data.data
          this.activeCase = responseData
        })
        .catch((error) => {
          this.isLoading = false
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async getCaseParties() {
      await this.store
        .dispatch('cases/getCaseParties', {
          case_id: this.$route.params.slug,
        })
        .then((response) => {
          const responseData = response.data.data
          this.caseParties = responseData
        })
        .catch((error) => {
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    changeDate(date) {
      this.dateActive = date
      this.filterDataAgenda.date = this.getDateString(date)
      this.agendas = []
      this.getAllAgenda()
    },
    randomBackground(type = 'bg') {
      const opacity = 500
      const colorArr = ['sky', 'orange', 'red', 'yellow', 'emerald']
      return `${type}-${
        colorArr[Math.floor(Math.random() * colorArr.length)]
      }-${opacity}`
    },
    setMarginLeftCalendar(timeStart, timeEnd) {
      console.log(timeStart, timeEnd)
      const BreakException = {}
      try {
        this.times.forEach((time) => {
          if (parseInt(timeEnd) < parseInt(time.twentyFour)) {
            throw BreakException
          } else if (parseInt(timeStart) >= parseInt(time.twentyFour)) {
            if (this.marginIndexCalendar.hasOwnProperty(timeStart)) {
              if (parseInt(timeStart) === parseInt(time.twentyFour))
                this.marginIndexCalendar[timeStart] += 1
            } else {
              if (parseInt(timeStart) === parseInt(time.twentyFour))
                this.marginIndexCalendar[timeStart] = 1
            }
          } else if (parseInt(timeEnd) === parseInt(time.twentyFour)) {
            if (this.marginIndexCalendar.hasOwnProperty(timeEnd)) {
              this.marginIndexCalendar[timeEnd] += 1
            } else {
              this.marginIndexCalendar[timeEnd] = 1
            }
          }
        })
      } catch (e) {
        if (e !== BreakException) throw e
        console.log(e)
      }

      console.log(this.marginIndexCalendar)
    },
    getMarginLeftCalendar(timeStart, timeEnd) {
      const oneMargin = 16.25
      if (!this.checkEmptyObject(this.marginIndexCalendar)) {
        if (this.marginIndexCalendar.hasOwnProperty(timeStart)) {
          if (this.marginIndexCalendar[timeStart] > 0) {
            const multiplier = this.marginIndexCalendar[timeStart]
            this.marginIndexCalendar[timeStart] =
              this.marginIndexCalendar[timeStart] - 1

            return oneMargin * multiplier
          }
        } else if (this.marginIndexCalendar.hasOwnProperty(timeEnd)) {
          if (this.marginIndexCalendar[timeEnd] > 0) {
            const multiplier = this.marginIndexCalendar[timeEnd]
            this.marginIndexCalendar[timeEnd] =
              this.marginIndexCalendar[timeEnd] - 1

            return oneMargin * multiplier
          }
        }
      }
    },
    showCalendarDetail(agenda) {
      this.activeAgenda = agenda
      this.calendarDetailAgendaOpen = true
    },

    // Reject Request Document
    rejectRequestDocumentAction(event) {
      this.acceptRejectRequestData.id = event.id
      this.acceptRejectRequestData.reason = event.reason
      this.acceptRejectRequestData.accept = false
      this.acceptOrRejectRequest()
    },
    accepetRequestDocumentAction(event) {
      this.acceptRejectRequestData.id = event.id
      this.acceptRejectRequestData.file_name = event.file_name
      this.acceptRejectRequestData.attachment = event.attachment
      this.acceptRejectRequestData.accept = true
      this.acceptOrRejectRequest()
    },
  },
}
</script>
