<template>
  <div class="relative rounded-sm">
    <div>
      <div class="overflow-x-auto">
        <table class="w-full table-auto divide-y divide-slate-200">
          <thead
            class="bg-transparent text-xs font-semibold uppercase text-slate-500"
          >
            <tr>
              <th
                v-for="header in headerData"
                class="whitespace-nowrap px-2 py-3 first:pl-5 last:pr-5"
                :colspan="header.colspan"
              >
                <div class="text-left font-semibold">{{ header.name }}</div>
              </th>
            </tr>
          </thead>
          <tbody :class="tbodyClass">
            <slot></slot>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TableComponent',
  props: {
    headerData: {
      type: Array,
      default: [],
    },
    tbodyClass: {
      type: String,
      default: '',
    },
  },
}
</script>
