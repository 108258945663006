<template>
  <div class="-m-1.5 flex flex-wrap items-center">
    <div class="m-1.5">
      <ModalBasic
        id="notes-modal"
        :backdrop="true"
        :modal-open="isOpen"
        :show-border-header="false"
        :title="$t('calendar.modalRequestDocument.title')"
        @close-modal="close"
      >
        <Form
          ref="form"
          v-slot="{ errors }"
          :validation-schema="newRequestDocumentSchema"
          @submit="confirm"
        >
          <div class="px-5 pb-4">
            <div class="space-y-5">
              <div>
                <label class="mb-1 block text-xs font-bold" for="title">
                  {{ $t('calendar.modalNewAgenda.formTitle') }}
                </label>
                <Field v-slot="{ field }" v-model="title" name="title">
                  <input
                    v-bind="field"
                    id="title"
                    class="form-input w-full p-2"
                    type="text"
                    required
                    :placeholder="$t('calendar.modalNewAgenda.formTitle')"
                  />
                </Field>
                <div v-if="errors.title !== undefined" class="flex w-full">
                  <span class="text-sm text-red-500">
                    {{ $t(errors.title) }}
                  </span>
                </div>
              </div>
              <div>
                <label class="mb-1 block text-xs font-bold" for="requestTo">
                  {{ $t('calendar.modalRequestDocument.requestTo') }}
                </label>
                <VueMultiselect
                  v-model="party_ids"
                  :options="caseParties.filter((item) => item.id !== me.id)"
                  :multiple="true"
                  :close-on-select="true"
                  :placeholder="$t('validation.selectRequestTo')"
                  label="name"
                  track-by="id"
                  :allow-empty="false"
                >
                </VueMultiselect>
              </div>
              <div>
                <label class="mb-1 block text-xs font-bold" for="fileTypes">
                  {{ $t('document.modalCreateFile.fileType') }}
                </label>
                <select
                  id="fileTypes"
                  v-model="file_type_id"
                  class="form-select w-full p-2"
                >
                  <option
                    v-for="fileType in fileTypes"
                    :key="fileType.id"
                    :value="fileType.id"
                  >
                    {{ fileType.name }}
                  </option>
                </select>
              </div>

              <div class="flex w-full flex-col">
                <label class="mb-1 block text-xs font-bold" for="meetingTime">
                  {{ $t('calendar.modalRequestDocument.dueTime') }}
                </label>
                <Datepicker
                  v-model="due_date"
                  :max-today="false"
                  :max-one-month="true"
                  :min-today="false"
                  :min-twoday="true"
                  :show-icon="false"
                  :date-only="true"
                  wrap-class="form-input h-12 w-full rounded-md"
                  mode="single"
                  @change-date="changeDate"
                />
              </div>
              <div class="flex w-full flex-col">
                <label class="mb-1 block text-xs font-bold" for="notes">
                  {{ $t('calendar.modalRequestDocument.additionalNotes') }}
                </label>

                <Field v-slot="{ field }" v-model="notes" name="notes">
                  <textarea
                    v-bind="field"
                    class="form-input w-full resize-y p-2"
                    :placeholder="
                      $t('calendar.modalRequestDocument.additionalNotes')
                    "
                  ></textarea>
                </Field>
                <div v-if="errors.notes !== undefined" class="flex w-full">
                  <span class="text-sm text-red-500">
                    {{ $t(errors.notes) }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="px-5 py-4">
            <div class="flex flex-wrap justify-center space-x-2">
              <button
                type="submit"
                class="btn-sm hover:bg-primary-600 w-full whitespace-nowrap rounded-lg bg-primary p-3 text-white"
              >
                {{ $t('button.save') }}
              </button>
            </div>
          </div>
        </Form>
      </ModalBasic>
    </div>
  </div>
</template>
<script>
import { Form, Field } from 'vee-validate'
import * as Yup from 'yup'
import ModalBasic from '@/components/ModalBasic.vue'
import Datepicker from '@/components/Datepicker.vue'

export default {
  name: 'ModalNewAgendaComponent',
  components: {
    Form,
    Field,
    ModalBasic,
    Datepicker,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    caseParties: {
      type: Array,
      default: () => {
        return []
      },
    },
    meetingTypes: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  emits: ['closed', 'confirm', 'empty-member'],
  data: () => ({
    fileTypes: [],
    party_ids: [],
    file_type_id: null,
    title: '',
    notes: '',
    due_date: null,
    newRequestDocumentSchema: Yup.object().shape({
      title: Yup.string()
        .typeError('validation.enterAgendaTitle')
        .required('validation.enterAgendaTitle'),
      notes: Yup.string()
        .typeError('validation.enterAgenda')
        .required('validation.enterAgenda'),
    }),
    me: null,
    filterDataFileTypes: {
      page: 1,
      take: 50,
      search: '',
      order: 'DESC',
    },
    metaDataFileTypes: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
  }),
  watch: {
    isOpen(val) {
      if (!val) this.close()
      else this.$refs.form.resetForm()
    },
  },
  created() {
    this.me = this.$store.getters['auth/activeUser']
    this.getFileTypes()
  },
  methods: {
    close() {
      this.$refs.form.resetForm()
      this.party_ids = []
      this.$emit('closed')
    },
    confirm() {
      if (this.party_ids.length <= 0) return this.$emit('empty-member')

      const newRequestDocumentData = {}
      newRequestDocumentData.party_ids = this.party_ids.map((item) => item.id)
      newRequestDocumentData.document_file_type_id = this.file_type_id
      newRequestDocumentData.title = this.title
      newRequestDocumentData.description = this.notes
      newRequestDocumentData.due_date = this.due_date

      this.$emit('confirm', newRequestDocumentData)
    },
    changeDate(val) {
      this.due_date = val
    },
    async getFileTypes() {
      this.isLoading = true
      await this.$store
        .dispatch('document/getFileTypes', {
          order: this.filterDataFileTypes.order,
          page: this.filterDataFileTypes.page,
          take: this.filterDataFileTypes.take,
          q: this.filterDataFileTypes.search,
        })
        .then((response) => {
          this.isLoading = false
          const responseData = response.data.data
          if (responseData.data) {
            this.fileTypes = responseData.data
            this.file_type_id = this.fileTypes[0].id
          }
          if (responseData.meta) {
            this.metaDataFileTypes = responseData.meta
          }
        })
        .catch((error) => {
          this.isLoading = false
          this.toast.error(this.extractErrorResponse(error))
        })
    },
  },
}
</script>
