<template>
  <div class="flex h-screen overflow-hidden">
    <!-- Sidebar -->
    <Sidebar :sidebarOpen="sidebarOpen" @close-sidebar="sidebarOpen = false" />

    <!-- Content area -->
    <div
      ref="contentArea"
      class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden"
    >
      <main>
        <div class="relative flex">
          <!-- Messages sidebar -->
          <MessagesSidebar
            :msgSidebarOpen="msgSidebarOpen"
            @close-msgsidebar="msgSidebarOpen = false"
          />

          <!-- Messages body -->
          <div
            class="flex grow transform flex-col transition-transform duration-300 ease-in-out md:translate-x-0"
            :class="msgSidebarOpen ? 'translate-x-1/3' : 'translate-x-0'"
          >
            <MessagesHeader
              :msgSidebarOpen="msgSidebarOpen"
              @toggle-msgsidebar="msgSidebarOpen = !msgSidebarOpen"
            />
            <MessagesBody />
            <MessagesFooter />
          </div>
        </div>
      </main>
    </div>

    <RightSidebar
      :sidebarOpen="sidebarOpen"
      @close-sidebar="sidebarOpen = false"
    />
  </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue'
import Sidebar from '../partials/Sidebar.vue'
import RightSidebar from '../partials/RightSidebar.vue'
import Header from '../partials/Header.vue'
import MessagesSidebar from '../partials/messages/MessagesSidebar.vue'
import MessagesHeader from '../partials/messages/MessagesHeader.vue'
import MessagesBody from '../partials/messages/MessagesBody.vue'
import MessagesFooter from '../partials/messages/MessagesFooter.vue'

export default {
  name: 'Messages',
  components: {
    Sidebar,
    RightSidebar,
    Header,
    MessagesSidebar,
    MessagesHeader,
    MessagesBody,
    MessagesFooter,
  },
  props: {
    status: {
      type: [String, Number],
      required: true,
    },
  },
  setup() {
    const sidebarOpen = ref(false)
    const msgSidebarOpen = ref(true)
    const contentArea = ref(null)

    const handleScroll = () => {
      contentArea.value.scrollTop = 99999999
    }

    onMounted(() => {
      handleScroll()
    })

    watch(msgSidebarOpen, () => {
      handleScroll()
    })

    return {
      sidebarOpen,
      msgSidebarOpen,
      contentArea,
    }
  },
}
</script>
