<template>
  <div class="flex h-screen overflow-hidden">
    <!-- Sidebar -->
    <Sidebar :sidebar-open="sidebarOpen" @close-sidebar="sidebarOpen = false" />

    <!-- Content area -->
    <div
      class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden"
    >
      <main>
        <div class="max-w-9xl mx-auto w-full px-4 py-8 sm:px-6 lg:px-8">
          <div class="flex justify-between">
            <div class="flex w-full flex-col">
              <h1 class="text-2xl font-bold text-black">
                {{ $t(`admin.cases.title.${status}`) }}
              </h1>
              <p>{{ $t(`admin.cases.description.${status}`) }}</p>
            </div>

            <DropdownNotifications
              align="right"
              class="md:mr-4"
              wrapper-class="bg-white rounded-lg"
            />
          </div>

          <div class="my-4 flex w-full justify-between">
            <div class="flex items-center space-x-4">
              <SearchForm
                :placeholder="$t('admin.cases.searchPlaceholder')"
                @search="searchCase"
              />
              <div class="flex cursor-pointer items-start text-sm text-black">
                <div class="flex">
                  <p>{{ $t('formLabel.sortBy') }}</p>
                </div>
                <div class="flex">
                  <select
                    class="border-none bg-inherit py-0 font-bold focus:ring-0"
                    @change="changeOrder"
                  >
                    <option
                      v-for="order in orderOptions"
                      :key="order.value"
                      :value="order.value"
                      :selected="filterDataCases.order === order.value"
                    >
                      {{ $t(`${order.label}`) }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="flex space-x-2">
              <div
                class="cursor-pointer"
                :class="[caseListType === 'grid' ? 'text-black' : '']"
                @click="caseListType = 'grid'"
              >
                <GridIcon />
              </div>
              <div
                class="cursor-pointer"
                :class="[caseListType !== 'grid' ? 'text-black' : '']"
                @click="caseListType = 'list'"
              >
                <ListIcon />
              </div>
            </div>
          </div>

          <div class="w-full py-5">
            <div
              v-if="!isLoading && cases.length > 0 && caseListType === 'grid'"
              class="grid grid-cols-12 gap-6"
            >
              <CaseCard
                :cases="cases"
                @click-case="toCaseDetail($event, true)"
              />
            </div>
            <div
              v-else-if="
                !isLoading && cases.length > 0 && caseListType !== 'grid'
              "
            >
              <CaseList
                :cases="cases"
                @click-case="toCaseDetail($event, true)"
              />
            </div>
            <DualRingLoader v-else-if="isLoading" />
            <NoData v-else />
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import globalMixin from '@/mixins/global.js'
import caseMixin from '@/mixins/case.js'

import Sidebar from '@/partials/Sidebar.vue'
import SearchForm from '@/components/SearchForm.vue'
import CaseCard from '@/components/CaseCard.vue'
import CaseList from '@/components/CaseList.vue'
import NoData from '@/components/NoData.vue'

import GridIcon from '@/assets/svg/view-grid.svg'
import ListIcon from '@/assets/svg/view-list.svg'

import DualRingLoader from '@/components/DualRingLoader.vue'
import DropdownNotifications from '@/components/DropdownNotifications.vue'

export default {
  name: 'AdminCasesPage',
  components: {
    Sidebar,
    SearchForm,
    CaseCard,
    CaseList,
    NoData,
    DualRingLoader,
    GridIcon,
    ListIcon,
    DropdownNotifications,
  },
  mixins: [globalMixin, caseMixin],
  props: {
    status: {
      type: [String, Number],
      required: true,
    },
  },
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const toast = useToast()

    const sidebarOpen = ref(false)

    return {
      t,
      n,
      store,
      toast,
      sidebarOpen,
    }
  },
  data: () => ({
    cases: [],
    allCases: [],
    filterDataCases: {
      page: 1,
      take: 50,
      search: '',
      order: 'DESC',
    },
    metaDataCases: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
  }),
  mounted() {
    this.checkUserAccess('REDEK_ADMIN')
    this.getAllCases()
  },
  methods: {
    async getAllCases() {
      this.isLoading = true
      await this.store
        .dispatch('cases/getAllCasesAdmin', {
          order: this.filterDataCases.order,
          page: this.filterDataCases.page,
          take: this.filterDataCases.take,
          q: this.filterDataCases.search,
          status: this.caseStatusFilter(this.status),
        })
        .then((response) => {
          this.isLoading = false
          const responseData = response.data.data
          if (responseData.data) {
            let mappedDataCase = []
            responseData.data.forEach((item) => {
              item.status = this.stripAndLower(item.status)
              mappedDataCase.push(item)
            })
            this.cases = mappedDataCase
            this.allCases = mappedDataCase
          }
          if (responseData.meta) {
            this.metaDataCases = responseData.meta
          }
        })
        .catch((error) => {
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    changeOrder(event) {
      this.filterDataCases.order = ['DESC', 'ASC'].includes(event.target.value)
        ? event.target.value
        : 'DESC'
      this.getAllCases()
    },
    searchCase(event) {
      this.filterDataCases.search = event
      this.getAllCases()
    },
  },
}
</script>
