export default {
  data: () => ({
    folderData: {},
    fileData: {},
    documents: [],
    folders: [],
    folderDetail: {},
    activeDocument: {},
    fileData: {},

    isInsideFolder: false,
    newFileModalOpen: false,
    editDocumentModalOpen: false,
    infoDocumentModalOpen: false,
    newFolderModalOpen: false,
    deleteDocumentModalOpen: false,

    filterDataDocument: {
      page: 1,
      take: 50,
      search: '',
      order: 'DESC',
    },
    metaDataDocument: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    filterDataFolder: {
      page: 1,
      take: 50,
      search: '',
      order: 'DESC',
      type: 'Folder',
    },
    metaDataFolder: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
  }),
  methods: {
    // Async Method
    async createFolderAPI() {
      await this.store
        .dispatch('document/createFolder', this.folderData)
        .then((response) => {
          const responseData = response.data
          this.toast.success(responseData.message)
          this.newFolderModalOpen = false
          this.getAllDocument()
          this.getFolders()
        })
        .catch((error) => {
          this.newFolderModalOpen = true
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async createFileAPI() {
      await this.store
        .dispatch('document/createFile', this.fileData)
        .then((response) => {
          const responseData = response.data
          this.toast.success(responseData.message)
          this.newFileModalOpen = false
          if (this.isInsideFolder) {
            this.getFolderDocument()
          } else {
            this.getAllDocument()
          }
        })
        .catch((error) => {
          this.newFileModalOpen = true
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async updateFolderAPI() {
      await this.store
        .dispatch('document/updateFolder', this.folderData)
        .then((response) => {
          const responseData = response.data
          this.toast.success(responseData.message)
          this.editDocumentModalOpen = false
          this.getAllDocument()
          this.getFolders()
        })
        .catch((error) => {
          this.editDocumentModalOpen = true
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async updateFileAPI() {
      await this.store
        .dispatch('document/updateFile', this.fileData)
        .then((response) => {
          const responseData = response.data
          this.toast.success(responseData.message)
          this.editDocumentModalOpen = false
          if (this.isInsideFolder) {
            this.getFolderDocument()
          } else {
            this.getAllDocument()
          }
        })
        .catch((error) => {
          this.editDocumentModalOpen = true
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async getAllDocument() {
      this.isLoading = true
      await this.store
        .dispatch('document/getAllDocument', {
          order: this.filterDataDocument.order,
          page: this.filterDataDocument.page,
          take: this.filterDataDocument.take,
          q: this.filterDataDocument.search,
          case_id: this.$route.params.slug,
        })
        .then((response) => {
          this.isLoading = false
          const responseData = response.data.data
          if (responseData.data) {
            this.documents = responseData.data
          }
          if (responseData.meta) {
            this.metaDataDocument = responseData.meta
          }
        })
        .catch((error) => {
          this.isLoading = false
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async getFolders() {
      this.isLoading = true
      await this.$store
        .dispatch('document/getAllDocument', {
          order: this.filterDataFolder.order,
          page: this.filterDataFolder.page,
          take: this.filterDataFolder.take,
          q: this.filterDataFolder.search,
          type: this.filterDataFolder.type,
          case_id: this.$route.params.slug,
        })
        .then((response) => {
          this.isLoading = false
          const responseData = response.data.data
          if (responseData.data) {
            this.folders = responseData.data
          }
          if (responseData.meta) {
            this.metaDataFolder = responseData.meta
          }
        })
        .catch((error) => {
          this.isLoading = false
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async deleteDocumentAPI(events) {
      console.log(events)
      await this.store
        .dispatch('document/deleteDocument', {
          id: events.id,
        })
        .then((response) => {
          if (response.data.statusCode === 200) {
            const message = response.data.message
            this.toast.success(message)
            this.deleteDocumentModalOpen = false
            this.getAllDocument()
          }
        })
        .catch((error) => {
          console.log(error)
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async getDetailById() {
      this.isLoading = true
      await this.store
        .dispatch('document/getDocumentDetail', {
          id: this.$route.params.folderId,
        })
        .then((response) => {
          this.isLoading = false
          const responseData = response.data.data
          if (responseData) this.folderDetail = responseData
        })
        .catch((error) => {
          this.isLoading = false
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async getFolderDocument() {
      this.isLoading = true
      await this.store
        .dispatch('document/getByFolder', {
          order: this.filterDataDocument.order,
          page: this.filterDataDocument.page,
          take: this.filterDataDocument.take,
          q: this.filterDataDocument.search,
          folder_id: this.$route.params.folderId,
        })
        .then((response) => {
          this.isLoading = false
          const responseData = response.data.data
          if (responseData.data) {
            this.documents = responseData.data
          }
          if (responseData.meta) {
            this.metaDataDocument = responseData.meta
          }
        })
        .catch((error) => {
          this.isLoading = false
          this.toast.error(this.extractErrorResponse(error))
        })
    },

    // Local method
    infoDocument(event) {
      this.infoDocumentModalOpen = true
      this.activeDocument = event.document
    },
    editDocument(event) {
      this.editDocumentModalOpen = true
      this.activeDocument = event.document
    },
    editCloseModal() {
      this.editDocumentModalOpen = false
      this.activeDocument = {}
    },
    deleteDocument(event) {
      this.deleteDocumentModalOpen = true
      this.activeDocument = event.document
    },
    saveFolder(event) {
      const folderData = {}
      folderData.name = event.name
      folderData.party_ids = event.party_id
      folderData.case_id = this.$route.params.slug
      this.folderData = folderData
      this.createFolderAPI()
    },
    saveFile(event) {
      const fileData = {}
      fileData.name = event.name
      fileData.party_ids = event.party_id
      fileData.document_type_id = event.file_type_id
      fileData.attachment = event.attachment
      fileData.case_id = this.$route.params.slug
      if (this.isInsideFolder) {
        fileData.folder_id = this.$route.params.folderId
      } else {
        fileData.folder_id = event.folder_id
      }
      this.fileData = fileData
      this.createFileAPI()
    },
    updateFolder(event) {
      const folderData = {}
      folderData.name = event.name
      folderData.party_ids = event.party_id
      folderData.id = event.id
      this.folderData = folderData
      this.updateFolderAPI()
    },
    updateFile(event) {
      const fileData = {}
      fileData.name = event.name
      fileData.party_ids = event.party_id
      fileData.document_type_id = event.file_type_id
      fileData.folder_id = event.folder_id
      fileData.id = event.id
      this.fileData = fileData
      this.updateFileAPI()
    },
    searchDocument(event) {
      this.filterDataDocument.search = event
      this.getAllDocument()
    },
  },
}
