<template>
  <div class="flex h-screen overflow-hidden">
    <!-- Sidebar -->
    <Sidebar
      :sidebar-open="sidebarOpen"
      :case-state="$route.query.status"
      @close-sidebar="sidebarOpen = false"
    />

    <!-- Content area -->
    <div
      class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden"
    >
      <!-- Site header -->
      <!-- <Header
        :sidebarOpen="sidebarOpen"
        @toggle-sidebar="sidebarOpen = !sidebarOpen"
      /> -->

      <main>
        <div class="max-w-9xl mx-auto w-full px-4 py-8 sm:px-6 lg:px-8">
          <div class="flex w-full flex-col">
            <h1 class="text-2xl font-bold text-black">
              {{ $t('privateNotebook.title') }}
            </h1>
            <p>{{ $t('privateNotebook.description') }}</p>
          </div>
          <div class="my-4 flex w-full items-center justify-between">
            <SearchForm
              :placeholder="$t('privateNotebook.searchPlaceholder')"
              @search="searchNotebook"
            />
            <ButtonAdd
              :title="$t('privateNotebook.buttonAdd')"
              custom-class="rounded-xl"
              @clicked="addNote"
            />
          </div>
          <section
            v-if="!isLoading && (pinnedNotes.length > 0 || notes.length > 0)"
          >
            <!-- Pinned -->
            <div v-if="pinnedNotes.length > 0" class="w-full">
              <div class="pb-4 pt-8">
                <h1 class="text-xl font-semibold text-black">
                  {{ $t('privateNotebook.pinned') }}
                </h1>
              </div>
              <div class="grid grid-cols-12 gap-6">
                <PrivateNotebokItem
                  v-for="note in pinnedNotes"
                  :key="note.id"
                  :note="note"
                  :pinned="note.is_pinned === true"
                  :date="formatDateDay(note.modified)"
                  @edit-note="editNote"
                  @pin-item="pinItem"
                />
              </div>
            </div>
            <!-- All Notes -->
            <div v-if="notes.length > 0" class="w-full">
              <div class="pb-4 pt-8">
                <h1 class="text-xl font-semibold text-black">
                  {{ $t('privateNotebook.allNotes') }}
                </h1>
              </div>
              <div class="grid grid-cols-12 gap-6">
                <PrivateNotebokItem
                  v-for="note in notes"
                  :key="note.id"
                  :note="note"
                  :pinned="note.is_pinned === true"
                  :date="formatDateDay(note.modified)"
                  @edit-note="editNote"
                  @pin-item="pinItem"
                />
              </div>
            </div>
          </section>
          <DualRingLoader v-else-if="isLoading" />
          <NoData v-else />
        </div>
        <ModalNote
          :is-open="notesModalOpen"
          :date="
            updateNoteData.modified !== ''
              ? formatDateDay(updateNoteData.modified)
              : formatDateDay(new Date())
          "
          :pinned="updateNoteData.is_pinned === true"
          :active-note="updateNoteData"
          @closed="notesModalOpen = false"
          @new-note="newNote"
          @edit-note="updateNote"
          @delete-note="deleteNote"
          @duplicate-note="duplicateNote"
        />
      </main>
    </div>

    <RightSidebar
      :sidebar-open="sidebarOpen"
      @close-sidebar="sidebarOpen = false"
    />
  </div>
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import globalMixin from '@/mixins/global.js'

import Sidebar from '@/partials/Sidebar.vue'
import RightSidebar from '@/partials/RightSidebar.vue'
import SearchForm from '@/components/SearchForm.vue'
import ButtonAdd from '@/components/ButtonAdd.vue'
import PrivateNotebokItem from '@/components/PrivateNotebokItem.vue'
import ModalNote from '@/components/ModalNote.vue'
import DualRingLoader from '@/components/DualRingLoader.vue'
import NoData from '@/components/NoData.vue'

export default {
  name: 'PrivateNotebookPage',
  components: {
    Sidebar,
    RightSidebar,
    SearchForm,
    ButtonAdd,
    PrivateNotebokItem,
    ModalNote,
    DualRingLoader,
    NoData,
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const toast = useToast()
    const sidebarOpen = ref(false)

    return {
      t,
      n,
      store,
      toast,
      sidebarOpen,
    }
  },
  data: () => ({
    filterDataNotes: {
      page: 1,
      take: 50,
      search: '',
      order: 'DESC',
    },
    metaDataNotes: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    notes: [],
    pinnedNotes: [],
    defaultNote: {
      title: '',
      content: '',
      pinned: false,
      modified: '',
    },
    updateNoteData: {},
    newNoteData: {},
    notesModalOpen: false,
  }),
  mounted() {
    this.getAllNotes()
  },
  methods: {
    editNote(event) {
      this.updateNoteData = event
      this.notesModalOpen = true
    },
    addNote() {
      this.updateNoteData = this.defaultNote
      this.notesModalOpen = true
    },
    newNote(event) {
      this.newNoteData = event
      this.createNote()
    },
    updateNote(event) {
      this.updateNoteData = event
      this.updateNoteAPI()
    },
    deleteNote(event) {
      this.deleteNoteAPI(event)
    },
    duplicateNote(event) {
      this.newNoteData = event
      this.createNote()
    },
    pinItem(event) {
      if (event.is_pinned === true) {
        this.notes = this.notes.filter((item) => item.id !== event.id)
        this.pinnedNotes.push(event)
      } else {
        this.pinnedNotes = this.pinnedNotes.filter(
          (item) => item.id !== event.id
        )
        this.notes.push(event)
      }
      this.updateNoteData = event
      this.updateNoteAPI(false)
    },
    async deleteNoteAPI(id) {
      await this.store
        .dispatch('notes/deleteNote', { id })
        .then((response) => {
          this.getAllNotes()
          const responseData = response.data
          this.toast.success(responseData.message)
          this.notesModalOpen = false
        })
        .catch((error) => {
          this.notesModalOpen = true
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async updateNoteAPI(reload = true) {
      await this.store
        .dispatch('notes/updateNote', this.updateNoteData)
        .then((response) => {
          if (reload === true) {
            this.getAllNotes()
            const responseData = response.data
            this.toast.success(responseData.message)
            this.notesModalOpen = false
          }
        })
        .catch((error) => {
          if (reload === true) this.notesModalOpen = true
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async createNote() {
      await this.store
        .dispatch('notes/createNote', {
          case_id: this.$route.params.slug,
          ...this.newNoteData,
        })
        .then((response) => {
          const responseData = response.data
          this.toast.success(responseData.message)
          this.notesModalOpen = false
          this.getAllNotes()
        })
        .catch((error) => {
          this.notesModalOpen = true
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async getAllNotes() {
      this.isLoading = true
      let params = {}
      params.order = this.filterDataNotes.order
      params.page = this.filterDataNotes.page
      params.take = this.filterDataNotes.take
      params.q = this.filterDataNotes.search
      params.case_id = this.$route.params.slug

      await this.store
        .dispatch('notes/getAllNote', params)
        .then((response) => {
          this.isLoading = false
          const responseData = response.data.data
          if (responseData.data) {
            this.pinnedNotes = responseData.data.filter(
              (item) => item.is_pinned === true && item.deleted_at === null
            )
            this.notes = responseData.data.filter(
              (item) => item.is_pinned !== true && item.deleted_at === null
            )
          }
          if (responseData.meta) {
            this.metaDataDocument = responseData.meta
          }
        })
        .catch((error) => {
          this.isLoading = false
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    searchNotebook(event) {
      this.filterDataNotes.search = event
      this.getAllNotes()
    },
  },
}
</script>
