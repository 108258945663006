<template>
  <div
    v-if="advocate"
    class="flex justify-between rounded-lg border border-gray-300 p-4"
  >
    <div class="flex w-6/12 justify-start space-x-2">
      <ImageLoader
        wrapper-class="rounded-full"
        :src="advocate.user.avatar"
        width="50"
        height="50"
        :alternate="advocate.user.first_name"
      />
      <div>
        <p class="font-medium text-slate-800">
          {{ advocate.user.first_name }}
        </p>
        <p class="font-xs text-slate-800">{{ $t('general.advocate') }}</p>
      </div>
    </div>
    <div class="flex w-6/12 items-center justify-end space-x-2">
      <span>{{ advocate.rating }}</span>
      <svg
        class="h-4 w-4 shrink-0 fill-current text-amber-500"
        viewBox="0 0 16 16"
      >
        <path
          d="M8 0L6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934h-6L8 0z"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import ImageLoader from '@/components/image/ImageLoader.vue'

export default {
  name: 'AdvocateItemComponent',
  components: { ImageLoader },
  props: {
    advocate: {
      type: Object,
      required: true,
    },
  },
}
</script>
