<template>
  <div
    class="w-full text-center"
    :class="[wrapperClass !== '' ? wrapperClass : '']"
  >
    <p v-if="searchKeyword !== ''" :class="[textClass !== '' ? textClass : '']">
      {{ $t('noData') }} for keyword
      <span class="font-bold">'{{ searchKeyword }}' </span>
    </p>
    <p v-else :class="[textClass !== '' ? textClass : '']">
      {{ $t('noData') }}
    </p>
  </div>
</template>
<script>
export default {
  name: 'NoData',
  props: {
    searchKeyword: {
      type: String,
      default: '',
    },
    wrapperClass: {
      type: String,
      default: '',
    },
    textClass: {
      type: String,
      default: '',
    },
  },
}
</script>
