<template>
  <div class="-m-1.5 flex flex-wrap items-center">
    <div class="m-1.5">
      <ModalBasic
        id="notes-modal"
        :modal-open="isOpen"
        :show-border-header="false"
        :title="
          activeActivity && Object.keys(activeActivity).length !== 0
            ? $t('admin.timeline.modalCreateTimeline.editTitle')
            : $t('admin.timeline.modalCreateTimeline.title')
        "
        @close-modal="close"
      >
        <Form
          ref="form"
          v-slot="{ errors }"
          :validation-schema="newActivitySchema"
          @submit="submitForm"
        >
          <div class="px-5">
            <div class="space-y-3 px-5">
              <div v-if="isAdmin">
                <label class="mb-1 block text-xs font-bold" for="users">
                  {{ $t('admin.timeline.modalCreateTimeline.user') }}
                </label>
                <select
                  id="users"
                  v-model="user_id"
                  class="form-select w-full p-2"
                >
                  <option
                    v-for="user in users"
                    :key="user.id"
                    :value="user.id"
                    :selected="
                      activeActivity.user
                        ? activeActivity.user.name === user.name
                        : ''
                    "
                  >
                    {{ user.name }}
                  </option>
                </select>
              </div>
              <div>
                <label class="mb-1 block text-xs font-bold" for="types">
                  {{ $t('admin.timeline.modalCreateTimeline.type') }}
                </label>
                <select
                  id="types"
                  v-model="type_id"
                  class="form-select w-full p-2"
                >
                  <option
                    v-for="activityType in types"
                    :key="activityType.id"
                    :value="activityType.id"
                    :selected="
                      activeActivity.activityType
                        ? activeActivity.activityType.name === activityType.name
                        : ''
                    "
                  >
                    {{ activityType.name }}
                  </option>
                </select>
              </div>
              <div>
                <label class="mb-1 block text-xs font-bold" for="title">
                  {{ $t('admin.timeline.modalCreateTimeline.activityTitle') }}
                </label>
                <Field v-slot="{ field }" v-model="title" name="title">
                  <input
                    id="title"
                    v-bind="field"
                    class="form-input w-full p-2"
                    type="text"
                    required
                    :placeholder="
                      $t('admin.timeline.modalCreateTimeline.activityTitle')
                    "
                  />
                </Field>
                <div v-if="errors.title !== undefined" class="flex w-full">
                  <span class="text-sm text-red-500">
                    {{ $t(errors.title) }}
                  </span>
                </div>
              </div>
              <div>
                <label class="mb-1 block text-xs font-bold" for="subtitle">
                  {{ $t('admin.timeline.modalCreateTimeline.subtitle') }}
                </label>
                <input
                  id="subtitle"
                  v-model="subtitle"
                  class="form-input w-full p-2"
                  type="text"
                  required
                  :placeholder="
                    $t('admin.timeline.modalCreateTimeline.subtitle')
                  "
                />
              </div>
              <div>
                <label class="mb-1 block text-xs font-bold" for="content">
                  {{ $t('admin.timeline.modalCreateTimeline.content') }}
                </label>
                <textarea
                  id="content"
                  v-model="content"
                  class="form-input w-full p-2"
                  required
                  :placeholder="
                    $t('admin.timeline.modalCreateTimeline.content')
                  "
                ></textarea>
              </div>
              <div>
                <label class="mb-1 block text-xs font-bold" for="extra">
                  {{ $t('admin.timeline.modalCreateTimeline.extra') }}
                </label>
                <textarea
                  id="extra"
                  v-model="extra"
                  class="form-input w-full p-2"
                  required
                  :placeholder="$t('admin.timeline.modalCreateTimeline.extra')"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="px-5 py-4">
            <div class="flex flex-wrap justify-center space-x-2 px-4">
              <button
                type="submit"
                class="btn-sm hover:bg-primary-600 w-full whitespace-nowrap rounded-lg bg-primary p-3 text-white"
              >
                <span>
                  {{ $t('admin.timeline.modalCreateTimeline.save') }}
                </span>
              </button>
            </div>
          </div>
        </Form>
      </ModalBasic>
    </div>
  </div>
</template>
<script>
import ModalBasic from './ModalBasic.vue'
import { Form, Field } from 'vee-validate'
import globalMixin from '@/mixins/global.js'

export default {
  name: 'ModalCreateActivityComponent',
  components: {
    ModalBasic,
    Form,
    Field,
  },
  mixins: [globalMixin],

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    activeActivity: {
      type: Object,
      default: () => {
        return {
          user_id: '',
          type_id: '',
          case_id: '',
          title: '',
          subtitle: '',
          content: '',
          extra: '',
        }
      },
    },
    users: {
      type: Array,
      default: () => {
        return []
      },
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['closed', 'submitData'],
  data: () => ({
    user_id: '',
    type_id: 'stage',
    case_id: '',
    title: '',
    subtitle: '',
    content: '',
    extra: '',
    types: [
      { id: 'stage', name: 'Stage' },
      { id: 'chat', name: 'Mail' },
    ],
  }),
  watch: {
    isOpen(val) {
      if (!val) this.close()
      else this.$refs.form.resetForm()
    },
  },
  mounted() {
    this.user_id = this.activeActivity.user_id
    this.type_id = this.activeActivity.type_id
      ? this.activeActivity.type_id
      : 'stage'
    this.case_id = this.activeActivity.case_id
    this.title = this.activeActivity.title
    this.subtitle = this.activeActivity.subtitle
    this.content = this.activeActivity.content
    this.extra = this.activeActivity.extra
  },
  methods: {
    close() {
      this.$refs.form.resetForm()
      this.$emit('closed')
    },
    submitForm() {
      const formData = {
        user_id: this.user_id,
        type_id: this.type_id,
        case_id: this.case_id,
        title: this.title,
        subtitle: this.subtitle,
        content: this.content,
        extra: this.extra,
      }
      this.$emit('submitData', formData)
    },
  },
}
</script>
