<template>
  <ModalBlank id="danger-modal" :modal-open="isOpen" @close-modal="close">
    <Form
      ref="form"
      v-slot="{ errors }"
      :validation-schema="shareEmailSchema"
      @submit="shareEmail"
    >
      <div class="flex space-x-4 p-5 text-center">
        <!-- Content -->
        <div class="w-full">
          <!-- Modal header -->
          <div class="mb-2">
            <div class="text-lg font-bold text-slate-800">
              <p>{{ $t('general.share') }}</p>
            </div>
          </div>
          <!-- Modal content -->
          <div class="mb-2 text-sm">
            <div class="space-y-2">
              <p>{{ $t('validation.pleaseDescribeEmail') }}</p>
            </div>
          </div>
          <div class="mb-4">
            <Field v-slot="{ field }" v-model="email" name="email">
              <input
                v-bind="field"
                type="email"
                placeholder="xx@xx.com,yy@yy.com"
                autofocus="autofocus"
                class="w-full rounded-lg border border-gray-200 shadow-sm focus:border-gray-300"
              />
            </Field>
            <div v-if="errors.email !== undefined" class="flex w-full">
              <span class="text-sm text-red-500">
                {{ $t(errors.email) }}
              </span>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="flex w-full justify-center space-x-2">
            <button
              class="btn-lg w-full rounded-lg border-slate-200 text-slate-600 hover:border-slate-300"
              type="button"
              @click.stop="close()"
            >
              {{ $t('button.cancel') }}
            </button>
            <button
              class="btn-lg w-full rounded-lg bg-primary text-white"
              :class="[
                isLoading
                  ? 'cursor-not-allowed'
                  : 'hover:bg-primary-600 cursor-pointer',
              ]"
              type="submit"
            >
              <span v-if="isLoading">
                {{ $t('general.loading') }}
              </span>
              <span v-else>
                {{ $t('button.shareToThisEmail') }}
              </span>
            </button>
          </div>
        </div>
      </div>
    </Form>
  </ModalBlank>
</template>
<script>
import { Form, Field } from 'vee-validate'
import * as Yup from 'yup'
import ModalBlank from '@/components/ModalBlank.vue'

export default {
  name: 'ModalShareEmailComponent',
  components: {
    ModalBlank,
    Form,
    Field,
  },
  props: {
    id: {
      type: [String, Number],
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['closed', 'share'],
  data: () => ({
    email: '',
    shareEmailSchema: Yup.object().shape({
      email: Yup.string()
        .transform((value) => Array.from(new Set(value.split(','))).join(',')) // dedupe - optional step
        .required()
        .test(
          'emails',
          'validation.enterValidEmail',
          (value) =>
            value &&
            value
              .split(',')
              .every((val) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(val))
        ),
      // email: Yup.string()
      //   .email('validation.enterValidEmail')
      //   .typeError('validation.enterValidEmail')
      //   .required('validation.enterValidEmail'),
    }),
  }),
  watch: {
    isOpen(val) {
      if (!val) this.close()
      else this.$refs.form.resetForm()
    },
  },
  methods: {
    close() {
      this.$refs.form.resetForm()
      this.$emit('closed')
    },
    shareEmail() {
      if (!this.isLoading) {
        const params = { id: this.id, email: this.email }
        this.$emit('share', params)
      }
    },
  },
}
</script>
