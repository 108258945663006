import axios from 'axios'

const tokenType = localStorage.getItem('inresolve.tokenType') || 'Bearer'
const accessToken = localStorage.getItem('inresolve.accessToken') || null
const refreshToken = localStorage.getItem('inresolve.refreshToken') || null

if (accessToken !== null) {
  axios.defaults.headers.common['Authorization'] = `${tokenType} ${accessToken}`
} else {
  delete axios.defaults.headers.common['Authorization']
}

if (
  import.meta.env.MODE === 'production' ||
  import.meta.env.MODE === 'staging'
) {
  axios.defaults.baseURL = import.meta.env.VITE_API_URL
} else {
  // axios.defaults.baseURL = 'https://api.pinia.app'
}

export function routePathAPI() {
  if (
    import.meta.env.MODE === 'production' ||
    import.meta.env.MODE === 'staging'
  ) {
    return '/v1'
  } else {
    return '/api/v1'
  }
}

const axiosInstance = axios.create({
  timeout: 90000,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'x-api-key': import.meta.env.VITE_API_KEY,
    'x-language-code': import.meta.env.VITE_LANGUAGE_CODE,
  },
})

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response, config } = error

    if (response.status === 401) {
      return axios
        .post(
          `${routePathAPI()}/auth/token/refresh`,
          { refresh_token: refreshToken },
          {
            ...config,
          }
        )
        .then((response) => {
          const statusCode = response.data.statusCode
          if (statusCode === 200) {
            const token = response.data.data

            // SET NEW TOKEN
            localStorage.setItem('inresolve.accessToken', token.access_token)
            // APPEND TOKEN TO CURRENT HEADER BEARER
            axiosInstance.defaults.headers.common[
              'Authorization'
            ] = `${tokenType} ${token.access_token}`

            // RETRYING REQUEST
            return axiosInstance(config)
          } else {
            return Promise.reject(response)
          }
        })
        .catch(() => {
          return Promise.reject(error)
        })
    }
    return Promise.reject(error)
  }
)

export default axiosInstance
