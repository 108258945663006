<template>
  <div
    v-for="item in cases"
    :key="item.id"
    class="col-span-12 flex transform cursor-pointer flex-col rounded-2xl bg-white shadow-sm transition duration-500 hover:scale-105 sm:col-span-6 lg:col-span-4 xl:col-span-3"
    @click="buttonClicked(item)"
  >
    <div class="flex h-full flex-col justify-between px-5 py-5">
      <div clas="flex">
        <p class="text-xl font-bold">
          {{ item.name }}
        </p>
      </div>
      <div class="mt-2 flex justify-start space-x-2">
        <div class="flex">
          <div
            v-if="item.status === 'onprepare'"
            class="flex w-max rounded-lg bg-red-100 py-1 px-4"
          >
            <p class="text-xs font-bold text-red-500">
              {{ $t('case.onPrepare') }}
            </p>
          </div>
          <div
            v-else-if="item.status === 'ongoing'"
            class="flex w-max rounded-lg bg-orange-100 py-1 px-4"
          >
            <p class="text-xs font-bold text-orange-500">
              {{ $t('case.onGoing') }}
            </p>
          </div>
          <div
            v-else-if="item.status === 'solved'"
            class="flex w-max rounded-lg bg-green-100 py-1 px-4"
          >
            <p class="text-xs font-bold text-green-500">
              {{ $t('case.solved') }}
            </p>
          </div>
        </div>
        <div class="flex">
          <div
            v-if="item.priority === 'high'"
            class="flex w-max justify-center rounded-lg bg-red-100 py-1 px-2"
          >
            <p class="text-xs font-bold text-red-500">
              {{ $t('case.highUrgency') }}
            </p>
          </div>
          <div
            v-else-if="item.priority === 'medium'"
            class="flex w-full justify-center rounded-lg bg-orange-100 py-1 px-2"
          >
            <p class="text-xs font-bold text-orange-500">
              {{ $t('case.mediumUrgency') }}
            </p>
          </div>
          <div
            v-else-if="item.priority === 'low'"
            class="flex w-full justify-center rounded-lg bg-green-100 py-1 px-2"
          >
            <p class="text-xs font-bold text-green-500">
              {{ $t('case.lowUrgency') }}
            </p>
          </div>
        </div>
      </div>

      <div class="mt-8 flex w-full flex-row justify-between">
        <div class="flex">
          <Avatars :parties="item.parties" />
        </div>
        <div class="flex self-center">
          <p class="text-sm">
            {{ item.parties.length }} {{ $t('case.parties') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatars from './Avatars.vue'

export default {
  name: 'CaseCard',
  components: {
    Avatars,
  },
  props: {
    cases: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  emits: ['click-case'],
  methods: {
    buttonClicked(item) {
      this.$emit('click-case', item)
    },
  },
}
</script>
