<template>
  <div class="flex w-full flex-wrap bg-white">
    <div class="flex justify-start space-x-2">
      <div class="flex px-4">
        <ImageLoader
          wrapper-class="shrink-0 rounded-full"
          :src="user.avatar"
          width="60"
          height="60"
          :alternate="user.first_name"
        />
      </div>
      <div>
        <div class="space-x-2 font-medium">
          <span>{{ user.first_name }} {{ user.last_name }}</span>
          <span v-if="me.id === user.id">(you)</span>
        </div>
        <div v-if="user.role" class="flex justify-start">
          <p
            class="rounded-lg bg-purple-100 px-2 py-2 text-center text-base font-semibold text-purple-600"
          >
            {{ user.role.name }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ImageLoader from '@/components/image/ImageLoader.vue'

export default {
  name: 'MemberItemComponent',
  components: { ImageLoader },
  props: {
    user: {
      type: Object,
      default: () => {
        return {}
      },
    },
    me: {
      type: Object,
      default: () => {
        return {
          id: '',
        }
      },
    },
  },
  methods: {},
}
</script>
