<template>
  <div class="flex h-screen overflow-hidden">
    <!-- Sidebar -->
    <Sidebar
      :sidebar-open="sidebarOpen"
      :case-state="
        activeCase.stage !== undefined ? activeCase.stage : $route.query.status
      "
      @close-sidebar="sidebarOpen = false"
    />

    <!-- Content area -->
    <div
      class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden"
    >
      <main>
        <div class="max-w-9xl mx-auto w-full px-4 py-8 sm:px-6 lg:px-8">
          <div class="flex justify-between">
            <div class="flex w-full flex-col pb-4">
              <h1 class="text-2xl font-bold text-black">
                {{ $t('caseDetail.title') }}
              </h1>
              <p>{{ $t('caseDetail.description') }}</p>
            </div>

            <DropdownNotifications
              align="right"
              class="md:mr-4"
              wrapper-class="bg-white rounded-lg"
            />
          </div>
          <section v-if="!isLoading" class="space-y-2">
            <!-- Case Details -->
            <div class="rounded-lg bg-white px-6 shadow-sm">
              <div class="py-6">
                <div class="flex items-start">
                  <div class="grow">
                    <div class="items-start justify-between py-4 sm:flex">
                      <div
                        class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                      >
                        <p>{{ $t('caseDetail.details.caseName') }}</p>
                      </div>
                      <div
                        class="mb-2 whitespace-nowrap text-lg font-medium text-slate-800 sm:mb-0"
                      >
                        <p>{{ activeCase.name }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="rounded-lg bg-white px-6 shadow-sm">
              <div class="py-6">
                <div class="flex items-start">
                  <div class="grow">
                    <div class="items-start justify-between py-4 sm:flex">
                      <div
                        class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                      >
                        <p>{{ $t('caseDetail.details.caseDetails') }}</p>
                      </div>
                      <div
                        class="mb-2 whitespace-nowrap text-lg font-medium text-slate-800 sm:mb-0"
                      >
                        <p></p>
                      </div>
                    </div>
                    <div
                      class="items-center justify-between border-t py-4 sm:flex"
                    >
                      <div
                        class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                      >
                        <p>{{ $t('caseDetail.details.currentStage') }}</p>
                      </div>
                      <div
                        class="mb-2 whitespace-nowrap text-right text-lg font-medium text-yellow-500 sm:mb-0"
                      >
                        <p>{{ activeCase.status }}</p>
                        <p v-if="lastDeadlineStage !== null">
                          {{ lastDeadlineStage }}
                        </p>
                      </div>
                    </div>
                    <div
                      v-if="
                        lastDeadlineTime !== null && lastDeadlineStage !== null
                      "
                      class="items-start justify-between border-t py-4 sm:flex"
                    >
                      <div
                        class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                      >
                        <p>
                          {{
                            $t('caseDetail.details.stageDuedate', {
                              stage: lastDeadlineStage,
                            })
                          }}
                        </p>
                      </div>
                      <div
                        class="mb-2 whitespace-nowrap text-lg font-medium text-slate-800 sm:mb-0"
                      >
                        <p>{{ formatDateDay(lastDeadlineTime) }}</p>
                      </div>
                    </div>
                    <div
                      class="items-start justify-between border-t py-4 sm:flex"
                    >
                      <div
                        class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                      >
                        <p>{{ $t('caseDetail.details.urgency') }}</p>
                      </div>
                      <div
                        class="mb-2 whitespace-nowrap text-lg font-medium text-slate-800 sm:mb-0"
                      >
                        <select
                          class="border-none focus:ring-0"
                          :class="priorityClass(activeCase.priority)"
                          @change="changePriority"
                        >
                          <option
                            v-for="casePriority in casePriorities"
                            :key="casePriority.id"
                            :value="casePriority.id"
                            :selected="activeCase.priority === casePriority.id"
                          >
                            {{ casePriority.name }}
                          </option>
                          <option
                            v-if="activeCase.priority === null"
                            :selected="true"
                          >
                            -
                          </option>
                        </select>
                      </div>
                    </div>
                    <div
                      class="items-start justify-between border-t py-4 sm:flex"
                    >
                      <div
                        class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                      >
                        <p>{{ $t('caseDetail.details.submissionDate') }}</p>
                      </div>
                      <div
                        class="mb-2 whitespace-nowrap text-lg font-medium text-slate-800 sm:mb-0"
                      >
                        <p>{{ formatDateDay(activeCase.time) }}</p>
                      </div>
                    </div>

                    <div
                      class="items-start justify-between border-t py-4 sm:flex"
                    >
                      <div
                        class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                      >
                        <p>{{ $t('caseDetail.details.submittedBy') }}</p>
                      </div>
                      <div
                        class="mb-2 whitespace-nowrap text-lg font-medium text-slate-800 sm:mb-0"
                      >
                        <p v-if="activeCase.submitted_by">
                          {{ activeCase.submitted_by.first_name }}
                          {{ activeCase.submitted_by.last_name }}
                        </p>
                        <p v-else>-</p>
                      </div>
                    </div>
                    <div
                      v-for="party in activeCase.parties"
                      :key="party.id"
                      class="items-start justify-between border-t py-4 sm:flex"
                    >
                      <div
                        class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                      >
                        <p>{{ party.role }}</p>
                      </div>
                      <div
                        class="mb-2 whitespace-nowrap text-lg font-medium text-slate-800 sm:mb-0"
                      >
                        <p>{{ party.name }}</p>
                      </div>
                    </div>
                    <!-- <section v-for="neutral in threeNeutral" :key="neutral"> -->
                    <div
                      v-if="neutral !== ''"
                      class="items-start justify-between border-t py-4 sm:flex"
                    >
                      <div
                        class="mb-2 flex items-center justify-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                      >
                        <p>{{ $t(`general.${neutral}`) }}</p>
                      </div>
                      <div
                        class="mb-2 whitespace-nowrap text-lg font-medium sm:mb-0"
                      >
                        <button
                          class="rounded-lg bg-primary py-2 px-4 capitalize text-white"
                          @click="pick(neutral)"
                        >
                          {{ $t('button.pick', { role: `${neutral}` }) }}
                        </button>
                      </div>
                    </div>
                    <!-- </section> -->

                    <div
                      v-if="activeCase.attachment"
                      class="items-center justify-between border-t py-4 sm:flex"
                    >
                      <div
                        class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                      >
                        <p>
                          {{ $t('caseDetail.details.assignationDocument') }}
                        </p>
                      </div>
                      <div
                        class="mb-2 whitespace-nowrap text-lg font-medium text-slate-800 sm:mb-0"
                      >
                        <button
                          class="rounded-lg bg-primary py-2 px-4 text-white"
                          @click="openDocumentCase(activeCase.attachment)"
                        >
                          {{ $t('button.open') }}
                        </button>
                      </div>
                    </div>

                    <div
                      class="items-start justify-between border-t py-4 sm:flex"
                    >
                      <div
                        class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                      ></div>
                      <div
                        class="mb-2 space-x-4 whitespace-nowrap text-lg font-medium text-slate-800 sm:mb-0"
                      >
                        <button
                          v-if="activeCase.stage !== 5"
                          class="rounded-lg bg-red-500 py-2 px-4 text-white"
                          @click.stop="deleteCaseModalOpen = true"
                        >
                          {{ $t('button.remove') }}
                        </button>
                        <button
                          v-if="
                            (activeCase.stage === 2 &&
                              activeCase.advocate_time === null) ||
                            (activeCase.stage === 3 &&
                              activeCase.mediator_time === null) ||
                            (activeCase.stage === 4 &&
                              activeCase.arbitrator_time === null)
                          "
                          class="rounded-lg bg-primary py-2 px-4 text-white"
                          @click="assignCase"
                        >
                          {{ $t('button.assign') }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Case Details -->
          </section>
          <DualRingLoader v-else />
        </div>
      </main>
    </div>

    <ModalDelete
      :is-open="deleteCaseModalOpen"
      :title="$t('modal.modalDelete.title')"
      :description="$t('modal.modalDelete.description')"
      @closed="deleteCaseModalOpen = false"
      @delete="removeCase"
    />

    <ModalAssignCaseDate
      :is-open="assignCaseOpen"
      @closed="assignCaseOpen = false"
      @confirm="assignCaseDate"
    />

    <RightSidebar
      v-if="loggedinUser.role.code.toUpperCase() !== 'REDEK_ADMIN'"
      :sidebar-open="false"
      @close-sidebar="sidebarOpen = false"
    />
  </div>
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import globalMixin from '@/mixins/global.js'
import caseMixin from '@/mixins/case.js'

import Sidebar from '@/partials/Sidebar.vue'
import RightSidebar from '@/partials/RightSidebar.vue'
import DualRingLoader from '@/components/DualRingLoader.vue'
import ModalDelete from '@/components/ModalDelete.vue'
import ModalAssignCaseDate from '@/components/modal/case/ModalAssignCaseDate.vue'
import DropdownNotifications from '@/components/DropdownNotifications.vue'

export default {
  name: 'CaseDetailPage',
  components: {
    Sidebar,
    RightSidebar,
    DualRingLoader,
    ModalDelete,
    ModalAssignCaseDate,
    DropdownNotifications,
  },
  mixins: [globalMixin, caseMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const toast = useToast()
    const sidebarOpen = ref(false)
    const assignCaseOpen = ref(false)
    const activeCase = ref({
      parties: [],
    })

    return {
      t,
      n,
      store,
      toast,
      sidebarOpen,
      assignCaseOpen,
      activeCase,
    }
  },
  data: () => ({
    deleteCaseModalOpen: false,
    priority: '',
    threeNeutral: ['advocate', 'mediator', 'arbitrator'],
    neutral: '',
  }),
  mounted() {
    this.checkUserAccess('REDEK_ADMIN')
    this.getCaseDetailAdmin()
  },
  methods: {
    changePriority(event) {
      const priority = this.validPriority.includes(event.target.value)
        ? event.target.value
        : this.validPriority[0]

      this.priority = priority
      this.changeCasePriority()
    },
    assignCase() {
      this.assignCaseOpen = true
    },
    assignCaseDate(dueDate) {
      const params = { dueDate, id: this.$route.params.slug }
      this.caseDuteDateAPI(params)
    },
    pick(neutral) {
      this.store.dispatch('cases/setActiveCase', this.$route.params.slug)
      this.$router.push({
        name: `${neutral}-selection`,
        params: {
          slug: this.$route.params.slug,
        },
        query: {
          lang: this.$route.query.lang,
        },
      })
    },
  },
}
</script>
