<template>
  <ModalBlank id="danger-modal" :modal-open="isOpen" @close-modal="close">
    <div class="flex space-x-4 p-5 text-center">
      <!-- Content -->
      <div class="w-full">
        <!-- Modal header -->
        <div class="mb-2">
          <div class="text-lg font-semibold text-slate-800">
            {{ $t('document.modalInfo.title') }}
          </div>
        </div>
        <!-- Modal content -->
        <div class="mb-10 text-sm">
          <div class="space-y-2">
            <div class="flex justify-between">
              <div class="font-bold">
                {{ $t('document.modalInfo.documentName') }}
              </div>
              <div>{{ document.name }}</div>
            </div>
            <div v-if="document.owner" class="flex justify-between">
              <div class="font-bold">{{ $t('document.modalInfo.owner') }}</div>
              <div>
                {{ document.owner.first_name }} {{ document.owner.last_name }}
              </div>
            </div>
            <div class="flex justify-between">
              <div class="font-bold">{{ $t('document.modalInfo.type') }}</div>
              <div>
                {{ document.type }}
              </div>
            </div>
            <div class="flex justify-between">
              <div class="font-bold">
                {{ $t('document.modalInfo.shareWith') }}
              </div>
              <div v-if="document.shares && document.shares.length > 0">
                <span v-for="(share, index) in document.shares" :key="share.id">
                  {{ share.user.first_name }} {{ share.user.last_name }}
                  <span v-if="index !== document.shares.length - 1">,</span>
                </span>
              </div>
              <div v-else>
                <span>-</span>
              </div>
            </div>
          </div>
        </div>
        <!-- Modal footer -->
        <div class="flex flex-wrap justify-end space-x-2">
          <button
            class="btn-lg cursor-pointer bg-red-400 text-white hover:bg-red-500"
            @click.stop="close()"
          >
            {{ $t('button.cancel') }}
          </button>
        </div>
      </div>
    </div>
  </ModalBlank>
</template>
<script>
import ModalBlank from '@/components/ModalBlank.vue'

export default {
  name: 'ModalDeleteComponent',
  components: {
    ModalBlank,
  },
  props: {
    document: {
      type: Object,
      default: () => {},
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['closed', 'delete'],
  methods: {
    close() {
      this.$emit('closed')
    },
  },
}
</script>
