<template>
  <div class="flex h-screen overflow-hidden">
    <div
      class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden"
    >
      <!-- Site header -->
      <Header
        :sidebar-open="sidebarOpen"
        @toggle-sidebar="sidebarOpen = !sidebarOpen"
      />

      <main>
        <div class="max-w-9xl mx-auto w-full px-4 py-8 sm:px-6 lg:px-8">
          <div class="mb-8 sm:flex sm:items-center sm:justify-between">
            <h2 class="mb-2 text-3xl font-bold text-slate-800">
              {{ $t('case.title') }}
            </h2>

            <div
              v-if="roleCode === 'USER'"
              class="grid grid-flow-col justify-start gap-2 sm:auto-cols-max sm:justify-end"
            >
              <ButtonAdd
                :title="$t('case.newComplaint')"
                custom-class="rounded-3xl px-6"
                @clicked="
                  $router.push({
                    name: 'new-case',
                  })
                "
              />
            </div>
          </div>

          <div class="mb-8 flex self-center">
            <div class="flex w-full justify-between">
              <div class="flex space-x-8">
                <ul
                  class="no-scrollbar relative -mx-4 flex flex-nowrap overflow-x-scroll text-sm font-medium sm:-mx-6 lg:-mx-8"
                >
                  <li
                    v-for="status in statusPage"
                    :key="status.id"
                    class="mr-8 first:pl-4 last:mr-0 last:pr-4 sm:first:pl-6 sm:last:pr-6 lg:first:pl-8 lg:last:pr-8"
                  >
                    <a
                      class="block cursor-pointer whitespace-nowrap pb-3"
                      :class="
                        activePage === status.status
                          ? ' border-b-3 border-slate-900 font-bold text-slate-800'
                          : 'text-slate-500 hover:text-slate-600'
                      "
                      @click="changeActivePage(status.status)"
                    >
                      {{ $t(status.name) }}
                    </a>
                  </li>
                </ul>

                <div class="flex cursor-pointer items-start text-sm text-black">
                  <div class="flex">
                    <p>{{ $t('formLabel.sortBy') }}</p>
                  </div>
                  <div class="flex">
                    <select
                      class="border-none bg-inherit py-0 font-bold focus:ring-0"
                      @change="changeOrder"
                    >
                      <option
                        v-for="order in orderOptions"
                        :key="order.value"
                        :value="order.value"
                        :selected="filterDataCases.order === order.value"
                      >
                        {{ $t(`${order.label}`) }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="flex">
                <div class="flex space-x-2">
                  <div
                    class="cursor-pointer"
                    :class="[caseListType === 'grid' ? 'text-black' : '']"
                    @click="caseListType = 'grid'"
                  >
                    <GridIcon />
                  </div>
                  <div
                    class="cursor-pointer"
                    :class="[caseListType !== 'grid' ? 'text-black' : '']"
                    @click="caseListType = 'list'"
                  >
                    <ListIcon />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Cards -->
          <div
            v-if="!isLoading && cases.length > 0 && caseListType === 'grid'"
            class="grid grid-cols-12 gap-6"
          >
            <CaseCard :cases="cases" @click-case="openLink" />
          </div>
          <div
            v-else-if="
              !isLoading && cases.length > 0 && caseListType !== 'grid'
            "
          >
            <CaseList :cases="cases" @click-case="openLink" />
          </div>
          <DualRingLoader v-else-if="isLoading" />
          <NoData v-else />
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import globalMixin from '@/mixins/global.js'
import caseMixin from '@/mixins/case.js'

import Header from '@/partials/Header.vue'
import ButtonAdd from '@/components/ButtonAdd.vue'
import CaseCard from '@/components/CaseCard.vue'
import CaseList from '@/components/CaseList.vue'
import DualRingLoader from '@/components/DualRingLoader.vue'
import NoData from '@/components/NoData.vue'

import GridIcon from '@/assets/svg/view-grid.svg'
import ListIcon from '@/assets/svg/view-list.svg'

export default {
  name: 'CasesPage',
  components: {
    Header,
    CaseCard,
    CaseList,
    ButtonAdd,
    DualRingLoader,
    NoData,
    GridIcon,
    ListIcon,
  },
  mixins: [globalMixin, caseMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const toast = useToast()
    const sidebarOpen = ref(false)

    return {
      t,
      n,
      store,
      toast,
      sidebarOpen,
    }
  },
  data() {
    return {
      activePage: 'all',
      statusPage: [
        { status: 'all', name: 'case.allCases' },
        { status: 'onprepare', name: 'case.onPrepare' },
        { status: 'ongoing', name: 'case.onGoing' },
        { status: 'solved', name: 'case.solved' },
      ],
      cases: [],
      allCases: [],
      filterDataCases: {
        page: 1,
        take: 50,
        search: '',
        order: 'DESC',
      },
      metaDataCases: {
        page: 1,
        take: 10,
        itemCount: 10,
        pageCount: 1,
        hasPreviousPage: false,
        hasNextPage: true,
      },
    }
  },
  mounted() {
    this.checkUserAccess('USER')
    this.getAllCases()
  },
  methods: {
    openLink(item) {
      this.$router.push({
        name: 'dashboard',
        params: { slug: item.id },
        query: {
          status: item.stage,
          lang: this.$route.query.lang,
        },
      })
    },
    changeActivePage(status) {
      this.activePage = status
      this.cases = this.allCases.filter(
        function (item) {
          return item.status === this.status || this.status === 'all'
        },
        { status }
      )
    },
    async getAllCases() {
      this.isLoading = true
      await this.store
        .dispatch('cases/getAllCases', {
          order: this.filterDataCases.order,
          page: this.filterDataCases.page,
          take: this.filterDataCases.take,
          q: this.filterDataCases.search,
        })
        .then((response) => {
          this.isLoading = false
          const responseData = response.data.data
          if (responseData.data) {
            let mappedDataCase = []
            responseData.data.forEach((item) => {
              item.status = this.stripAndLower(item.status)
              mappedDataCase.push(item)
            })
            this.cases = mappedDataCase
            this.allCases = mappedDataCase
          }
          if (responseData.meta) {
            this.metaDataCases = responseData.meta
          }
        })
        .catch((error) => {
          this.isLoading = false
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    changeOrder(event) {
      this.filterDataCases.order = ['DESC', 'ASC'].includes(event.target.value)
        ? event.target.value
        : 'DESC'
      this.getAllCases().then(() => this.changeActivePage(this.activePage))
    },
  },
}
</script>
