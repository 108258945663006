<template>
  <div class="flex w-full bg-white">
    <div class="flex w-2/6 justify-start">
      <div class="hidden px-4 md:block">
        <ImageLoader
          wrapper-class="mr-3 shrink-0 rounded-full"
          :src="user.avatar"
          width="60"
          height="60"
          :alternate="user.first_name"
        />
      </div>
      <div class="pt-1">
        <span>{{ user.first_name }} {{ user.last_name }}</span>
      </div>
    </div>
    <div class="flex w-2/6 flex-col justify-center pt-1">
      <p
        class="w-1/2 rounded-lg bg-purple-100 px-2 py-2 text-center text-base font-semibold text-purple-600"
      >
        {{ user.role.name }}
      </p>
    </div>
    <div class="flex w-2/6 px-2">
      <div class="flex w-full justify-between">
        <div class="pt-1">
          <span>{{ user.email }}</span>
        </div>
        <div>
          <EditMenu align="right" class="relative ml-2 inline-flex">
            <li>
              <span
                class="flex cursor-pointer py-1 px-3 text-sm font-medium text-slate-600 hover:text-slate-800"
                @click.stop="editUser()"
              >
                {{ $t('edit') }}
              </span>
            </li>
            <li>
              <span
                class="flex cursor-pointer py-1 px-3 text-sm font-medium text-rose-500 hover:text-rose-600"
                @click.stop="removeUser()"
              >
                {{ $t('remove') }}
              </span>
            </li>
          </EditMenu>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EditMenu from './DropdownEditMenu.vue'
import ImageLoader from '@/components/image/ImageLoader.vue'

export default {
  name: 'UserItemComponent',
  components: {
    EditMenu,
    ImageLoader,
  },
  props: {
    user: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  emits: ['remove-user', 'edit-user'],
  methods: {
    editUser() {
      this.$emit('edit-user', {
        user: this.user,
      })
    },
    removeUser() {
      this.$emit('remove-user', {
        user: this.user,
      })
    },
  },
}
</script>
