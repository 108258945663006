<template>
  <div class="flex h-screen overflow-hidden">
    <div
      class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden"
    >
      <!-- Site header -->
      <Header
        :sidebar-open="sidebarOpen"
        @toggle-sidebar="sidebarOpen = !sidebarOpen"
      />

      <main>
        <div class="w-full">
          <div class="flex h-full flex-col justify-center bg-gray-100 md:mt-10">
            <div
              class="w-full self-center px-2 py-5 text-left md:w-8/12 md:py-2 md:px-0"
            >
              <BreadcrumNewCase :active="2" :is-show="true" />
            </div>
            <div
              class="w-full self-center rounded-lg bg-white px-8 py-8 shadow-md md:w-8/12"
            >
              <h1 class="mb-2 text-3xl font-bold text-slate-800">
                {{ $t('advocateRequirement.title') }}
              </h1>
              <p class="text-sm">
                {{ $t('advocateRequirement.description') }}
              </p>
              <div
                class="flex w-full justify-between overflow-scroll border-gray-500 pt-6 text-center font-bold"
              >
                <div class="flex w-full px-2 md:w-3/6">
                  <div
                    class="h-80 w-full cursor-pointer rounded-xl border p-6"
                    :class="typeSelected === 1 ? 'border-primary-500' : ''"
                    @click="typeSelected = 1"
                  >
                    <ProfileIcon class="mx-auto mb-8 w-52 text-primary" />
                    {{ $t('advocateRequirement.letInResolve') }}
                  </div>
                </div>
                <div class="flex w-full px-2 md:w-3/6">
                  <div
                    class="h-80 w-full cursor-pointer rounded-xl border p-6"
                    :class="typeSelected === 2 ? 'border-primary-500' : ''"
                    @click="typeSelected = 2"
                  >
                    <PaperIcon class="mx-auto mb-8 w-52 text-primary" />
                    {{ $t('advocateRequirement.pickOne') }}
                  </div>
                </div>
              </div>
              <div class="mt-5 flex flex-row justify-end">
                <div class="w-3/12">
                  <router-link
                    class="btn text-md mt-6 h-12 w-full rounded-md text-slate-500 shadow-none hover:text-slate-700"
                    to="/new-case"
                  >
                    <svg
                      class="mr-2 h-7 w-7 fill-current text-slate-500"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z"
                      />
                    </svg>
                    {{ $t('action.back') }}
                  </router-link>
                </div>
                <button
                  class="btn text-md hover:bg-primary-600 mt-6 h-12 w-3/12 rounded-md bg-primary text-white"
                  @click="nextPage"
                >
                  {{ $t('action.next') }}
                </button>
              </div>
            </div>
            <DualRingLoader v-if="isLoading" />
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import Header from '@/partials/Header.vue'
import globalMixin from '@/mixins/global.js'
import caseMixin from '@/mixins/case.js'

import BreadcrumNewCase from '@/components/case/BreadcrumNewCase.vue'
import DualRingLoader from '@/components/DualRingLoader.vue'

import PaperIcon from '@/assets/svg/paper.svg'
import ProfileIcon from '@/assets/svg/profile.svg'

export default {
  name: 'AdvocateRequirementPage',
  components: {
    Header,
    BreadcrumNewCase,
    DualRingLoader,

    PaperIcon,
    ProfileIcon,
  },
  mixins: [globalMixin, caseMixin],
  setup() {
    const sidebarOpen = ref(false)
    const store = useStore()
    const toast = useToast()

    return {
      store,
      toast,
      sidebarOpen,
    }
  },
  data() {
    return {
      typeSelected: 2,
    }
  },
  mounted() {
    this.newCaseData = this.store.getters['cases/newCase']
    if (this.checkEmptyObject(this.newCaseData)) {
      return this.$router.push({ name: 'index' })
    }
  },
  methods: {
    nextPage() {
      if (this.typeSelected === 1) {
        if (!this.isLoading) {
          this.createCase()
        }
      } else {
        return this.$router.push({ name: 'advocate-selection' })
      }
    },
    async createCase() {
      this.newCaseData.advocate_initiate = true
      this.newCaseData.advocate_id = ''
      this.isLoading = true
      await this.store
        .dispatch('cases/createCase', this.newCaseData)
        .then((response) => {
          this.isLoading = false
          const responseData = response.data

          this.store.dispatch('cases/resetActiveCase')
          this.store.dispatch('cases/resetNewCase')
          this.store.dispatch('cases/resetActiveNewCaseBreadcrum')

          this.toast.success(responseData.message)
          return this.$router.push({ name: 'case-submitted' })
        })
        .catch((error) => {
          this.isLoading = false
          this.toast.error(this.extractErrorResponse(error))
        })
    },
  },
}
</script>
