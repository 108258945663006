<template>
  <div v-if="me" class="flex space-x-4">
    <div
      v-if="me.role.code === 'BANK_LAWYER'"
      class="cursor relative flex cursor-pointer flex-wrap"
      @click.stop="makeOffer"
    >
      <div class="flex w-full justify-end">
        <div
          class="rounded-full bg-primary py-4 px-8 text-white shadow-lg hover:shadow-xl"
        >
          {{ $t('chat.discussion.makeOffer') }}
        </div>
      </div>
    </div>
    <div class="relative flex cursor-pointer flex-wrap" @click.stop="newChat">
      <div class="flex w-full justify-end">
        <div
          class="text-light rounded-full bg-primary p-4 shadow-lg hover:shadow-xl"
        >
          <div class="flex justify-between text-sm">
            <NewChatIcon class="inline h-5 w-5" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NewChatIcon from '@/assets/svg/new_chat.svg'

export default {
  name: 'FloatingNewChat',
  components: {
    NewChatIcon,
  },
  emits: ['make-offer', 'new-chat'],
  data: () => ({
    me: null,
  }),
  created() {
    this.me = this.$store.getters['auth/activeUser']
  },
  methods: {
    makeOffer() {
      this.$emit('make-offer')
    },
    newChat() {
      this.$emit('new-chat')
    },
  },
}
</script>
