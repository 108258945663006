<template>
  <div class="relative inline-flex">
    <button
      ref="trigger"
      class="group inline-flex items-center justify-center"
      :class="mode === 'aesthetic' && 'rounded-full bg-slate-100 px-4 py-2'"
      aria-haspopup="true"
      :aria-expanded="dropdownOpen"
      @click.prevent="dropdownOpen = !dropdownOpen"
    >
      <ImageLoader
        wrapper-class="h-8 w-8 rounded-full"
        :src="activeUser.avatar"
        width="32"
        height="32"
        :alternate="activeUser.first_name"
      />
      <div class="flex items-center truncate">
        <span
          class="ml-2 hidden truncate text-sm font-medium group-hover:text-slate-800 md:inline"
        >
          {{ activeUser ? activeUser.first_name : '' }}
          {{ activeUser ? activeUser.last_name : '' }}
        </span>
        <svg
          v-if="mode === 'aesthetic'"
          class="ml-2 h-3 w-3 shrink-0 text-slate-800"
          viewBox="0 0 8 12"
          fill="none"
        >
          <path
            d="M1.66669 1.33341L6.33335 6.00008L1.66669 10.6667"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>

        <svg
          v-else
          class="ml-2 h-3 w-3 shrink-0 fill-current text-slate-400"
          viewBox="0 0 12 12"
        >
          <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
        </svg>
      </div>
    </button>
    <transition
      enter-active-class="transition ease-out duration-200 transform"
      enter-from-class="opacity-0 -translate-y-2"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="transition ease-out duration-200"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-show="dropdownOpen"
        class="min-w-44 absolute top-full z-10 mt-1 origin-top-right overflow-hidden rounded border border-slate-200 bg-white py-1.5 shadow-lg"
        :class="align === 'right' ? 'right-0' : 'left-0'"
      >
        <div class="mb-1 border-b border-slate-200 px-3 pt-0.5 pb-2">
          <div class="font-medium text-slate-800">
            {{ activeUser ? activeUser.first_name : '' }}
            {{ activeUser ? activeUser.last_name : '' }}
          </div>
          <div class="text-xs italic text-slate-500">
            {{ activeUserRole ?? '' }}
          </div>
        </div>
        <ul
          ref="dropdown"
          @focusin="dropdownOpen = true"
          @focusout="dropdownOpen = false"
        >
          <li>
            <router-link
              class="hover:text-primary-600 flex items-center py-1 px-3 text-sm font-medium text-primary"
              to="/profile/account"
              @click="dropdownOpen = false"
            >
              {{ $t('profile.title') }}
            </router-link>
          </li>
          <li>
            <span
              class="hover:text-primary-600 flex cursor-pointer items-center py-1 px-3 text-sm font-medium text-primary"
              @click="logout"
            >
              {{ $t('profile.signOut') }}
            </span>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

import ImageLoader from '@/components/image/ImageLoader.vue'

export default {
  name: 'DropdownProfile',
  components: { ImageLoader },
  props: ['align', 'mode'],
  setup() {
    const dropdownOpen = ref(false)
    const store = useStore()
    const trigger = ref(null)
    const dropdown = ref(null)
    const router = useRouter()

    // close on click outside
    const clickHandler = ({ target }) => {
      if (
        !dropdownOpen.value ||
        dropdown.value.contains(target) ||
        trigger.value.contains(target)
      )
        return
      dropdownOpen.value = false
    }

    // close if the esc key is pressed
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen.value || keyCode !== 27) return
      dropdownOpen.value = false
    }

    const activeUserRole = ref(null)
    const activeUser = ref({})
    onMounted(() => {
      document.addEventListener('click', clickHandler)
      document.addEventListener('keydown', keyHandler)

      activeUser.value = store.getters['auth/activeUser']
      if (!activeUser.value) {
        router.push({ name: 'login' })
      } else {
        activeUserRole.value = activeUser.value.role.position
      }
    })

    onUnmounted(() => {
      document.removeEventListener('click', clickHandler)
      document.removeEventListener('keydown', keyHandler)
    })

    return {
      activeUserRole,
      activeUser,
      dropdownOpen,
      trigger,
      dropdown,
    }
  },
  methods: {
    logout() {
      this.dropdownOpen = false
      this.$store.dispatch('auth/logout')
      this.$router.push({ name: 'login' })
    },
  },
}
</script>
