<template>
  <!-- Modal backdrop -->
  <transition
    enter-active-class="transition ease-out duration-200"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class="transition ease-out duration-100"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
  >
    <div
      v-show="modalOpen"
      class="fixed inset-0 z-50 bg-slate-900 bg-opacity-30 transition-opacity"
      aria-hidden="true"
    ></div>
  </transition>
  <!-- Modal dialog -->
  <transition
    enter-active-class="transition ease-in-out duration-200"
    enter-from-class="opacity-0 translate-y-4"
    enter-to-class="opacity-100 translate-y-0"
    leave-active-class="transition ease-in-out duration-200"
    leave-from-class="opacity-100 translate-y-0"
    leave-to-class="opacity-0 translate-y-4"
  >
    <div
      v-show="modalOpen"
      :id="id"
      class="fixed inset-0 z-50 my-4 flex transform items-center justify-center overflow-hidden px-4 sm:px-6"
      role="dialog"
      aria-modal="true"
    >
      <div
        ref="modalContent"
        class="max-h-full w-full overflow-auto rounded-lg bg-white shadow-lg"
        :class="[wrapperClass !== '' ? wrapperClass : 'max-w-lg']"
      >
        <!-- Modal header -->
        <div
          v-if="showHeader"
          class="px-5 py-5"
          :class="{ 'border-b border-slate-200': showBorderHeader }"
        >
          <div class="flex items-center justify-between">
            <div class="text-lg font-semibold text-slate-800">{{ title }}</div>
            <button
              class="text-slate-400 hover:text-slate-500"
              @click.stop="$emit('close-modal')"
            >
              <div class="sr-only">Close</div>
              <svg class="h-4 w-4 fill-current">
                <path
                  d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z"
                />
              </svg>
            </button>
          </div>
          <div class="pt-2 text-sm text-slate-700">{{ subtitle }}</div>
        </div>
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'

export default {
  name: 'ModalBasic',
  props: {
    id: { type: String, default: '' },
    wrapperClass: { type: String, default: '' },
    modalOpen: { type: Boolean, default: false },
    backdrop: { type: Boolean, default: false },
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    showHeader: { type: Boolean, default: true },
    showBorderHeader: { type: Boolean, default: true },
  },
  emits: ['close-modal'],
  setup(props, { emit }) {
    const modalContent = ref(null)

    // close on click outside
    const clickHandler = ({ target }) => {
      if (props.backdrop) return
      if (!props.modalOpen || modalContent.value.contains(target)) return
      emit('close-modal')
    }

    // close if the esc key is pressed
    const keyHandler = ({ keyCode }) => {
      if (!props.modalOpen || keyCode !== 27) return
      emit('close-modal')
    }

    onMounted(() => {
      document.addEventListener('click', clickHandler)
      document.addEventListener('keydown', keyHandler)
    })

    onUnmounted(() => {
      document.removeEventListener('click', clickHandler)
      document.removeEventListener('keydown', keyHandler)
    })

    return {
      modalContent,
    }
  },
}
</script>
