<template>
  <div class="my-6 py-6">
    <div class="flex flex-row py-4">
      <img
        class="mr-3 shrink-0 rounded-full"
        :src="
          chat.created_by.avatar !== null
            ? chat.created_by.avatar
            : defaultImage
        "
        width="60"
        height="60"
        :alt="chat.created_by.first_name"
      />
      <div class="w-full pl-4">
        <div
          class="flex w-full items-center text-lg font-semibold text-slate-800"
        >
          <p class="line-clamp-1">
            {{ chat.created_by.first_name }}
            {{ chat.created_by.last_name }}
          </p>
          <span v-if="me && chat.created_by.id === me.id" class="pl-2">
            {{ $t('you') }}
          </span>
          <div
            v-if="chat.created_by.role"
            class="ml-2 flex w-max rounded-lg py-1 px-4"
            :class="
              chat.created_by.role.name === 'Customer'
                ? 'bg-red-100'
                : 'bg-blue-100'
            "
          >
            <p
              class="text-xs font-bold"
              :class="
                chat.created_by.role.name === 'Customer'
                  ? 'text-red-500'
                  : 'text-blue-700'
              "
            >
              {{ chat.created_by.role.name }}
            </p>
          </div>
        </div>
        <p class="text-sm text-slate-500">
          {{
            formatDateDay(chat.created, {
              month: 'long',
              day: 'numeric',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
            })
          }}
        </p>
      </div>
      <div class="flex w-2/6 justify-end">
        <p class="inline pr-2">Read by:</p>
        <Avatars :parties="chat.chat_read" />
      </div>
    </div>
    <div class="flex items-start">
      <div class="grow">
        <div class="items-start justify-between border-t py-4 sm:flex">
          <div class="mb-2 items-center space-y-4 text-left text-base sm:mb-0">
            <h1 class="text-xl text-primary">{{ chat.title }}</h1>
            <p>
              {{ chat.content }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="chat.chat_attachment.length > 0"
      class="my-6 flex flex-col items-start space-y-4 text-slate-500"
    >
      <p class="text-sm">
        {{
          $t('general.totalAttachments', {
            total: String(chat.chat_attachment.length),
          })
        }}
      </p>
      <FileItem
        v-for="attachment in chat.chat_attachment"
        :key="attachment.id"
        :file="attachment"
      />
    </div>
    <div
      v-if="chat.chat_offer.length > 0"
      class="flex w-full justify-start space-x-4"
    >
      <div class="flex items-center">
        <span class="mr-2 text-green-500">
          {{ $t('chat.discussion.accepted') }}
        </span>
        <Avatars :extract-user="true" :parties="accepted" />
      </div>
      <div class="flex items-center">
        <span class="mr-2 text-red-500">
          {{ $t('chat.discussion.rejected') }}
        </span>
        <Avatars :extract-user="true" :parties="rejected" />
      </div>
    </div>
    <div
      v-if="hasOfferToMe && new Date(chat.deadline_at) > new Date()"
      class="flex w-full items-center justify-end space-x-4"
    >
      <p class="text-xs text-red-500">
        {{
          formatDateDay(chat.deadline_at, {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          })
        }}
      </p>
      <button
        class="rounded-md bg-green-500 py-2 px-4 text-white"
        @click.stop="accept()"
      >
        {{ $t('button.accept') }}
      </button>
      <button
        class="rounded-md bg-red-500 py-2 px-4 text-white"
        @click.stop="reject()"
      >
        {{ $t('button.reject') }}
      </button>
    </div>
  </div>
</template>
<script>
import globalMixin from '@/mixins/global.js'
import Avatars from '@/components/Avatars.vue'
import FileItem from '@/components/FileItem.vue'

export default {
  name: 'ChatDisscussionItemComponent',
  components: {
    Avatars,
    FileItem,
  },
  mixins: [globalMixin],
  inject: ['defaultImage'],
  props: {
    chat: {
      type: Object,
      default: () => {
        return {}
      },
    },
    me: {
      type: Object,
      default: () => {
        return {
          id: '',
        }
      },
    },
  },
  emits: ['accept', 'reject'],
  computed: {
    hasOfferToMe() {
      if (this.chat.chat_offer.length > 0) {
        const hasOfferToMe = this.chat.chat_offer.some((offer) => {
          return offer.user.id === this.me.id && offer.process_at === null
        })
        return hasOfferToMe
      } else {
        return false
      }
    },
    accepted() {
      if (this.chat.chat_offer.length > 0) {
        const hasOfferToMe = this.chat.chat_offer.filter((offer) => {
          return offer.is_accept === true && offer.process_at !== null
        })
        return hasOfferToMe
      } else {
        return []
      }
    },
    rejected() {
      if (this.chat.chat_offer.length > 0) {
        const hasOfferToMe = this.chat.chat_offer.filter((offer) => {
          return offer.is_accept === false && offer.process_at !== null
        })
        return hasOfferToMe
      } else {
        return []
      }
    },
  },
  methods: {
    reject() {
      this.$emit('reject', this.chat)
    },
    accept() {
      this.$emit('accept', this.chat)
    },
  },
}
</script>
