<template>
  <div class="flex h-screen overflow-hidden">
    <!-- Sidebar -->
    <Sidebar
      :sidebar-open="sidebarOpen"
      :case-state="$route.query.status"
      @close-sidebar="sidebarOpen = false"
    />

    <!-- Content area -->
    <div
      class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden"
    >
      <main>
        <div class="max-w-9xl mx-auto w-full px-4 py-8 sm:px-6 lg:px-8">
          <div class="flex w-full flex-col pb-4">
            <h1 class="text-2xl font-bold text-black">
              {{ $t('timeline.title') }}
            </h1>
            <p>{{ $t('timeline.description') }}</p>
          </div>

          <div class="mb-8 flex w-full items-center justify-between">
            <SearchForm
              :placeholder="$t('timeline.searchPlaceholder')"
              @search="searchTimeline"
            />
            <div
              class="grid grid-flow-col justify-start gap-2 sm:auto-cols-max sm:justify-end"
            >
              <div
                class="my-2 flex w-full cursor-pointer items-center justify-center rounded-lg bg-primary px-8 py-3 text-sm text-white"
                @click.stop="newActivityModalOpen = true"
              >
                <div class="flex w-full">
                  <div class="flex">{{ $t('admin.timeline.buttonAdd') }}</div>
                </div>
              </div>
            </div>
          </div>

          <!-- Timeline -->
          <div class="relative mb-8">
            <ul
              class="no-scrollbar relative -mx-4 flex flex-nowrap overflow-x-scroll text-sm font-medium sm:-mx-6 lg:-mx-8"
            >
              <li
                v-for="activity in typeActivity"
                :key="activity.type"
                class="mr-8 first:pl-4 last:mr-0 last:pr-4 sm:first:pl-6 sm:last:pr-6 lg:first:pl-8 lg:last:pr-8"
              >
                <a
                  class="block cursor-pointer whitespace-nowrap pb-3"
                  :class="
                    activePage === activity.type
                      ? 'border-b-3 border-slate-900 font-bold text-slate-800'
                      : 'text-slate-500 hover:text-slate-600'
                  "
                  @click="changeActivePage(activity.type)"
                >
                  {{ $t(activity.name) }}
                </a>
              </li>
            </ul>
          </div>
          <template v-if="activeTimelines.length > 0 && !isLoading">
            <ActivityItem
              :activities="activeTimelines"
              wrapper-class="bg-white"
            />
          </template>
          <div v-else-if="!isLoading">
            <NoData />
          </div>
          <DualRingLoader v-else />
        </div>
      </main>
    </div>

    <ModalCreateActivity
      :is-open="newActivityModalOpen"
      :active-activity="activeActivity"
      @closed="newActivityModalOpen = false"
      @submit-data="saveTimeline"
    />

    <RightSidebar
      :sidebar-open="sidebarOpen"
      @close-sidebar="sidebarOpen = false"
    />
  </div>
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import globalMixin from '@/mixins/global.js'

import Sidebar from '@/partials/Sidebar.vue'
import RightSidebar from '@/partials/RightSidebar.vue'
import ActivityItem from '@/components/ActivityItem.vue'
import SearchForm from '@/components/SearchForm.vue'
import ModalCreateActivity from '@/components/ModalCreateActivity.vue'
import NoData from '@/components/NoData.vue'
import DualRingLoader from '@/components/DualRingLoader.vue'

export default {
  name: 'TimelinePage',
  components: {
    Sidebar,
    RightSidebar,
    ActivityItem,
    SearchForm,
    ModalCreateActivity,
    NoData,
    DualRingLoader,
  },
  mixins: [globalMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const toast = useToast()
    const sidebarOpen = ref(false)
    const newActivityModalOpen = ref(false)
    const deleteActivityModalOpen = ref(false)
    const activeActivity = ref({})

    return {
      t,
      n,
      store,
      toast,
      sidebarOpen,
      activeActivity,
      newActivityModalOpen,
      deleteActivityModalOpen,
    }
  },
  data: () => ({
    activePage: 'all',
    typeActivity: [
      { type: 'all', name: 'activity.all' },
      { type: 'chat', name: 'activity.chat' },
      { type: 'stage', name: 'activity.stage' },
    ],
    activeUser: {
      user_id: null,
    },
    timelineData: {},
    filterDataTimeline: {
      page: 1,
      take: 50,
      search: '',
      order: 'DESC',
    },
    metaDataTimeline: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    timelines: [],
    activeTimelines: [],
  }),
  mounted() {
    this.activeUser = this.$store.getters['auth/activeUser']
    this.getAllTimeline()
  },
  methods: {
    changeActivePage(type) {
      this.activePage = type
      this.activeTimelines = this.timelines.filter(
        function (item) {
          return item.type === this.type || this.type === 'all'
        },
        { type }
      )
    },
    saveTimeline(event) {
      const timelineData = {}
      timelineData.type = event.type_id
      timelineData.title = event.title
      timelineData.subtitle = event.subtitle
      timelineData.content = event.content
      timelineData.extra = event.extra
      timelineData.case_id = this.$route.params.slug
      timelineData.user_id = this.activeUser.user_id
      this.timelineData = timelineData
      this.createTimeline()
    },
    async createTimeline() {
      await this.store
        .dispatch('timeline/createTimeline', this.timelineData)
        .then((response) => {
          const responseData = response.data
          this.toast.success(responseData.message)
          this.newActivityModalOpen = false
          this.getAllTimeline()
        })
        .catch((error) => {
          this.newActivityModalOpen = true
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async getAllTimeline() {
      this.isLoading = true
      await this.store
        .dispatch('timeline/getAllTimeline', {
          order: this.filterDataTimeline.order,
          page: this.filterDataTimeline.page,
          take: this.filterDataTimeline.take,
          q: this.filterDataTimeline.search,
          case_id: this.$route.params.slug,
        })
        .then((response) => {
          this.isLoading = false
          const responseData = response.data.data
          if (responseData.data) {
            this.timelines = responseData.data
            this.changeActivePage('all')
          }
          if (responseData.meta) {
            this.metaDataTimeline = responseData.meta
          }
        })
        .catch((error) => {
          this.isLoading = false
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    searchTimeline(event) {
      this.filterDataTimeline.search = event
      this.getAllTimeline()
    },
  },
}
</script>
