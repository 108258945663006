import axios, { routePathAPI } from '../../plugins/axios.js'
import router from '../../router'

// initial state
const state = () => ({
  activeCase: '',
  newCase: {},
  activeNewCaseBreadcrum: 1,
  threeNeutralAdminId: '',
})

// getters
const getters = {
  activeCase(state) {
    // return state.activeCase
    return localStorage.getItem('inresolve.activeCase')
  },
  newCase(state) {
    return state.newCase || {}
  },
  activeNewCaseBreadcrum(state) {
    return state.activeNewCaseBreadcrum
  },
  threeNeutralAdminId(state) {
    return state.threeNeutralAdminId
  },
}

// actions
const actions = {
  getCaseDetail({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axios
        .get(`${routePathAPI()}/case/${payload.id}`, {
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getAllCases({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {}
      if (payload.page) params.page = payload.page
      if (payload.take) params.take = payload.take
      if (payload.order) params.order = payload.order
      if (payload.q) params.q = payload.q
      if (payload.status) params.status = payload.status

      axios
        .get(`${routePathAPI()}/case`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getAllTypes({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {}
      if (payload.page) params.page = payload.page
      if (payload.take) params.take = payload.take
      if (payload.order) params.order = payload.order
      if (payload.q) params.q = payload.q

      axios
        .get(`${routePathAPI()}/case/type`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  removeCase({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axios
        .delete(`${routePathAPI()}/case/delete/${payload.id}`, {
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getAllAreas({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {}
      if (payload.page) params.page = payload.page
      if (payload.take) params.take = payload.take
      if (payload.order) params.order = payload.order
      if (payload.q) params.q = payload.q

      axios
        .get(`${routePathAPI()}/case/area`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getCaseOverview({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axios
        .get(`${routePathAPI()}/case/overview`, {
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getCaseAreaOverview({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {
        semester: payload.semester,
        year: payload.year,
      }
      axios
        .get(`${routePathAPI()}/case/area/overview`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  generatePDFCase({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {
        semester: payload.semester,
        year: payload.year,
      }
      axios
        .get(`${routePathAPI()}/case/pdf/generate`, {
          params: params,
          responseType: 'blob',
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              dispotition: response.headers['content-disposition'],
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  sendPDFCase({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {
        title: payload.title,
        to: payload.to,
        cc: payload.cc || '',
      }
      axios
        .post(
          `${routePathAPI()}/case/pdf/send?semester=${payload.semester}&year=${
            payload.year
          }`,
          params,
          {
            headers: {
              'x-language-code': currentLanguageCode,
            },
          }
        )
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              dispotition: response.headers['content-disposition'],
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getCaseParties({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axios
        .get(`${routePathAPI()}/case/${payload.case_id}/parties`, {
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getAllCasesAdmin({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {}
      if (payload.page) params.page = payload.page
      if (payload.take) params.take = payload.take
      if (payload.order) params.order = payload.order
      if (payload.q) params.q = payload.q
      if (payload.status) params.status = payload.status

      axios
        .get(`${routePathAPI()}/case/admin`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createCase({ state, commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }
      let formData = new FormData()

      formData.append('name', payload.name)
      formData.append('case_type_id', payload.case_type_id)
      formData.append('case_area_id', payload.case_area_id)
      formData.append('description', payload.description)
      formData.append('notif_via', payload.notif_via)
      formData.append('bank_id', payload.bank_id)
      formData.append('amount', payload.amount)
      formData.append('time', payload.time)
      formData.append('currency', payload.currency)
      formData.append(
        'advocate_initiate',
        payload.advocate_initiate ? payload.advocate_initiate : false
      )
      formData.append('advocate_id', payload.advocate_id)
      formData.append('attachment', payload.attachment)

      axios
        .post(`${routePathAPI()}/case/create`, formData, config)
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  casePriority({ state, commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {
        priority: payload.priority,
      }

      axios
        .put(`${routePathAPI()}/case/priority/${payload.id}`, params, {
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  caseAssign({ state, commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {}
      if (payload.advocate_id) params.advocate_id = payload.advocate_id
      if (payload.arbitrator_id) params.arbitrator_id = payload.arbitrator_id
      if (payload.mediator_id) params.mediator_id = payload.mediator_id

      axios
        .put(`${routePathAPI()}/case/assign/${payload.id}`, params, {
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  caseDuteDate({ state, commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {
        due_date: payload.dueDate,
      }

      axios
        .put(`${routePathAPI()}/case/due-date/${payload.id}`, params, {
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  caseInititate({ state, commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {
        stage: payload.stage,
      }

      axios
        .put(`${routePathAPI()}/case/initiate/${payload.id}`, params, {
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateCase({ state, commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {
        stage: payload.stage,
        party_id: payload.party_id,
      }

      axios
        .put(`${routePathAPI()}/case/${payload.case_id}`, params, {
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  resolveCase({ state, commit }, payload) {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
    let formData = new FormData()
    formData.append('result', payload.result)
    formData.append('notes', payload.notes)
    formData.append('attachment', payload.attachment)

    return new Promise((resolve, reject) => {
      axios
        .put(
          `${routePathAPI()}/case/solved/${payload.case_id}`,
          formData,
          config
        )
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  setActiveCase({ commit }, payload) {
    commit('SET_ACTIVE_CASE', payload)
  },
  setNewCase({ commit }, payload) {
    commit('SET_NEW_CASE', payload)
  },
  setActiveNewCaseBreadcrum({ commit }, payload) {
    commit('SET_NEW_CASE_ACTIVE_BREADCRUM', payload)
  },
  setThreeNeutralAdminId({ commit }, payload) {
    commit('SET_THREE_NEUTRAL_ADMIN_ID', payload)
  },
  resetActiveCase({ commit }) {
    commit('RESET_ACTIVE_CASE')
  },
  resetNewCase({ commit }) {
    commit('RESET_NEW_CASE')
  },
  resetActiveNewCaseBreadcrum({ commit }) {
    commit('RESET_NEW_CASE_ACTIVE_BREADCRUM')
  },
  resetThreeNeutralAdminId({ commit }) {
    commit('RESET_THREE_NEUTRAL_ADMIN_ID')
  },
}

// mutations
const mutations = {
  SET_ACTIVE_CASE(state, val) {
    // state.activeCase = val
    return localStorage.setItem('inresolve.activeCase', val)
  },
  SET_NEW_CASE(state, val) {
    state.newCase = val
  },
  SET_NEW_CASE_ACTIVE_BREADCRUM(state, val) {
    state.activeNewCaseBreadcrum = val
  },
  SET_THREE_NEUTRAL_ADMIN_ID(state, val) {
    state.threeNeutralAdminId = val
  },
  RESET_ACTIVE_CASE(state) {
    // state.activeCase = ''
    return localStorage.removeItem('inresolve.activeCase')
  },
  RESET_NEW_CASE(state) {
    state.newCase = {}
  },
  RESET_NEW_CASE_ACTIVE_BREADCRUM(state) {
    state.activeNewCaseBreadcrum = 1
  },
  RESET_THREE_NEUTRAL_ADMIN_ID(state) {
    state.threeNeutralAdminId = ''
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
