<template>
  <div class="flex h-screen overflow-hidden">
    <!-- Sidebar -->
    <Sidebar
      :sidebar-open="sidebarOpen"
      :case-state="$route.query.status"
      @close-sidebar="sidebarOpen = false"
    />
    <div
      class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden"
    >
      <main>
        <div class="max-w-9xl mx-auto w-full px-4 py-8 sm:px-6 lg:px-8">
          <div class="mb-8 flex items-center justify-between">
            <div class="flex flex-col">
              <h2 class="mb-2 text-3xl font-bold text-slate-800">
                {{ $t('chat.group.title') }}
              </h2>
              <p class="text-sm">
                {{ $t('chat.group.description') }}
              </p>
            </div>
            <ButtonAdd
              :title="$t('chat.group.newChat')"
              custom-class="rounded-xl"
              @clicked="newChat"
            />
          </div>

          <!-- Cards -->
          <div
            v-if="
              !isLoading &&
              (privateChatRoom.length > 0 || allChatRoom.length > 0)
            "
            class="space-y-10"
          >
            <div v-if="privateChatRoom.length > 0" class="space-y-4">
              <p class="px-2 font-bold">{{ $t('chat.group.privateChat') }}</p>
              <div class="grid grid-cols-12 gap-6">
                <ChatGroupItem :groups="privateChatRoom" @clicked="openLink" />
              </div>
            </div>
            <div v-if="allChatRoom.length > 0" class="space-y-4">
              <p class="px-2 font-bold">{{ $t('chat.group.stageChat') }}</p>
              <div class="grid grid-cols-12 gap-6">
                <ChatGroupItem :groups="allChatRoom" @clicked="openLink" />
              </div>
            </div>
          </div>
          <DualRingLoader v-else-if="isLoading" />
          <NoData v-else />
        </div>

        <ModalNewChatRoom
          :is-open="chatModalOpen"
          :share-with="caseParties"
          :me="activeUser"
          @closed="chatModalOpen = false"
          @empty-member="emptyMember"
          @new-chat-room="createChatRoom"
        />
      </main>
    </div>

    <RightSidebar
      :sidebar-open="sidebarOpen"
      @close-sidebar="sidebarOpen = false"
    />
  </div>
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import globalMixin from '@/mixins/global.js'
import chatMixin from '@/mixins/chat.js'
import caseMixin from '@/mixins/case.js'

import Sidebar from '@/partials/Sidebar.vue'
import RightSidebar from '@/partials/RightSidebar.vue'
import ChatGroupItem from '@/components/ChatGroupItem.vue'
import DualRingLoader from '@/components/DualRingLoader.vue'
import NoData from '@/components/NoData.vue'
import ButtonAdd from '@/components/ButtonAdd.vue'
import ModalNewChatRoom from '@/components/modal/chat/ModalNewChatRoom.vue'

export default {
  name: 'ChatGroupPage',
  components: {
    Sidebar,
    RightSidebar,
    ChatGroupItem,
    DualRingLoader,
    NoData,
    ButtonAdd,
    ModalNewChatRoom,
  },
  mixins: [chatMixin, globalMixin, caseMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const toast = useToast()
    const sidebarOpen = ref(false)
    const chatModalOpen = ref(false)

    return {
      t,
      n,
      store,
      toast,
      sidebarOpen,
      chatModalOpen,
    }
  },
  mounted() {
    this.activeUser = this.store.getters['auth/activeUser']

    this.getAllRoom()
    this.getCaseParties()
  },
  methods: {
    openLink(item) {
      this.toChatDiscussion(item)
    },
    newChat() {
      this.chatModalOpen = true
    },
  },
}
</script>
