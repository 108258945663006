<template>
  <div class="flex w-full cursor-pointer bg-white" @click="clickDocument">
    <div class="flex w-2/6 justify-start">
      <div class="px-4">
        <FolderIcon
          v-if="document.type === 'Folder'"
          class="inline w-8 cursor-pointer"
        />
        <FilePDFIcon v-else class="inline w-8 cursor-pointer" />
      </div>
      <div class="pt-1">
        <span>{{ document.name }}</span>
      </div>
    </div>
    <div class="flex w-2/6 pt-1">
      <span v-if="document.owner"
        >{{ document.owner.first_name }} {{ document.owner.last_name }}</span
      >
      <span v-else>-</span>
    </div>
    <div class="flex w-2/6 px-2">
      <div class="flex w-full justify-between">
        <div class="pt-1">
          <span>{{ document.type }}</span>
        </div>
        <div>
          <EditMenu align="right" class="relative ml-2 inline-flex" @click.stop>
            <li>
              <span
                class="flex cursor-pointer py-1 px-3 text-sm font-medium text-slate-600 hover:text-slate-800"
                @click="infoDocument"
              >
                {{ $t('about') }}
              </span>
            </li>
            <template v-if="document.owner">
              <li v-if="document.owner.id === me.id">
                <span
                  class="flex cursor-pointer py-1 px-3 text-sm font-medium text-slate-600 hover:text-slate-800"
                  @click.stop="editDocument"
                >
                  {{ $t('edit') }}
                </span>
              </li>
              <li v-if="document.owner.id === me.id">
                <span
                  class="flex cursor-pointer py-1 px-3 text-sm font-medium text-rose-500 hover:text-rose-600"
                  @click.stop="removeDocument"
                >
                  {{ $t('remove') }}
                </span>
              </li>
            </template>
          </EditMenu>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EditMenu from './DropdownEditMenu.vue'

import FolderIcon from '@/assets/svg/folder.svg'
import FilePDFIcon from '@/assets/svg/file_pdf.svg'

export default {
  name: 'DocumentItemComponent',
  components: {
    EditMenu,

    FolderIcon,
    FilePDFIcon,
  },
  props: {
    document: {
      type: Object,
      default: () => ({
        isFolder: false,
        name: '',
        owner: '',
        type: '',
      }),
    },
  },
  emits: ['edit-document', 'remove-document', 'info-document'],
  data: () => ({
    me: null,
  }),
  created() {
    this.me = this.$store.getters['auth/activeUser']
  },
  methods: {
    editDocument() {
      this.$emit('edit-document', {
        document: this.document,
      })
    },
    infoDocument() {
      this.$emit('info-document', {
        document: this.document,
      })
    },
    removeDocument() {
      this.$emit('remove-document', {
        document: this.document,
      })
    },
    clickDocument() {
      if (this.document.type !== 'Folder') {
        let url = ''
        if (this.document.files[0] !== undefined)
          url = this.document.files[0].file
        if (url !== '') window.open(url, '_blank').focus()
      } else {
        this.$router.push({
          name: 'folder',
          params: { slug: this.$route.params.slug, folderId: this.document.id },
          query: {
            name: this.$route.query.name,
            status: this.$route.query.status,
            sub_status: this.$route.query.sub_status,
            lang: this.$route.query.lang,
          },
        })
      }
    },
  },
}
</script>
