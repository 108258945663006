export default {
  data: () => ({
    hasCase: false,

    filterDataNotification: {
      page: 1,
      take: 50,
      search: '',
      order: 'DESC',
    },
    metaDataNotification: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    filterDataNotificationUnread: {
      page: 1,
      take: 50,
      search: '',
      order: 'DESC',
    },
    metaDataNotificationUnread: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    notifications: [],
    notificationData: {},
    activeNotifications: [],
    totalUnread: 0,
  }),
  methods: {
    // Async method
    async getAllNotification() {
      this.isLoading = true
      const params = {
        order: this.filterDataNotification.order,
        page: this.filterDataNotification.page,
        take: this.filterDataNotification.take,
        q: this.filterDataNotification.search,
      }
      if (this.hasCase) {
        params.case_id = this.$route.params.slug
      }
      await this.store
        .dispatch('notification/getAllNotification', params)
        .then((response) => {
          this.isLoading = false
          const responseData = response.data.data
          if (responseData.data) {
            this.notifications = responseData.data
          }
          if (responseData.meta) {
            this.metaDataNotification = responseData.meta
          }
        })
        .catch((error) => {
          this.isLoading = false
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async getTotalUnreadNotification() {
      this.isLoading = true
      const params = {
        order: this.filterDataNotificationUnread.order,
        page: this.filterDataNotificationUnread.page,
        take: this.filterDataNotificationUnread.take,
        q: this.filterDataNotificationUnread.search,
      }
      if (this.hasCase) {
        params.case_id = this.$route.params.slug
      }
      await this.store
        .dispatch('notification/getTotalUnreadNotification', params)
        .then((response) => {
          this.isLoading = false
          const responseData = response.data.data
          if (responseData) {
            this.totalUnread = responseData
          }
          if (responseData.meta) {
            this.metaDataNotificationUnread = responseData.meta
          }
        })
        .catch((error) => {
          this.isLoading = false
          this.toast.error(this.extractErrorResponse(error))
        })
    },

    // Local method
    searchNotification(event) {
      this.filterDataNotification.search = event
      this.getAllNotification()
    },
    toNotificationPage() {
      if (this.hasCase) {
        this.$router.push({
          name: 'notification-case',
          params: {
            slug: this.$route.params.slug,
          },
          query: {
            lang: this.$route.query.lang,
          },
        })
      } else {
        this.$router.push({
          name: 'notification',
          query: {
            lang: this.$route.query.lang,
          },
        })
      }
    },
  },
}
