<template>
  <header class="sticky top-0 z-30 border-b border-slate-200 bg-white">
    <div class="px-4 sm:px-6 lg:px-8">
      <div class="-mb-px flex h-16 items-center justify-between">
        <!-- Header: Left side -->
        <div class="flex w-1/3 justify-start">
          <Languages />
        </div>

        <div
          class="flex w-1/3 cursor-pointer justify-center"
          @click.stop="
            $router.push({ name: 'index', query: { lang: $route.query.lang } })
          "
        >
          <img
            class="w-24 object-cover object-center md:w-32"
            src="../assets/images/logo.png"
            alt="InResolve Logo"
          />
        </div>

        <!-- Header: Right side -->
        <div class="flex w-1/3 justify-end space-x-3">
          <DropdownNotifications align="right" class="md:mr-4" />
          <DropdownProfile align="right" />
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { ref } from 'vue'

import Languages from '@/components/DropdownLanguages.vue'
import DropdownNotifications from '@/components/DropdownNotifications.vue'
import DropdownProfile from '@/components/DropdownProfile.vue'

export default {
  name: 'HeaderPartial',
  components: {
    Languages,
    DropdownNotifications,
    DropdownProfile,
  },
  props: ['sidebarOpen'],
  setup() {
    const searchModalOpen = ref(false)
    return {
      searchModalOpen,
    }
  },
}
</script>
