<template>
  <div class="flex h-screen overflow-hidden">
    <div
      class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden"
    >
      <!-- Site header -->
      <Header
        v-if="isUser"
        :sidebar-open="sidebarOpen"
        @toggle-sidebar="sidebarOpen = !sidebarOpen"
      />

      <main>
        <div class="w-full">
          <div class="flex h-full flex-col justify-center bg-gray-100 md:mt-10">
            <div
              class="w-full self-center px-2 py-5 text-left md:w-5/6 md:py-2 md:px-0"
            >
              <BreadcrumNewCase :active="2" :is-show="isUser" />
            </div>
            <div
              class="w-full self-center rounded-lg bg-white px-8 py-8 shadow-md md:w-5/6"
            >
              <h1 class="mb-2 text-3xl font-bold text-slate-800">
                {{ $t('advocateSelection.title') }}
              </h1>
              <p class="text-sm">
                {{ $t('advocateSelection.description') }}
              </p>

              <div class="mt-10">
                <AdvocateTable
                  :advocates="advocates"
                  @change-selection="changeSelection"
                />
              </div>

              <div class="mt-5 flex flex-row justify-end">
                <div class="w-3/12">
                  <span
                    class="btn text-md mt-6 h-12 w-full cursor-pointer rounded-md text-slate-500 shadow-none hover:text-slate-700"
                    @click="back"
                  >
                    <svg
                      class="mr-2 h-7 w-7 fill-current text-slate-500"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z"
                      />
                    </svg>
                    {{ $t('action.back') }}
                  </span>
                </div>
                <button
                  class="btn text-md hover:bg-primary-600 mt-6 h-12 w-2/12 rounded-md bg-primary text-white"
                  @click="selectAdvocate"
                >
                  {{ $t('action.next') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import globalMixin from '@/mixins/global.js'
import caseMixin from '@/mixins/case.js'

import Header from '@/partials/Header.vue'
import AdvocateTable from '@/components/AdvocateTable.vue'
import BreadcrumNewCase from '@/components/case/BreadcrumNewCase.vue'

export default {
  name: 'AdvocateSelectionPage',
  components: {
    Header,
    AdvocateTable,
    BreadcrumNewCase,
  },
  mixins: [globalMixin, caseMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const toast = useToast()
    const sidebarOpen = ref(false)

    return {
      t,
      n,
      store,
      toast,
      sidebarOpen,
    }
  },
  data: () => ({
    headerData: [
      {
        name: '',
        colspan: 1,
      },
      {
        name: 'Name',
        colspan: 1,
      },
      {
        name: 'Ratings',
        colspan: 1,
      },
      {
        name: 'Cases handled',
        colspan: 1,
      },
      {
        name: 'Ongoing cases',
        colspan: 1,
      },
    ],
    advocates: [],
    filterDataAdvocate: {
      page: 1,
      take: 50,
      search: '',
      order: 'DESC',
    },
    metaDataAdvocate: {
      page: 1,
      take: 10,
      itemCount: 10,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
    advocateId: '',
    advocate: {},
    activeUser: {},
  }),
  computed: {
    isUser() {
      if (!this.checkEmptyObject(this.activeUser)) {
        return this.userCodes().includes(this.activeUser.role.code)
      }
      return false
    },
  },
  mounted() {
    this.activeCase = this.store.getters['cases/activeCase']
    this.activeUser = this.store.getters['auth/activeUser']
    this.newCaseData = this.store.getters['cases/newCase']
    if (this.checkEmptyObject(this.newCaseData) && this.isUser) {
      return this.$router.push({
        name: 'new-case',
        query: { lang: this.$route.query.lang },
      })
    }
    this.getAllAdvocate()
  },
  methods: {
    selectAdvocate() {
      if (this.advocateId === '') {
        this.toast.error(this.t('newCase.pleaseSelectAdvocate'))
        return
      }

      if (this.isUser) {
        this.newCaseData.advocate = this.advocate
        this.newCaseData.advocate_id = this.advocateId
        this.store.dispatch('cases/setNewCase', this.newCaseData)
        this.store.dispatch('cases/setActiveNewCaseBreadcrum', 3)
        this.$router.push({
          name: 'review-application',
          query: {
            lang: this.$route.query.lang,
          },
        })
      } else {
        // this.store.dispatch('cases/setThreeNeutralAdminId', this.advocateId)
        // const activeThreeNeutral =
        //   this.store.getters['cases/threeNeutralAdminId']
        // alert(activeThreeNeutral)
        this.caseAssignAPI({
          id: this.activeCase,
          advocate_id: this.advocateId,
        })
      }
    },
    async getAllAdvocate() {
      await this.store
        .dispatch('advocate/getAllAdvocate', {
          order: this.filterDataAdvocate.order,
          page: this.filterDataAdvocate.page,
          take: this.filterDataAdvocate.take,
          q: this.filterDataAdvocate.search,
        })
        .then((response) => {
          const responseData = response.data.data
          if (responseData.data) {
            this.advocates = responseData.data
          }
          if (responseData.meta) {
            this.metaDataAdvocate = responseData.meta
          }
        })
        .catch((error) => {
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    changeSelection(item) {
      if (typeof item[0] !== 'undefined') {
        this.advocate = item[0]
        this.advocateId = item[0].id
      }
    },
    back() {
      if (this.isUser) {
        this.$router.push({
          name: 'advocate-requirement',
          query: { lang: this.$route.query.lang },
        })
      } else {
        this.toDetailAdmin()
      }
    },
  },
}
</script>
