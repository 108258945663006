import axios, { routePathAPI } from '../../plugins/axios.js'
import router from '../../router'

// initial state
const state = () => ({})

// getters
const getters = {}

// actions
const actions = {
  createAgenda({ state, commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {
        title: payload.title ? payload.title : null,
        party_ids: payload.party_ids ? payload.party_ids : [],
        type: payload.type ? payload.type : null,
        description: payload.description ? payload.description : null,
        detail: payload.detail ? payload.detail : null,
        due_date: payload.due_date ? payload.due_date : null,
        document_file_type_id: payload.document_file_type_id
          ? payload.document_file_type_id
          : null,
        meeting_date: payload.meeting_date ? payload.meeting_date : null,
        meeting_url: payload.meeting_url ? payload.meeting_url : null,
        meeting_type: payload.meeting_type ? payload.meeting_type : null,
        meeting_platform: payload.meeting_platform
          ? payload.meeting_platform
          : null,
        meeting_time_from: payload.meeting_time_from
          ? payload.meeting_time_from
          : null,
        meeting_time_to: payload.meeting_time_to
          ? payload.meeting_time_to
          : null,
      }

      axios
        .post(`${routePathAPI()}/calendar/${payload.case_id}/create`, params, {
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  acceptOrRejectRequest({ state, commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        'x-language-code': currentLanguageCode,
      },
    }

    return new Promise((resolve, reject) => {
      let formData = new FormData()
      formData.append('accept', payload.accept)
      formData.append('file_name', payload.file_name ? payload.file_name : '')
      formData.append('reason', payload.reason ? payload.reason : '')
      formData.append(
        'attachment',
        payload.attachment ? payload.attachment : ''
      )

      axios
        .post(
          `${routePathAPI()}/calendar/request/action/${payload.id}`,
          formData,
          config
        )
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getAgendaDetail({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axios
        .get(`${routePathAPI()}/calendar/detail/${payload.id}`, {
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getAllAgenda({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {}
      if (payload.page) params.page = payload.page
      if (payload.take) params.take = payload.take
      if (payload.order) params.order = payload.order
      if (payload.q) params.q = payload.q
      if (payload.type) params.type = payload.type // Meeting, Alert
      if (payload.date) params.date = payload.date

      axios
        .get(`${routePathAPI()}/calendar/${payload.case_id}`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getAllDateline({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {}
      params.page = payload.page ? payload.page : 1
      params.take = payload.take ? payload.take : 10
      params.order = payload.order ? payload.order : 'DESC'

      axios
        .get(`${routePathAPI()}/calendar/dateline/${payload.case_id}`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
