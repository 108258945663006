<template>
  <div
    v-for="activity in activities"
    :key="activity.id"
    class="mt-4 flex w-full cursor-pointer items-center justify-center rounded-lg px-4 py-4 text-sm text-black"
    :class="[wrapperClass]"
  >
    <div class="flex items-center justify-center">
      <StageIcon v-if="activity.type === 'stage'" />
      <ChatIcon v-else />
    </div>
    <div class="ml-4 flex w-full flex-col">
      <div class="flex">{{ activity.title }}</div>
      <div class="flex text-sm text-slate-500">
        {{ timeAgo(activity.modified) }}
      </div>
    </div>
  </div>
</template>
<script>
import globalMixin from '@/mixins/global.js'
import StageIcon from '@/assets/svg/stage-icon.svg'
import ChatIcon from '@/assets/svg/chat-icon.svg'

export default {
  name: 'ActivityItemComponent',
  components: {
    StageIcon,
    ChatIcon,
  },
  mixins: [globalMixin],
  props: {
    activities: {
      type: Array,
      default: () => {
        return []
      },
    },
    wrapperClass: {
      type: String,
      default: '',
    },
  },
}
</script>
