<template>
  <div class="-m-1.5 flex flex-wrap items-center">
    <div class="m-1.5">
      <ModalBasic
        id="notes-modal"
        :modal-open="isOpen"
        :show-header="true"
        title="New Message"
        @close-modal="close"
      >
        <div class="px-5 py-4">
          <div class="flex pr-2">
            <!-- <div class="flex w-3/6 justify-start">
              <input
                v-model="title"
                type="text"
                class="border-none text-xl font-bold focus:border-transparent focus:outline-none focus:ring-0"
                :placeholder="$t('privateNotebook.forms.placeholders.title')"
              />
            </div> -->
            <div class="flex w-3/6 justify-end">
              <EditMenu
                v-if="activeChat.id"
                align="right"
                class="relative ml-2 inline-flex"
              >
                <li>
                  <span
                    class="flex cursor-pointer py-1 px-3 text-sm font-medium text-rose-500 hover:text-rose-600"
                    @click="deleteChat"
                  >
                    {{ $t('privateNotebook.forms.menus.delete') }}
                  </span>
                </li>
              </EditMenu>
            </div>
          </div>
          <!-- <div class="flex w-full pb-4">
            <p class="pl-3 text-xs">{{ date }}</p>
          </div> -->
          <div class="space-y-3">
            <div class="h-max-full w-full overflow-ellipsis">
              <textarea
                v-model="content"
                type="text"
                class="h-52 w-full resize-y border-none text-xs focus:border-transparent focus:outline-none focus:ring-0"
                placeholder="New Message"
              >
              </textarea>
            </div>
          </div>
        </div>
        <!-- <div>
          <label class="mb-1 block text-xs font-bold" for="files">
            {{ $t('forms.uploadFile') }}
          </label>
          <div class="flex w-full items-center justify-center">
            <label
              for="files"
              class="flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-gray-300 bg-gray-50 hover:bg-gray-100"
            >
              <div class="flex flex-col items-center justify-center py-2">
                <svg
                  aria-hidden="true"
                  class="mb-3 h-10 w-10 text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                  ></path>
                </svg>
                <p class="text-sm text-gray-500">
                  {{ $t('forms.dragAndDrop') }}
                </p>
                <p class="text-sm text-gray-500">
                  {{ $t('forms.or') }}
                </p>
                <p
                  class="text-sm font-semibold text-primary"
                >
                  {{ $t('forms.browseFiles') }}
                </p>
              </div>
              <input
                id="files"
                type="file"
                class="hidden"
                @change="fileInput"
              />
              <span v-if="attachment !== null">{{ attachment.name }}</span>
            </label>
          </div>
        </div> -->
        <div class="px-5 py-4">
          <div class="flex flex-wrap justify-end space-x-4">
            <span
              class="bg-primary-600 hover:text-primary-900 cursor-pointer rounded-lg py-2 px-8 text-sm text-white shadow-lg hover:shadow-xl"
              @click.stop="saveChat"
            >
              {{ $t('modal.save') }}
            </span>
          </div>
        </div>
      </ModalBasic>
    </div>
  </div>
</template>
<script>
import ModalBasic from './ModalBasic.vue'
import EditMenu from './DropdownEditMenu.vue'

export default {
  name: 'ModalChatComponent',
  components: {
    ModalBasic,
    EditMenu,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    date: {
      type: String,
      default: '',
    },
    activeChat: {
      type: Object,
      default: () => {
        return {
          title: '',
          content: '',
        }
      },
    },
  },
  emits: ['closed', 'delete-chat', 'edit-chat', 'new-chat'],
  data: () => ({
    title: '',
    content: '',
    attachment: null,
  }),
  methods: {
    close() {
      this.$emit('closed')
    },
    saveChat() {
      let params = {}
      params.content = this.content
      params.case_id = this.$route.params.slug
      params.chat_room_id = this.$route.params.id
      params.type = 'other'
      params.title = ''
      params.amount = null
      params.currency = null
      params.deadline_at = null
      params.attachment = this.attachment
      params.party_ids = ''
      if (this.activeChat.id) {
        params.id = this.activeChat.id
        this.$emit('edit-chat', params)
      } else {
        this.$emit('new-chat', params)
        this.content = ''
      }
    },
    deleteChat() {
      if (this.activeChat.id) {
        this.$emit('delete-chat', this.activeChat.id)
      } else {
        return
      }
    },
    fileInput(event) {
      this.attachment = event.target.files[0]
    },
  },
}
</script>
