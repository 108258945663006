<template>
  <div class="sticky bottom-0">
    <div
      class="flex h-16 items-center justify-between border-t border-slate-200 bg-white px-4 sm:px-6 md:px-5"
    >
      <!-- Plus button -->
      <button class="mr-3 shrink-0 text-slate-400 hover:text-slate-500">
        <span class="sr-only">Add</span>
        <svg class="h-6 w-6 fill-current" viewBox="0 0 24 24">
          <path
            d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12C23.98 5.38 18.62.02 12 0zm6 13h-5v5h-2v-5H6v-2h5V6h2v5h5v2z"
          />
        </svg>
      </button>
      <!-- Message input -->
      <form class="flex grow">
        <div class="mr-3 grow">
          <label for="message-input" class="sr-only">Type a message</label>
          <input
            id="message-input"
            class="form-input w-full border-transparent bg-slate-100 focus:border-slate-300 focus:bg-white"
            type="text"
            placeholder="Aa"
          />
        </div>
        <button
          type="submit"
          class="btn hover:bg-primary-600 whitespace-nowrap bg-primary text-white"
        >
          Send -&gt;
        </button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MessagesFooter',
}
</script>
