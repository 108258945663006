<template>
  <div
    v-for="notification in notifications"
    :key="notification.id"
    class="flex w-full cursor-pointer items-center justify-center space-x-5 text-sm text-black hover:bg-slate-50"
    :class="[
      bgClass !== '' ? bgClass : 'bg-white',
      wrapperClass !== '' ? wrapperClass : 'mt-4 rounded-lg px-4 py-4',
    ]"
  >
    <div class="flex items-center justify-center">
      <svg
        v-if="notification.type === 'case'"
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="19" cy="19" r="19" fill="url(#paint0_linear_1325_9916)" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M21.5087 10.5317H15.4101C13.5227 10.5317 11.8956 12.0617 11.8956 13.95V23.7702C11.8956 25.7649 13.4163 27.3553 15.4101 27.3553H22.7333C24.6217 27.3553 26.1516 25.6595 26.1516 23.7702V15.3681L21.5087 10.5317Z"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21.2679 10.521V13.1876C21.2679 14.4892 22.3211 15.5452 23.6219 15.548C24.8291 15.5507 26.0639 15.5517 26.1473 15.5462"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21.0937 22.2613H16.1464"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.2223 17.7218H16.146"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1325_9916"
            x1="8.32439"
            y1="-16.6969"
            x2="-25.0694"
            y2="33.4418"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#F98FEE" />
            <stop offset="1" stop-color="#634DEB" />
          </linearGradient>
        </defs>
      </svg>
      <svg
        v-else
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="19" cy="19" r="19" fill="url(#paint0_linear_1325_9903)" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.054 20.2749C14.4629 20.2749 13.9828 19.7948 13.9828 19.2037C13.9828 18.6144 14.4629 18.1343 15.054 18.1343C15.645 18.1343 16.1252 18.6144 16.1252 19.2037C16.1252 19.7948 15.645 20.2749 15.054 20.2749ZM19.2183 20.2749C18.6272 20.2749 18.1471 19.7948 18.1471 19.2037C18.1471 18.6144 18.6272 18.1343 19.2183 18.1343C19.8093 18.1343 20.2895 18.6144 20.2895 19.2037C20.2895 19.7948 19.8093 20.2749 19.2183 20.2749ZM22.3114 19.2037C22.3114 19.7948 22.7916 20.2749 23.3826 20.2749C23.9737 20.2749 24.4538 19.7948 24.4538 19.2037C24.4538 18.6144 23.9737 18.1343 23.3826 18.1343C22.7916 18.1343 22.3114 18.6144 22.3114 19.2037Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19.0183 9.83325C13.6925 9.83325 9.83334 14.1839 9.83334 19.0137C9.83334 20.556 10.2825 22.1433 11.0708 23.5938C11.2175 23.8334 11.2358 24.1355 11.135 24.421L10.5208 26.4774C10.3833 26.9731 10.805 27.3394 11.2725 27.1925L13.1242 26.6426C13.6283 26.4774 14.0225 26.6876 14.49 26.9731C15.8283 27.7617 17.4967 28.1666 19 28.1666C23.5467 28.1666 28.1667 24.6505 28.1667 18.9861C28.1667 14.1012 24.225 9.83325 19.0183 9.83325Z"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1325_9903"
            x1="8.32439"
            y1="-16.6969"
            x2="-25.0694"
            y2="33.4418"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#16B4EB" />
            <stop offset="1" stop-color="#23E73C" />
          </linearGradient>
        </defs>
      </svg>
    </div>
    <div class="flex w-full flex-col">
      <div class="line-clamp-1 flex items-center space-x-3">
        <span class="font-bold">{{ notification.title }}</span>
        <span v-if="notification.subtitle" class="text-xs text-slate-500">
          {{ notification.subtitle }}
        </span>
      </div>
      <div class="line-clamp-2 flex">{{ notification.content }}</div>
      <div class="flex text-sm text-slate-500">
        {{ timeAgo(notification.modified) }}
      </div>
      <div
        v-if="notification.slug !== null && notification.case_id !== null"
        class="flex justify-end text-sm text-blue-500 underline"
      >
        <span
          class="cursor-pointer hover:text-blue-700"
          @click.stop="toView(notification)"
        >
          {{
            $t('notification.view', {
              type: notification.slug.replace('-', ' '),
            })
          }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import globalMixin from '@/mixins/global.js'

export default {
  name: 'NotificationItemComponent',
  mixins: [globalMixin],
  props: {
    notifications: {
      type: Array,
      default: () => {
        return []
      },
    },
    wrapperClass: {
      type: String,
      default: '',
    },
    bgClass: {
      type: String,
      default: '',
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async toView(notification) {
      let activeRoute = ''
      if (notification.slug === 'case') {
        activeRoute = 'case-stage'

        await this.$router.push({
          name: activeRoute,
          params: {
            slug: notification.case_id,
          },
          query: { lang: this.$route.query.lang },
        })
      } else if (notification.slug === 'calendar') {
        activeRoute = 'calendar'

        await this.$router.push({
          name: activeRoute,
          params: {
            slug: notification.case_id,
          },
          query: { lang: this.$route.query.lang },
        })
      } else if (
        notification.slug === 'stage-request' ||
        notification.slug === 'stage'
      ) {
        if (this.isAdmin) {
          activeRoute = 'case-detail-admin'
        } else {
          activeRoute = 'case-detail'
        }

        await this.$router.push({
          name: activeRoute,
          params: {
            slug: notification.case_id,
          },
          query: { lang: this.$route.query.lang },
        })
      } else if (notification.slug === 'document') {
        if (notification.extra !== null) {
          activeRoute = 'folder'

          await this.$router.push({
            name: activeRoute,
            params: {
              slug: notification.case_id,
              folderId: notification.extra,
            },
            query: { lang: this.$route.query.lang },
          })
        } else {
          activeRoute = 'documents'

          await this.$router.push({
            name: activeRoute,
            params: {
              slug: notification.case_id,
            },
            query: { lang: this.$route.query.lang },
          })
        }
      } else if (
        notification.slug === 'chat' ||
        notification.slug === 'chat-offer' ||
        notification.slug === 'chat-room'
      ) {
        if (notification.extra !== null) {
          activeRoute = 'chats'

          await this.$router.push({
            name: activeRoute,
            params: {
              slug: notification.case_id,
              id: notification.extra,
            },
            query: { lang: this.$route.query.lang },
          })
        } else {
          activeRoute = 'chat-group'

          await this.$router.push({
            name: activeRoute,
            params: {
              slug: notification.case_id,
            },
            query: { lang: this.$route.query.lang },
          })
        }
      }

      if (this.$route.name === activeRoute) {
        await location.reload()
      }
    },
  },
}
</script>
