<template>
  <div
    class="flex cursor-pointer space-x-2 rounded-lg bg-gray-100"
    :class="[wrapperClass !== '' ? wrapperClass : 'min-w-56 py-2 px-4']"
    @click="toFile"
  >
    <div class="flex w-2/12">
      <FilePDFIcon class="inline w-6 cursor-pointer" />
    </div>
    <div class="flex w-7/12 flex-col">
      <p class="line-clamp-1 text-sm text-black">{{ file.name }}</p>
      <p v-if="file.hasOwnProperty('size')" class="text-xs text-slate-500">
        {{ file.size }}
      </p>
    </div>
    <div class="flex w-3/12 items-center justify-end">
      <DownloadIcon />
    </div>
  </div>
</template>
<script>
import FilePDFIcon from '@/assets/svg/file_pdf.svg'
import DownloadIcon from '@/assets/svg/download.svg'

export default {
  name: 'FileItemComponent',
  components: {
    FilePDFIcon,
    DownloadIcon,
  },
  props: {
    file: {
      type: Object,
      required: true,
    },
    wrapperClass: {
      type: String,
      default: '',
    },
  },
  methods: {
    toFile() {
      window.open(this.file.files[0].file, '_blank')
    },
  },
}
</script>
