<template>
  <div class="-m-1.5 flex flex-wrap items-center">
    <div class="m-1.5">
      <ModalBasic
        id="chat-media-modal"
        :modal-open="isOpen"
        :show-border-header="false"
        title="Media and Documents"
        @close-modal="close"
      >
        <div class="space-y-5 px-5 pb-5">
          <div
            v-for="document in documents"
            :key="document.id"
            class="flex cursor-pointer items-center justify-between"
            @click.stop="toFile(document)"
          >
            <div class="flex items-center justify-start space-x-3">
              <FilePDFIcon class="inline w-8 cursor-pointer" />
              <div>
                <p class="line-clamp-1 text-sm font-medium text-black">
                  {{ document.name }}
                </p>
                <p class="text-xs text-slate-500">4.25Mb</p>
              </div>
            </div>
            <div class="flex items-center justify-end">
              <DownloadIcon />
            </div>
          </div>
        </div>
      </ModalBasic>
    </div>
  </div>
</template>
<script>
import ModalBasic from './ModalBasic.vue'
import FilePDFIcon from '@/assets/svg/file_pdf.svg'
import DownloadIcon from '@/assets/svg/download.svg'

export default {
  name: 'ModalChatMediaComponent',
  components: {
    ModalBasic,
    FilePDFIcon,
    DownloadIcon,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    documents: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  emits: ['closed'],
  data: () => ({}),
  methods: {
    close() {
      this.$emit('closed')
    },
    toFile(document) {
      window.open(document.files[0].file, '_blank')
    },
  },
}
</script>
