<template>
  <div class="flex h-screen overflow-hidden">
    <!-- Sidebar -->
    <Sidebar
      :sidebar-open="sidebarOpen"
      :case-state="
        activeCase.stage !== undefined ? activeCase.stage : $route.query.status
      "
      @close-sidebar="sidebarOpen = false"
    />

    <!-- Content area -->
    <div
      class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden"
    >
      <main>
        <div class="max-w-9xl mx-auto w-full px-4 py-8 sm:px-6 lg:px-8">
          <div class="flex w-full flex-col pb-4">
            <h1 class="text-2xl font-bold text-black">
              {{ $t('caseDetail.title') }}
            </h1>
            <p>{{ $t('caseDetail.description') }}</p>
          </div>
          <template v-if="!isLoading">
            <!-- Case Details -->
            <div class="rounded-lg bg-white px-6 shadow-sm">
              <div class="py-6">
                <div class="flex items-start">
                  <div class="grow">
                    <div class="items-start justify-between py-4 sm:flex">
                      <div
                        class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                      >
                        <p>{{ $t('caseDetail.details.caseName') }}</p>
                      </div>
                      <div
                        class="mb-2 whitespace-nowrap text-lg font-medium text-slate-800 sm:mb-0"
                      >
                        <p>{{ activeCase.name }}</p>
                      </div>
                    </div>
                    <div
                      class="items-start justify-between border-t py-4 sm:flex"
                    >
                      <div
                        class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                      >
                        <p>{{ $t('caseDetail.details.submissionDate') }}</p>
                      </div>
                      <div
                        class="mb-2 whitespace-nowrap text-lg font-medium text-slate-800 sm:mb-0"
                      >
                        <p>{{ formatDateDay(activeCase.time) }}</p>
                      </div>
                    </div>
                    <div
                      class="items-start justify-between border-t py-4 sm:flex"
                    >
                      <div
                        class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                      >
                        <p>{{ $t('caseDetail.details.submittedBy') }}</p>
                      </div>
                      <div
                        class="mb-2 whitespace-nowrap text-lg font-medium text-slate-800 sm:mb-0"
                      >
                        <p v-if="activeCase.submitted_by">
                          {{ activeCase.submitted_by.first_name }}
                          {{ activeCase.submitted_by.last_name }}
                        </p>
                        <p v-else>-</p>
                      </div>
                    </div>

                    <div
                      class="items-center justify-between border-t py-4 sm:flex"
                    >
                      <div
                        class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                      >
                        <p>{{ $t('caseDetail.details.currentStage') }}</p>
                      </div>
                      <div
                        class="mb-2 whitespace-nowrap text-right text-lg font-medium text-yellow-500 sm:mb-0"
                      >
                        <p>{{ activeCase.status }}</p>

                        <p v-if="lastDeadlineStage !== null">
                          {{ lastDeadlineStage }}
                        </p>
                      </div>
                    </div>
                    <div
                      v-if="
                        lastDeadlineTime !== null && lastDeadlineStage !== null
                      "
                      class="items-start justify-between border-t py-4 sm:flex"
                    >
                      <div
                        class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                      >
                        <p>
                          {{
                            $t('caseDetail.details.stageDuedate', {
                              stage: lastDeadlineStage,
                            })
                          }}
                        </p>
                      </div>
                      <div
                        class="mb-2 whitespace-nowrap text-lg font-medium text-slate-800 sm:mb-0"
                      >
                        <p>{{ formatDateDay(lastDeadlineTime) }}</p>
                      </div>
                    </div>

                    <div
                      v-if="activeCase.attachment"
                      class="items-center justify-between border-t py-4 sm:flex"
                    >
                      <div
                        class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                      >
                        <p>
                          {{ $t('caseDetail.details.assignationDocument') }}
                        </p>
                      </div>
                      <div
                        class="mb-2 whitespace-nowrap text-lg font-medium text-slate-800 sm:mb-0"
                      >
                        <button
                          class="rounded-lg bg-primary py-2 px-4 text-white"
                          @click="openDocumentCase(activeCase.attachment)"
                        >
                          {{ $t('button.open') }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Case Details -->

            <!-- Parties -->
            <div class="pt-10">
              <h1 class="text-lg font-bold">
                {{ $t('caseDetail.parties.title') }}
              </h1>
            </div>
            <div
              v-for="party in activeCase.parties"
              :key="party.id"
              class="mt-4 rounded-lg bg-white px-6 shadow-sm"
            >
              <div class="py-6">
                <div class="flex flex-row py-4">
                  <ImageLoader
                    wrapper-class="mr-3 shrink-0 rounded-full"
                    :src="party.image"
                    width="60"
                    height="60"
                    :alternate="party.name"
                  />
                  <div class="pl-4">
                    <p class="text-lg font-semibold text-slate-800">
                      {{ party.name }}
                    </p>
                    <p class="text-sm text-slate-500">
                      {{ party.email }}
                    </p>
                  </div>
                </div>
                <div class="flex items-start">
                  <div class="grow">
                    <div class="items-start justify-between py-4 sm:flex">
                      <div
                        class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                      >
                        <p>{{ $t('caseDetail.parties.role') }}</p>
                      </div>
                      <div
                        class="mb-2 whitespace-nowrap text-lg font-medium text-slate-800 sm:mb-0"
                      >
                        <p>{{ party.role ?? '-' }}</p>
                      </div>
                    </div>
                    <div
                      class="items-start justify-between border-t py-4 sm:flex"
                    >
                      <div
                        class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                      >
                        <p>{{ $t('caseDetail.parties.phoneNumber') }}</p>
                      </div>
                      <div
                        class="mb-2 whitespace-nowrap text-lg font-medium text-slate-800 sm:mb-0"
                      >
                        <p>{{ party.phone ?? '-' }}</p>
                      </div>
                    </div>
                    <div
                      class="items-start justify-between border-t py-4 sm:flex"
                    >
                      <div
                        class="mb-2 items-center text-left text-lg font-semibold text-slate-500 sm:mb-0"
                      >
                        <p>{{ $t('caseDetail.parties.email') }}</p>
                      </div>
                      <div
                        class="mb-2 whitespace-nowrap text-lg font-medium text-slate-800 sm:mb-0"
                      >
                        <p>{{ party.email ?? '-' }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Parties -->
          </template>
          <DualRingLoader v-else />
        </div>
      </main>
    </div>

    <RightSidebar
      v-if="loggedinUser.role.code.toUpperCase() !== 'REDEK_ADMIN'"
      :sidebar-open="false"
      @close-sidebar="sidebarOpen = false"
    />
  </div>
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import globalMixin from '@/mixins/global.js'
import caseMixin from '@/mixins/case.js'

import Sidebar from '@/partials/Sidebar.vue'
import RightSidebar from '@/partials/RightSidebar.vue'
import DualRingLoader from '@/components/DualRingLoader.vue'
import ImageLoader from '@/components/image/ImageLoader.vue'

export default {
  name: 'CaseDetailPage',
  components: {
    Sidebar,
    RightSidebar,
    DualRingLoader,
    ImageLoader,
  },
  mixins: [globalMixin, caseMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const toast = useToast()
    const sidebarOpen = ref(false)
    const activeCase = ref({})

    return {
      t,
      n,
      store,
      toast,
      sidebarOpen,
      activeCase,
    }
  },
  mounted() {
    this.checkUserAccess('USER')
    this.getCaseDetail()
  },
}
</script>
