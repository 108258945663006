<template>
  <main>
    <div class="relative flex">
      <!-- Content -->
      <div class="w-full">
        <div
          class="flex h-full min-h-screen flex-col justify-center bg-gray-100"
        >
          <div class="mb-8 flex justify-center">
            <img
              class="h-14 object-cover object-center"
              src="../assets/images/logo.png"
              alt="InResolve Logo"
            />
          </div>
          <div
            class="w-2/4 self-center rounded-lg bg-white px-8 py-8 shadow-md lg:w-1/3"
          >
            <div
              class="flex w-full cursor-pointer flex-row items-center pb-4"
              @click="
                $router.push({
                  name: 'login',
                  query: {
                    lang: $route.query.lang,
                  },
                })
              "
            >
              <ArrowBackIcon class="h-5 w-5 fill-current text-slate-500" />
              <span
                class="hover:text-primary-400 ml-2 text-xs text-slate-500"
                >{{ $t('action.back') }}</span
              >
            </div>
            <h1 class="mb-2 text-3xl font-bold text-slate-800">
              {{ $t('login.enterOTP') }}
            </h1>
            <p class="text-justify text-sm">
              {{ $t('login.weSentOTP', { email: activeEmail }) }}
            </p>
            <!-- Form -->
            <form class="space-y-4" @submit.prevent="doLogin">
              <div>
                <v-otp-input
                  ref="otpInput"
                  input-classes="w-full h-20 lg:h-24 text-3xl text-center rounded-md  border-gray-300 outline-none focus:border-primary-500 focus:outline-none focus:ring-0 focus:ring-primary-500 focus:ring-opacity-50"
                  separator=" "
                  class="mt-4 grid grid-cols-2 justify-center gap-2 text-center"
                  :num-inputs="6"
                  :should-auto-focus="true"
                  :is-input-num="true"
                  @on-complete="OTPComplete"
                />
              </div>
              <div class="flex flex-col">
                <div class="flex w-full space-x-2 text-sm">
                  <span>{{ $t('login.didNotReceive') }}</span>
                  <span
                    class="cursor-pointer font-bold text-primary"
                    @click="resendOTP"
                  >
                    {{ $t('login.resendCode') }}
                  </span>
                </div>
              </div>
              <div class="flex flex-col">
                <button
                  class="btn text-md hover:bg-primary-600 h-12 w-full rounded-md bg-primary text-white"
                  type="submit"
                  @click="verifyOTPClick"
                >
                  {{ $t('button.submit') }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { useToast } from 'vue-toastification'
import globalMixin from '@/mixins/global.js'

import ArrowBackIcon from '@/assets/svg/arrow-back.svg'

export default {
  name: 'LoginOTPPage',
  components: {
    ArrowBackIcon,
  },
  mixins: [globalMixin],
  setup() {
    const toast = useToast()
    return { toast }
  },
  data: () => ({
    OTPCode: '',
    activeEmail: '',
  }),
  mounted() {
    // this.checkUserAccess('', true)
    const activeEmail = this.$store.getters['auth/activeEmail']
    if (activeEmail !== null) {
      this.activeEmail = activeEmail
    } else {
      this.toast.error(this.$t('message.failed.auth.emailExpired'))
      return this.$router.push({
        name: 'login',
        query: {
          lang: this.$route.query.lang,
        },
      })
    }
  },
  methods: {
    verifyOTPClick() {
      if (!this.isLoading) this.verifyOTP()
    },
    OTPComplete(code) {
      this.OTPCode = code
      if (!this.isLoading) this.verifyOTP()
    },
    async verifyOTP() {
      if (this.OTPCode.length < 6) {
        return this.toast.error(this.$t('message.failed.auth.fillOTP'))
      }

      this.isLoading = true
      const payload = {
        otp: this.OTPCode,
        email: this.activeEmail,
      }

      await this.$store
        .dispatch('auth/verifyOTP', payload)
        .then((response) => {
          this.isLoading = false
          if (response.statusCode === 200) {
            this.checkUserAccess('', true)
          }
          this.toast.success(response.message)
        })
        .catch((error) => {
          this.isLoading = false
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async resendOTP() {
      if (this.isLoading) return

      this.isLoading = true
      const payload = {
        email: this.activeEmail,
      }

      await this.$store
        .dispatch('auth/resendOTP', payload)
        .then((response) => {
          this.isLoading = false
        })
        .catch((error) => {
          this.isLoading = false
          this.toast.error(this.extractErrorResponse(error))
        })
    },
  },
}
</script>
