import axios, { routePathAPI } from '../../plugins/axios.js'
import router from '../../router'

// initial state
const state = () => ({})

// getters
const getters = {}

// actions
const actions = {
  // Chat Room
  getAllRoom({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {}
      if (payload.page) params.page = payload.page
      if (payload.take) params.take = payload.take
      if (payload.order) params.order = payload.order

      axios
        .get(`${routePathAPI()}/chat/room/${payload.case_id}`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getActiveChat({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axios
        .get(`${routePathAPI()}/chat/room/active/${payload.case_id}`, {
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getDetailRoom({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axios
        .get(`${routePathAPI()}/chat/room/single/${payload.id}`, {
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getAllDocumentRoom({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axios
        .get(`${routePathAPI()}/chat/room/document/${payload.id}`, {
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  joinRoom({ state, commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      axios
        .post(`${routePathAPI()}/chat/room/join/${payload.room_id}`, {
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then((response) => {
          const responseData = response.data
          if (responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // Chat Discussion
  getAllChat({ commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    return new Promise((resolve, reject) => {
      let params = {}
      if (payload.page) params.page = payload.page
      if (payload.take) params.take = payload.take
      if (payload.order) params.order = payload.order
      if (payload.type) params.type = payload.type

      axios
        .get(`${routePathAPI()}/chat/${payload.room_id}`, {
          params: params,
          headers: {
            'x-language-code': currentLanguageCode,
          },
        })
        .then(async (response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createChat({ state, commit }, payload) {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
    let formData = new FormData()
    formData.append('chat_room_id', payload.chat_room_id)
    formData.append('type', payload.type)
    formData.append('title', payload.title)
    formData.append('content', payload.content)
    formData.append('amount', payload.amount)
    formData.append('currency', payload.currency)
    formData.append('deadline_at', payload.deadline_at)
    formData.append('attachment', payload.attachment)
    formData.append('party_ids', payload.party_ids)

    return new Promise((resolve, reject) => {
      axios
        .post(
          `${routePathAPI()}/chat/create/${payload.case_id}`,
          formData,
          config
        )
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  createChatPrivate({ state, commit }, payload) {
    const params = {}
    params.case_id = payload.case_id
    params.title = payload.title
    params.members = payload.members

    return new Promise((resolve, reject) => {
      axios
        .post(`${routePathAPI()}/chat/room/private/create`, params)
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  closeChat({ state, commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${routePathAPI()}/chat/room/private/close/${payload.room_id}`)
        .then((response) => {
          const responseData = response.data
          if (response.status === 200 && responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  chatOfferAction({ state, commit }, payload) {
    const currentLanguageCode =
      router.currentRoute.value.query.lang || import.meta.env.VITE_LANGUAGE_CODE

    const params = {}
    params.chat_id = payload.chat_id
    params.accept = payload.accept
    params.reason = payload.reason || ''

    return new Promise((resolve, reject) => {
      axios
        .post(
          `${routePathAPI()}/chat/offer/action/${payload.room_id}`,
          params,
          {
            headers: {
              'x-language-code': currentLanguageCode,
            },
          }
        )
        .then((response) => {
          const responseData = response.data
          if (responseData.error === undefined) {
            resolve({
              status: response.status,
              message: 'success',
              data: responseData,
            })
          } else {
            reject({
              status: response.status,
              message: 'failed',
              data: responseData,
            })
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
