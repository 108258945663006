<template>
  <ModalBlank id="danger-modal" :modal-open="isOpen" @close-modal="close">
    <Form
      ref="form"
      v-slot="{ errors }"
      :validation-schema="rejectSchema"
      @submit="reject"
    >
      <div class="flex space-x-4 p-5 text-center">
        <!-- Content -->
        <div class="w-full">
          <!-- Modal header -->
          <div class="mb-2">
            <div class="text-lg font-bold text-slate-800">
              {{ title }}
            </div>
          </div>
          <!-- Modal content -->
          <div class="mb-2 text-sm">
            <div class="space-y-2">
              <p>{{ $t('validation.pleaseDescribeReason') }}</p>
            </div>
          </div>
          <div class="mb-8">
            <Field v-slot="{ field }" v-model="reason" name="reason">
              <input
                v-bind="field"
                type="text"
                class="w-full rounded-sm border border-gray-300 focus:border-gray-600"
              />
            </Field>
            <div v-if="errors.reason !== undefined" class="flex w-full">
              <span class="text-sm text-red-500">
                {{ $t(errors.reason) }}
              </span>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="flex w-full justify-center space-x-2">
            <button
              class="btn-lg w-full rounded-lg border-slate-200 text-slate-600 hover:border-slate-300"
              type="button"
              @click.stop="close()"
            >
              {{ $t('button.cancel') }}
            </button>
            <button
              class="btn-lg hover:bg-primary-600 w-full rounded-lg bg-primary text-white"
              type="submit"
            >
              {{ $t('button.confirm') }}
            </button>
          </div>
        </div>
      </div>
    </Form>
  </ModalBlank>
</template>
<script>
import { Form, Field } from 'vee-validate'
import * as Yup from 'yup'
import ModalBlank from './ModalBlank.vue'

export default {
  name: 'ModalRejectOfferComponent',
  components: {
    ModalBlank,
    Form,
    Field,
  },
  props: {
    id: {
      type: [String, Number],
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['closed', 'reject'],
  data: () => ({
    reason: '',
    rejectSchema: Yup.object().shape({
      reason: Yup.string()
        .typeError('validation.pleaseDescribeReason')
        .required('validation.pleaseDescribeReason'),
    }),
  }),
  watch: {
    isOpen(val) {
      if (!val) this.close()
      else this.$refs.form.resetForm()
    },
  },
  methods: {
    close() {
      this.$refs.form.resetForm()
      this.$emit('closed')
    },
    reject() {
      this.$emit('reject', { id: this.id, reason: this.reason })
    },
  },
}
</script>
