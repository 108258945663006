<template>
  <div class="flex h-screen overflow-hidden">
    <!-- Content area -->
    <div
      class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden"
    >
      <!-- Site header -->
      <Header
        :sidebar-open="sidebarOpen"
        @toggle-sidebar="sidebarOpen = !sidebarOpen"
      />

      <main>
        <div class="max-w-8xl mx-auto w-full px-4 py-8 sm:px-6 lg:px-8">
          <!-- Content -->
          <div class="mb-8 rounded-sm">
            <div class="flex flex-col space-x-4 md:-mr-px md:flex-row">
              <SettingsSidebar class="bg-white shadow-lg" />
              <AccountPanel class="bg-white shadow-lg" />
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import Header from '@/partials/Header.vue'
import SettingsSidebar from '@/partials/settings/SettingsSidebar.vue'
import AccountPanel from '@/partials/settings/AccountPanel.vue'

export default {
  name: 'AccountSettingPage',
  components: {
    Header,
    SettingsSidebar,
    AccountPanel,
  },
  setup() {
    const sidebarOpen = ref(false)

    return {
      sidebarOpen,
    }
  },
}
</script>
