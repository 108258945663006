<template>
  <div>
    <!-- Sidebar backdrop (mobile only) -->
    <div
      class="fixed inset-0 z-50 bg-white bg-opacity-30 transition-opacity duration-200 lg:z-auto lg:hidden"
      :class="sidebarOpen ? 'opacity-100' : 'pointer-events-none opacity-0'"
      aria-hidden="true"
    ></div>

    <!-- Sidebar -->
    <div
      id="sidebar"
      ref="sidebar"
      class="no-scrollbar absolute left-0 top-0 z-40 flex h-screen w-96 shrink-0 transform flex-col overflow-y-scroll bg-white p-4 transition-all duration-200 ease-in-out lg:static lg:left-auto lg:top-auto lg:w-96 lg:translate-x-0 lg:overflow-y-auto 2xl:!w-96"
      :class="sidebarOpen ? 'translate-x-0' : '-translate-x-64'"
    >
      <!-- Sidebar header -->
      <div class="mb-10 flex flex-col pr-3 sm:px-2">
        <div class="mt-4 flex w-full items-center justify-start">
          <DropdownNotifications align="left" class="absolute md:mr-6" />
          <UserMenu align="right" mode="aesthetic" />
        </div>
      </div>

      <!-- Links -->
      <div class="px-4">
        <div class="flex w-full justify-between">
          <div class="flex items-center">
            <p class="text-xl font-bold text-black">
              {{ $t('timeline.deadlines') }}
            </p>
          </div>
          <div v-if="false" class="flex items-center">
            <svg
              viewBox="0 0 17 15"
              fill="none"
              class="h-3 w-3 shrink-0 text-slate-800"
            >
              <path
                d="M15.75 7.72559L0.75 7.72559"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.70019 1.70124L15.7502 7.72524L9.7002 13.7502"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        <section v-if="deadlines.length > 0">
          <div
            v-for="deadline in deadlines"
            :key="deadline.id"
            class="mt-4 flex w-full cursor-pointer items-center justify-center rounded-md bg-slate-50 px-4 py-4 text-sm text-black"
            @click="toDeadlineDate(deadline)"
          >
            <div
              class="flex h-12 w-16 flex-col items-center justify-center rounded-md bg-white"
            >
              <div class="flex">
                <span class="text-red-500">{{
                  extractDate(deadline.due_date, 'DD')
                }}</span>
              </div>
              <div class="flex text-xs">
                <span class="text-black">{{
                  extractDate(deadline.due_date)
                }}</span>
              </div>
            </div>
            <div class="ml-4 flex w-full">
              Provide document to {{ deadline.owner.first_name }}
            </div>
          </div>
        </section>
        <div v-else>{{ $t('timeline.noDeadlines') }}</div>

        <div
          class="mt-8 flex w-full cursor-pointer justify-between"
          @click="clickRecentAction"
        >
          <div class="flex items-center">
            <p class="text-xl font-bold text-black">
              {{ $t('timeline.recentAction') }}
            </p>
          </div>
          <div v-if="false" class="flex items-center">
            <svg
              viewBox="0 0 17 15"
              fill="none"
              class="h-3 w-3 shrink-0 text-slate-800"
            >
              <path
                d="M15.75 7.72559L0.75 7.72559"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.70019 1.70124L15.7502 7.72524L9.7002 13.7502"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        <!-- <ActivityItem
          v-if="timelines.length > 0"
          :activities="timelines"
          wrapper-class="bg-slate-50"
        /> -->
        <div v-if="chatData.length > 0" class="mt-4 space-y-4">
          <div
            v-for="chat in chatData"
            :key="chat.id"
            class="flex w-full cursor-pointer items-center justify-center rounded-lg bg-slate-50 p-4 text-sm text-black"
            @click="toChatDiscussion(chat, true)"
          >
            <div class="flex items-center justify-center">
              <CalendarIcon />
            </div>
            <div class="ml-4 flex w-full flex-col">
              <div class="flex">{{ chat.content }}</div>
              <div class="flex text-sm text-slate-500">
                {{ formatDateDay(chat.deadline_at) }}
              </div>
            </div>
          </div>
        </div>
        <div v-else>{{ $t('timeline.noRecentAction') }}</div>
      </div>

      <!-- Expand / collapse button -->
      <div class="mt-auto hidden justify-start pt-3 lg:inline-flex 2xl:hidden">
        <div class="py-2">
          <!-- <button @click.prevent="sidebarExpanded = !sidebarExpanded">
            <span class="sr-only">Expand / collapse sidebar</span>
            <svg
              class="h-10 w-12"
              :class="sidebarExpanded && 'rotate-180'"
              viewBox="0 0 50 42"
              fill="none"
            >
              <path
                d="M33.0645 15.0566L28.2258 19.8114L33.0645 24.5661"
                stroke="#6F6C99"
                stroke-width="2.4"
              />
              <path
                d="M23.3871 16.6416L20.1613 19.8114L23.3871 22.9812"
                stroke="#6F6C99"
                stroke-width="2.4"
              />
            </svg>
          </button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import globalMixin from '@/mixins/global.js'
import caseMixin from '@/mixins/case.js'
import chatMixin from '@/mixins/chat.js'

import DropdownNotifications from '@/components/DropdownNotifications.vue'
import UserMenu from '@/components/DropdownProfile.vue'
import CalendarIcon from '@/assets/svg/calendar-icon.svg'

// import ActivityItem from '@/components/ActivityItem.vue'

export default {
  name: 'RightSidebar',
  components: {
    DropdownNotifications,
    UserMenu,
    // ActivityItem,
    CalendarIcon,
  },
  mixins: [globalMixin, caseMixin, chatMixin],
  props: {
    sidebarOpen: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close-sidebar'],
  setup(props, { emit }) {
    const store = useStore()

    const trigger = ref(null)
    const sidebar = ref(null)

    const storedSidebarExpanded = localStorage.getItem('sidebar-expanded')
    const sidebarExpanded = ref(
      storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true'
    )

    const route = useRoute()
    const router = useRouter()
    const currentRoute = router.currentRoute.value

    // close on click outside
    const clickHandler = ({ target }) => {
      if (!sidebar.value || !trigger.value) return
      if (
        !props.sidebarOpen ||
        sidebar.value.contains(target) ||
        trigger.value.contains(target)
      )
        return
      emit('close-sidebar')
    }

    // close if the esc key is pressed
    const keyHandler = ({ keyCode }) => {
      if (!props.sidebarOpen || keyCode !== 27) return
      emit('close-sidebar')
    }

    const openLink = (routeName) => {
      router.push({
        name: routeName,
        params: { slug: route.params.slug },
        query: { lang: route.query.lang },
      })
    }

    onMounted(() => {
      document.addEventListener('click', clickHandler)
      document.addEventListener('keydown', keyHandler)
    })

    onUnmounted(() => {
      document.removeEventListener('click', clickHandler)
      document.removeEventListener('keydown', keyHandler)
    })

    watch(sidebarExpanded, () => {
      localStorage.setItem('sidebar-expanded', sidebarExpanded.value)
      if (sidebarExpanded.value) {
        document.querySelector('body').classList.add('sidebar-expanded')
      } else {
        document.querySelector('body').classList.remove('sidebar-expanded')
      }
    })

    return {
      store,
      trigger,
      sidebar,
      sidebarExpanded,
      currentRoute,
      openLink,
    }
  },
  data: () => ({
    deadlines: [],
    timelines: [],
  }),
  created() {
    this.getAllDateline()
    // this.getAllTimeline()
    this.getCaseDetail(true)
  },
  methods: {
    async getAllDateline() {
      this.isLoading = true
      await this.store
        .dispatch('calendar/getAllDateline', {
          case_id: this.$route.params.slug,
        })
        .then((response) => {
          this.isLoading = false
          const responseData = response.data.data
          this.deadlines = responseData.data
        })
        .catch((error) => {
          this.isLoading = false
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async getAllTimeline() {
      this.isLoading = true
      await this.store
        .dispatch('timeline/getAllTimeline', {
          case_id: this.$route.params.slug,
        })
        .then((response) => {
          this.isLoading = false
          const responseData = response.data.data
          this.timelines = responseData.data
        })
        .catch((error) => {
          this.isLoading = false
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    toDeadlineDate(deadline) {
      return this.$router.push({
        name: 'calendar',
        params: {
          slug: this.$route.params.slug,
        },
        query: {
          status: this.$route.query.status,
          date: deadline.due_date,
        },
      })
    },
    clickRecentAction() {
      return

      $router.push({
        name: 'timeline',
        params: { slug: $route.params.slug },
        query: {
          name: $route.query.name,
          status: $route.query.status,
          sub_status: $route.query.sub_status,
          lang: $route.query.lang,
        },
      })
    },
  },
}
</script>
