<template>
  <div class="flex h-screen overflow-hidden">
    <!-- Sidebar -->
    <Sidebar :sidebar-open="sidebarOpen" @close-sidebar="sidebarOpen = false" />

    <!-- Content area -->
    <div
      class="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden pb-40"
    >
      <div class="absolute z-10 flex w-full justify-between bg-white py-4 px-4">
        <div
          class="flex w-full cursor-pointer flex-row items-center"
          @click="
            $router.push({
              name: 'chat-group',
              params: {
                slug: $route.params.slug,
              },
              query: {
                name: $route.query.name,
                status: $route.query.status,
                sub_status: $route.query.sub_status,
                lang: $route.query.lang,
              },
            })
          "
        >
          <svg
            class="h-7 w-7 fill-current text-slate-500"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z"
            />
          </svg>
          <h1
            v-if="activeChat.title"
            class="pl-5 text-2xl font-bold text-slate-800"
          >
            {{ activeChat.title }}
          </h1>
          <h1
            v-else-if="!isLoading"
            class="pl-5 text-2xl font-bold text-slate-800"
          >
            {{ $t('chat.discussion.title') }}
          </h1>
          <p
            v-if="activeChat.closed_at !== null && !isLoading"
            class="self-center pl-5 font-bold text-red-500"
          >
            {{ $t('chat.group.closed') }}
          </p>
        </div>
        <div class="flex w-full justify-end space-x-4">
          <Avatars
            :extract-user="true"
            :parties="chatMembers"
            tooltip-position="bottom"
          />
          <div>
            <EditMenu align="right" class="relative ml-2 inline-flex">
              <li>
                <span
                  class="flex cursor-pointer py-1 px-3 text-sm font-medium text-slate-600 hover:text-slate-800"
                  @click.stop="conversationInfo()"
                >
                  {{ $t('about') }}
                </span>
              </li>
            </EditMenu>
          </div>
        </div>
      </div>
      <main>
        <div class="max-w-9xl mx-auto w-full px-4 py-8 pt-20 sm:px-6 lg:px-8">
          <section v-if="chatData.length > 0">
            <ChatDiscussionItem
              v-for="chat in chatData"
              :key="chat.id"
              :chat="chat"
              :me="activeUser"
              class="mt-2 rounded-lg bg-white px-6 shadow-sm"
              @reject="rejectOffer"
              @accept="acceptOfferAction"
            />
          </section>
          <DualRingLoader v-else-if="isLoading" />
          <NoData v-else class="self-center" />
          <div
            v-if="activeChat.closed_at === null"
            class="fixed bottom-0 right-1/4 z-10 mr-10"
          >
            <div class="mb-10 flex w-full justify-end">
              <FloatingNewChat
                :me="activeUser"
                @new-chat="newChat"
                @make-offer="makeOffer"
              />
            </div>
          </div>
        </div>
      </main>
    </div>

    <ModalChatInfo
      :is-open="infoModalOpen"
      :active-chat="activeChat"
      :active-user="activeUser"
      @closed="infoModalOpen = false"
      @close-chat="closeChatAPI"
      @show-media="mediaModalOpen = true"
    />

    <ModalChatMedia
      :is-open="mediaModalOpen"
      :documents="allChatRoomDocuments"
      @closed="mediaModalOpen = false"
    />

    <ModalMakeOffer
      :is-open="makeOfferModalOpen"
      :chat-members="chatMembers"
      @empty-member="emptyMember('selectOfferTo')"
      @closed="makeOfferModalOpen = false"
      @save="createOffer"
    />

    <ModalRejectOffer
      :id="activeChat.id"
      :is-open="rejectOfferModalOpen"
      :title="$t('chat.discussion.rejectOffer')"
      @reject="rejectOfferAction"
      @closed="rejectOfferModalOpen = false"
    />

    <ModalNewChat
      :is-open="chatModalOpen"
      :date="formatDateDay(new Date())"
      @closed="chatModalOpen = false"
      @new-chat="createChat"
      @edit-chat="updateChat"
      @delete-chat="deleteChat"
    />

    <RightSidebar
      :sidebar-open="sidebarOpen"
      @close-sidebar="sidebarOpen = false"
    />
  </div>
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import globalMixin from '@/mixins/global.js'
import chatMixin from '@/mixins/chat.js'

import Sidebar from '@/partials/Sidebar.vue'
import RightSidebar from '@/partials/RightSidebar.vue'
import Avatars from '@/components/Avatars.vue'
import FloatingNewChat from '@/components/FloatingNewChat.vue'
import ModalMakeOffer from '@/components/ModalMakeOffer.vue'
import ModalRejectOffer from '@/components/ModalRejectOffer.vue'
import ModalNewChat from '@/components/ModalNewChat.vue'
import ModalChatInfo from '@/components/ModalChatInfo.vue'
import ModalChatMedia from '@/components/ModalChatMedia.vue'
import ChatDiscussionItem from '@/components/ChatDiscussionItem.vue'
import EditMenu from '@/components/DropdownEditMenu.vue'
import NoData from '@/components/NoData.vue'
import DualRingLoader from '@/components/DualRingLoader.vue'

export default {
  name: 'CaseDetailPage',
  components: {
    Sidebar,
    RightSidebar,
    Avatars,
    FloatingNewChat,
    ModalMakeOffer,
    ModalRejectOffer,
    ModalNewChat,
    ModalChatInfo,
    ModalChatMedia,
    ChatDiscussionItem,
    EditMenu,
    NoData,
    DualRingLoader,
  },
  mixins: [globalMixin, chatMixin],
  setup() {
    const { t, n } = useI18n()
    const store = useStore()
    const toast = useToast()
    const sidebarOpen = ref(false)

    return {
      t,
      n,
      store,
      toast,
      sidebarOpen,
    }
  },
  data: () => ({
    makeOfferModalOpen: false,
    rejectOfferModalOpen: false,
    chatModalOpen: false,
    infoModalOpen: false,
    mediaModalOpen: false,
  }),
  mounted() {
    this.activeUser = this.store.getters['auth/activeUser']
    this.getDetailRoom()
    this.getAllDocumentRoom()
    this.getAllChat()
  },
  updated() {
    window.scrollTo(0, document.body.scrollHeight)
  },
  methods: {
    conversationInfo() {
      this.infoModalOpen = true
    },
    newChat() {
      this.chatModalOpen = true
    },
    makeOffer() {
      this.makeOfferModalOpen = true
    },
    rejectOffer(events) {
      this.rejectOfferModalOpen = true
      this.activeChat = events
    },
    rejectOfferAction(event) {
      this.chatId = event.id
      this.reason = event.reason
      this.accept = false
      this.offerActionAPI()
      this.rejectOfferModalOpen = false
    },
    acceptOfferAction(event) {
      this.chatId = event.id
      this.reason = ''
      this.accept = true
      this.offerActionAPI()
    },
    saveOffer() {
      console.log('save offer')
    },
    createOffer(events) {
      this.createChatAPI(events)
    },
    createChat(events) {
      this.createChatAPI(events)
    },
    updateChat() {
      console.log('update chat')
    },
    deleteChat() {
      console.log('delet chat')
    },
  },
}
</script>
