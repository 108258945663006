<template>
  <div class="-m-1.5 flex flex-wrap items-center">
    <div class="m-1.5">
      <Form
        ref="form"
        v-slot="{ errors }"
        :validation-schema="newDocumentSchema"
        @submit="confirm"
      >
        <ModalBasic
          id="document-info-modal"
          :modal-open="isOpen"
          :show-border-header="false"
          title="Provide Document"
          @close-modal="close"
        >
          <div class="space-y-5 px-5 pb-5">
            <div v-if="activeAgenda.document_file_type" class="-mt-5 text-sm">
              {{ activeAgenda.document_file_type.name }}
            </div>
            <div
              v-if="activeAgenda.owner"
              class="flex items-center justify-start space-x-3"
            >
              <div>
                <UserIcon />
              </div>
              <div class="flex items-center space-x-2">
                <div class="space-x-2">
                  <span>
                    Request by {{ activeAgenda.owner.first_name }}
                    {{ activeAgenda.owner.last_name }}
                  </span>
                </div>
                <div
                  v-if="activeAgenda.owner.role.name"
                  class="flex justify-start"
                >
                  <p
                    class="rounded-lg bg-purple-100 px-2 text-center text-xs font-semibold text-purple-600"
                  >
                    {{ activeAgenda.owner.role.name }}
                  </p>
                </div>
              </div>
            </div>
            <div class="flex items-center justify-start space-x-3">
              <div>
                <PaperIcon />
              </div>
              <div>
                {{ activeAgenda.description }}
              </div>
            </div>
            <div class="space-y-5">
              <div>
                <label class="mb-1 block text-xs font-bold" for="title">
                  Filename
                </label>
                <Field v-slot="{ field }" v-model="name" name="name">
                  <input
                    id="name"
                    v-bind="field"
                    class="form-input w-full p-2"
                    type="text"
                    required
                    :placeholder="
                      $t('document.modalCreateFile.fileNamePlaceholder')
                    "
                  />
                </Field>
                <div v-if="errors.name !== undefined" class="flex w-full">
                  <span class="text-sm text-red-500">
                    {{ $t(errors.name) }}
                  </span>
                </div>
              </div>
              <div>
                <label class="mb-1 block text-xs font-bold" for="files">
                  {{ $t('forms.uploadFile') }}
                </label>
                <div class="flex w-full items-center justify-center">
                  <Field v-model="attachment" name="attachment">
                    <label
                      for="files"
                      class="flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-gray-300 bg-gray-50 hover:bg-gray-100"
                    >
                      <div
                        class="flex flex-col items-center justify-center py-2"
                      >
                        <svg
                          aria-hidden="true"
                          class="mb-3 h-10 w-10 text-gray-400"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                          ></path>
                        </svg>
                        <p class="text-sm text-gray-500">
                          {{ $t('forms.dragAndDrop') }}
                        </p>
                        <p class="text-sm text-gray-500">
                          {{ $t('forms.or') }}
                        </p>
                        <p class="text-sm font-semibold text-primary">
                          {{ $t('forms.browseFiles') }}
                        </p>
                      </div>
                      <input
                        id="files"
                        type="file"
                        class="hidden"
                        @change="fileInput"
                      />
                      <span v-if="attachment">{{ attachment.name }}</span>
                    </label>
                  </Field>
                </div>
                <div
                  v-if="errors.attachment !== undefined"
                  class="flex w-full pb-4"
                >
                  <span class="pl-3 text-sm text-red-500">
                    {{ $t(errors.attachment) }}
                  </span>
                </div>
              </div>
            </div>
            <div
              v-if="activeAgenda.owner && me.id !== activeAgenda.owner.id"
              class="flex flex-col space-y-2"
            >
              <button
                class="btn-sm hover:bg-primary-600 w-full whitespace-nowrap rounded-lg bg-primary p-3 text-white"
                type="submit"
              >
                {{ $t('calendar.modalInfo.provideDocument') }}
              </button>
            </div>
          </div>
        </ModalBasic>
      </Form>
    </div>
  </div>
</template>
<script>
import globalMixin from '@/mixins/global.js'
import ModalBasic from '@/components/ModalBasic.vue'
import PaperIcon from '@/assets/svg/paper-small.svg'
import UserIcon from '@/assets/svg/user-small.svg'

import { Form, Field } from 'vee-validate'
import * as Yup from 'yup'

export default {
  name: 'ModalProvideDocumentComponent',
  components: {
    ModalBasic,
    PaperIcon,
    UserIcon,
    Form,
    Field,
  },
  mixins: [globalMixin],
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    activeAgenda: {
      type: Object,
      required: true,
    },
  },
  emits: ['closed', 'confirm'],
  data: () => ({
    attachment: null,
    name: '',
    me: null,
    newDocumentSchema: Yup.object().shape({
      name: Yup.string()
        .typeError('validation.enterFileName')
        .required('validation.enterFileName'),
      attachment: Yup.mixed().required('validation.attachmentRequired'),
    }),
  }),
  created() {
    this.me = this.$store.getters['auth/activeUser']
  },
  methods: {
    close() {
      this.$emit('closed')
    },
    confirm() {
      this.$emit('confirm', {
        file_name: this.name,
        attachment: this.attachment,
        id: this.activeAgenda.id,
      })
    },
    fileInput(event) {
      this.attachment = event.target.files[0]
    },
  },
}
</script>
