<template>
  <div class="-m-1.5 flex flex-wrap items-center">
    <div class="m-1.5">
      <ModalBasic
        id="newChatModal"
        :modal-open="isOpen"
        :show-border-header="false"
        :title="$t('chat.group.newChat')"
        @close-modal="close"
      >
        <Form
          ref="form"
          v-slot="{ errors }"
          :validation-schema="newChatRoomSchema"
          @submit="saveChatRoom"
        >
          <div class="px-5">
            <div class="space-y-3">
              <div>
                <label class="mb-1 block text-xs font-bold" for="name">
                  {{ $t('chat.group.groupName') }}
                </label>
                <Field v-slot="{ field }" v-model="title" name="title">
                  <input
                    id="title"
                    v-bind="field"
                    class="form-input w-full p-2"
                    type="text"
                    required
                    :placeholder="$t('chat.group.groupName')"
                  />
                </Field>
                <div v-if="errors.title !== undefined" class="flex w-full">
                  <span class="text-sm text-red-500">
                    {{ $t(errors.title) }}
                  </span>
                </div>
              </div>
              <div>
                <label class="mb-1 block text-xs font-bold" for="shareWith">
                  {{ $t('chat.group.members') }}
                </label>
                <VueMultiselect
                  v-model="partyId"
                  :options="shareWith.filter((item) => item.id !== me.id)"
                  :multiple="true"
                  :close-on-select="true"
                  :placeholder="$t('validation.selectMember')"
                  label="name"
                  track-by="id"
                  :allow-empty="false"
                >
                </VueMultiselect>
              </div>
            </div>
          </div>
          <div class="py-4">
            <div class="flex flex-wrap justify-center space-x-2 px-4">
              <button
                type="submit"
                class="btn-sm hover:bg-primary-600 w-full whitespace-nowrap rounded-lg bg-primary p-3 text-white"
              >
                {{ $t('modal.save') }}
              </button>
            </div>
          </div>
        </Form>
      </ModalBasic>
    </div>
  </div>
</template>
<script>
import ModalBasic from '@/components/ModalBasic.vue'
import { Form, Field } from 'vee-validate'
import * as Yup from 'yup'

export default {
  name: 'ModalNewChatRoomComponent',
  components: {
    ModalBasic,
    Form,
    Field,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    shareWith: {
      type: Array,
      default: () => {
        return []
      },
    },
    me: {
      type: Object,
      default: () => {
        return {
          id: '',
        }
      },
    },
  },
  emits: ['closed', 'new-chat-room', 'empty-member'],
  data: () => ({
    title: 'Private Chat',
    partyId: [],
    newChatRoomSchema: Yup.object().shape({
      title: Yup.string()
        .typeError('validation.enterGroupName')
        .required('validation.enterGroupName'),
      // partyId: Yup.array()
      //   .typeError('validation.selectMember')
      //   .required('validation.selectMember'),
    }),
  }),
  watch: {
    isOpen(val) {
      if (!val) this.close()
      else this.$refs.form.resetForm()
    },
  },
  methods: {
    close() {
      this.$refs.form.resetForm()
      this.partyId = []
      this.$emit('closed')
    },
    saveChatRoom() {
      if (this.partyId.length <= 0) return this.$emit('empty-member')
      const payload = {
        title: this.title,
        members: this.partyId.map((item) => item.id),
        case_id: this.$route.params.slug,
      }
      this.$emit('new-chat-room', payload)
    },
  },
}
</script>
