<template>
  <div class="border shadow-sm">
    <div
      v-if="ratingData.created_by"
      class="flex items-center space-x-4 border-b px-3 py-4"
    >
      <ImageLoader
        wrapper-class="h-8 w-8 rounded-full"
        :src="ratingData.created_by.avatar"
        width="40"
        height="40"
        :alternate="ratingData.created_by.first_name"
      />
      <p class="flex flex-col">
        <span class="text-sm font-bold"
          >{{ ratingData.created_by.first_name }}
          {{ ratingData.created_by.last_name }}
        </span>
        <span class="text-xs text-gray-500">
          {{ timeAgo(ratingData.created) }}
        </span>
      </p>
    </div>
    <div class="flex flex-col space-y-4 py-4 px-6">
      <StarRating
        :total-rating="5"
        :value="ratingData.rating"
        :can-hover="false"
        custom-class="h-4 w-4"
      />
      <p class="line-clamp-6 text-justify">{{ ratingData.review }}</p>
    </div>
  </div>
</template>
<script>
import globalMixin from '@/mixins/global.js'

import StarRating from '@/components/StarRating.vue'
import ImageLoader from '@/components/image/ImageLoader.vue'

export default {
  name: 'RatingItemComponent',
  components: {
    StarRating,
    ImageLoader,
  },
  mixins: [globalMixin],
  props: {
    ratingData: {
      type: Object,
      required: true,
    },
  },
}
</script>
