<template>
  <div class="-m-1.5 flex flex-wrap items-center">
    <div class="m-1.5">
      <ModalBasic
        id="notes-modal"
        :modal-open="isOpen"
        :show-header="false"
        @close-modal="close"
      >
        <Form
          ref="form"
          v-slot="{ errors }"
          :validation-schema="newNoteSchema"
          @submit="saveNote"
        >
          <div class="px-5 py-4">
            <div class="flex pr-2">
              <div class="flex w-3/6 justify-start">
                <Field v-slot="{ field }" v-model="title" name="title">
                  <input
                    v-bind="field"
                    autofocus
                    type="text"
                    class="border-none text-xl font-bold focus:border-transparent focus:outline-none focus:ring-0"
                    :placeholder="
                      $t('privateNotebook.forms.placeholders.title')
                    "
                  />
                </Field>
              </div>
              <div class="flex w-3/6 justify-end">
                <PinIcon
                  class="w-4 cursor-pointer"
                  :class="
                    is_pinned
                      ? 'text-gray-600 hover:text-gray-900'
                      : 'text-gray-200 hover:text-gray-600'
                  "
                  @click="is_pinned = !is_pinned"
                />

                <EditMenu
                  v-if="activeNote.id"
                  align="right"
                  class="relative ml-2 inline-flex"
                >
                  <li>
                    <span
                      class="flex cursor-pointer py-1 px-3 text-sm font-medium text-slate-600 hover:text-slate-800"
                      @click="duplicateNote"
                    >
                      {{ $t('privateNotebook.forms.menus.duplicate') }}
                    </span>
                  </li>
                  <li>
                    <span
                      class="flex cursor-pointer py-1 px-3 text-sm font-medium text-rose-500 hover:text-rose-600"
                      @click="deleteNote"
                    >
                      {{ $t('privateNotebook.forms.menus.delete') }}
                    </span>
                  </li>
                </EditMenu>
              </div>
            </div>
            <div v-if="errors.title !== undefined" class="flex w-full pb-4">
              <span class="pl-3 text-sm text-red-500">
                {{ $t(errors.title) }}
              </span>
            </div>
            <div class="flex w-full pb-4">
              <p class="pl-3 text-xs">{{ date }}</p>
            </div>
            <div class="space-y-3">
              <Field v-slot="{ field }" v-model="content" name="content">
                <div class="h-max-full w-full overflow-ellipsis">
                  <textarea
                    v-bind="field"
                    type="text"
                    class="h-52 w-full resize-y border-none text-xs focus:border-transparent focus:outline-none focus:ring-0"
                    :placeholder="
                      $t('privateNotebook.forms.placeholders.content')
                    "
                  >
                  </textarea>
                </div>
              </Field>
              <div v-if="errors.content !== undefined" class="flex w-full pb-4">
                <span class="pl-3 text-sm text-red-500">
                  {{ $t(errors.content) }}
                </span>
              </div>
            </div>
          </div>
          <div class="px-5 py-4">
            <div class="flex flex-wrap justify-end space-x-4">
              <span
                class="cursor-pointer text-xs text-slate-600 hover:text-slate-900"
                @click.stop="close"
              >
                {{ $t('modal.close') }}
              </span>
              <button
                class="text-xs"
                type="submit"
                :class="[
                  errors.title === undefined &&
                  title !== '' &&
                  errors.content === undefined &&
                  content !== ''
                    ? 'text-primary-600 hover:text-primary-900 cursor-pointer'
                    : 'cursor-not-allowed  text-slate-100',
                ]"
              >
                {{ $t('modal.save') }}
              </button>
            </div>
          </div>
        </Form>
      </ModalBasic>
    </div>
  </div>
</template>
<script>
import ModalBasic from './ModalBasic.vue'
import EditMenu from './DropdownEditMenu.vue'
import { Form, Field } from 'vee-validate'
import globalMixin from '@/mixins/global.js'

import PinIcon from '@/assets/svg/pin.svg'

export default {
  name: 'ModalNoteComponent',

  components: {
    Form,
    Field,
    ModalBasic,
    EditMenu,

    PinIcon,
  },
  mixins: [globalMixin],
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    pinned: {
      type: Boolean,
      default: false,
    },
    date: {
      type: String,
      default: '',
    },
    activeNote: {
      type: Object,
      default: () => {
        return {
          title: '',
          content: '',
        }
      },
    },
  },
  emits: ['closed', 'duplicate-note', 'delete-note', 'edit-note', 'new-note'],
  data: () => ({
    is_pinned: false,
    title: '',
    content: '',
  }),
  watch: {
    isOpen(val) {
      this.is_pinned = this.pinned
      this.title = this.activeNote.title
      this.content = this.activeNote.content

      if (!val) this.close()
      else this.$refs.form.resetForm()
    },
  },
  methods: {
    close() {
      this.$refs.form.resetForm()

      this.$emit('closed')
    },
    saveNote(values, { resetForm }) {
      let params = {}
      params.title = this.title
      params.content = this.content
      params.is_pinned = this.is_pinned
      resetForm()
      if (this.activeNote.id) {
        params.id = this.activeNote.id
        this.$emit('edit-note', params)
      } else {
        this.$emit('new-note', params)
      }
    },
    duplicateNote() {
      if (this.activeNote.id) {
        this.$emit('duplicate-note', this.activeNote)
      } else {
        return
      }
    },
    deleteNote() {
      if (this.activeNote.id) {
        this.$emit('delete-note', this.activeNote.id)
      } else {
        return
      }
    },
  },
}
</script>
