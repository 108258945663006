// import { useI18n } from 'vue-i18n'
// import { useToast } from 'vue-toastification'

// const { t, n } = useI18n()
// const toast = useToast()

export default {
  data: () => ({
    caseAreaLoading: false,
    caseListType: 'grid',
    caseTypes: [],
    typeOfPart: '',
    caseAreas: [],
    caseArea: '',
    caseParties: [],
    newCaseData: {},
    casePriorities: [
      {
        id: 'high',
        name: 'High Urgency',
      },
      {
        id: 'medium',
        name: 'Medium Urgency',
      },
      {
        id: 'low',
        name: 'Low Urgency',
      },
    ],
    validPriority: ['high', 'medium', 'low'],
    activeCase: '',
    advocateReview: {},
    mediatorReview: {},
    arbitratorReview: {},
  }),
  computed: {
    lastDeadlineTime() {
      if (this.activeCase.stage === 2) return this.activeCase.advocate_time
      else if (this.activeCase.stage === 3) return this.activeCase.mediator_time
      else if (this.activeCase.stage === 4)
        return this.activeCase.arbitrator_time
      else return null
    },
    lastDeadlineStage() {
      if (this.activeCase.stage === 2) return 'Advocation'
      else if (this.activeCase.stage === 3) return 'Mediation'
      else if (this.activeCase.stage === 4) return 'Arbitration'
      else return null
    },
  },
  methods: {
    async getCaseDetail(getActiveChat = false) {
      this.isLoading = true
      await this.$store
        .dispatch('cases/getCaseDetail', {
          id: this.$route.params.slug,
        })
        .then((response) => {
          this.isLoading = false
          const responseData = response.data.data
          this.activeCase = responseData

          if (
            responseData.stage !== 1 &&
            responseData.stage < 5 &&
            getActiveChat
          ) {
            this.getActiveChat().then(() => {
              this.getAllChat({ type: 'offer', id: this.activeChat.id })
            })
          }
          // if stage is solved
          if (responseData.stage === 5) {
            this.advocateReview =
              typeof responseData.advocate_review !== 'undefined' &&
              responseData.advocate_review !== null
                ? responseData.advocate_review
                : {}
            this.mediatorReview =
              typeof responseData.mediator_review !== 'undefined' &&
              responseData.advocate_review !== null
                ? responseData.mediator_review
                : {}
            this.arbitratorReview =
              typeof responseData.arbitrator_review !== 'undefined' &&
              responseData.advocate_review !== null
                ? responseData.arbitrator_review
                : {}
          }
        })
        .catch((error) => {
          this.isLoading = false
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async getCaseParties() {
      this.isLoading = true
      await this.store
        .dispatch('cases/getCaseParties', {
          case_id: this.$route.params.slug,
        })
        .then((response) => {
          const responseData = response.data.data
          this.caseParties = responseData
        })
        .catch((error) => {
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async getAllAreas() {
      this.caseAreaLoading = true
      await this.store
        .dispatch('cases/getAllAreas', {
          page: 1,
          take: 50,
          order: 'DESC',
        })
        .then((response) => {
          this.caseAreaLoading = false
          const responseData = response.data.data
          this.caseAreas = responseData.data
          if (responseData.data.length > 0)
            this.caseArea = responseData.data[0].id
        })
        .catch((error) => {
          this.caseAreaLoading = false
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async getAllTypes() {
      await this.store
        .dispatch('cases/getAllTypes', {
          page: 1,
          take: 50,
          order: 'DESC',
        })
        .then((response) => {
          const responseData = response.data.data
          this.caseTypes = responseData.data
          if (responseData.data.length > 0)
            this.typeOfPart = responseData.data[0].id
        })
        .catch((error) => {
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async caseAssignAPI(paramsData = {}) {
      this.isLoading = true
      await this.store
        .dispatch('cases/caseAssign', paramsData)
        .then((response) => {
          this.isLoading = false
          this.$store.dispatch('cases/resetActiveCase')
          // this.toast.success(this.t(response.data.message))
          this.toDetailAdmin()
        })
        .catch((error) => {
          console.log(error)
          this.isLoading = false
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async caseDuteDateAPI(paramsData = {}) {
      this.isLoading = true
      await this.store
        .dispatch('cases/caseDuteDate', paramsData)
        .then((response) => {
          this.isLoading = false
          // this.$store.dispatch('cases/resetActiveCase')
          // this.toDetailAdmin()
          this.toast.success(response.data.message)
          this.assignCaseOpen = false
          this.getCaseDetailAdmin()
        })
        .catch((error) => {
          this.assignCaseOpen = true
          this.isLoading = false
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async caseInititateAPI(paramsData = {}) {
      this.isLoading = true
      await this.store
        .dispatch('cases/caseInititate', paramsData)
        .then((response) => {
          this.isLoading = false
          this.toast.success(this.$t('caseStage.successInitiate'))
          this.getCaseDetail(true)
        })
        .catch((error) => {
          this.isLoading = false
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async getCaseDetailAdmin() {
      this.isLoading = true
      await this.store
        .dispatch('cases/getCaseDetail', {
          id: this.$route.params.slug,
        })
        .then((response) => {
          this.isLoading = false
          const responseData = response.data.data
          this.activeCase = responseData

          if (
            responseData.advocate_id === null &&
            responseData.advocate_initiate &&
            responseData.stage <= 2
          ) {
            this.neutral = 'advocate'
          } else if (
            responseData.mediator_id === null &&
            responseData.mediator_initiate &&
            responseData.stage <= 3
          ) {
            this.neutral = 'mediator'
          } else if (
            responseData.arbitrator_id === null &&
            responseData.arbitrator_initiate &&
            responseData.stage <= 4
          ) {
            this.neutral = 'arbitrator'
          }
        })
        .catch((error) => {
          this.isLoading = false
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async removeCase() {
      this.isLoading = true
      await this.store
        .dispatch('cases/removeCase', {
          id: this.$route.params.slug,
        })
        .then((response) => {
          this.isLoading = false
          this.$router.push({
            name: 'all-cases',
            query: { lang: this.$route.query.lang },
          })
        })
        .catch((error) => {
          this.isLoading = false
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async changeCasePriority() {
      this.isLoading = true
      await this.store
        .dispatch('cases/casePriority', {
          id: this.$route.params.slug,
          priority: this.priority,
        })
        .then((response) => {
          this.isLoading = false
          this.toast.success(this.t(response.data.message))
          this.activeCase.priority = this.priority
        })
        .catch((error) => {
          this.isLoading = false
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    priorityClass(priority) {
      switch (priority) {
        case 'high':
          return 'text-red-500'
        case 'medium':
          return 'text-orange-500'
        case 'low':
          return 'text-green-500'
        default:
          return 'text-red-500'
      }
    },
    toDetailAdmin() {
      if (this.activeCase === null) {
        this.$router.push({
          name: 'index',
          query: {
            lang: this.$route.query.lang,
          },
        })
      } else {
        this.$router.push({
          name: 'case-detail-admin',
          params: { slug: this.activeCase },
          query: {
            lang: this.$route.query.lang,
          },
        })
        this.getCaseDetailAdmin()
        this.$store.dispatch('cases/resetActiveCase')
      }
    },
    openDocumentCase(document) {
      window.open(document, '_blank')
    },
    caseInititate() {
      const params = {
        id: this.$route.params.slug,
        stage: this.activeCase.stage + 1,
      }
      this.initiateMediatorModalOpen = false
      this.initiateArbitratorModalOpen = false
      this.caseInititateAPI(params)
    },
    canReviewCase(role) {
      let canReviewCase = false
      if (
        role.toLowerCase() === 'advocate' &&
        this.checkEmptyObject(this.advocateReview)
      ) {
        canReviewCase = true
      } else if (
        role.toLowerCase() === 'mediator' &&
        this.checkEmptyObject(this.mediatorReview)
      ) {
        canReviewCase = true
      } else if (
        role.toLowerCase() === 'arbitrator' &&
        this.checkEmptyObject(this.arbitratorReview)
      ) {
        canReviewCase = true
      }

      return canReviewCase
    },
    getCaseReview(role) {
      let reviewData = { rating: 0, review: '' }
      if (
        role.toLowerCase() === 'advocate' &&
        !this.checkEmptyObject(this.advocateReview)
      ) {
        reviewData = this.advocateReview
      } else if (
        role.toLowerCase() === 'mediator' &&
        !this.checkEmptyObject(this.mediatorReview)
      ) {
        reviewData = this.mediatorReview
      } else if (
        role.toLowerCase() === 'arbitrator' &&
        !this.checkEmptyObject(this.arbitratorReview)
      ) {
        reviewData = this.arbitratorReview
      }

      return reviewData
    },
  },
}
