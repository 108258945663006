import * as Yup from 'yup'
const phoneRegExp = /^[\+]?[(]?[1-9]{3}[)]?[0-9]{3}?[0-9]{4,7}$/

export default {
  data: () => ({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    username: '',
    currentPassword: '',
    showCurrentPassword: false,
    password: '',
    showPassword: false,
    confirmPassword: '',
    showConfirmPassword: false,
    passwordChange: false,
    attachment: null,
    role: {},

    profileData: {},

    updateProfileSchema: Yup.object().shape({
      firstName: Yup.string()
        .typeError('validation.enterFirstName')
        .required('validation.enterFirstName')
        .min(3, 'validation.minimumFirstName')
        .matches(/^[A-z ]+$/, 'validation.alphabetOnly'),
      lastName: Yup.string(),
      phoneNumber: Yup.string()
        .matches(phoneRegExp, 'validation.enterValidPhoneNumber')
        .typeError('validation.enterPhoneNumber')
        .required('validation.enterPhoneNumber'),
      username: Yup.string()
        .typeError('validation.enterUserName')
        .required('validation.enterUserName'),
      email: Yup.string()
        .email('validation.enterValidEmail')
        .typeError('validation.enterValidEmail')
        .required('validation.enterValidEmail'),
    }),
    changePasswordSchema: Yup.object().shape({
      currentPassword: Yup.string()
        .typeError('validation.enterValidPassword')
        .required('validation.enterValidPassword')
        .min(6, 'validation.enterValidPassword'),
      password: Yup.string()
        .typeError('validation.enterValidPassword')
        .required('validation.enterValidPassword')
        .min(6, 'validation.enterValidPassword'),
      confirmPassword: Yup.string()
        .required('validation.enterValidPassword')
        .min(6, 'validation.enterValidPassword')
        .test(
          'passwords-match',
          'validation.passwordMustMatch',
          function (value) {
            return this.parent.password === value
          }
        ),
    }),
  }),
  methods: {
    async getProfile() {
      this.isLoading = true
      await this.store
        .dispatch('profile/getProfile')
        .then((response) => {
          this.isLoading = false
          const responseData = response.data.data
          this.profileData = responseData
          if (!this.checkEmptyObject(this.profileData)) {
            this.firstName = this.profileData.first_name
            this.lastName = this.profileData.last_name || ''
            this.email = this.profileData.email
            this.phoneNumber = this.profileData.phone
            this.username = this.profileData.username
            this.role = this.profileData.role
          }
        })
        .catch((error) => {
          console.log(error)
          this.isLoading = false
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async updateProfileAPI() {
      this.isLoading = true
      const params = {}
      params.first_name = this.firstName
      params.last_name = this.lastName
      params.username = this.username
      params.email = this.email
      params.phone = this.phoneNumber
      if (this.attachment !== null) params.avatar = this.attachment

      await this.store
        .dispatch('profile/updateProfile', params)
        .then((response) => {
          this.isLoading = false
          if (response.data.statusCode === 200) {
            this.toast.success(response.data.message)
            this.store.dispatch('auth/setActiveUser', response.data.data)
            this.getProfile()
          }
        })
        .catch((error) => {
          console.log(error)
          this.isLoading = false
          this.toast.error(this.extractErrorResponse(error))
        })
    },
    async updatePasswordAPI() {
      this.isLoading = true
      await this.store
        .dispatch('profile/updatePassword', {
          old_password: this.currentPassword,
          new_password: this.password,
        })
        .then((response) => {
          this.isLoading = false
          if (response.data.statusCode === 200) {
            this.toast.success(response.data.message)
            this.passwordChange = false
          }
        })
        .catch((error) => {
          console.log(error)
          this.isLoading = false
          this.toast.error(this.extractErrorResponse(error))
        })
    },

    updateProfile() {
      if (!this.isLoading) {
        this.updateProfileAPI()
      }
    },
    updatePassword() {
      if (!this.isLoading) {
        this.updatePasswordAPI()
      }
    },
    fileAvatarChange(event) {
      this.attachment = event.target.files[0]
      this.profileData.avatar = URL.createObjectURL(this.attachment)
    },
  },
}
