<template>
  <ul
    v-if="extractUser"
    class="mb-8 -ml-px flex flex-wrap justify-center -space-x-3 sm:mb-0 sm:justify-start"
  >
    <li v-for="item in parties" :key="item.id">
      <router-link class="block" to="#0">
        <Tooltip
          bg="dark"
          :position="tooltipPosition"
          :custom-image="true"
          :image="item.user.avatar !== null ? item.user.avatar : defaultImage"
        >
          <div
            v-if="item.user.first_name && item.user.last_name"
            class="text-xs text-slate-200"
          >
            {{ item.user.first_name }} {{ item.user.last_name }}
          </div>
          <div v-else class="text-xs text-slate-200">{{ item.user.name }}</div>
        </Tooltip>
      </router-link>
    </li>
  </ul>
  <ul
    v-else
    class="mb-8 -ml-px flex flex-wrap justify-center -space-x-3 sm:mb-0 sm:justify-start"
  >
    <li v-for="item in parties" :key="item.id">
      <router-link class="block" to="#0">
        <Tooltip
          bg="dark"
          :position="tooltipPosition"
          :custom-image="true"
          :image="item.avatar !== null ? item.avatar : defaultImage"
        >
          <div
            v-if="item.first_name && item.last_name"
            class="text-xs text-slate-200"
          >
            {{ item.first_name }} {{ item.last_name }}
          </div>
          <div v-else class="text-xs text-slate-200">{{ item.name }}</div>
        </Tooltip>
      </router-link>
    </li>
  </ul>
</template>

<script>
import Tooltip from './Tooltip.vue'

export default {
  name: 'AvatarsComponent',
  components: {
    Tooltip,
  },
  inject: ['defaultImage'],
  props: {
    parties: {
      type: Array,
      default: () => {
        return []
      },
    },
    extractUser: {
      type: Boolean,
      default: false,
    },
    tooltipPosition: {
      type: String,
      default: 'top',
    },
  },
}
</script>
