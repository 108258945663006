export default {
  appName: 'InResolve',
  language: {
    id: 'ID',
    en: 'EN',
    pt: 'PT',
    languages: 'Bahasa',
    change: 'Ubah Bahasa',
    bahasa: 'Indonesia',
    english: 'Inggris',
    portuguese: 'Portugis',
  },
  menu: {
    dashboard: 'Dashboard',
    caseStage: 'Tahapan Kasus',
    privateNotebook: 'Buku Catatan Pribadi',
    communication: {
      title: 'Komunikasi',
      chats: 'Obrolan',
    },
    documents: 'Dokumen',
    timeline: 'Linimasa',
    calendar: 'Kalender',
    caseDetails: 'Detail Kasus',
  },
  action: {
    back: 'Kembali',
  },
  about: 'About',
  edit: 'Edit',
  remove: 'Remove',
  forms: {
    uploadFile: 'Upload file',
    dragAndDrop: 'Drag and Drop here',
    or: 'or',
    browseFiles: 'Browse files',
  },
  general: {
    greeting: 'Selamat Datang!',
    hello: 'Halo',
    tnc: 'Syarat & Ketentuan',
    privacyPolicy: 'Kebijakan Pribadi',
    optional: 'opsional',
  },
  info: {
    dataNotFound: 'Data tidak ditemukan',
    loginGreeting: 'Beberapa klik lagi untuk masuk ke akun Anda',
    loginGreetingDetail: 'Kelola akun anda di satu tempat',
    currentVersion: 'Versi Dashboard Saat Ini',
    pageMissing: 'Ups. Halaman ini tidak ditemukan',
    pageMissingInstruction:
      'Anda mungkin salah mengetik alamat atau halaman mungkin telah dipindahkan.',
  },
  confirmation: {
    logout: 'Apakah Anda yakin ingin keluar?',
  },
  button: {
    submit: 'Kirim',
    login: 'Masuk',
    logout: 'Keluar',
    cancel: 'Batal',
    register: 'Daftar',
    sendEmail: 'Kirim Email',
    sendOTP: 'Kirim OTP',
    resetPassword: 'Setel Ulang Kata Sandi',
    forgotPassword: 'Lupa Password?',
    saveChanges: 'Simpan Perubahan',
    connect: 'Hubungkan',
    connected: 'Terhubung',
    backToHome: 'Kembali ke Beranda',
  },
  formInfo: {
    notLogin: 'Anda belum masuk',
    registerSuccess: 'Anda telah berhasil mendaftar',
    registerFailed: 'Registrasi gagal',
    loginSuccess: 'Berhasil Masuk',
    loginFailed: 'Gagal Masuk',
    saveChangesSuccess: 'Perubahan Berhasil Disimpan',
    saveChangesFailed: 'Perubahan Gagal Disimpan',
    loginDesc: 'Masukan email dan kata sandi anda',
    loginCheck: 'Dengan mendaftar, Anda setuju dengan ',
  },
  formLabel: {
    name: 'Nama',
    fullName: 'Nama Lengkap',
    bussinessName: 'Nama Bisnis',
    tenantName: 'Nama Tenant',
    email: 'Email',
    phone: 'Nomor Telepon',
    password: 'Kata Sandi',
    passwordConfirmation: 'Konfirmasi Password',
    oldPassword: 'Kata Sandi Lama',
    newPassword: 'Kata Sandi Baru',
    newPasswordConfirmation: 'Konfirmasi Kata Sandi Baru',
    accessRight: 'Hak Akses',
    pin: 'PIN',
    description: 'Deskripsi',
    website: 'Website',
    province: 'Provinsi',
    city: 'Kota',
    district: 'Kecamatan',
    village: 'Kelurahan',
    postalCode: 'Kode Pos',
    address: 'Alamat',
    select: {
      province: 'Pilih Provinsi',
      city: 'Pilih Kota',
      district: 'Pilih Kecamatan',
      village: 'Pilih Kelurahan',
    },
  },
  formInput: {
    enterName: 'Mohon masukan nama anda',
    enterFullName: 'Mohon masukan nama lengkap anda',
    enterBussinessName: 'Mohon masukan nama bisnis',
    enterTenantName: 'Mohon masukan nama tenant',
    enterDescription: 'Mohon masukan deskripsi',
    enterWebsite: 'Mohon masukan website',
    enterEmail: 'Mohon masukan email anda',
    enterPhone: 'Mohon masukan nomor telepon anda',
    enterPassword: 'Mohon masukan password anda',
    enterPIN: 'Mohon masukan PIN anda',
    enterAddress: 'Mohon masukan alamat anda',
    enterPostalCode: 'Mohon masukan kode pos',
  },
  formError: {
    name: {
      required: 'Nama tidak boleh kosong',
    },
    fullName: {
      required: 'Nama Lengkap tidak boleh kosong',
    },
    bussinessName: {
      required: 'Nama Bisnis tidak boleh kosong',
    },
    tenantName: {
      required: 'Nama Tenant tidak boleh kosong',
    },
    email: {
      required: 'Email tidak boleh kosong',
      validEmail: 'Email harus berupa email yang valid',
    },
    phone: {
      required: 'Nomor Telepon tidak boleh kosong',
    },
    pin: {
      required: 'PIN tidak boleh kosong',
    },
    address: {
      required: 'Alamat tidak boleh kosong',
    },
    postalCode: {
      required: 'Kode Pos tidak boleh kosong',
    },
    oldPassword: {
      required: 'Kata sandi lama tidak boleh kosong',
    },
    newPassword: {
      required: 'Kata sandi baru tidak boleh kosong',
    },
    newPasswordConfirmation: {
      required: 'Konfirmasi kata sandi baru tidak sama',
    },
    password: {
      required: 'Kata sandi tidak boleh kosong',
      minLength: 'Kata sandi minimal 6 karakter',
      alphaNumeric: 'Password only allowed alphanumeric and special characters',
    },
    wrongEmailOrPassword: 'Wrong email or password',
    validEmailAddress: 'This field must be valid email address',
    validPhoneNumber:
      'Please enter valid WhatsApp number, min 10 digit numbers',
    fieldAlphabet: 'must only contain alphabetic characters',
    fieldAlphaNumeric: 'must only contain alphanumeric characters',
    fieldNumeric: 'must only contain numeric characters',
  },
  case: {
    title: 'Pemilihan Kasus',
    newComplaint: 'Keluhan Baru',
    allCases: 'Semua Kasus',
    onPrepare: 'Persiapan',
    onGoing: 'Berlangsung',
    solved: 'Selesai',
    parties: 'Pihak',
  },
  dashboard: {
    title: 'Dashboard',
    description: 'Pintasan cepat ke alat penting Anda',
  },
  caseStage: {
    title: 'Tahapan Kasus',
    description: 'Detail tahapan kasus dan tindakan Anda yang tertunda',
  },
  timeline: {
    title: 'Linimasa',
    description: 'Lihat kegiatan yang dilakukan oleh semua pihak yang terlibat',
    searchPlaceholder: 'Cari linimasa',
  },
  privateNotebook: {
    title: 'Private notebook',
    description: 'Your own private notes',
    searchPlaceholder: 'Search notes',
    buttonAdd: 'New note',
    pinned: 'Pinned',
    allNotes: 'All Notes',
    forms: {
      placeholders: {
        title: 'Title',
        content: 'Take a note..',
      },
      menus: {
        duplicate: 'Duplicate',
        delete: 'Delete Note',
      },
    },
  },
  caseDetail: {
    title: 'Case Details',
    description: 'More detailed information about the case',
    details: {
      caseName: 'Case Name',
      submissionDate: 'Submission Date',
      submittedBy: 'Submitted By',
      currentStage: 'Current Stage',
    },
    parties: {
      title: 'Parties',
      role: 'Role',
      phoneNumber: 'Phone Number',
      email: 'E-mail',
    },
  },
  document: {
    title: 'Documents',
    description: 'Access and manage files regarding the case',
    searchPlaceholder: 'Search documents',
    buttonAdd: 'New',
    newFile: 'New File',
    newFolder: 'New Folder',
    modalCreateFile: {
      title: 'Upload New File',
      titleFolder: 'Create New Folder',
      fileName: 'File name',
      fileNamePlaceholder: 'Settlement',
      shareWith: 'Share with',
      fileType: 'File type',
      uploadFile: 'Upload file',
      createFolder: 'Create folder',
    },
    headerTable: {
      name: 'Name',
      owner: 'Owner',
      type: 'Type',
    },
  },
  calendar: {
    title: 'Calendar',
    monthly: 'Monthly',
    daily: 'Daily',
  },
  modal: {
    close: 'Close',
  },
}
