<template>
  <div class="grow">
    <!-- Panel body -->
    <div class="space-y-4 p-6">
      <h2 class="text-2xl font-bold text-slate-800">
        {{ $t('profile.rating.title') }}
      </h2>
      <!-- <svg
        class="block h-10 w-10 hover:text-yellow-400"
        :class="[value >= i ? 'text-yellow-400' : 'text-gray-400']"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
      >
        <path
          d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z"
        />
      </svg> -->
      <div class="flex items-center space-x-2">
        <svg
          class="block h-5 w-5 text-yellow-400"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path
            d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z"
          />
        </svg>
        <span v-if="role.rating" class="font-bold">
          {{
            Math.round((parseFloat(role.rating) + Number.EPSILON) * 100) / 100
          }}
        </span>
        <span v-else class="font-bold">-</span>
        <span class="text-sm text-gray-500">{{
          $t('profile.rating.totalReviews', { total: metaDataReview.itemCount })
        }}</span>
      </div>
      <div class="space-y-4 py-4">
        <RatingItem
          v-for="review in allReviews"
          :key="review.id"
          :rating-data="review"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import globalMixins from '@/mixins/global.js'
import profileMixins from '@/mixins/profile.js'

import RatingItem from '@/components/RatingItem.vue'

export default {
  name: 'RatingPanel',
  components: {
    RatingItem,
  },
  mixins: [globalMixins, profileMixins],
  setup() {
    const store = useStore()
    const toast = useToast()

    return {
      store,
      toast,
    }
  },
  data: () => ({
    allReviews: [],
    filterDataReview: {
      page: 1,
      take: 50,
      search: '',
      order: 'DESC',
    },
    metaDataReview: {
      page: 1,
      take: 10,
      itemCount: 0,
      pageCount: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    },
  }),
  mounted() {
    this.getAllReview()
    this.getProfile()
  },
  methods: {
    async getAllReview() {
      this.isLoading = true
      await this.store
        .dispatch('review/getAllReview', {
          page: this.filterDataReview.page,
          take: this.filterDataReview.take,
          order: this.filterDataReview.order,
        })
        .then((response) => {
          this.isLoading = false
          const responseData = response.data.data
          if (responseData.data) this.allReviews = responseData.data
          if (responseData.meta) this.metaDataReview = responseData.meta
        })
        .catch((error) => {
          this.isLoading = false
          this.toast.error(this.extractErrorResponse(error))
        })
    },
  },
}
</script>
