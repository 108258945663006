export default {
  appName: 'InResolve',
  noData: 'There is no data available for this time',
  hourAgo: 'hours ago',
  you: '(You)',
  updated: 'Updated',
  language: {
    languages: 'Languages',
    change: 'Change Language',
    id: 'ID',
    en: 'EN',
    pt: 'PT',
    es: 'ES',
    bahasa: 'Indonesia',
    english: 'English',
    portuguese: 'Portuguese',
    spanish: 'Spanish',
  },
  menu: {
    dashboard: 'Dashboard',
    caseStage: 'Case Stage',
    privateNotebook: 'Private Notebook',
    communication: {
      title: 'Communication',
      chats: 'Chats',
    },
    documents: 'Documents',
    timeline: 'Timeline',
    calendar: 'Calendar',
    caseDetails: 'Case Details',
    finalDecision: 'Final Decision',
    user: 'Users',
    cases: {
      title: 'Cases',
      allCase: 'All Case',
      pendingCase: 'Pending Case',
      ongoingCase: 'Ongoing Case',
      settledCase: 'Settled Case',
    },
    inbox: {
      title: 'Inbox',
      allInbox: 'All Inbox',
      unanswered: 'Unanswered',
      answered: 'Answered',
      archived: 'Archived',
    },
    logout: 'Logout',
  },
  action: {
    back: 'Back',
    next: 'Next',
    startODR: 'Start ODR',
  },
  activity: {
    all: 'All activity',
    chat: 'Mail Activity',
    stage: 'Stage Activity',
  },
  about: 'About',
  edit: 'Edit',
  remove: 'Remove',
  forms: {
    uploadFile: 'Upload file',
    dragAndDrop: 'Drag and Drop here',
    or: 'or',
    browseFiles: 'Browse files',
  },
  general: {
    greeting: 'Welcome!',
    hello: 'Hello',
    share: 'Share',
    tnc: 'Terms & Conditions',
    privacyPolicy: 'Privacy Policy',
    optional: 'optional',
    advocate: 'Advocate',
    mediator: 'Mediator',
    arbitrator: 'Arbitrator',
    totalParties: '%{total} Parties',
    totalAttachments: '%{total} Attachments',
    loading: 'Loading....',
  },
  info: {
    dataNotFound: 'Data not found',
    loginGreeting: 'A few more clicks to sign in to your account.',
    loginGreetingDetail: 'Manage your accounts in one place',
    currentVersion: 'Current Version Dashboard',
    pageMissing: 'Oops. This page has gone missing',
    pageMissingInstruction:
      'You may have mistyped the address or the page may have moved.',
  },
  confirmation: {
    logout: 'Are you sure want to logout?',
  },
  button: {
    submit: 'Submit',
    login: 'Login',
    signUp: 'Sign Up',
    logout: 'Logout',
    cancel: 'Cancel',
    register: 'Register',
    sendEmail: 'Send Email',
    sendOTP: 'Send OTP',
    resetPassword: 'Reset Password',
    forgotPassword: 'Forgot Password?',
    loginInstead: 'Login Instead',
    change: 'Change',
    saveChanges: 'Save Changes',
    connect: 'Connect',
    connected: 'Connected',
    backToHome: 'Back to Home',
    delete: 'Delete',
    remove: 'Remove',
    open: 'Open',
    assign: 'Assign',
    review: 'Review',
    resolveTheCase: 'Resolve the case',
    confirm: 'Confirm',
    pick: 'Pick %{role}',
    save: 'Save',
    accept: 'Accept',
    reject: 'Reject',
    shareToThisEmail: 'Share to this email',
  },
  badge: {
    accepted: 'Accepted',
    rejected: 'Rejected',
    pending: 'Pending',
  },
  formInfo: {
    notLogin: 'You are not logged in',
    registerSuccess: 'You have successfully registered',
    registerFailed: 'Registration failed',
    loginSuccess: 'Login Success',
    loginFailed: 'Login Failed',
    saveChangesSuccess: 'Changes Saved Successfully',
    saveChangesFailed: 'Changes Failed to Save',
    loginDesc: 'Enter your email and password',
    loginCheck: 'By signin up, you agree to our',
  },
  formLabel: {
    name: 'Nama',
    firstName: 'First Name',
    lastName: 'Last Name',
    secondName: 'Second Name',
    fullName: 'Full Name',
    bussinessName: 'Bussiness Name',
    email: 'Email Address',
    phone: 'Phone Number',
    currentPassword: 'Current Password',
    password: 'Password',
    passwordConfirmation: 'Confirm Password',
    oldPassword: 'Old Password',
    newPassword: 'New Password',
    newPasswordConfirmation: 'New Password Confirmation',
    accessRight: 'Access Right',
    pin: 'OTP',
    description: 'Description',
    website: 'Website',
    province: 'Province',
    city: 'City',
    district: 'District',
    village: 'Village',
    postalCode: 'Postal Code',
    address: 'Address',
    select: {
      province: 'Select Province',
      city: 'Select City',
      district: 'Select District',
      village: 'Select Village',
    },
    sortBy: 'Sort By :',
  },
  formInput: {
    enterName: 'Please enter your name',
    enterFirstName: 'Please enter your first name',
    enterLastName: 'Please enter your last name',
    enterSecondName: 'Please enter your second name',
    enterFullName: 'Please enter your full name',
    enterBussinessName: 'Please enter your bussiness name',
    enterDescription: 'Please enter desription',
    enterWebsite: 'Please enter website',
    enterEmail: 'Please enter your email',
    enterPhone: 'Please enter your phone number',
    enterPassword: 'Please enter your password',
    enterConfirmPassword: 'Please enter same as your password',
    enterCurrentPassword: 'Please enter your current password',
    enterNewPassword: 'Please enter your new password',
    enterNewConfirmPassword: 'Please enter same as your new password',
    enterPIN: 'Please enter your PIN',
    enterAddress: 'Please enter your address',
    enterPostalCode: 'Please enter postal code',
  },
  formError: {
    name: {
      required: 'Name is required',
    },
    fullName: {
      required: 'Full Name is required',
    },
    bussinessName: {
      required: 'Bussiness Name is required',
    },
    tenantName: {
      required: 'Tenant Name is required',
    },
    email: {
      required: 'Email is required',
      validEmail: 'Email must be valid email address',
    },
    phone: {
      required: 'Phone number is required',
    },
    pin: {
      required: 'PIN is required',
    },
    address: {
      required: 'Address is required',
    },
    postalCode: {
      required: 'Postal Code is required',
    },
    password: {
      required: 'Password is required',
      minLength: 'Password at least 6 characters',
      alphaNumeric: 'Password only allowed alphanumeric and special characters',
    },
    wrongEmailOrPassword: 'Wrong email or password',
    validEmailAddress: 'This field must be valid email address',
    validPhoneNumber:
      'Please enter valid WhatsApp number, min 10 digit numbers',
    fieldAlphabet: 'must only contain alphabetic characters',
    fieldAlphaNumeric: 'must only contain alphanumeric characters',
    fieldNumeric: 'must only contain numeric characters',
  },
  login: {
    title: 'Login',
    already:
      'If you already have an account, please fill the required form below. If you haven`t had an account yet',
    signUp: 'Signup Here',
    enterOTP: 'Enter OTP',
    weSentOTP: 'Enter the code we have sent you via your E-Mail %{email}',
    didNotReceive: 'Didn’t receive the OTP?',
    resendCode: 'Resend Code',
  },
  register: {
    title: 'Sign Up',
    already: 'Already have an account?',
    signIn: 'Sign in',
  },
  forgotPassword: {
    title: 'Forgot Password',
    description: 'Please enter your email address to reset password',
  },
  case: {
    title: 'Case Selection',
    allCases: 'All Cases',
    onPrepare: 'On Prepare',
    onGoing: 'On Going',
    solved: 'Solved',
    parties: 'Parties',
    newComplaint: 'New Complaint',
    applicationForm: 'Application Form',
    advocateSelect: 'Advocate Select',
    reviewApplication: 'Review Application',
    advocateRequirement: 'Advocate Requirement',
    advocateSelection: 'Advocate Selection',
    caseSubmitted: 'Case Submitted',
    highUrgency: 'High Urgency',
    mediumUrgency: 'Medium Urgency',
    lowUrgency: 'Low Urgency',

    dueTime: 'Stage due time',
    setDateTime: 'Set date and time',
  },
  newCase: {
    title: 'New Complaint',
    description: 'Please describe your case by filling the form below',
    caseName: 'Please fill the name of your complaint',
    caseNamePlaceholder: 'Case Name',
    caseFrom: 'From which bank do you want to complaint?',
    caseWhen: 'When was the case happened?',
    caseWhenPlaceholder: 'Time',
    caseHowMuch: 'How much money do you want to claim?',
    caseHowMuchPlaceholder: 'Amount of money',
    bySubmit:
      'By submitting this form, you agree to be contacted regarding your request, and you are confirming you agree to our',
    and: 'and',
    terms: 'Terms of Use',
    privacyPolicy: 'Privacy Policy',
    pleaseCheckAggreement: 'Please check the agreement',
    pleaseSelectAdvocate: 'Please select an Advocate',
    pleaseSelectMediator: 'Please select a Mediator',
    failedGetCurrent: 'Failed to get current case',
    typeOfPart: 'Type Of Part',
    caseArea: 'Case Area',
    caseDescription: 'Please describe your request',
    caseDescriptionPlaceholder: 'Description',
    caseNotification: 'How would you like to recieve notifications?',
    email: 'Through Email',
    system: 'Through System',
    odrDesc: 'ODR Description',
    amountOfProcess: 'Amount of The Process',
    estimatedStartDate: 'Estimated Start Date',
    descriptionOnDemands: 'Description of the demands',
    partOfProcess: 'Part of the Process',
    partOfProcessDesc: 'Summary regarding your case',
    notificationMethod: 'Notificaton Method',

    applicationSummary: 'Application Summary',
    applicationSummaryDesc:
      'Please review your application before submitting them',
  },
  advocateRequirement: {
    title: 'Advocate Requirement',
    description: 'How do you want to pick your advocate?',
    letInResolve: 'Let REDEK pick one for me',
    pickOne: 'I will pick for one',
  },
  advocateSelection: {
    title: 'Advocate Selection',
    description: 'Please pick your advocate for this case',
    searchPlaceholder: 'Search for advocate',
  },
  caseSubmitted: {
    title: 'Case Submitted',
    thanks: 'Thank you!',
    description: 'Your case has been submitted for assignation.',
    description2: "We will inform you when it's ready",
    return: 'Return to case selection',
  },
  mediatorRequirement: {
    title: 'Mediator Requirement',
    description: 'How do you want to pick your mediator?',
    letInResolve: 'Let REDEK pick one for me',
    pickOne: 'I will pick for one',
  },
  mediatorSelection: {
    title: 'Mediator Selection',
    description: 'Please pick your mediator for this case',
    searchPlaceholder: 'Search for mediator',
  },
  mediatorSubmitted: {
    title: 'Mediator Submitted',
    thanks: 'Thank you!',
    description: 'You have submitted your prefered mediator.',
    description2: 'You will be notified when the next stage has been started',
    return: 'Return to Case Dashboard',
  },
  arbitratorRequirement: {
    title: 'Arbitrator Requirement',
    description: 'How do you want to pick your arbitrator?',
    letInResolve: 'Let REDEK pick one for me',
    pickOne: 'I will pick for one',
  },
  arbitratorSelection: {
    title: 'Arbitrator Selection',
    description: 'Please pick your arbitrator for this case',
    searchPlaceholder: 'Search for arbitrator',
  },
  arbitratorSubmitted: {
    title: 'Arbitrator Submitted',
    thanks: 'Thank you!',
    description: 'You have submitted your prefered arbitrator.',
    description2: 'You will be notified when the next stage has been started',
    return: 'Return to Case Dashboard',
  },
  dashboard: {
    title: 'Dashboard',
    description: 'Quick shortcuts to your important tools',
    showTimeline: 'Show timeline',
    startedAt: 'Started at',
    recentActivities: 'Recent Activities',

    downloadPDF: 'Download PDF',
    sharePDF: 'Share PDF',
    semester: 'Semester %{semester}',
  },
  caseStage: {
    title: 'Case Stages',
    description: 'Case stage details and your pending actions',
    pendingOffer: 'Pending offers:',
    actionToDo: 'Action you need to do:',

    onPrepareStage: 'On Prepare',
    onPrepareStageDesc:
      "Your case is still on prepare. We will tell let you know once it's assigned",

    // negotiationStage: 'Negotiation',
    negotiationStage: 'Blind Offer',
    negotiationStageDesc:
      'In this stage you can negotiate with bank in attempt to settle down the case',
    // participateNegotiationChat: 'Participate in negotiation chats',
    participateNegotiationChat: 'Participate in blind offer chats',

    inititateMediation: 'Initiate mediation stage',
    inititateMediationDesc:
      'You will be assisted by our mediator during mediation stage. You can pick your own mediator',
    mediationStage: 'Mediation Stage',
    mediationStageDesc:
      'In this stage you will be assisted by our mediator to identify and assess options and negotiate an agreement to resolve your dispute.',
    participateMediationChat: 'Participate in mediation chats',

    initiateMediationStage: 'Initiate Mediation Stage',
    initiateMediationStageDesc:
      'Please wait to Super Admin aggreement to Mediation stage',

    inititateArbitration: 'Initiate arbitration stage',
    inititateArbitrationDesc:
      'Only arbitrator will be able to decide the final decision. All parties will only be able to submit the required documents during the next stage.',
    arbitratorStage: 'Arbitrator Stage',
    arbitratorStageDesc:
      'In this stage our arbitrator will help both parties to get to the final decision',

    initiateArbitrationStage: 'Initiate Arbitration Stage',
    initiateArbitrationStageDesc:
      'Please wait to Super Admin aggreement to Arbitration stage',

    caseResolved: 'Case Resolved',
    caseResolvedDesc:
      'Your case has been resolved. You can access the final decision on the final decision page. Thank you!',

    resolveTheCase: 'Resolve the case',
    resolve: 'Resolve',
    areYouSureToResolve: 'Resolve the case?',
    resolveCaseDesc: 'All parties will be notified and the case will be closed',

    accessFinalDecision: 'Access Final Decision',
    feelFreeReview: 'Feel free to leave a review for your assistants!',

    finalDecisionResult: 'What is the result of the dispute?',
    resultOverAll: 'Overall Resolution',
    resultEveryGood: 'Everything is Good',
    disputeClosureNote: 'Dispute Closure Notes',

    successInitiate: 'Success Initiate Case Stage',

    giveRating: 'Give Rating',
    review: 'Review',
    writeReview: 'Write your review',
    leaveReview: 'Leave Review',
  },
  notification: {
    title: 'Notification',
    description: 'View notifications of this platform',
    searchPlaceholder: 'Search for notification',

    showAll: 'Show All',
    view: 'View %{type}',
  },
  timeline: {
    title: 'Timeline',
    description: 'View activities done by all parties involved',
    searchPlaceholder: 'Search for timeline',
    recentAction: 'Recent Actions',
    noDeadlines: 'No Deadlines',
    deadlines: 'Deadlines',
    noRecentAction: 'No Recent Actions',
  },
  privateNotebook: {
    title: 'Private notebook',
    description: 'Your own private notes',
    searchPlaceholder: 'Search notes',
    buttonAdd: 'New note',
    pinned: 'Pinned',
    allNotes: 'All Notes',
    forms: {
      placeholders: {
        title: 'Title',
        content: 'Take a note..',
      },
      menus: {
        duplicate: 'Duplicate',
        delete: 'Delete Note',
      },
    },
  },
  caseDetail: {
    title: 'Case Details',
    description: 'More detailed information about the case',
    details: {
      caseName: 'Case Name',
      caseDetails: 'Case Details',
      urgency: 'Urgency',
      submissionDate: 'Submission Date',
      submittedBy: 'Submitted By',
      currentStage: 'Current Stage',
      assignationDocument: 'Assignation Document',
      stageDuedate: '%{stage} Due Date',
    },
    parties: {
      title: 'Parties',
      role: 'Role',
      phoneNumber: 'Phone Number',
      email: 'E-mail',
    },
  },
  folder: {
    title: 'Folders',
    description: 'Access and manage files inside the folder',
  },
  document: {
    title: 'Documents',
    description: 'Access and manage files regarding the case',
    searchPlaceholder: 'Search documents',
    buttonAdd: 'New',
    newFile: 'New File',
    newFolder: 'New Folder',
    modalRemoveFile: {
      title: 'Are you sure to delete this document?',
      description:
        "You won't be able to restore the document if you choose to delete it.",
    },
    modalEditFile: {
      title: 'Edit File',
      titleFolder: 'Edit Folder',
    },
    modalCreateFile: {
      title: 'Upload New File',
      titleFolder: 'Create New Folder',
      fileName: 'File name',
      folderName: 'Folder name',
      fileNamePlaceholder: 'Settlement',
      shareWith: 'Share with',
      fileType: 'File type',
      folder: 'Folder',
      withoutFolder: '-----Outside Folder------',
      uploadFile: 'Upload file',
      updateFile: 'Update file info',
      createFolder: 'Create folder',
      updateFolder: 'Update folder info',
    },
    modalInfo: {
      title: 'Document Info',
      documentName: 'Document Name',
      owner: 'Owner',
      type: 'Type',
      shareWith: 'Share with',
    },
    headerTable: {
      name: 'Name',
      owner: 'Owner',
      type: 'Type',
    },
  },
  calendar: {
    title: 'Calendar',
    newAgenda: 'New Agenda',
    monthly: 'Monthly',
    daily: 'Daily',
    buttonAdd: 'New / Request',
    documentRequest: 'Document Request',
    joinMeeting: 'Join Meeting',
    modalRequestDocument: {
      title: 'Request Document',
      requestTo: 'Request To',
      dueTime: 'Due Time',
      additionalNotes: 'Notes',
      requestAdditional:
        '%{from} has requested additional informations to %{to}',
    },
    modalNewAgenda: {
      title: 'New Agenda',
      formTitle: 'Title',
      agendaType: 'Agenda Type',
      meetingType: 'Meeting Type',
      meetingPlatform: 'Meeting Platform',
      meetingURL: 'Meeting URL',
      meetingTime: 'Date',
      from: 'From',
      to: 'To',
      meetingInvitees: 'Meeting Invitees',
      agenda: 'Agenda',
      otherDetails: 'Other Details',
      otherDetailPlaceholder: 'PIN/Password',
      addEvent: 'Add Event',
      provideURL: 'I will provide the URL',
      inresolveVideoMeet: 'InResolve Video Meeting',
    },
    modalConfirm: {
      title: 'Are you sure you sure to make this agenda?',
      description:
        'Invitees will be notified through the platform and their email. You can edit this agenda later.',
      cancel: 'Cancel',
      confirm: 'Confirm',
    },
    modalInfo: {
      provideDocument: 'Provide document',
      rejectRequest: 'Reject request',
      dueDate: 'Due %{date} (%{totalDay} day)',
      requestBy: 'Request by %{by}',
      requestTo: 'Request to',
      yourRespond: 'Your response',
      reason: 'Reason',
    },
    modalReject: {
      title: 'Reject Request?',
    },
  },
  chat: {
    group: {
      title: 'Chats rooms',
      description: 'Conversations between parties',
      private: 'Private discussion',
      newChat: 'New Chat Room',
      groupName: 'Group Name',
      members: 'Members',

      privateChat: 'Private Chat',
      stageChat: 'Stage Chat',
      closed: 'Closed',
    },
    discussion: {
      title: 'Private discussion',
      info: 'Conversation Info',
      closeChat: 'Close Chat',
      mediaAndDocuments: 'Media and documents',
      accepted: 'Accepted:',
      rejected: 'Rejected:',

      makeOffer: 'Make Offer',
      offerSubject: 'Offer Subject',
      offerSubjectPlaceholder: 'Offer from fintech',
      offerTo: 'Offer To',
      description: 'Description',
      dueTime: 'Due Time',
      rejectOffer: 'Reject Offer?',
    },
  },
  finalDecision: {
    title: 'Final Decision',
    description: 'Access the final decision of the case',
    resolveDate: 'Resolve Date',
    disputeResult: 'Dispute Result',
    partialResolution: 'Partial Resolution',
    notes: 'Notes',
  },
  admin: {
    timeline: {
      title: 'Timeline & Activites',
      description: 'Manually input activities',
      searchPlaceholder: 'Search for activities',
      buttonAdd: 'New Activity',
      modalRemoveTimeline: {
        title: 'Are you sure to delete this user?',
        description:
          'You won’t be able to restore the file if you choose to delete it.',
      },
      modalCreateTimeline: {
        title: 'Manually create an activity',
        editTitle: 'Edit Activity',
        type: 'Activity Type',
        case: 'Activity Case',
        user: 'Activity User',
        activityTitle: 'Title',
        subtitle: 'Subtitle',
        content: 'Content',
        extra: 'Extra',
        save: 'Save',
      },
    },
    user: {
      title: 'Users',
      description: 'Manage all users in the platform',
      searchPlaceholder: 'Search for user',
      buttonAdd: 'New user',
      headerTable: {
        name: 'Name',
        role: 'Role',
        email: 'E-mail',
        action: 'Actions',
      },
      modalRemoveUser: {
        title: 'Are you sure to delete this user?',
        description:
          'You won’t be able to restore the file if you choose to delete it.',
      },
      modalCreateUser: {
        title: 'Register a new user',
        editTitle: 'Edit user',
        role: 'Role',
        username: 'Username',
        firstName: 'First Name',
        secondName: 'Second Name',
        email: 'E-mail',
        password: 'Password',
        phone: 'Phone Number',
        bank: 'Bank',
        confirmPassword: 'Confirm Password',
        save: 'Save',
      },
    },
    dashboard: {
      title: 'Dashboard',
      description: 'Quick shortcuts to your important tools',
      overview: 'Overview',
      pendingCase: 'Pending Case',
      newUser: 'New Users',
    },
    cases: {
      title: {
        all: 'All case',
        onprepare: 'Pending case',
        ongoing: 'Ongoing case',
        solved: 'Settled case',
      },
      description: {
        all: 'Manage all cases in the platform',
        onprepare: 'Manage pending cases in the platform',
        ongoing: 'Manage ongoing cases in the platform',
        solved: 'Manage settled cases in the platform',
      },
      cases: 'Cases',
      searchPlaceholder: 'Search for cases',
      cardTitle: {
        all: 'Total case',
        pending: 'Pending case',
        ongoing: 'Ongoing case',
        settled: 'Settled case',
      },
      area: {
        familia: 'Family',
        laboral: 'Labor',
        publico: 'Public',
        penal: 'Criminal',
      },
    },
  },
  modal: {
    close: 'Close',
    save: 'Save',
    attachment: 'Attachment',
    modalDelete: {
      title: 'Are you sure to delete?',
      description: "You won't be able to restore if you choose to delete it.",
    },
  },
  validation: {
    // Login
    enterValidEmail: 'Please enter a valid email',
    isNotValidEmail: '%{email} is not a valid email',
    enterValidPassword: 'Please enter a password. Minimum 6 characters',

    // Register
    enterFirstName: 'Please enter the first name',
    minimumFirstName: 'Minimum 3 characters',
    alphabetOnly: 'Please enter alphabet only',
    enterPhoneNumber: 'Please enter the phone number',
    enterValidPhoneNumber: 'Please enter the valid phone number',
    passwordMustMatch: 'Passwords must match',

    // Activity
    enterActivityTitle: 'Please enter the activity title',

    // Notes
    enterNoteTitle: 'Please enter your note title',
    enterNoteContent: 'Please enter your note content',

    // Solve Case
    selectResultSolveCase: 'Please select the result',
    enterNote: 'Please enter your note',
    attachmentRequired: 'Attachment is required',

    // Admin User
    selectRole: 'Please select the role',
    selectBank: 'Please select the bank',
    enterUserName: 'Please enter the username',

    // members
    selectMember: 'Please select member',
    selectInvitees: 'Please select invitees',
    selectRequestTo: 'Please select request to',
    selectOfferTo: 'Please select offer to',
    selectShareWith: 'Please select share with',

    // Chat room
    enterGroupName: 'Please enter the group name',

    // Document
    enterFileName: 'Please enter the file name',
    enterFolderName: 'Please enter the folder name',

    // Make Offer
    enterSubject: 'Please enter the subject',
    enterDescription: 'Please enter a description',
    enterAmount: 'Please enter amount',

    // New Agenda
    enterAgendaTitle: 'Please enter the agenda title',
    enterMeetingURL: 'Please enter the meeting URL',
    enterAgenda: 'Please enter the agenda',
    selectMeetingPlatform: 'Please select meeting platform',
    selectMeetingType: 'Please select meeting type',
    selectAgendaType: 'Please select agenda type',
    enterCorrectURL: 'Please enter the correct url',
    selectTime: 'Select time',
    selectTimeFirst: 'Please select the time from and to',
    selectTimeInvalid: 'The Time from cannot be greater than equal time to',

    // review
    pleaseInsertRating: 'Please choose star rating first',

    // reject
    pleaseDescribeReason: 'Please describe the reason why',
    pleaseDescribeEmail:
      'Please enter the email for share data (separated by comma)',

    enterCaseName: 'Please enter a case name',
    enterCaseDescription: 'Please enter a case description',
    minimumCaseName: 'Minimum 5 characters',
    enterCaseAmount: 'Please enter case amount',
    minimumCaseAmount: 'Amount must be greater than equal 100 USD',
  },
  options: {
    ordering: {
      dateAsc: 'Date Ascending',
      dateDesc: 'Date Descending',
    },
  },
  profile: {
    title: 'Profile',
    editProfile: 'Edit Profile',
    editProfileDesc: 'Manage informations regarding your REDEK profile',
    terms: 'Terms and Conditions',
    yourRating: 'Your Rating and Reviews',
    signOut: 'Sign Out',
    maximumSize: 'Maximum size',
    fileType: 'File type',
    uploadPicture: 'Upload Picture',

    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    username: 'Username',
    phoneNumber: 'Phone Number',

    rating: {
      title: 'Your rating and reviews',
      totalReviews: '(%{total} reviews)',
    },
  },
  message: {
    success: {
      case: {
        priority: 'Success change case priority',
      },
    },
    failed: {
      calendar: {
        is_already_exist: 'Agenda is already exist',
      },
      auth: {
        emailExpired: 'Email for sent OTP is expired, please login again',
        fillOTP: 'Please fill the OTP code',
      },
    },
  },
  report: {
    inresolveCaseSemester: 'InResolve Case Report %{year} Semester %{semester}',
  },
}
