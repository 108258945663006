import * as Yup from 'yup'

const phoneRegExp = /^[\+]?[(]?[1-9]{3}[)]?[0-9]{3}?[0-9]{4,7}$/

export default {
  inject: ['dayjs'],
  data: () => ({
    appName: 'InResolve',

    userAgent: '',
    activeUser: {
      id: null,
      created: null,
      modified: null,
      full_name: null,
      email: null,
      phone_number: null,
      avatar: null,
      is_verified: true,
      institute: null,
      institute_slug: null,
      role: null,
      role_position: null,
    },

    userMenus: {},
    userFeatures: {},

    maintenanceToPage: false,
    isAuthenticated: false,

    showModalMaintenance: false,
    showModalComingSoon: false,
    showModalLogout: false,

    multiSelectClasses: {
      container:
        'relative mx-auto w-full h-11 flex items-center justify-end box-border cursor-pointer border border-gray-200 rounded-md bg-white text-sm leading-snug outline-none',
      containerDisabled: 'cursor-default bg-gray-100',
      containerOpen: 'rounded-b-none',
      containerOpenTop: 'rounded-t-none',
      containerActive: 'ring ring-primary ring-opacity-30',
      search:
        'w-full absolute rounded-md inset-0 outline-none focus:ring-0 appearance-none box-border border-0 text-sm bg-white',
      placeholder:
        'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 text-gray-400',
      clearIcon:
        'bg-multiselect-remove bg-center bg-no-repeat w-2.5 h-4 py-px box-content inline-block',
      option:
        'flex items-center justify-start box-border text-left cursor-pointer text-sm leading-snug py-2 px-3',
      optionPointed: 'text-gray-800 bg-gray-100',
      optionSelected: 'text-white bg-primary',
      optionDisabled: 'text-gray-300 cursor-not-allowed',
      noOptions: 'py-2 px-3 text-gray-600 bg-white text-left',
      noResults: 'py-2 px-3 text-gray-600 bg-white text-left',
    },
    isLoading: false,
    roleCode: '',
    userId: '',

    newActivitySchema: Yup.object().shape({
      title: Yup.string()
        .typeError('validation.enterActivityTitle')
        .required('validation.enterActivityTitle'),
    }),
    newNoteSchema: Yup.object().shape({
      title: Yup.string()
        .typeError('validation.enterNoteTitle')
        .required('validation.enterNoteTitle'),
      content: Yup.string()
        .typeError('validation.enterNoteContent')
        .required('validation.enterNoteContent'),
    }),
    resolveCaseSchema: Yup.object().shape({
      result: Yup.string()
        .typeError('validation.selectResultSolveCase')
        .required('validation.selectResultSolveCase'),
      notes: Yup.string()
        .typeError('validation.enterNote')
        .required('validation.enterNote'),
      attachment: Yup.mixed().required('validation.attachmentRequired'),
    }),
    newCaseSchema: Yup.object().shape({
      caseName: Yup.string()
        .typeError('validation.enterCaseName')
        .required('validation.enterCaseName')
        .min(5, 'validation.minimumCaseName'),
      caseDescription: Yup.string()
        .typeError('validation.enterCaseDescription')
        .required('validation.enterCaseDescription')
        .min(5, 'validation.minimumCaseName'),
      caseAmount: Yup.number()
        .typeError('validation.enterCaseAmount')
        .required('validation.enterCaseAmount')
        .test(
          'checkMinimumAmount',
          'validation.minimumCaseAmount',
          (value) => value >= 100
        ),
    }),
    loginSchema: Yup.object().shape({
      email: Yup.string()
        .email('validation.enterValidEmail')
        .typeError('validation.enterValidEmail')
        .required('validation.enterValidEmail'),
      password: Yup.string()
        .typeError('validation.enterValidPassword')
        .required('validation.enterValidPassword')
        .min(6, 'validation.enterValidPassword'),
    }),
    registerSchema: Yup.object().shape({
      firstName: Yup.string()
        .typeError('validation.enterFirstName')
        .required('validation.enterFirstName')
        .min(3, 'validation.minimumFirstName')
        .matches(/^[A-z ]+$/, 'validation.alphabetOnly'),
      secondName: Yup.string(),
      phoneNumber: Yup.string()
        .matches(phoneRegExp, 'validation.enterValidPhoneNumber')
        .typeError('validation.enterPhoneNumber')
        .required('validation.enterPhoneNumber'),
      email: Yup.string()
        .email('validation.enterValidEmail')
        .typeError('validation.enterValidEmail')
        .required('validation.enterValidEmail'),
      password: Yup.string()
        .typeError('validation.enterValidPassword')
        .required('validation.enterValidPassword')
        .min(6, 'validation.enterValidPassword'),
      confirmPassword: Yup.string()
        .required('validation.enterValidPassword')
        .min(6, 'validation.enterValidPassword')
        .test(
          'passwords-match',
          'validation.passwordMustMatch',
          function (value) {
            return this.parent.password === value
          }
        ),
    }),
    adminRegisterSchema: Yup.object().shape({
      isUpdate: Yup.boolean(),
      role_id: Yup.string().when('isUpdate', {
        is: false,
        then: Yup.string()
          .typeError('validation.selectRole')
          .required('validation.selectRole'),
      }),
      bank_id: Yup.string().when('role_id', {
        is: 'e10a2333-5ba6-42ee-8960-481f1a469409',
        then: Yup.string()
          .typeError('validation.selectBank')
          .required('validation.selectBank'),
      }),
      username: Yup.string()
        .typeError('validation.enterUserName')
        .required('validation.enterUserName')
        .min(3, 'validation.minimumFirstName'),
      first_name: Yup.string()
        .typeError('validation.enterFirstName')
        .required('validation.enterFirstName')
        .min(3, 'validation.minimumFirstName')
        .matches(/^[A-z ]+$/, 'validation.alphabetOnly'),
      // last_name: Yup.string(),
      email: Yup.string()
        .email('validation.enterValidEmail')
        .typeError('validation.enterValidEmail')
        .required('validation.enterValidEmail'),
      phone: Yup.string()
        .typeError('validation.enterPhoneNumber')
        .required('validation.enterPhoneNumber'),
      password: Yup.string().when('isUpdate', {
        is: false,
        then: Yup.string()
          .typeError('validation.enterValidPassword')
          .required('validation.enterValidPassword')
          .min(6, 'validation.enterValidPassword'),
      }),
    }),

    orderOptions: [
      {
        value: 'ASC',
        label: 'options.ordering.dateAsc',
      },
      {
        value: 'DESC',
        label: 'options.ordering.dateDesc',
      },
    ],
  }),
  computed: {
    isLoggedIn: {
      get() {
        return this.$store.getters['auth/isUserLoggedIn']
      },
      set(userLogggedIn) {
        return this.$store.dispatch('auth/updateLoginState', userLogggedIn)
      },
    },
    isUnderMaintenance: {
      get() {
        // return this.underMaintenance
        return this.$store.getters['home/underMaintenanceGetter']
      },
      set(maintenance) {
        return this.$store.dispatch('home/setUnderMaintenance', maintenance)
      },
    },
    loggedinUser() {
      return this.$store.getters['auth/activeUser']
    },
    currentTimeline() {
      return this.$store.getters['timeline/currentTimeline']
    },
  },
  mounted() {
    this.userAgent = navigator.userAgent
  },
  methods: {
    checkIsAuthenticated() {
      const accessToken = localStorage.getItem('accessToken') || null

      if (accessToken !== null) {
        this.$router.push({
          name: 'index',
          params: { slug: this.$route.params.slug },
          query: { lang: this.$route.query.lang },
        })
      }
    },
    checkMaintenance() {
      if (this.isUnderMaintenance) {
        if (this.maintenanceToPage) this.$router.push({ name: 'maintenance' })
        else this.$emit('showModalMaintenance', this.isUnderMaintenance)
      }
    },
    isPhoneNumber: function (evt) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (charCode != 43 && charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    mergeText(text1, text2) {
      return text1 + ' ' + text2
    },
    persentage(price, discount) {
      return Math.floor(((price - discount) / price) * 100)
    },
    imageUrlAlternate(event) {
      crossOriginIsolated.log('called imageUrlAlternate')
      event.target.src = '@/assets/image/icon.png'
    },
    imageUrlAlternateBox(event) {
      event.target.src = '@/assets/image/icon.png'
    },
    formatDateDay(
      date,
      options = {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      }
    ) {
      return new Date(date).toLocaleDateString('en-US', options)
    },
    hoursMinutesFromTwoDates(date1, date2 = null) {
      const localStartTime = new Date(date1)
      let localEndTime = new Date()
      if (date2 !== null) {
        localEndTime = new Date(date2)
      }

      const diffTime = Math.abs(localStartTime - localEndTime)

      var hours = diffTime / 36e5
      if (hours > 0) return Math.round(hours)
    },
    timeAgo(time) {
      return this.dayjs(time).fromNow()
    },
    extractDate(date, type = 'MMM') {
      return this.dayjs(date).format(type)
    },
    diffDateFromNow(date, type = 'day') {
      const dateTo = this.dayjs(this.toDatetime(new Date(), false))
      const dateFrom = this.dayjs(date)

      return dateFrom.diff(dateTo, type)
    },
    estimatedTimeCase() {
      return this.dayjs().add(2, 'day')
    },
    dateStillActive(endDate, startToday = true, startDate = '') {
      if (startToday) startDate = new Date()
      endDate = new Date(endDate)
      return endDate > startDate
    },
    dateDiffDays(endDate, startToday = true, startDate = '') {
      if (startToday) startDate = new Date()
      endDate = new Date(endDate)
      const diffTime = endDate - startDate
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
      return diffDays
    },
    diffPrice(price, discountedPrice) {
      return discountedPrice - price
    },
    hoursMinutesFromDate(date) {
      const localDateTime = this.dayjs(date).format('HH:mm')
      return localDateTime
    },
    formattedDate(value, dateFormat = 'DD MMMM YYYY') {
      if (!value) return ''
      const formattedDate = this.dayjs(value).format(dateFormat)
      return formattedDate
    },
    getDateRange(totalDay = 30) {
      const curr = new Date()
      const dateList = []
      dateList.push(
        new Date(curr.setDate(curr.getDate())).toISOString().slice(0, 10)
      )

      for (let i = 1; i <= totalDay; i++) {
        const first = curr.getDate() + 1
        const day = new Date(curr.setDate(first)).toISOString().slice(0, 10)
        dateList.push(day)
      }
      return dateList
    },
    getRangeInteger(start, end, length = end - start) {
      return Array.from({ length }, (_, i) => start + i)
    },
    timeEstimatedFromDate(date, addMinutes = 5) {
      const startTime = this.dayjs(date).format('HH:mm')

      const endTime = this.dayjs(date).add(addMinutes, 'm').format('HH:mm')
      return `${startTime} - ${endTime}`
    },
    mergeTwoArrayByKey(arr1, arr2, key = 'id') {
      return arr1
        .filter((elem) => !arr2.find((subElem) => subElem[key] === elem[key]))
        .concat(arr2)
    },
    toDatetime(date, withTime = true) {
      const year = date.getFullYear()
      const month = ('0' + (date.getMonth() + 1)).slice(-2)
      const day = ('0' + date.getDate()).slice(-2)
      const hours = ('0' + date.getHours()).slice(-2)
      const minutes = ('0' + date.getMinutes()).slice(-2)
      const seconds = ('0' + date.getSeconds()).slice(-2)

      const dateOnly = year + '-' + month + '-' + day
      if (withTime) {
        return dateOnly + ' ' + hours + ':' + minutes + ':' + seconds
      }
      return dateOnly
    },
    arrayToString(array, key) {
      return array.map((item) => item[key]).join(', ')
    },
    onCopy(e) {
      const messageAlert = {
        show: true,
        message: e.text + ' ' + this.$t('copy_to_clipboard_success'),
        type: 'default',
        position: 'center',
      }
      this.$emit('showToast', messageAlert)
    },
    onError(e) {
      const messageAlert = {
        show: true,
        message: e.text + ' ' + this.$t('copy_to_clipboard_failed'),
        type: 'error',
        position: 'center',
      }
      this.$emit('showToast', messageAlert)
    },
    getIndexString(stringRaw, splitParam = ' ', index = 0) {
      const stringArr = stringRaw.split(splitParam)
      return stringArr[index]
    },
    extractErrorResponse(error) {
      if (error.response) {
        if (error.response.data.message.detail) {
          if (Array.isArray(error.response.data.message.detail))
            return error.response.data.message.detail[0]
          else return error.response.data.message.detail
        }
      }
      // return error.response.statusText
      return error.response.statusText
    },
    userCodes(withoutUser = false) {
      const userRoles = [
        'USER',
        'BANK_LAWYER',
        'REDEK_ADVOCATE',
        'REDEK_MEDIATOR',
        'REDEK_ARBITRATOR',
      ]
      if (withoutUser) {
        userRoles.shift()
        userRoles.shift()
      }
      return userRoles
    },
    checkUserAccess(type = 'USER', checkUser = false, isRegister = false) {
      const loggedinUser = this.$store.getters['auth/activeUser']
      if (loggedinUser) {
        const userCodes = this.userCodes()
        const roleCode = loggedinUser.role.code.toUpperCase()
        this.roleCode = roleCode
        this.userId = loggedinUser.id
        if (
          userCodes.includes(roleCode) &&
          (!userCodes.includes(type) || checkUser)
        ) {
          this.$router.push({
            name: 'index',
            params: { slug: this.$route.params.slug },
            query: { lang: this.$route.query.lang },
          })
        }
        if (
          roleCode === 'REDEK_ADMIN' &&
          (type !== 'REDEK_ADMIN' || checkUser)
        ) {
          this.$router.push({
            name: 'admin-dashboard',
            params: { slug: this.$route.params.slug },
            query: { lang: this.$route.query.lang },
          })
        }
        if (roleCode === 'REDEK_CS' && (type !== 'REDEK_CS' || checkUser)) {
          this.$router.push({
            name: 'all-cs-chat',
            params: { slug: this.$route.params.slug },
            query: { lang: this.$route.query.lang },
          })
        }
      } else {
        if (isRegister) {
          this.$router.push({
            name: 'register',
            params: { slug: this.$route.params.slug },
            query: { lang: this.$route.query.lang },
          })
        } else {
          this.$router.push({
            name: 'login',
            params: { slug: this.$route.params.slug },
            query: { lang: this.$route.query.lang },
          })
        }
      }
    },
    stripAndLower(str) {
      return str.toLowerCase().replace(/\s/g, '')
    },
    caseStatusFilter(status) {
      switch (status) {
        case 'onprepare':
          return 'On prepare'
        case 'ongoing':
          return 'Ongoing'
        case 'solved':
          return 'Solved'
        default:
          return ''
      }
    },
    toCaseDetail(item, isAdmin = false) {
      if (isAdmin) {
        this.$router.push({
          name: 'case-detail-admin',
          params: { slug: item.id },
          query: {
            name: item.name,
            status: this.stripAndLower(item.status),
            sub_status: '',
            lang: this.$route.query.lang,
          },
        })
      } else {
        this.$router.push({
          name: 'case-detail',
          params: { slug: item.id },
          query: {
            name: item.name,
            status: this.stripAndLower(item.status),
            sub_status: '',
            lang: this.$route.query.lang,
          },
        })
      }
    },
    setStatusByName(name) {
      switch (name.toUpperCase()) {
        case 'TOTAL':
          return 'all'
        case 'ON PREPARE':
          return 'pending'
        case 'ONGOING':
          return 'ongoing'
        case 'SOLVED':
          return 'settled'
        default:
          return 'all'
      }
    },
    checkEmptyObject(obj) {
      // because Object.keys(new Date()).length === 0;
      // we have to do some additional check
      return (
        obj && // 👈 null and undefined check
        Object.keys(obj).length === 0 &&
        Object.getPrototypeOf(obj) === Object.prototype
      )
    },
    getPercentage(val, sumVal) {
      let percentage = 0
      if (isNaN(val) && isNaN(sumVal)) {
        return percentage
      } else {
        percentage = Math.round((val / sumVal) * 100)
        if (isNaN(percentage)) return 0
        else return percentage
      }
    },
    sumObject(obj) {
      var sum = 0
      for (var el in obj) {
        if (obj.hasOwnProperty(el)) {
          sum += parseFloat(obj[el])
        }
      }
      return sum
    },
    emptyMember(type = 'selectMember') {
      if (typeof this.toast !== 'undefined' && typeof this.t !== 'undefined') {
        this.toast.error(this.t(`validation.${type}`))
      }
    },
    extractFilename(dispotition, defaultName) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      const matches = filenameRegex.exec(dispotition)
      let filename = defaultName
      if (matches && matches[1]) {
        filename = matches[1].replace(/['"]/g, '')
      }
      return filename
    },
  },
}
